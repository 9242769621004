import PreloginRouter from "./routers/PreloginRouter";
import DashRouter from "./routers/DashRouter";
import { LoginContext } from "./contexts/LoginContext/LoginContext";
import { useContext } from "react";

function App() {
  const { state: loginState } = useContext(LoginContext);
  const { loginSuccess } = loginState;

  // If staff has not logged in already, hand over to Prelogin Router
  if (loginSuccess === false) return <PreloginRouter />;

  // If they already logged in, let the App Router take over
  if (loginSuccess === true) return <DashRouter />;

  // Do not show anything until loginSuccess resolves to either true or false
  // This is to prevent glitches in initial load
  return null;
}

export default App;
