import { createContext } from "react";
import { ArrearsInvoice } from "../../../utils/data-classes/ArrearsInvoice";
import { currencies } from "../../../contexts/OrderContext/OrderContext";
import { Provider } from "../../../utils/data-classes/Provider";

const PLACEHOLDER_INVOICE = new ArrearsInvoice({
  invoiceId: -1,
  periodStartedOnMs: 0,
  periodEndedOnMs: 0,
  billing: {
    blid: -1,
    billingAddress1: undefined,
    billingCity: undefined,
    billingCountry: undefined,
    billingEmail: undefined,
    billingInterval: undefined,
    billingName: undefined,
    billingPhone: undefined,
    billingState: undefined,
    billingZip: undefined,
    stripeCustomerKey: undefined,
  },
  createdOnMs: 0,
  currency: currencies["USD"],
  items: {
    devices: {
      shippedToPatient: [],
      shippedToProvider: [],
    },
    substrates: [],
  },
  orid: -1,
  provider: Provider.DEFAULT,
  skus: {},
  total: 0,
  taxTotal: 0,
  token: "",
});

const ArrearsInvoiceContext = createContext<ArrearsInvoice>(PLACEHOLDER_INVOICE);

export default ArrearsInvoiceContext;