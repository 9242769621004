import React from "react";
import s from "./MiniLayout.module.scss";

interface MiniLayoutProps {
  children: React.ReactNode;
}

export default function MiniLayout({ children }: MiniLayoutProps) {
  return <div className={s.miniLayout}>{children}</div>;
}
