import { DateTime } from "luxon";
import React from "react";
import {
  DateRangeContextAction,
  setEarliestTime,
  updateByRange,
} from "../../contexts/DateRangeContext/DateRangeContextActions";
import _styles from "./digest.module.scss";

/**
 * Dispatches date range from 2017-08-01T11:33:00.000Z to now
 *  - meant to get patient appropriate date range [TEMP FIX]
 */
export const dispatchPatientDateRangeToDateRangeContext = (rangeDispatch: React.Dispatch<DateRangeContextAction>) => {
  // Temp fix for to ensure all possible dates included for existing users
  // Existing users have patient createdOn date after they have possible finished a challenge
  const startDT = DateTime.fromISO("2017-08-01T11:33:00.000Z", { zone: "utc" });
  const endDT = DateTime.now();
  dispatchRangeToDateRangeContext(rangeDispatch, startDT, endDT);
};

export const dispatchRangeToDateRangeContext = (
  rangeDispatch: React.Dispatch<DateRangeContextAction>,
  startDT: DateTime,
  endDT: DateTime
) => {
  rangeDispatch(setEarliestTime(startDT));
  rangeDispatch(updateByRange([startDT, endDT]));
};