import { Font, StyleSheet } from "@react-pdf/renderer";

export const COLOR = {
  clinicalGreen: "#0e6c73",
  lightGray: "#707070",
  lighterGray: "#a6a6a6",
  darkGray: "#5e5d5d",
  positivePink: "#fcd1cd",
  white: "#ffffff",
  red: "#ff392e",
};

const UTIL = {
  BORDER: `1px solid ${COLOR.clinicalGreen}`,
  LEFT_PADDING: "10px",
  COMMON_PADDING: "2px 0 2px 10px",
};

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
      fontWeight: 600,
    },
  ],
});

export const shared = StyleSheet.create({
  body: {
    fontFamily: "Montserrat",
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
    fontSize: 10,
    display: "flex",
    flexDirection: "column",
    color: COLOR.darkGray,
  },
  header: {
    padding: UTIL.COMMON_PADDING,
    fontWeight: 600,
    color: COLOR.white,
    backgroundColor: COLOR.clinicalGreen,
    border: "",
  },
  label: {
    fontWeight: 600,
    color: COLOR.darkGray,
  },
  box: {
    border: UTIL.BORDER,
    marginBottom: "15px",
  },
  foodmarbleLogo: {
    height: "100%",
    width: "150px",
  },
  providerLogo: {
    maxHeight: "100%",
    paddingLeft: "0px",
    marginLeft: "0px",
  },
});

export const s = {
  headerPageOne: StyleSheet.create({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "60px",
    },
    title: {
      height: "100%",
      width: "130px",
      right: "5px",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      height: "30px",
      justifyContent: "center",
    },
    border: {
      borderLeft: UTIL.BORDER,
      margin: "2px 5px",
    },
  }),
  headerPageTwo: StyleSheet.create({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "30px",
      marginBottom: "20px",
    },
  }),
  challengeMetaData: StyleSheet.create({
    main: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
    },
    infoBox: {
      ...shared.box,
      display: "flex",
      flexDirection: "row",
      width: "31%",
    },
    infoBoxHead: {
      ...shared.header,
      width: "40%",
    },
    infoBoxData: {
      padding: UTIL.COMMON_PADDING,
      paddingLeft: UTIL.LEFT_PADDING,
      width: "60%",
    },
  }),
  detailsBoxes: StyleSheet.create({
    main: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    box: {
      ...shared.box,
      display: "flex",
      flexDirection: "column",
      width: "48%",
    },
    boxHeader: {
      ...shared.header,
    },
    boxBody: {
      display: "flex",
      flexDirection: "column",
    },
    item: {
      display: "flex",
      flexDirection: "row",
      marginTop: "5px",
      marginBottom: "5px",
    },
    itemLabel: {
      textAlign: "right",
      ...shared.label,
    },
    itemContent: {
      textAlign: "left",
      marginLeft: "5px",
    },
  }),
  summaryTable: StyleSheet.create({
    main: {
      ...shared.box,
      display: "flex",
      flexDirection: "column",
      height: "65px",
    },
    header: {
      ...shared.header,
      display: "flex",
      flexDirection: "row",
      padding: "",
      justifyContent: "space-between",
      marginBottom: "5px",
    },
    headerCell: {
      fontWeight: 600,
      color: COLOR.white,
      backgroundColor: COLOR.clinicalGreen,
      textAlign: "left",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    gas: {
      width: "6%",
      padding: UTIL.COMMON_PADDING,
      fontWeight: 600,
    },
    baseline: {
      width: "12%",
      padding: UTIL.COMMON_PADDING,
      textAlign: "right",
    },
    result: {
      width: "10%",
      padding: UTIL.COMMON_PADDING,
      textAlign: "right",
    },
    interpretation: {
      width: "30%",
      padding: UTIL.COMMON_PADDING,
    },
    criteria: {
      width: "42%",
      padding: UTIL.COMMON_PADDING,
      paddingRight: "2%",
    },
    isPositive: {
      color: COLOR.red,
    },
  }),
  challengeData: StyleSheet.create({
    main: {},
    table: {
      ...shared.box,
      display: "flex",
      flexDirection: "column",
      marginBottom: 0,
    },
    sideHeader: {
      width: "20%",
      fontWeight: 600,
      padding: "4px 0 4px 10px",
    },
    padding: {
      width: "2%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerCell: {
      fontWeight: 600,
      color: COLOR.white,
    },
    cell: {
      minWidth: "5%",
      display: "flex",
      flexDirection: "column",
      padding: "4px 0 4px 0",
      paddingRight: "4px",
      textAlign: "right",
    },
    positive: {
      backgroundColor: COLOR.positivePink,
    },
    desc: {
      color: COLOR.lighterGray,
      fontSize: 8,
      marginTop: 5,
    },
  }),
  testCriteria: StyleSheet.create({
    main: {
      ...shared.box,
      height: "140px",
    },
    body: {
      padding: "2 10 2 10",
    },
    text: {
      marginTop: "10px",
    },
  }),
  notes: StyleSheet.create({
    main: {
      ...shared.box,
      minHeight: "200px",
    },
  }),
  references: StyleSheet.create({
    main: {
      ...shared.box,
      paddingBottom: "10px",
    },
    body: {
      padding: "2 10 2 10",
    },
    referenceItem: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
    },
    index: {
      width: "5%",
    },
    reference: {
      width: "95%",
    },
    text: {
      marginTop: "10px",
    },
  }),
  chart: StyleSheet.create({
    main: {
      ...shared.box,
      padding: "5 5 5 2",
    },
  }),
  pageNumber: StyleSheet.create({
    main: {
      textAlign: "right",
      marginTop: "auto",
    },
    content: {
      textAlign: "right",
    },
  }),
};
