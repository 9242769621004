class Fodmap {
  readonly label: string;
  readonly value: number;

  static LABELS = [
    "Excess Fructose",
    "Lactose",
    "Sorbitol",
    "FOS",
    "GOS",
    "Inulin",
    "Mannitol",
  ];

  constructor(label: string,value: number) {
    this.label = label;
    this.value = value;
  }

  get name(): string {
    if (this.label === "Excess Fructose") return "Fructose";
    return this.label;
  }
}

export default Fodmap;