import { Document } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import InvoicePage from "./InvoicePage";

interface InvoiceDocumentProps {
  invoices: Invoice[];
}

export default function InvoiceDocument({invoices}: InvoiceDocumentProps) {
  return (
    <Document>
      {invoices.map((invoice,i) => <InvoicePage key={i} invoice={invoice} />)}
    </Document>
  );
}