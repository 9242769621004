/**
 * Turns a unit id into a unit string (with reference to `units` and `natural_units` tables)
 */
export const getUnitLabel = (dosable: Dosable): string => {
  const { unitsId, nuLabel } = dosable;
  if (!unitsId) return getUnitsForIOSMistake(dosable);
  if (unitsId === 1) return nuLabel ?? "unit";
  const result = UNITS[unitsId];
  if (!result) throw new TypeError("Invalid unitsId");
  return result;
};

export const createDosageString = (dosable: Dosable) => {
  if (!dosable.unitsId) return createDosageStringForIOSMistake(dosable);
  return createDosageStringWithoutMistake(dosable);
};

/**
 * Simple utility to create dosage strings like "40 gr" or "3 slice"
 */
const createDosageStringWithoutMistake = (dosable: Dosable): string => {
  let unit = getUnitLabel(dosable);
  const amountString = dosable.amount?.toFixed(0) ?? "0";
  return `${amountString} ${unit}`;
};

/**
 * There was an iOS mistake that caused all iOS challenges to have a unitsId of 0. This function is to compensate that mistake.
 */
const createDosageStringForIOSMistake = (dosable: Dosable): string => {
  return `${dosable.amount?.toFixed(0) ?? "0"} ${getUnitsForIOSMistake(dosable)}`;
};

const getUnitsForIOSMistake = ({ label }: Dosable): string => {
  return label && label.toLowerCase() === "lactulose" ? "ml" : "g";
};

interface Dosable {
  unitsId?: number | undefined;
  amount?: number | undefined;
  label?: string | undefined;
  nuLabel?: string | undefined;
}

export const UNITS: Partial<Record<number,string>> = {
  2: "g",
  3: "ml",
  4: "g/100g",
  5: "kcal/100g",
  6: "kJ/100g",
  7: "oz",
  8: "fl. oz",
  9: "natural units",
  10: "tbsp",
  14: "mg",
  12: "tsp",
  13: "cup",
  15: "μg",
  16: "tablet",
  17: "spray",
}