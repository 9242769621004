import s from "../DayToDay.module.scss";
import loadingPng from "../../../../assets/loading.png";

const DEFAULT_LOADING_SPINNER = <img className={s.rotate} src={loadingPng} alt="Loading data" />;

type ChartCanvasProps = {
  id: string;
  canvasRef: React.LegacyRef<HTMLCanvasElement> | undefined;
  fetching: boolean;
} & Omit<React.HTMLProps<HTMLCanvasElement>, 'ref' | 'id' | 'style'>;

const ChartCanvas = ({ id, canvasRef, fetching, ...rest }: ChartCanvasProps) => (
  <div id={id}>
    {fetching && DEFAULT_LOADING_SPINNER}
    <canvas ref={canvasRef} style={{ opacity: fetching ? 0.2 : 1 }} {...rest} />
  </div>
);

export default ChartCanvas;
