import { useContext, useState } from "react";
import useDigest, { Digest } from "../../../hooks/useDigest";
import { Patient } from "../../../utils/data-classes/Patient";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { DateTime } from "luxon";
import { DateRange } from "../../../contexts/DateRangeContext/DateRangeContext";
import { DATA_START_DATE_ISO } from "../../../utils/consts";
import Meal from "../../../utils/data-classes/Meal";
import MedLog from "../../../utils/data-classes/MedLog";
import Ppm from "../../../utils/data-classes/Ppm";
import Symptom from "../../../utils/data-classes/Symptom";
import Sleep from "../../../utils/data-classes/Sleep";
import Stress from "../../../utils/data-classes/Stress";
import { DigestContentFlag } from "../DigestLogic";

export default function useRPMDigest() {
  const [dateRange] = useState<DateRange>(initDateRange());
  const loginState = useContext(LoginContext).state;
  const { preferences } = loginState;
  const canFetch = !!preferences?.provider.hasRPM;
  const { digest } = useDigest(
    {},
    "LOGIN",
    {
      content: content(canFetch),
      range: dateRange,
    }
  );
  const result = createRPMDigest(canFetch ? digest : createEmptyDigest());
  return result;
}

function content(canFetch: boolean): DigestContentFlag[] {
  if (!canFetch) return [];
  return ["challenges", "meals", "meds", "ppms", "sleeps", "stress", "symptoms"];
}

function createEmptyDigest(): Partial<Digest> {
  return {
    ppms: [],
    symptoms: [],
    meds: [],
    meals: [],
    sleeps: [],
    stress: [],
  };
}

function initDateRange(): DateRange {
  return [DateTime.fromISO(DATA_START_DATE_ISO), DateTime.now()];
}

export function createRPMDigest(digest: Partial<Digest>): RPMDigest | undefined {
  if (!digest.meds) return undefined;
  return {
    ppms: digest.ppms ?? [],
    symptoms: digest.symptoms ?? [],
    meds: digest.meds ?? [],
    meals: digest.meals ?? [],
    sleeps: digest.sleeps ?? [],
    stress: digest.stress ?? [],
  };
}

export interface RPMDigest {
  ppms: Ppm[];
  symptoms: Symptom[];
  meds: MedLog[];
  meals: Meal[];
  sleeps: Sleep[];
  stress: Stress[];
}
