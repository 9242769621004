import { ORDERING_ITEMS } from "./BulkOrderContext";
import { OrderingPrices } from "./OrderingPrice";
import DiscountScheme from "./DiscountScheme";
import { OrderingFormFieldValues } from "./Ordering";

export const getSubtotal = (values: OrderingFormFieldValues, pricing: OrderingPrices) => {
  return Object.values(ORDERING_ITEMS).reduce<number>((sum,item) => {
    const quantity = values[item.name]
    const price = pricing[item.name];
    return sum + quantity * price.unitPriceNet();
  },0);
};

export const getTotal = (pricing: OrderingPrices): number => {
  return Object.values(ORDERING_ITEMS).reduce<number>((sum, item) => {
    const price = pricing[item.name];
    return sum + price.totalPriceNet();
  }, 0);
};

export const getExtraDiscount = (values: OrderingFormFieldValues, schemes: { [skuId: number]: DiscountScheme | undefined; }): number => {
  return Object.values(ORDERING_ITEMS).reduce<number>((sum, item) => {
    const quantity = values[item.name];
    const backpayOwed = schemes[item.skuId]?.band(quantity)?.backpayOwed ?? 0;
    return sum + (quantity ? backpayOwed : 0);
  }, 0);
};
