/**
 * This context handles information relating to order details, including device pricing and form data.
 * On a sku being dispatched to it, will fetch relevent pricing for that sku and staff and store the payment totals for the order.
 * This data can then be accessed by a component using the Stripe API to process the payment.
 */

import React, { createContext, useEffect, useReducer } from "react";
import { Provider } from "../../utils/data-classes/Provider";
import { AppError } from "../../utils/utils";
import { PaymentIntent, PaymentMethod } from "@stripe/stripe-js"
import { Staff } from "../../utils/data-classes/Staff";
import { Sku } from "../../hooks/useSkus";

export type ShippingType = "free" | "express";
export type AireType = "medaire2";
export type OrderCountryCode = "US" | "EU" | "IE" | "GB" | "CA" | "AU" | "NZ" | "CH"
export type OrderCurrencyCode = "USD" | "EUR" | "GBP" | "CAD" | "AUD" | "NZD";

export interface LocationDetails {
  firstName: string | undefined,
  lastName: string | undefined,
  email: string | undefined,
  phone: string | undefined,
  address: string | undefined,
  city: string | undefined,
  country: string | undefined,
  countryCode: OrderCountryCode | undefined,
  zipCode: string | undefined,
  stateCode: string | undefined,
}

export interface Order {
  sku: Sku | undefined,
  shipping: ShippingType,
  currency: OrderCurrencyCode | undefined,
  total: number | undefined,
  taxTotal: number | undefined,
}

export interface Price {
  final: number | undefined,
  taxRate: number | undefined,
  taxTotal: number | undefined,
}

export interface LocalPrices {
  itemPrice: Price,
  shipping: Price,
  currency: OrderCurrencyCode | undefined,
  currencySymbol: string | undefined,
}

export enum OrderStage {
  DELIVERY = "DELIVERY",
  BILLING = "BILLING",
  PAYMENT = "PAYMENT",
}

export interface OrderContextState {
  orderComplete: boolean,
  orderStage: OrderStage,
  customerId: number | undefined,
  contactConsent: boolean | undefined,
  localPrices: LocalPrices,
  order: Order,
  delivery: LocationDetails,
  billing: LocationDetails,
  paymentIntent: PaymentIntent | undefined,
  paymentMethod: PaymentMethod | undefined,
  stripeCustomerId: string | undefined,
  sameBillingAsDelivery: boolean,
  error: AppError | undefined,
}

const initialState: OrderContextState = {
  orderComplete: false,
  orderStage: OrderStage.DELIVERY,
  customerId:undefined,
  contactConsent:false,
  localPrices: {
    itemPrice: {
      final: undefined,
      taxRate: undefined,
      taxTotal: undefined,
    },
    shipping: {
      final: undefined,
      taxRate: undefined,
      taxTotal: undefined,
    },
    currency: undefined,
    currencySymbol: undefined
  },
  order: {
    sku: undefined,
    shipping: "free",
    currency: undefined,
    total: undefined,
    taxTotal: undefined,
  },
  delivery: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    countryCode: undefined,
    stateCode: undefined,
    zipCode: undefined,
  },
  billing: {
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    address: undefined,
    city: undefined,
    country: undefined,
    countryCode: undefined,
    stateCode: undefined,
    zipCode: undefined
  },
  paymentIntent: undefined,
  paymentMethod: undefined,
  stripeCustomerId: undefined,
  sameBillingAsDelivery: true,
  error: undefined,
}

export type OrderContextAction =
  | { type: "SET_ORDER_COMPLETE", payload: boolean }
  | { type: "SET_ORDER_STAGE", payload: OrderStage }
  | { type: "SET_CUSTOMER_ID", payload: number }
  | { type: "TOGGLE_CONTACT_CONSENT", payload: boolean }
  | { type: "SET_CONTACT_CONSENT", payload: boolean }
  | { type: "SET_VISITOR_LOCATION", payload: string }
  | { type: "SET_LOCAL_PRICES", payload: LocalPrices }
  | { type: "SET_SHIPPING_COUNTRY", payload: string }
  | { type: "UPDATE_SHIPPING_TYPE", payload: ShippingType }
  | { type: "UPDATE_ORDER_CURRENCY", payload: OrderCurrencyCode|undefined }
  | { type: "RECALCULATE_ORDER_TOTALS", payload: { total: number } }
  | { type: "ADD_AN_AIRE_TO_ORDER", payload: AireType }
  | { type: "SUBTRACT_AN_AIRE_FROM_ORDER", payload: AireType }
  | { type: "ADD_A_FODMAP_TO_ORDER", payload: null }
  | { type: "SUBTRACT_A_FODMAP_FROM_ORDER", payload: null }
  | { type: "RESET_ORDER", payload: null }
  | { type: "UPDATE_ADDRESS_INFO", payload: { shippingForm: LocationDetails, billingForm: LocationDetails } }
  | { type: "SET_PAYMENT_INTENT", payload: PaymentIntent | undefined }
  | { type: "SET_PAYMENT_METHOD", payload: PaymentMethod | undefined }
  | { type: "SET_ORID", payload: number }
  | { type: "SET_BLID", payload: number }
  | { type: "UPDATE_SHIPPING_ADDRESS", payload: LocationDetails }
  | { type: "UPDATE_BILLING_ADDRESS", payload: LocationDetails }
  | { type: "SET_SAME_BILLING_AS_DELIVERY", payload: boolean }
  | { type: "SET_ORDER", payload: Order }
  | { type: "SET_ORDER_TOTAL", payload: number | undefined }
  | { type: "SET_ORDER_TAX_TOTAL", payload: number | undefined }
  | { type: "SET_SKU", payload: Sku }
  | { type: "SET_PAYMENT_DETAILS", payload: { paymentIntent: PaymentIntent|undefined, paymentMethod: PaymentMethod|undefined, stripeCustomerId: string|undefined }}
  | { type: "SET_ERROR", payload: AppError | undefined }

const reducer = (state: OrderContextState, action: OrderContextAction): OrderContextState => {
  switch(action.type) {
    case 'SET_ORDER_COMPLETE':
      return { ...state, orderComplete: action.payload }
    case 'SET_ORDER_STAGE':
      return { ...state, orderStage: action.payload }
    case 'SET_CUSTOMER_ID':
      return { ...state, customerId: action.payload }
    case 'TOGGLE_CONTACT_CONSENT':
      return { ...state, contactConsent: !state.contactConsent }
    case 'SET_CONTACT_CONSENT':
      return { ...state, contactConsent: action.payload }
    case 'SET_LOCAL_PRICES':
      return { ...state, localPrices: action.payload }
    case 'SET_SHIPPING_COUNTRY':
      return { ...state, delivery: { ...state.delivery, country: action.payload }}
    case 'UPDATE_SHIPPING_TYPE':
      return { ...state, order: { ...state.order, shipping: action.payload }}
    case 'UPDATE_ORDER_CURRENCY':
      return { ...state, order: { ...state.order, currency: action.payload }}
    case 'RECALCULATE_ORDER_TOTALS':
      const { total } = action.payload
      return { ...state, order: { ...state.order, total }}
    case 'SET_ORDER':
      return { ...state, order: action.payload }
    case 'SET_ORDER_TOTAL': 
      return { ...state, order: { ...state.order, total: action.payload } }
    case 'SET_ORDER_TAX_TOTAL':
      return { ...state, order: { ...state.order, taxTotal: action.payload } }
    case 'RESET_ORDER':
      return {
        ...state,
        order: {
          ...state.order,
          sku: undefined,
          shipping: "free",
        }
      }
    case 'UPDATE_ADDRESS_INFO':
      const { shippingForm, billingForm } = action.payload
      return { ...state, delivery: shippingForm, billing: billingForm }
    case 'SET_PAYMENT_INTENT':
      return { ...state, paymentIntent: action.payload }
    case 'SET_PAYMENT_METHOD':
      return { ...state, paymentMethod: action.payload }
    case 'UPDATE_SHIPPING_ADDRESS':
      return { ...state, delivery: action.payload}
    case 'UPDATE_BILLING_ADDRESS':
      return { ...state, billing: action.payload }
    case 'SET_SAME_BILLING_AS_DELIVERY':
      return { 
        ...state, 
        sameBillingAsDelivery: action.payload,
        billing: action.payload ? state.delivery : createEmptyLocationDetails()
      }
    case 'SET_SKU':
      return { ...state, order: { ...state.order, sku: action.payload } }
    case 'SET_PAYMENT_DETAILS':
      return { ...state, ...action.payload }
    case 'SET_ERROR':
      return { ...state, error: action.payload }
    default:
      return state
  }
}

interface OrderContextProviderProps {
  children: React.ReactNode,
  staff: Staff,
}

export interface OrderContextType {
  state: OrderContextState, 
  dispatch: React.Dispatch<OrderContextAction>
}

const OrderContext = createContext<OrderContextType>({state: initialState, dispatch: () => {}});

export const OrderContextProvider = ({children,staff}: OrderContextProviderProps) => {
  const [state,dispatch] = useReducer<React.Reducer<OrderContextState,OrderContextAction>>(reducer,initialState);

  /**
   * 1. Update item price on sku being set
   */
  useEffect(() => {
    const { sku } = state.order;
    if (!sku) return;
    fetchLocalPrices(state,staff).then(localPrices => {
      dispatch({ type: "UPDATE_ORDER_CURRENCY", payload: localPrices.currency});
      dispatch({ type: "SET_LOCAL_PRICES", payload: localPrices });
    }).catch((error: AppError|Error) => {
      dispatch({type: "SET_ERROR", payload: error instanceof AppError ? error : new AppError(400,error.message)});
    });
  },[state.order.sku]);

  /**
   * 2. Update order total values on localPrices being set
   */
  useEffect(() => {
    const getTotal = (priceTotal: number|undefined, shippingTotal: number|undefined): number|undefined => {
      if (priceTotal === undefined || shippingTotal === undefined) return undefined;
      const result = priceTotal + shippingTotal;
      return result;
    }

    const { itemPrice, shipping } = state.localPrices;
    
    const orderTotal = getTotal(itemPrice.final,shipping.final);
    const taxTotal = getTotal(itemPrice.taxTotal,shipping.taxTotal);

    dispatch({ type: "SET_ORDER_TOTAL", payload: orderTotal});
    dispatch({ type: "SET_ORDER_TAX_TOTAL", payload: taxTotal ?? 0});
  },[state.localPrices]);

  /**
   * 3. Update shipping prices on shipping type change
   */
  useEffect(() => {
    if (state.order.shipping === "free") {
      dispatch({ type:"SET_LOCAL_PRICES", payload: { ...state.localPrices, shipping: {
        final: 0,
        taxRate: 0,
        taxTotal: 0,
      }}});
    }
  },[state.order.shipping]);

  return (
    <OrderContext.Provider value={{state,dispatch}}>
      { children }
    </OrderContext.Provider>
  );
}

interface Pricing {
  pricingId: number,
  createdOn: Date,
  startedOn: Date,
  endedON: Date,
  prid: number,
  skuId: number,
  currency: string,
  price: number,
  description: string,
  taxAmount: number,
}

const getTaxByCountryCode = (countryCode: string): number => {
  throw new Error("Function not completed")
}

/**
 * @todo need to handle shipping
 */
const fetchLocalPrices = async (state: OrderContextState, staff: Staff): Promise<LocalPrices> => {
  const { order, localPrices} = state;
  const { sku } = order;
  
  if (!sku?.skuId) throw new TypeError("sku is undefined");

  const { itemPrice, currency } = await fetchNewItemPrice(staff,sku.skuId);

  return {
    ...localPrices,
    itemPrice,
    currency: currency.name as OrderCurrencyCode,
    currencySymbol: currency.symbol,
  }
}

const fetchNewItemPrice = async (staff: Staff, skuId: number): Promise<{itemPrice: Price, currency: Currency}> => {
  const searchParams = new URLSearchParams({
    stfid: staff.stfid.toString(),
    skuId: skuId.toString(),
  });

  const res = await fetch(`/api/pricings?${searchParams}`);

  const { pricing, error: errorMsg }: { pricing: Pricing, error: string } = await res.json();

  if (!res.ok) throw new AppError(res.status,errorMsg ?? "An unkown error occurred fetching device pricing");

  const itemPrice = createPrice(pricing);
  const currency = currencies[pricing.currency as OrderCurrencyCode];
  
  return { itemPrice, currency }
}

const createPrice = (pricing: Pricing| undefined): Price => {
  return {
    final: pricing?.price,
    taxRate: 0,
    taxTotal: 0
  };
}

const CurrencySymbols = {
  DOLLAR: String.fromCharCode(36),
  EURO: String.fromCharCode(8364),
  POUND: String.fromCharCode(163),
}

export class Currency {
  readonly name: string;
  readonly symbol: string;
  constructor(name: string,symbol: string) {
    this.name = name;
    this.symbol = symbol;
  }

  parse(val: number | undefined, decimals=true): string | undefined {
    if (val == undefined) return undefined
    return `${this.symbol}${val.toFixed(decimals ? 2 : 0)}`
  }
}

export const currencies: Record<OrderCurrencyCode,Currency> = {
  USD: new Currency("USD",CurrencySymbols.DOLLAR),
  EUR: new Currency("EUR",CurrencySymbols.EURO),
  GBP: new Currency("GBP",CurrencySymbols.POUND),
  CAD: new Currency("CAD",CurrencySymbols.DOLLAR),
  AUD: new Currency("AUD",CurrencySymbols.DOLLAR),
  NZD: new Currency("NZD",CurrencySymbols.DOLLAR),
}

export const countryCodeCurrencyMap: Record<OrderCountryCode,Currency> = {
  US: currencies.USD,
  EU: currencies.EUR,
  IE: currencies.EUR,
  GB: currencies.GBP,
  CA: currencies.GBP,
  AU: currencies.AUD,
  NZ: currencies.NZD,
  CH: currencies.EUR,
}

/**
 * @example 
 * // returns 100
 * extractTaxTotal(123,0.23);
 */
const extractTaxTotal = (total: number, taxRate: number) => {
  return total - 100 * total / (100 + 100*taxRate);
}

const createEmptyLocationDetails = (): LocationDetails => ({
  firstName: undefined,
  lastName: undefined,
  address: undefined,
  city: undefined,
  country: undefined,
  countryCode: undefined,
  email: undefined,
  phone: undefined,
  stateCode: undefined,
  zipCode: undefined,
});

export default OrderContext;