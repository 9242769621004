import { Text, View } from "@react-pdf/renderer";
import { shared, s } from "../../challenge-report-styles";
import { ChallengeFollowonContent, ChallengeFollowonProps } from "./ChallengeFollowonContent";

export function ChallengeFollowonBox(props: ChallengeFollowonProps) {
  return (
    <View style={s.testCriteria.main}>
      <Text style={shared.header}>Further Testing</Text>
      <View style={s.testCriteria.body}>
        <Text style={s.testCriteria.text}>
          <ChallengeFollowonContent {...props} />
        </Text>
      </View>
    </View>
  );
}

