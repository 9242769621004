import React, { useContext } from "react";
import XLSX from "xlsx-js-style";
import { DateTime } from "luxon";
import { ReactComponent as ExcelIcon } from "../../../assets/excel.svg";
import { LoginContext, LoginContextState } from "../../../contexts/LoginContext/LoginContext";
import { logCdAction } from "../../../utils/utils";
import { Patient } from "../../../utils/data-classes/Patient";
import { CdAction } from "../../../utils/CdActions";
import InterfaceButton from "../../../components/buttons/InterfaceButton/InterfaceButton";
import { Digest } from "../../../hooks/useDigest";
import useRPMDigest, { RPMDigest } from "../rpm-excel/useRPMDigest";
import { createRpmWorksheets } from "../rpm-excel/rpm-excel";
import excelFuncs from "./excel-funcs";

interface ExportToExcelButtonProps {
  data: Digest;
  patient: Patient;
  range: [DateTime, DateTime];
}

const WORKSHEET_LABELS = {
  breaths: "Breath Data",
  meals: "Meals Data",
  ingredients: "Ingredients Data",
  symptoms: "Symptoms Data",
  medications: "Medication Data",
};

const ExportToExcelButton: React.FC<ExportToExcelButtonProps> = ({ data: dayToDayDigest, patient, range }) => {
  const { state: loginState } = useContext(LoginContext);
  const rpmDigest = useRPMDigest();

  const getUniqueNotNullish = <T,>(arr: (T | null | undefined)[]): T[] => {
    return Array.from(
      arr.reduce<Set<T>>((acc, cur) => {
        if (cur !== null && cur !== undefined) acc.add(cur);
        return acc;
      }, new Set())
    );
  };

  const isLoadingDigest = (digest: Digest): boolean => {
    const { ppms, challenges, meals, meds, sleeps, stress, symptoms } = digest;
    return !ppms || !challenges || !meals || !meds || !symptoms || !sleeps || !stress;
  };

  const getFilename = (patient: Patient, range: [DateTime, DateTime]): string => {
    return `${patient.name.replace(/ /g, "_")}_${range[0].toISODate()}-${range[1].toISODate()}.xlsx`;
  };

  const getDayToDayArrs = (dayToDayData: Digest) => {
    const { ppms, meals, symptoms, meds } = dayToDayData;
    return {
      breathsArr: excelFuncs.getBreathsArray(ppms ?? []),
      mealsArr: excelFuncs.getMealsArray(meals ?? []),
      ingredientsArr: excelFuncs.getIngredientsArray(meals ?? []),
      symptomsArr: excelFuncs.getSymptomsArray(symptoms ?? []),
      medsArr: excelFuncs.getMedLogsArray(meds ?? []),
    };
  };

  const createWorksheets = (dayToDayData: Digest, rpmData: RPMDigest | undefined) => {
    const { breathsArr, mealsArr, ingredientsArr, symptomsArr, medsArr } = getDayToDayArrs(dayToDayData);
    const sheetOptions = { cellDates: true, dateNF: 'yyyy-mm-dd hh:mm:ss' };

    const result: [string, XLSX.WorkSheet][] = [
      [WORKSHEET_LABELS.breaths, XLSX.utils.aoa_to_sheet(breathsArr, sheetOptions)],
      [WORKSHEET_LABELS.meals, XLSX.utils.aoa_to_sheet(mealsArr, sheetOptions)],
      [WORKSHEET_LABELS.ingredients, XLSX.utils.aoa_to_sheet(ingredientsArr, sheetOptions)],
      [WORKSHEET_LABELS.symptoms, XLSX.utils.aoa_to_sheet(symptomsArr, sheetOptions)],
      [WORKSHEET_LABELS.medications, XLSX.utils.aoa_to_sheet(medsArr, sheetOptions)],
    ];

    if (rpmData) {
      createRpmWorksheets(rpmData).forEach((worksheet, tradeName) => result.push([`RPM (${tradeName})`, worksheet]));
    }

    return result;
  };

  const exportToExcelAndDownload = () => {
    const wb = XLSX.utils.book_new();

    createWorksheets(dayToDayDigest, rpmDigest).forEach(([name, worksheet]) =>
      XLSX.utils.book_append_sheet(wb, worksheet, name)
    );

    XLSX.writeFile(wb, getFilename(patient, range));
  };

  const logDownload = () => {
    const { loginToken, preferences } = loginState;
    const medTradeNames = getUniqueNotNullish(rpmDigest?.meds.map((m) => m.tradeName) ?? []);
    logCdAction(loginToken, CdAction.DOWNLOAD_EXCEL_SUMMARY, [patient.ptid, range[0], range[1]].join(","));
    if (preferences?.provider.hasRPM) {
      logCdAction(loginToken, CdAction.DOWNLOAD_RPM_EXCEL, [patient.ptid, ...medTradeNames].join(","));
    }
  };

  const onClick = () => {
    exportToExcelAndDownload();
    logDownload();
  };

  const isLoading = isLoadingDigest(dayToDayDigest) || !rpmDigest;

  const label = "Export to Excel";
  return (
    <InterfaceButton icon={<ExcelIcon />} onClick={onClick} title={label} loading={isLoading}>
      {label}
    </InterfaceButton>
  );
};

export default ExportToExcelButton;