import DashLayout from "../../layouts/dash/DashLayout";
import s from "./Contact.module.scss";

const CONTACT_EMAIL = "healthcare@foodmarble.com";

const Contact = () => {
  return (
    <DashLayout>
      <div className={s.contact}>
          <p>If you encounter any problems in your use of the Clinical Dashboard please don't hesitate to contact us.</p>
          <div className={s.contactDetails}>
            <table>
              <tbody>
                <tr>
                  <td>Email:</td>
                  <td><a href={`mailto: ${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </DashLayout>
  );
}

export default Contact;
