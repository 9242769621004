import Fodmap from "./Fodmap";
import Meal from "./Meal";

/**
 * Stores data needed for fodmap datapoint marker for charts.js chart
 */
class FodmapDatapoint {
  readonly x: string;
  readonly y: number;
  readonly fodmap: Fodmap;
  readonly meal: Meal;
  constructor(fodmap: Fodmap,meal: Meal) {
    this.x = meal.consumedOn;
    this.y = fodmap.value;
    this.fodmap = fodmap;
    this.meal = meal;
  }
}

export default FodmapDatapoint;