/*
 * Common logic for `/digest` pages
 * Returns (and updates) patient, provider and digest info
 */

import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { DateRangeContext } from "../../contexts/DateRangeContext/DateRangeContext";
import useDigest, { Digest, createDigest } from "../../hooks/useDigest";
import { parseURLSearchParams, AppError } from "../../utils/utils";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { Provider } from "../../utils/data-classes/Provider";
import { Patient } from "../../utils/data-classes/Patient";
import usePatientPreferences, { PatientPreferences } from "../../hooks/usePatientPreferences";

/**
 * @param {DigestContentFlag[]} content
 * @returns {{patient:Patient, preferences: import("../../hooks/usePatientPreferences").PatientPreferences|undefined, provider:Provider, digest: import("../../hooks/useDigest").Digest, error: AppError}}
 */
const DigestLogic = (content: DigestContentFlag[]): DigestLogicReturn => {
  let history = useHistory();
  const { ptid: ptidStr }  = parseURLSearchParams(history.location.search);
  const ptid = parseInt(ptidStr);

  const { state: loginState } = useContext(LoginContext);
  const { loginToken: token, patients, provider } = loginState;

  const patient = patients?.filter((p) => ptid === p.ptid)[0];

  const { state: rangeState } = useContext(DateRangeContext);
  const { range } = rangeState;

  const { digest, error } = useDigest({}, "LOGIN", { range, content });

  const { preferences } = usePatientPreferences(ptid);

  if (!ptid || !token || !provider || !patient) return { 
    patient,
    preferences,
    provider,
    digest: createDigest({}),
    error: !patient ? new AppError(403,"Forbidden") : new AppError(400,"Bad Request")
  };

  return {
    patient,
    preferences,
    provider,
    digest,
    error,
  };
};

export type DigestContentFlag = 'sleeps'|'stress'|'meals'|'symptoms'|'ppms'|'challenges'|'meds';

export interface DigestLogicReturn {
  patient: Patient|undefined;
  preferences: PatientPreferences|undefined;
  provider: Provider|undefined;
  digest: Digest;
  error: AppError|undefined;
}

export default DigestLogic;