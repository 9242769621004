import React, { useContext } from 'react';
import { Document } from '@react-pdf/renderer';
import { symptomsForChallenge } from "../../../utils/data-classes/challenge/challenge-util";
import { ChallengeReport, ChallengeReportProps } from '../ChallengeReport/ChallengeReport';
import { ChallengesContext, useChosenCards } from '../../../pages/digest/challenges/ChallengesContext';
import { LoginContext } from '../../../contexts/LoginContext/LoginContext';

export function ChallengeReportDocument(props: ChallengeReportProps) {
  return (
    <Document>
      <ChallengeReport {...props}/>
    </Document>
)};

export function ChallengeReportCombinedDocument() {
  const { staff, provider } = useContext(LoginContext).state;
  const { fetching, patient, preferences, pdfLogoSrc, followonContent } = useContext(ChallengesContext).state;
  const { challenges, chartHandlers, symptoms } = useChosenCardData();
  const referringClinician = preferences?.referringClinician;

  if (fetching || !patient || !staff || !provider || !pdfLogoSrc || followonContent === undefined) return undefined;

  return (
    <Document>
      {challenges.map((challenge,i) => 
        <ChallengeReport
          key={i}
          challenge={challenge}
          chartHandler={chartHandlers[i]}
          patient={patient}
          staff={staff}
          provider={provider}
          symptoms={symptomsForChallenge(challenge,symptoms)}
          logoSrc={pdfLogoSrc}
          referringClinician={referringClinician}
          followonContent={followonContent}
        />
      )}
    </Document>
  );
}

function useChosenCardData() {
  const cards = useChosenCards();
  return {
    challenges: cards.map((card) => card.challenge),
    chartHandlers: cards.map((card) => card.chartHandler),
    symptoms: cards.map((card) => card.symptoms).flat(),
  };
}