import React, { useEffect, useTransition } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Sidebar from "../../components/sidebar/Sidebar";
import useTranslation from "../../hooks/useTranslation";
import s from "./DashLayout.module.scss";
import InternalDeploymentWarningBanner from "../../components/internal-deployment-warning/InternalDeploymentWarningBanner";

/**
 * @typedef _DashLayoutProps 
 * @property {React.ReactNode} children
 */

/**
 * @typedef {React.HTMLProps<HTMLDivElement> & _DashLayoutProps} DashLayoutProps
 */

/**
 * @param {DashLayoutProps} props
 * @returns 
 */
const DashLayout = ({ children, ...rest }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("en");
  },[]);
  return (
    <div { ...rest } className={`${s.dashlayout} ${rest.className ?? ''}`}>
      <InternalDeploymentWarningBanner />
      <Header />
      <div className={s.content}>
        <Sidebar />
        <div>
          <main>{children}</main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default DashLayout;
