import { DateTime } from "luxon";
import MultiChartRenderer from "../../../../components/charts/MultiChartRenderer";
import Symptom from "../../../../utils/data-classes/Symptom";
import { createDatasetsForPeakSymptoms, createDatasetsForRawSymptoms, createDatasetsForSymptoms } from "../../charts/chart-datasets";
import { isDayToDayAggregateView } from "../../charts/chart-funcs";
import { symptomMultichartOptions } from "../../charts/chart-options";
import s from "../DayToDay.module.scss";

/**
 * @param {Object} props
 * @param {Symptom[]|undefined} props.symptoms 
 * @param {import("../../../../contexts/DateRangeContext/DateRangeContext").DateRange} props.range 
 * @returns
 */
const SymptomMultiChart = ({symptoms,range}) => {
  const dataSetsArray = symptoms ? createDataSetsArray(symptoms,range) : null;
  const options = range && range.length ? symptomMultichartOptions(range) : null
  return <MultiChartRenderer id={s.symptomMultichartCanvasWrapper} dataSetsArray={dataSetsArray} options={options} />
}

/**
 * 
 * @param {Symptom[]} symptoms 
 * @param {[DateTime,DateTime]} range 
 */
const createDataSetsArray = (symptoms,range) => {
  const peakSymptomsDatasets = createDatasetsForPeakSymptoms(symptoms,range);
  const labelOrder = peakSymptomsDatasets.map(d => d.label);
  const symptomsDatasets = isDayToDayAggregateView(range) ? [] : createDatasetsForRawSymptoms(symptoms,labelOrder);
  return [
    ...symptomsDatasets,
    ...peakSymptomsDatasets,
  ]
}

export default SymptomMultiChart;