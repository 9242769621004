/**
 * Component which takes react-compatible svg xml as a string and parses it into a React-pdf - compatible Svg component.
 * Based on this gist: https://gist.github.com/dennemark/5f0f3d7452d9334f9349172db6c40f74
 * Which is a reponse to this React-pdf issue: https://github.com/diegomura/react-pdf/issues/1234
 */

import { useMemo, createElement } from "react";
import { parse } from "svg-parser";
import ReactDOMServer from 'react-dom/server';
import React from "react";

/**
 * 
 * @param {Object} props 
 * @param {React.ReactNode} [props.ReactSvgComponent]
 * @param {string} [props.svgXmlString]
 * @param {React.StyleHTMLAttributes<React.ReactSVG>} [props.style]
 * @returns 
 */
const SvgComponent = ({ReactSvgComponent,svgXmlString,style=null}) => {
  if (!ReactSvgComponent && !svgXmlString) throw new Error('SvgComponent requires a React component or an xml string');
  
  const svgXml = ReactSvgComponent ? ReactDOMServer.renderToString(ReactSvgComponent) : svgXmlString;
  const svgElement = useMemo(() => {
    if (!svgXml || svgXml === "") return <></>;
    const svg = replacePxWithScaledPts(svgXml);
    const parsed = parse(svg);
    return svgToJSXWithRelPositioning(parsed.children[0],null,null,null,style);
  }, [svgXml]);
  
  return <>{svgElement}</>;
};

const supportedStyleProps = [
  "color",
  "dominantBaseline",
  "fill",
  "fillOpacity",
  "fillRule",
  "opacity",
  "stroke",
  "strokeWidth",
  "strokeOpacity",
  "strokeLinecap",
  "strokeDasharray",
  "transform",
  "textAnchor",
  "visibility"
]

function isElementNode(node) {
  return node.type === 'element'
}

function removeLineBreaks(text) {
  if (typeof text === 'string') {

    return text.replace(/(\r\n|\n|\r)/gm, "")
  }
  
  return text;
}

// https://dev.to/qausim/convert-html-inline-styles-to-a-style-object-for-react-components-2cbi
const formatStringToCamelCase = (str) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

const getStyleObjectFromString = (str) => {
  const style = {};
  if (!str) return {};

  str.split(";").forEach((el) => {
    let [property, value] = el.split(":");
    if (!property) return;
    if (property === "cursor") return;
    const formattedProperty = formatStringToCamelCase(property.trim());
    if (supportedStyleProps.includes(formattedProperty)) {
      if(formattedProperty === "strokeDasharray"){
        value = value.replace(/pt/g, "") //dasharray has now px
      }
      style[formattedProperty] = value.trim();
    }
  });

  return style;
};

function handleRelativePositioning(node, parentX, parentY) {
  return {
          x: (Number(node.properties?.x ?? parentX ?? 0)) + Number(node.properties?.dx ?? 0),
          y: (Number(node.properties?.y ?? parentY ?? 0)) + Number(node.properties?.dy ?? 0)
    };
}

function getParentPosition(pos) {
  if (!pos) return 0;
  if (typeof pos === 'string') return Number(pos);
  return pos;
}

function svgToJSXWithRelPositioning(
  node, key, parentX, parentY, style
) {
  if (typeof node === 'string') {
    return removeLineBreaks(node);
  }
  if (!isElementNode(node)) {
    return removeLineBreaks(node.value);
  }
  const elementName = node.tagName;
  if (!elementName) {
    console.log('NO TAG NAME: ', node);
    return null;
  }
  let componentProps;
  if (node.tagName === 'desc' || node.tagName === 'defs') return null;

  if (node.properties !== undefined) {
      if (node.tagName === "text" || node.tagName === "tspan" || node.tagName === "rect") {
        componentProps = handleRelativePositioning(node, parentX, parentY);
        if(node.tagName !== "rect"){
          componentProps = {
            ...componentProps,
            textAnchor: node.properties['text-anchor']
          }
        }else{
            componentProps = {
              ...node.properties,
              ...componentProps,
            }            
        }
      }else{
        componentProps = node.properties;
      }
      
      if (node.properties.style) {
        componentProps = {
          ...componentProps,
          style: getStyleObjectFromString(node.properties.style)
        }
    }
  }
  let children = [];
  if (node.children && node.children.length > 0) {
    children = node.children.map(
      (childNode, i) => 
        svgToJSXWithRelPositioning(
          childNode, key+"-"+i, getParentPosition(node.properties.x), getParentPosition(node.properties.y)
        ) 
    )
  }else{
      children = [""]
    }
  componentProps = {
    ...componentProps, 
    key: key ?? "root", 
    style: !key && style ? style : componentProps.style,
  };
  return createElement(elementName.toUpperCase(), componentProps, children);
}

/**
 * Replace "px" unit with "pt" and scale down values accordingly
 * @param {string} string 
 * @returns
 */
const replacePxWithScaledPts = (string) => {
  const scalingFactor = 72/96 // 1px = 1/96 inch, 1pt = 1/72 inch
  return string.replace(/[0-9]+px/g,(s) => {
    const value = parseInt(s.replace(/px/g,""));
    const scaledValue = Math.round(value * scalingFactor);
    return `${scaledValue}pt`;
  });
}

export default SvgComponent;