import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { LocationDetails } from "../../../contexts/OrderContext/OrderContext";
import { AppError, authFetch } from "../../../utils/utils";
import { Address } from "./AddCard";
import useErrorState from "../../../hooks/useErrorState";

interface UseNewCustomerIdAndClientSecretReturn {
  customerId: string | undefined;
  clientSecret: string | undefined;
  error: AppError | undefined;
}

export default function useNewCustomerIdAndClientSecret(billing: Address|undefined): UseNewCustomerIdAndClientSecretReturn {
  const [customerId, setCustomerId] = useState<string>()
  const [clientSecret, setClientSecret] = useState<string>();
  const [error, setError] = useErrorState();

  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!billing || customerId  || clientSecret || error || !loginToken) return;
    createCustomerAndSetupIntent(loginToken,billing).then(({customerId,clientSecret}) => {
      setCustomerId(customerId);
      setClientSecret(clientSecret);
    }).catch(setError);
  },[billing,loginToken,customerId,clientSecret,error]);

  return { customerId, clientSecret, error }
}

const createCustomerAndSetupIntent = async (token: string, billing: Address): Promise<{customerId: string, clientSecret: string}>  => {
  const customerId = await createCustomer(token,billing);
  const clientSecret = await createSetupIntent(token,customerId);
  return { customerId, clientSecret };
}

type CreateCustomerJSON = 
  | { stripeCustomerKey: string, error: undefined } 
  | { stripeCustomerKey: undefined, error: string }

/**
 * Create customer and payment intent, returning client secret
 */
const createCustomer = async (token: string, billing: Address): Promise<string> => {
  const res = await authFetch(token,"/api/create-stripe-customer-for-staff",{
    method: "POST",
    body: JSON.stringify({
      billing,
    }),
  })
  const { stripeCustomerKey, error }: CreateCustomerJSON  = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return stripeCustomerKey;
}

type CreateSetupIntentJSON = 
  | { clientSecret: string, error: undefined } 
  | { clientSecret: undefined, error: string } 

const createSetupIntent = async (token: string, customerId: string): Promise<string> => {
  const res = await authFetch(token,"/api/create-setup-intent",{
    method: "POST",
    body: JSON.stringify({
      customerId,
    })
  });
  const { clientSecret, error }: CreateSetupIntentJSON = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return clientSecret;
}