/**
 * charts.js chart axes and functions for generating axes based on ranges 
 */

import Chart from "chart.js";
import { DateTime } from "luxon";
import styles from "../_styles";

/**
 * @param {DateTime} startDT 
 * @param {DateTime} endDT
 * @returns {Chart.ChartXAxe}
 */
export const timeSeriesXAxis = (startDT,endDT,displayLabels=true) => {
  let days = endDT.diff(startDT, "days").as("days");
  return {
    type: "time",
    time: {
      unit: days > 1 ? "day" : "hour", // if view is daily, show hours in x axis, otherwise show days
      unitStepSize: days < 28 ? 1 : 7, // 1 unit increment up to 28 at which weekly
    },
    scaleLabel: {
      display: displayLabels,
      fontSize: 14,
      fontColor: styles.chartFontBlack,
    },
    ticks: {
      fontSize: 14,
      min: startDT.toISODate(), // set start of x axis to start of the query (startDT 00:00)
      max: endDT.toISODate(), // set end of the x axis to end of the query (endDT 00:00)
      display: displayLabels,
      beginAtZero: true,
    },
    stacked: true,
  };
}

/**
 * @constant 
 * @type {Chart.ChartYAxe}
 */
export const ppmYAxis = {
  id: "static",
  type: "linear",
  position: "left",
  scaleLabel: {
    display: true,
    labelString: "Gas Concentration (ppm)",
    fontSize: 14,
    fontColor: styles.chartFontBlack,
  },
  ticks: {
    beginAtZero: true,
    fontSize: 14,
    callback: (value) => value,
    suggestedMax: 50,
  },
  gridLines: {
    display: true,
  },
};

/**
 * @constant
 * @type {Chart.ChartYAxe}
 */
export const fodmapYAxis = {
  id: "dynamic",
  type: "linear",
  position: "right",
  stacked: true,
  scaleLabel: {
    display: true,
    labelString: "FODMAP Content (g)",
    fontSize: 14,
    fontColor: styles.chartFontBlack,
  },
  ticks: {
    fontSize: 14,
  },
  gridLines: {
    display: false,
  },
};

/**
 * @constant
 * @type {Chart.ChartYAxe}
 */
export const unstackedHiddenFodmapYAxis = {
  type: "linear",
  position: "right",  
  id: "unstacked-hidden-fodmap-yaxis",
  stacked: false,
  scaleLabel: {
    display: false,
  },
  ticks: {
    display: false,
  },
  gridLines: {
    display: false,
  }
}

/**
 * @constant
 * @type {Chart.ChartYAxe}
 */
export const symptomYAxis = {
  id: "static",
  type: "linear",
  position: "left",
  scaleLabel: {
    display: true,
    labelString: "Symptom Score",
    fontSize: 14,
    fontColor: styles.chartFontBlack,
  },
  ticks: {
    beginAtZero: true,
    max: 10,
    fontSize: 14,
    stepSize: 2.5,
  },
}

/**
 * Blank y-axis consisting of empty string label for padding charts to match width of main multichart
 * @param {string} position 
 * @returns {Chart.ChartYAxe}
 */
export const paddingYAxis = (position) => ({
  position,
  gridLines: {
    display: false
  },
  ticks: {
    callback: () => "",
    padding: 60
  }
});

/**
 * @constant
 * @type {Chart.ChartYAxe}
 */
export const stoolYAxis = {
  id: "static",
  type: "linear",
  position: "left",
  scaleLabel: {
    display: true,
    labelString: "Stool Score",
    fontSize: 14,
    fontColor: styles.chartFontBlack,
  },
  ticks: {
    fontSize: 14,
    min: 1,
    max: 7,
    padding: 9,
    stepSize: 4,
  },
  gridLines: {
    display: true,
  }
}

/**
 * 
 * @typedef YAxisDataLimit   
 * @property {number} limit 
 * @property {string} label
 */

/**
 * 
 * @param {[YAxisDataLimit,YAxisDataLimit]} param0 
 * @param {string} position 
 * @returns {Chart.ChartYAxe}
 */
export const topBottomLabelYAxis = ([topLimit,bottomLimit],position) => ({
  position,
  ticks: {
    callback: (value) => {
      if (value === topLimit.limit) return topLimit.label;
      if (value === bottomLimit.limit) return bottomLimit.label; 
    },
    // beginAtZero: true,
    fontSize: 14,
    min: bottomLimit.limit,
    max: topLimit.limit,
    stepSize: topLimit.limit - bottomLimit.limit,
  },
  gridLines: {
    display: false,
  }
});

/**
 * @param {string} position 
 * @returns {Chart.ChartYAxe}
 */
export const stoolQualityLabelYAxis = (position) => ({
  id: "dynamic",
  type: "linear",
  position: "right",
  scaleLabel: {
    display: true,
    labelString: "Stool Score",
    fontSize: 14,
    fontColor: styles.chartFontBlack,
  },
  ticks: {
    callback: (value) => {
      if (value === 1) return "Soft";
      if (value === 7) return "Hard"; 
    },
    beginAtZero: true,
    fontSize: 14,
    min: 1,
    max: 7,
    padding: 9
  },
  gridLines: {
    display: true,
  }
});