import { Link } from "react-router-dom";
import s from "./Login.module.scss";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import { useForm } from "react-hook-form";
import IFTAInput from "../register/IFTAElements/IFTAInput/IFTAInput";
import useSubmitReducer from "../../hooks/useSubmitReducer";
import { AppError } from "../../utils/utils";
import { useContext, useEffect } from "react";
import { LoginContext, LoginMethod } from "../../contexts/LoginContext/LoginContext";
import { setLoginDetails, setLoginToken } from "../../contexts/LoginContext/LoginContextActions";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";
import InternalDeploymentWarningModal from "../../components/internal-deployment-warning/InternalDeploymentWarningModal";

interface LoginMethodFormFieldValues {
  email: string;
}

export default function Login() {
  return (
    <PreloginLayout>
      <div className={s.login}>
        <LoginMethodForm />
        <PasswordForm />
        <MagicLink />
      </div>
      <InternalDeploymentWarningModal />
    </PreloginLayout>
  )
}

function LoginMethodForm() {
  const { state: { loginDetails }, dispatch: loginDispatch } = useContext(LoginContext);
  const form = useForm<LoginMethodFormFieldValues>({
    defaultValues: {
      email: "",
    }
  });
  const [{processing,error},dispatch] = useSubmitReducer(form);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = form;

  const onSubmit = async ({email}: LoginMethodFormFieldValues) => {
    dispatch({type: "PROCESSING"});
    try {
      const loginMethod = await getLoginMethod({email});
      loginDispatch(setLoginDetails({
        email,
        method: loginMethod,
      }));
      dispatch({type: "COMPLETE"});
    } catch (err) {
      console.error(err);
      dispatch({type: "ERROR", payload: err});
    }
  }

  const email = watch("email");
  useEffect(() => {
    setValue("email", email.toLowerCase());
  },[email]);

  if (!!loginDetails) return <></>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IFTAInput id="email" type="email" label="Email Address" reg={register("email",{required: "Email is required"})} />
      <FormErrorMessage errors={errors} name="email" />
      <button type="submit" disabled={processing}>
        {processing ? "Processing..." : "Next"}
      </button>
      <p className={s.error}>{error?.message ?? ""}</p>
    </form>
  )
}

async function getLoginMethod({email}: LoginMethodFormFieldValues): Promise<LoginMethod> {
  const res = await fetch("/api/login/email",{
    method: "POST",
    body: JSON.stringify({email}),
  });
  const { error, authMethod } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return authMethod;
}

function PasswordForm() {
  const { state: { loginDetails }, dispatch: loginDispatch } = useContext(LoginContext);
  const form = useForm({
    defaultValues: {
      password: "",
    }
  });
  const [{processing,error},dispatch] = useSubmitReducer(form);
  const { register, handleSubmit, formState: { errors } } = form;

  if (!loginDetails || loginDetails.method !== "PASSWORD") return <></>;

  const { email } = loginDetails;

  const onSubmit = async ({password}: {password: string}) => {
    dispatch({type: "PROCESSING"});
    try {
      const token = await login({email,password});
      loginDispatch(setLoginToken(token));
    } catch (err) {
      dispatch({type: "ERROR", payload: loginError(err)});
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IFTAInput id="password" type="password" label="Password" reg={register("password",{required: "Password is required"})} />
      <FormErrorMessage errors={errors} name="password" />
      <button type="submit" disabled={processing}>
        {processing ? "Processing..." : "Log In"}
      </button>
      <p className={s.error}>{error?.message ?? ""}</p>
      <Link to="/forgot-password" className={s.forgotPassword}>
        Forgot Password
      </Link>
    </form>
  );
}

function MagicLink() {
  const { state: { loginDetails } } = useContext(LoginContext);
  if (!loginDetails || loginDetails.method !== "MAGIC LINK") return <></>;
  return (
    <div>
      <p>Login Email has been sent</p>
    </div>
  )
}

const login = async ({email,password}: LoginFormFieldValues) => {
  const res = await fetch("/api/login/password",{
    method: "POST",
    body: JSON.stringify({
      email: email.toLowerCase(),
      password,
    })
  });
  const { token, error }: { token: string; error: undefined } | { token: undefined, error: string } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return token;
}

const loginError = (error: unknown): AppError => {
  if (error instanceof AppError) {
    if (error.code === 401) return new AppError(401,"Incorrect Email and/or Password");
    if (error.code === 500) return new AppError(500,"Communication Error");
  }
  return new AppError(400,"Authorization Failed");
}

interface LoginFormFieldValues {
  email: string;
  password: string;
}