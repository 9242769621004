import React from "react";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import s from "./ForgotPassword.module.scss";
import RecognisedEmail from "./RecognisedEmail";
import { useForm } from "react-hook-form";
import useSubmitReducer from "../../hooks/useSubmitReducer";
import { AppError } from "../../utils/utils";
import IFTAInput from "../register/IFTAElements/IFTAInput/IFTAInput";
import { validationRegEx } from "../../utils/FormValidation";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";
import ErrorP from "../../components/errors/ErrorP/ErrorP";

interface ForgotPasswordFormFieldValues {
  email: string;
}

const ForgotPassword = () => {
  const form = useForm<ForgotPasswordFormFieldValues>({
    defaultValues: {
      email: "",
    }
  })
  const { handleSubmit, register } = form;
  const [state,dispatch] = useSubmitReducer(form);
  const { processing, complete, error } = state;

  const onSubmit = async (data: ForgotPasswordFormFieldValues) => {
    dispatch({type: "PROCESSING"});
    try {
      await requestPasswordResetLink(data.email);
      dispatch({type: "COMPLETE"});
    } catch (err) {
      dispatch({type: "ERROR", payload: err});
    }
  };

  return (
    <PreloginLayout>
      <div className={s.forgotPassword}>
        {complete && <RecognisedEmail />}
        {!complete && (
          <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
            <p>
              Please enter your email so we can send you a link to reset your
              password.
            </p>
            <IFTAInput id="email-input" label="Email address" reg={register("email",{
              required: "Email address is required",
              pattern: {
                message: "Invalid email address",
                value: validationRegEx.email,
              }
            })} />
            <FormErrorMessage errors={form.formState.errors} name="email" />
            <button type="submit" disabled={processing}>{processing ? "Processing..." : "Reset Password"}</button>
            <ErrorP error={error} />
          </form>
        )}
      </div>
    </PreloginLayout>
  );
};

const requestPasswordResetLink = async (email: string) => {
  const res = await fetch("/api/create-pw-reset-key",{
    method: "POST",
    body: JSON.stringify({
      email,
    })
  });
  const { error }: { error: string|undefined} = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return;
}

export default ForgotPassword;
