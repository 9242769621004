import { useContext } from "react";
import s from "../Register.module.scss";
import { ReactComponent as Calendar } from "./Calendar.svg";
import { ReactComponent as HealthInsurance } from "./HealthInsurance.svg";
import { ReactComponent as HSA } from "./HSA.svg";
import { ReactComponent as Refund } from "./Refund.svg";
import { RegistrationContext } from "../../../contexts/RegisterContext/RegisterContext";
import useOrderPriceTotal from "../useOrderPriceTotal";
import useTranslation from "../../../hooks/useTranslation";
import { Trans as _Trans } from "react-i18next";
import { ValidI18nKey } from "../../../hooks/useTranslation";

export default function BeforeYouRegister() {
  const { dispatch } = useContext(RegistrationContext);
  const { t } = useTranslation();
  const orderPriceTotal = useOrderPriceTotal();

  return (
    <div className={s.beforeYouRegister}>
      <h1>{t("beforeYouRegister.title")}</h1>
      <p>{t("beforeYouRegister.intro")}</p>
      <div className={s.beforeInfoItems}>
        <InfoItem icon={<HealthInsurance/>}><Trans i18nKey="beforeYouRegister.insurance" /></InfoItem>
        <InfoItem icon={<HSA/>}><Trans i18nKey="beforeYouRegister.hsa" /></InfoItem>
        <InfoItem icon={<Refund/>}><Trans i18nKey="beforeYouRegister.refund" values={{orderPriceTotal}}/></InfoItem>
        <InfoItem icon={<Calendar/>}><Trans i18nKey="beforeYouRegister.twoWeeks" /></InfoItem>
      </div>
      <button type="submit" onClick={() => dispatch({type: "SET_HAS_VIEWED_CONSENT_FORM", payload: true})}>{t("buttons.next")}</button>
    </div>
  );
}

function InfoItem({icon,children}: {icon: React.ReactNode,children: React.ReactNode}) {
  return (
    <div className={s.beforeInfoItem}>
      <div>{icon}</div>
      <div><p>{children}</p></div>
    </div>
  )
}

const Trans = _Trans<ValidI18nKey>;