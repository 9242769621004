import { Text, View } from "@react-pdf/renderer";
import { Provider } from "../../../../utils/data-classes/Provider";
import { DateTime } from "luxon";
import { ReferringClinician } from "../../../../hooks/usePatientPreferences";
import { s } from "../challenge-report-styles";
import { ChallengeReportProps } from "../ChallengeReport";
import { dateFormat } from "../../../../utils/utils";

export function ChallengeMetaData({ provider, challenge }: ChallengeReportProps): JSX.Element {
  return (
    <View style={s.challengeMetaData.main}>
      <HorizontalInfoBox label="Substrate" data={challenge.label} />
      <HorizontalInfoBox label="Dose" data={challenge.dose} />
      <HorizontalInfoBox label="Date" data={formatDate(provider, challenge.createdOn)} />
    </View>
  );
}

function HorizontalInfoBox({ label, data }: { label: string; data: string }): JSX.Element {
  return (
    <View style={s.challengeMetaData.infoBox}>
      <Text style={s.challengeMetaData.infoBoxHead}>{label}</Text>
      <Text style={s.challengeMetaData.infoBoxData}>{data}</Text>
    </View>
  );
}

function DetailsBox({ label, children }: { label: string; children: React.ReactNode }) {
  return (
    <View style={s.detailsBoxes.box}>
      <Text style={s.detailsBoxes.boxHeader}>{label}</Text>
      <View style={s.detailsBoxes.boxBody}>{children}</View>
    </View>
  );
}

function DetailsBoxItem({ label, data, widthPerc }: { label: string; data: string; widthPerc: number }) {
  return (
    <View style={s.detailsBoxes.item}>
      <Text style={{ width: `${widthPerc}%`, ...s.detailsBoxes.itemLabel }}>{label}:</Text>
      <Text style={{ width: `${100 - widthPerc}%`, ...s.detailsBoxes.itemContent }}>{data}</Text>
    </View>
  );
}

export function PatientDetailsBox({ provider, patient }: ChallengeReportProps) {
  const widthPerc = 17.5;
  return (
    <DetailsBox label="Patient Details">
      <DetailsBoxItem label="Name" data={patient.name} widthPerc={widthPerc} />
      <DetailsBoxItem label="DOB" data={formatDate(provider, patient.dateOfBirth)} widthPerc={widthPerc} />
    </DetailsBox>
  );
}

function formatDate(provider: Provider, isoDate: string | undefined): string {
  if (!isoDate) return "N/A";
  return DateTime.fromISO(isoDate).toFormat(dateFormat(provider).date);
}

function fullName({ salutation, name, surname, postNominal }: Partial<ReferringClinician>): string {
  let result = [salutation,name,surname].filter(s => !!s).join(" ");
  if (postNominal) result += `, ${postNominal}`;
  return result;
}

export function StaffDetailsBox({ staff, provider, referringClinician }: ChallengeReportProps): JSX.Element {
  const widthPerc = 35;
  return (
    <DetailsBox label="Clinician Details">
      { staff && !referringClinician && <DetailsBoxItem label="Ordered by" data={fullName(staff)} widthPerc={widthPerc} /> }
      <DetailsBoxItem label="Practice" data={provider.emailLabel ?? provider.label ?? ""} widthPerc={widthPerc} />
      {referringClinician && (
        <DetailsBoxItem label="Referred by" data={fullName(referringClinician)} widthPerc={widthPerc} />
      )}
    </DetailsBox>
  );
}
