import React, { createContext, useEffect, useReducer } from "react";
import { AppError } from "../utils/utils";
import useOrderHistoryNew, { OrderHistoryItem } from "../hooks/useOrderHistory";

export interface OrderHistoryContextState {
  history: OrderHistoryItem[] | undefined,
  fetching: boolean;
  error: AppError | undefined;
  selected: Record<number,OrderHistoryItem>;
}

const initialState: OrderHistoryContextState = {
  history: undefined,
  fetching: false,
  error: undefined,
  selected: {},
}

export type OrderHistoryContextAction = 
  | { type: "SET_HISTORY", payload: OrderHistoryItem[] | undefined }
  | { type: "SET_FETCHING", payload: boolean }
  | { type: "SET_ERROR", payload: AppError | undefined }
  | { type: "TOGGLE_ORDER", payload: OrderHistoryItem }
  | { type: "SELECT_ALL_ORDERS" }
  | { type: "DESELECT_ALL_ORDERS" }

const reducer = (state: OrderHistoryContextState, action: OrderHistoryContextAction): OrderHistoryContextState => {
  switch (action.type) {
    case "SET_HISTORY": {
      return { ...state, history: action.payload }
    }
    case "SET_FETCHING": {
      return { ...state, fetching: action.payload }
    }
    case "SET_ERROR": {
      return { ...state, error: action.payload }
    }
    case "TOGGLE_ORDER": {
      const { orid } = action.payload.order;
      const newSelected = { ...state.selected };
      if (state.selected[orid]) delete newSelected[orid];
      else newSelected[orid] = action.payload;
      return { ...state, selected: newSelected };
    }
    case "SELECT_ALL_ORDERS": {
      if (!state.history) return state;
      const selected: Record<number,OrderHistoryItem> = Object.fromEntries(state.history.map(item => [item.order.orid,item]));
      return { ...state, selected }
    }
    case "DESELECT_ALL_ORDERS": {
      return { ...state, selected: {} }
    }
  }
}

export interface OrderHistoryContextType {
  state: OrderHistoryContextState, 
  dispatch: React.Dispatch<OrderHistoryContextAction>
}

export const OrderHistoryContext = createContext<OrderHistoryContextType>({state: initialState, dispatch: () => {}});

export const OrderHistoryContextProvider = ({children}: {children: React.ReactNode}) => {
  const [state,dispatch] = useReducer<React.Reducer<OrderHistoryContextState,OrderHistoryContextAction>>(reducer,initialState);

  const { history, error } = useOrderHistoryNew();

  useEffect(() => {
    if (!history && !error) {
      dispatch({type: "SET_FETCHING", payload: true});
      return;
    };
    if (!history) return;
    dispatch({type: "SET_HISTORY", payload: history});
  },[history]);

  useEffect(() => {
    if (!error) return;
    dispatch({type: "SET_ERROR", payload: error});
  },[error]);

  useEffect(() => {
    if (!state.error) return;
    dispatch({type: "SET_FETCHING", payload: false});
  },[state.error]);

  return (
    <OrderHistoryContext.Provider value={{state,dispatch}}>
      {children}
    </OrderHistoryContext.Provider>
  )
}
