import React from "react";
import { Sale } from "../useSales";
import s from "../Sales.module.scss";
import { useDateFormat } from "../../../hooks/useDateFormat";

export default function SalesDetailedOverviewTable({sales}: {sales: Sale[]}) {
  return (
    <div className={s.salesDetailedOverviewTable}>
      <Header />
      {sales.map((sale,i) => <Row sale={sale} />)}
    </div>
  );
}

const Header = () => {
  return (
    <div className={s.header}>
      <span>Date & Time</span>
      <span>Item</span>
      <span>Quantity</span>
      <span>Value</span>
      <span>Earnings</span>
      <span>Source</span>
    </div>
  )
}

const Row = ({sale}: {sale: Sale}) => (
  <div>
    <span>{sale.occurredOn.toFormat(useDateFormat("datetime"))}</span>
    <span>{sale.sku.shortLabel}</span>
    <span>{sale.quantity}</span>
    <span>{sale.currency.parse(sale.value)}</span>
    <span>{sale.currency.parse(sale.earnings)}</span>
    <span>{sale.source}</span>
  </div>
);