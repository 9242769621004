import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { AppError, authFetch } from "../../utils/utils"
import useErrorState from "../../hooks/useErrorState";

export interface Pricing {
  pricingId: number,
  createdOn: Date,
  startedOn: Date,
  endedOn: Date,
  prid: number,
  skuId: number,
  currency: string,
  price: number,
  description: string,
  taxAmount: number,
}

type SkuIdPricingMap = {[skuId: number]: Pricing}

interface UsePricingsReturn {
  pricings: SkuIdPricingMap | undefined;
  error: AppError | undefined;
}

export default function usePricings(): UsePricingsReturn {
  const [pricings, setPricings] = useState<SkuIdPricingMap>();
  const [error, setError] = useErrorState();
  
  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!loginToken || pricings || error) return;
    const abortController = new AbortController();
    fetchPricings(loginToken,abortController.signal)
      .then(setPricings)
      .catch(setError);
  },[loginToken,pricings,error]);

  return { pricings, error };
}

type FetchPricingJSON = 
  | { pricings: Pricing[], error: undefined }
  | { pricings: undefined, error: string }

const fetchPricings = async (loginToken: string,signal: AbortSignal): Promise<{[skuId: number]: Pricing}> => {
  const res = await authFetch(loginToken,"/api/pricings-all",{signal});
  const { pricings, error }: FetchPricingJSON = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return Object.fromEntries(pricings.map(pricing => [pricing.skuId,pricing]));
}