/**
 * Indicates whether a given sku is just a device or a combo containing FODMAPs and/or glucose kits.
 * Certain behaviours depend on contents of order sku.
 */
export function orderContentsType(skuId: number): OrderContentsType {
  const result = skuIdOrderContentsTypeRecord[skuId];
  if (result === undefined) throw new Error("Invalid skuId");
  return result;
}

const skuIdOrderContentsTypeRecord: Partial<{[skuId: number]: OrderContentsType}> = {
  // AIRE 2
  68: "device",
  67: "fodmaps",
  95: "glucose",
  98: "glucoseAndFodmaps",
  // MedAIRE 2
  89: "device",
  90: "fodmaps",
  91: "glucose",
  92: "glucoseAndFodmaps",
}

export type OrderContentsType = "device"|"fodmaps"|"glucose"|"glucoseAndFodmaps";
