import { DateTime } from "luxon";
import tz from "../tz";
import { dateBuckets } from "./generic-fns";

class Symptom {
  readonly pertainsTo: string;
  readonly label: string;
  readonly score: number;
  constructor(pertainsToMs: number,label: string,score: number) {
    this.pertainsTo = tz.toLocal(pertainsToMs).toISO();
    this.label = label;
    this.score = score;
  }

  datapoint(): SymptomDatapoint {
    const x = this.pertainsTo;
    const y = this.score;
    return new SymptomDatapoint(x,y,this.label);
  }
}

class SymptomDatapoint {
  readonly x: string;
  readonly y: number;
  readonly label: string;
  constructor(x: string,y: number,label: string) {
    this.x = x;
    this.y = y;
    this.label = label;
  }
}

export class Stool extends Symptom {
  constructor(pertainsToMs: number,score: number) {
    super(pertainsToMs,"Stool Form",score);
  }

  datapoint(): SymptomDatapoint {
    const x = this.pertainsTo;
    const y = this.score;
    return new SymptomDatapoint(x,y,this.label);
  }

  /**
   * @param {Symptom} symptom 
   * @returns 
   */
  static fromSymptom(symptom: Symptom): Stool {
    return new Stool(tz.toUTC(symptom.pertainsTo).toMillis(),symptom.score);
  }
}

export class SymptomLabel {
  static BLOATING = "Bloating";
  static ABDOMINAL_PAIN = "Abdominal Pain";
  static FLATULENCE = "Flatulence"; 
  static STOOL_FORM = "Stool Form";

  /**
   * @param {string} label 
   */
  static isOther(label: string): boolean {
    return !Object.values(SymptomLabel).includes(label);
  }
}

/**
 * Sorts flat array of symptoms into arrays for each label, containing only the max symptom value for each date for that label
 */
export const dailyMaxSymptoms = (symptoms: Symptom[]): Symptom[][] => {
  const resultObj = symptoms.reduce<{[symptomLabel: string]: {[pertainsTo: string]: Symptom}}>((acc,cur) => {
    const date = DateTime.fromISO(cur.pertainsTo).toISODate();
    if (!acc[cur.label]) acc[cur.label] = {};
    if (!acc[cur.label][date]) acc[cur.label][date] = cur;
    if (cur.score > acc[cur.label][date].score) acc[cur.label][date] = cur;
    return acc;
  },{});
  return Object.values(resultObj).map(symptoms => Object.values(symptoms).map(symptomWithPertainsToSetToISODate));
}

export const dailyAverageSymptomsWithLabel = (symptoms: Symptom[],label: string): Symptom[] => {
  const symptomsByDay = Object.values(dateBuckets(symptoms.filter(s => s.label === label),"pertainsTo"));
  const result = symptomsByDay.reduce((averages,symptoms) => {
    averages.push(averageSymptomOnDay(symptoms));
    return averages;
  },[]);
  return result;
}

/**
 * @param {Symptom[]} symptomsOfLabelOnDay symptoms all occurring on the same day with the same label
 */
const averageSymptomOnDay = (symptomsOfLabelOnDay: Symptom[]): Symptom => {
  const score = symptomsOfLabelOnDay.reduce((avg,s) => avg + s.score/symptomsOfLabelOnDay.length,0);
  const pertainsTo = DateTime.fromISO(symptomsOfLabelOnDay[0].pertainsTo).toISODate();
  const label = symptomsOfLabelOnDay[0].label
  const result = new Symptom(tz.toUTC(pertainsTo).toMillis(),label,score);
  return result;
}

const symptomWithPertainsToSetToISODate = (symptom: Symptom): Symptom => {
  return new Symptom(tz.toDateMillisUTC(symptom.pertainsTo),symptom.label,symptom.score);
}

/**
 * @param {Symptom[][]} symptomsArrays 
 * @returns Array of arrays of symptoms sorted by array with biggest max score
 */
export const symptomsArraysByMax = (symptomsArrays: Symptom[][]): Symptom[][] => {
  const result = symptomsArrays
    .map(symptoms => ({symptoms, max: maxSymptom(symptoms)}))
    .sort((a,b) => b.max.score - a.max.score)
    .map(({symptoms}) => symptoms);
  return result;
}

/**
 * @param {Symptom[]} symptoms 
 * @returns symptom with max score
 */
export const maxSymptom = (symptoms: Symptom[]): Symptom => {
  return symptoms.reduce((max,s) => s.score > max.score ? s : max,symptoms[0]);
}

export const maxDailySymptomsArrays = (symptoms: Symptom[]): Symptom[][] => {
  return symptomsArraysByMax(dailyMaxSymptoms(symptoms));
}

/**
 * plain symptom object as expected to come from backend
 */
interface RawSymptom {
  label: string;
  pertainsToMs: number;
  score: number;
}

export const createSymptom = (rawSymptom: RawSymptom): Symptom => {
  const { label, pertainsToMs, score } = rawSymptom;
  return new Symptom(pertainsToMs,label,score);
}

export const createSymptoms = (symptoms: RawSymptom[]): Symptom[] => {
  return symptoms.map(s => createSymptom(s));
}

export default Symptom;