import { AppError } from "../utils/utils";
import useCdContent, { CdContent, ResultType } from "./useCdContent";

export type Substrate = "glucose" | "lactulose" | "lactose" | "fructose" | "inulin" | "sorbitol";

export default function useChallengeFollowonContent(): { content: ChallengeFollowonContentRecord | undefined, error: AppError | undefined } {
  const { content: data, error } = useCdContent("challenge_pdf_follow_on_testing");
  return { content: createContentMap(data), error };
}

function createContentMap(content: CdContent<"challenge_pdf_follow_on_testing">[] | undefined): ChallengeFollowonContentRecord | undefined {
  if (content === undefined) return undefined;
  const result: any = {};
  content.forEach(c => {
    const { substrate, resultType } = c.metadata;
    if (!result[substrate]) result[substrate] = {};
    result[substrate][resultType] = c.content;
  });
  return result
};

export type ChallengeFollowonContentRecord = Partial<Record<Substrate,Partial<Record<ResultType,string>>>>;