import { useContext } from "react";
import { requestLogout } from "../../../contexts/LoginContext/LoginContextActions";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";

const LogoutButton = ({history}) => {
  const loginContext = useContext(LoginContext);
  const { state: { loggingOut } } = loginContext;
  
  return <button onClick={onClick(history,loginContext)}>{loggingOut ? "Logging out..." : "Log Out"}</button>
}

/**
 * @param {*} history 
 * @param {import("../../../contexts/LoginContext/LoginContext").LoginContextType} loginContext 
 * @returns 
 */
const onClick = (history, loginContext) => async e => {
  const { state: { loginToken, staff }, dispatch } = loginContext;
  
  dispatch(requestLogout());
  history.push("/");
}

export default LogoutButton;