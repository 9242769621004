import React, { useContext, useEffect, useState } from "react";
import Dialog from "../../../components/dialogs/Dialog/Dialog";
import ErrorDialog from "../../../components/errors/ErrorDialog/ErrorDialog";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import s from "../DashAdmin.module.scss";
import { useForm } from "react-hook-form";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import { validationRegEx } from "../../../utils/FormValidation";
import { AppError, authFetch } from "../../../utils/utils";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import CopyToClipboardButton from "../../../components/buttons/CopyToClipboardButton/CopyToClipboardButton";
import useSubmitReducer from "../../../hooks/useSubmitReducer";

interface PatientPasswordResetFormFieldValues {
  email: string,
}

const PatientPasswordReset = () => {
	const { state: { loginToken: token } } = useContext(LoginContext);
	const [passwordResetLink, setPasswordResetLink] = useState<string>();

  const form = useForm({
    defaultValues: {
      email: "",
    },
  });

  const { register, handleSubmit, getValues } = form;

  const [state,dispatch] = useSubmitReducer(form);

	const onSubmit = async ({email}: PatientPasswordResetFormFieldValues) => {
    if (!token || !email) return;
    dispatch({type: "PROCESSING"});
    try {
      const link = await getPasswordResetLink(token,email);
      setPasswordResetLink(link);
      dispatch({type: "COMPLETE"});
    } catch (err) {
      dispatch({type: "ERROR", payload: err});
    }
	}

  useEffect(() => {
    if (passwordResetLink) return;
    form.reset();
  },[passwordResetLink]);

	return (
		<PreloginLayout>
			<div className={s.dashAdmin}>
				<h1>Get Patient Password Reset Link</h1>
				<div>
					<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					  <p>Please enter the patient's email you want to generate a password reset link for. </p>
					  <p>This page will generate a link for the patient with the email you entered, which you may forward to the patient.</p>
            <IFTAInput id="email" label="Email address" autoComplete="off" reg={register("email",{
              required: "Email address is required",
              pattern: {
                value: validationRegEx.email,
                message: "Invalid email address",
              }
            })} />
            <FormErrorMessage errors={form.formState.errors} name="email" />
					  <button type="submit" disabled={state.processing}>
					  	{(state.processing) ? 'Processing...' : 'Get Password Reset Link'}
					  </button>
					</form>
				</div>
			</div>
			{/* Success Dialog */}
			<Dialog
				isOpen={!!passwordResetLink}
				onClose={() => setPasswordResetLink(undefined)}
			>
				<h2>Password Reset Link Generated</h2>
				<div className={s.dialogContent}>
					<p>The following password reset link has been generated for the patient registered with the email <b>{getValues("email")}</b>: </p>
					{passwordResetLink && (
            <div className={s.resetLink}>
              <span>{passwordResetLink}</span>
              <span><CopyToClipboardButton data={passwordResetLink} title="Copy to clipboard" /></span>
            </div>
          )}
					<button className={s.btnPrimary} onClick={() => setPasswordResetLink(undefined)}>Return</button>
				</div>
			</Dialog>
			<ErrorDialog
				error={state.error?.message ?? ""}
				onClose={() => dispatch({type: "RESET"})}
			/>
		</PreloginLayout>
	);
}

type PatientPasswordResetResJSON = 
    | { passwordResetLink: string, error: undefined }
    | { passwordResetLink: undefined, error: string }

const getPasswordResetLink = async (token: string, email: string): Promise<string> => {
  const res = await authFetch(token,"/api/get-patient-password-reset-link",{
    method: "POST",
    body: JSON.stringify({
      token,
      email,
    })
  });
  const { passwordResetLink, error }: PatientPasswordResetResJSON = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return passwordResetLink;
}

export default PatientPasswordReset;
