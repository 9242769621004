import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { AppError, authFetch } from "../../utils/utils";

interface UseReturnShipping {
  shipping: Shipping|undefined;
  error: ShippingError|undefined;
}

export default function useShipping(): UseReturnShipping {
  const [shipping, setShipping] = useState<Shipping>();
  const [error, setError] = useState<ShippingError>();

  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!loginToken || shipping || error) return;
    const abortController = new AbortController();
    fetchShipping(loginToken,abortController.signal)
      .then(setShipping)
      .catch(err => setError(err ? err : new ShippingError(400,"Bad Request")));
  },[loginToken,shipping,error]);

  return { shipping, error };
}

export interface Shipping {
  shipId: string;
  createdOn: string|undefined;
  shippingName: string|undefined;
  shippingEmail: string|undefined;
  shippingPhone: string|undefined;
  shippingAddress1: string|undefined;
  shippingCity: string|undefined;
  shippingZip: string|undefined;
  shippingState: string|undefined;
  shippingCountry: string|undefined;
}

interface ShippingBackend {
  shipId: string;
  createdOn: string|null;
  shippingName: string|null;
  shippingEmail: string|null;
  shippingPhone: string|null;
  shippingAddress1: string|null;
  shippingCity: string|null;
  shippingZip: string|null;
  shippingState: string|null;
  shippingCountry: string|null;
}


type FetchShippingJSON = 
  | { shipping: ShippingBackend, error: undefined }
  | { shipping: undefined, error: string }

const fetchShipping = async (token: string, signal: AbortSignal): Promise<Shipping> => {
  const res = await authFetch(token,"/api/shipping",{signal});
  const { shipping, error } = await res.json();
  if (error !== undefined) throw new ShippingError(res.status,error);
  return {
    shipId: shipping.shipId,
    createdOn: shipping.createdOn ?? undefined,
    shippingName: shipping.shippingName ?? undefined,
    shippingEmail: shipping.shippingEmail ?? undefined,
    shippingPhone: shipping.shippingPhone ?? undefined,
    shippingAddress1: shipping.shippingAddress1 ?? undefined,
    shippingCity: shipping.shippingCity ?? undefined,
    shippingZip: shipping.shippingZip ?? undefined,
    shippingState: shipping.shippingState ?? undefined,
    shippingCountry: shipping.shippingCountry ?? undefined,
  }
}

export class ShippingError extends AppError {
  constructor(code: number, message: string) {
    super(code,message,true);
    this.name = "ShippingError";
  }
}