import React from "react";
import s from "./PosNegMarker.module.scss";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'

/**
 * @typedef PosNegMarkerTooltip
 * @property {string} positive
 * @property {string} negative
 */

/**
 * @param {Object} props
 * @param {boolean} props.isPositive
 * @param {PosNegMarkerTooltip=} props.tooltip
 * @returns 
 */
const PosNegMarker = ({isPositive,tooltip=undefined}) => {
  return (
    <Tippy disabled={!tooltip} content={<span>{tooltip ? (isPositive ? tooltip.positive : tooltip.negative) : null}</span>}>
      <div className={isPositive ? s.poorlyDigested : s.adequatelyDigested} />
    </Tippy>
  );
}

export default PosNegMarker;