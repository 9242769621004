export type GasLabel = "H2"|"CH4";

export default class Gas {
  readonly gasId: number;
  readonly gas: GasLabel;
  readonly description: string;
  readonly fancy: string;

  static H2 = new Gas(1,"H2","Hydrogen");
  static CH4 = new Gas(2,"CH4","Methane");

  constructor(gasId: number,gas: GasLabel,description: string) {
    this.gasId = gasId;
    this.gas = gas; // Chemical formula
    this.description = description;
    this.fancy = fancyString(this.gas); // Chemical formula with numeric subscript
  };
  
  isGas(gasId: number) {
    return gasId === this.gasId;
  }
}

function isDigit(charCode: number): boolean {
  return charCode >= 48 && charCode <= 57;
}

function toSubscript(digitCharCode: number): number {
  return 8320 + digitCharCode - 48;
}

function fancyChar(charCode: number): string {
  return String.fromCharCode(isDigit(charCode) ? toSubscript(charCode) : charCode);
}

function fancyString(str: string): string {
  return Array.from(str).reduce((acc,cur) => acc + fancyChar(cur.charCodeAt(0)))
}