import { LoginContextAction } from "./LoginContextActions";
import { LoginContextState } from "./LoginContext";

const loginContextReducer = (state: LoginContextState, action: LoginContextAction): LoginContextState => {
  switch (action.type) {
    case "SET_LOGIN_TOKEN":
      const loginToken = action.payload;
      if (loginToken) {
        window.localStorage.setItem("login-token", loginToken);
      } else {
        window.localStorage.removeItem("login-token");
      }
      return { ...state, loginToken };
    case "SET_LOGIN_SUCCESS":
      const loginSuccess = action.payload;
      return { ...state, loginSuccess };
    case "LOG_IN":
      const { staff, provider, patients, support, preferences } = action.payload;
      return { ...state, loginSuccess: true, staff, provider, patients, support, preferences };
    case "SET_LOGGING_OUT": 
      return { ...state, loggingOut: action.payload }
    case "LOG_OUT":
      window.localStorage.removeItem("login-token");
      return {
        ...state,
        loginToken: undefined,
        loginSuccess: false,
        loginDetails: undefined,
        loggingOut: false,
        staff: undefined,
        provider: undefined,
        patients: undefined,
        preferences: undefined,
      };
    case "SET_PROVIDER_LOGO":
      return { ...state, providerLogo: action.payload };
    case "SET_LOGIN_DETAILS":
      return { ...state, loginDetails: action.payload };
    default:
      return state;
  }
};

export default loginContextReducer;