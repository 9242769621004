import { StyleSheet } from "@react-pdf/renderer";
import { COLOR } from "../../../styles/_variables/variables";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: 'Open Sans',
  fonts: [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 900 }
]
});

export const OUTER_MARGIN = "30px";
export const ROW_PADDING = "5px";
export const OUTER_BORDER = `1px solid ${COLOR.clinicalGreen}`;
export const INNER_BORDER = `0.75px solid ${COLOR.clinicalGreen}`;

export const s = {
  general: StyleSheet.create({
    body: {
      color: COLOR.clinicalGreen,
      fontFamily: "Open Sans",
      fontSize: 12,
    },
    content: {
      marginLeft: OUTER_MARGIN,
      marginRight: OUTER_MARGIN,
    },
    h1: {
      fontSize: 16,
    },
    h2: {
      fontSize: 14,
    },
    h3: {},
    b: {
      fontWeight: 900,
    },
    table: {
      border: OUTER_BORDER,
      display: "flex",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: ROW_PADDING,
      paddingRight: ROW_PADDING,
    },
    headerRow: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: ROW_PADDING,
      paddingRight: ROW_PADDING,
      backgroundColor: COLOR.clinicalGreen,
      color: "#FFFFFF",
    },
    tabularNum: {
      fontFamily: "Roboto",
    },
  }),
  meta: StyleSheet.create({
    item: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
    },
  }),
  summaryTable: StyleSheet.create({
    label: {
      width: "40%",
      textAlign: "right",
    },
    quantity: {
      width: "40%",
      textAlign: "right",
    },
    total: {
      width: "20%",
      textAlign: "right",
    },
  }),
  ptTable: StyleSheet.create({
    date: {
      width: "20%",
      textAlign: "left",
    },
    item: {
      width: "40%",
      textAlign: "left",
    },
    unitPrice: {
      width: "20%",
      textAlign: "right",
    },
    total: {
      width: "20%",
      textAlign: "right",
    },
  }),
  substrateTable: StyleSheet.create({
    orderDate: {
      width: "20%",
      textAlign: "left",
    },
    item: {
      width: "25%",
      textAlign: "left",
    },
    quantity: {
      width: "15%",
      textAlign: "right",
    },
    unitPrice: {
      width: "20%",
      textAlign: "right",
    },
    total: {
      width: "20%",
      textAlign: "right",
    },
  }),
  pageNumber: StyleSheet.create({
    main: {
      textAlign: "right",
      marginTop: "auto",
      marginBottom: OUTER_MARGIN,
      marginRight: OUTER_MARGIN,
    },
    content: {
      textAlign: "right",
    },
  }),
};

