import { createProvider, Provider } from "../utils/data-classes/Provider";
import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function useProviders(): UseProvidersReturn {
    const { data, error } = useDataAuth(fetchProviders);
    return { providers: data, error }
}

async function fetchProviders(loginToken: string, signal: AbortSignal): Promise<Provider[]> {
    const res = await authFetch(loginToken,"/api/get-all-providers",{signal});
    const { error, providers } = await res.json();
    if (error !== undefined) throw new AppError(res.status,error);
    return createProviders(providers);
}

const createProviders = (providers: any[]): Provider[] => {
    return providers.map(p => createProvider(p));
}

interface UseProvidersReturn {
    providers: Provider[] | undefined,
    error: AppError | undefined,
}