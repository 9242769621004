import ChartRenderer from "./ChartRenderer/ChartRenderer";

/**
 * @param {Object} props
 * @param {string} props.id 
 * @param {import("chart.js").ChartDataSets[]} props.dataSetsArray 
 * @param {import("chart.js").ChartOptions} props.options 
 * @returns
 */
const MultiChartRenderer = ({id,dataSetsArray,options}) => {
  return <ChartRenderer id={id} data={dataSetsArray} options={options} type="scatter" />
}

export default MultiChartRenderer;