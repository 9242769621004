import { useState } from 'react';
import s from "./Challenges.module.scss";

const HighBaselineAlert = () => {
    const [innerText, setInnerText] = useState("HB");

    return (  
        <aside onMouseEnter={() => setInnerText("High Baseline")} onMouseLeave={() => setInnerText("HB")} className={s.highBaseline}>
            {innerText}
        </aside>
    );
}
 
export default HighBaselineAlert;