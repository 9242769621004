import Chart from "chart.js";
import { useEffect, useRef, useState } from "react";
import loadingPng from "../../../assets/loading.png";
import s from "./ChartRenderer.module.scss";

const DEFAULT_LOADING_SPINNER = <img className={s.rotate} src={loadingPng} alt="Loading data"/>;

/**
 * Renders charts.js chart to a canvas.
 * Renders loading spinner if chartData is falsey.
 * @param {Object} props  
 * @param {Chart.ChartData|Chart.ChartDataSets[]} props.data 
 *  - **ChartData** for rendering a single dataset as a line plot 
 *  - **ChartDatasets[]** for rendering multiple datasets as a scatter plot 
 * @param {Chart.ChartOptions} props.options
 * @param {string} props.id
 * @param {string} props.type set to true if rendering multiple seperate datasets to a scatter plot, ie chartData of type **ChartDatasets[]** 
 * @returns 
 */
const ChartRenderer = ({ id, data, options, type }) => {
  // const chartType = isMultiChart ? "scatter" : "line";
  const chartType = type;

  const canvasRef = useRef(null);

  const [chart,setChart] = useState(null);
  const [fetching, setFetching] = useState(false);

  // Initialize empty chart 
  useEffect(() => {
    if (!options) return;
    if (!chart) setChart(createChart(canvasRef.current,options,chartType));
  }, [options]);
  
  // Update datasets & chart 
  useEffect(() => {
    if (!data) {
      setFetching(true);
      return;
    }
    setChart(getUpdatedChart(data,options));
    setFetching(false);
  },[data]);

  return (
    <div id={id}>
      {fetching && DEFAULT_LOADING_SPINNER}
      <canvas
        ref={canvasRef}
        style={{ opacity: fetching ? 0.2 : 1 }}
      ></canvas>
    </div>
  );
}

/**
 * @param {string} context 
 * @param {Chart.ChartOptions} options 
 * @param {string} type
 * @returns 
 */
const createChart = (context,options,type) => {
  return new Chart(context, {
    type,
    data: {},
    options,
  });
}

/**
 * @param {Chart.ChartData|Chart.ChartDataSets[]} data 
 * @param {Chart.ChartOptions} options
 * @returns 
 */
const getUpdatedChart = (data,options) => 
/**
 * @param {Chart} chart 
 */
(chart) => {
  removeDataAndOptions(chart);
  addNewDataAndOptions(chart,data,options);
  return chart;
}

/**
 * @param {Chart} chart 
 */
const removeDataAndOptions = (chart) => {
  chart.data.labels?.pop();
  chart.data.datasets = [];
  chart.options = {};
  chart.update();
}

/**
 * @param {Chart} chart 
 * @param {Chart.ChartData|Chart.ChartDataSets[]} data 
 * @param {Chart.ChartOptions} options 
 */
const addNewDataAndOptions = (chart,data,options) => {
  if (data instanceof Array) chart.data.datasets = data;
  else chart.data = data;
  chart.options = options;
  chart.update();
}

export default ChartRenderer;