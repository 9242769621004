import DialogBase from "../../dialogs/DialogBase/DialogBase";
import s from "./ErrorDialog.module.scss";

/**
 * 
 * @param {Object} props 
 * @param {String} props.error
 * @param {Function} props.onClose
 * @returns 
 */
const ErrorDialog = ({error, onClose}) => {
	return (
		<DialogBase
			appElement={'body'}
			isOpen={!!error}
			onClose={onClose}
		>
			<div className={s.errorDialogContent}>
				<h2>We Have Encountered an Error</h2>
				<p>{error}</p>
				<button onClick={onClose}>Close</button>
			</div>
		</DialogBase>
	);
}

export default ErrorDialog;
