import tz from "../../utils/tz";

/**
 * Class for handling description of an RPM program
 * Currently only has start date of RPM. Can be expanded later to include further details of program.
 */
export class RPMProgram {
  readonly startedOn: string;
  constructor(startedOnMs: number) {
    this.startedOn = tz.toLocal(startedOnMs).toISO();
  }
}

export function createRPMProgram({startedOnMs}: {startedOnMs: number}) {
  return new RPMProgram(startedOnMs);
}