import { Text, View } from "@react-pdf/renderer";
import { shared, s } from "../challenge-report-styles";
import { Challenge } from "../../../../utils/data-classes/challenge/Challenge";

export default function NotesBox({ challenge }: { challenge: Challenge }) {
  const notes = challenge.settings.notes ?? "";
  return (
    <View style={s.notes.main} wrap={false}>
      <Text style={shared.header}>Notes</Text>
      <View style={s.testCriteria.body}>
        <Text style={s.testCriteria.text}>{notes}</Text>
      </View>
    </View>
  );
}
