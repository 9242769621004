import React from "react";
import { DeepRequired, FieldErrors, FieldErrorsImpl, FieldName, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { ErrorMessage, FieldValuesFromFieldErrors } from "@hookform/error-message";
import s from "./FormErrorMessage.module.scss";

interface FormErrorMessageProps<TFieldValues extends FieldValues,TName extends Path<TFieldValues>> {
  errors: FieldErrors<TFieldValues>;
  name: TName;
}

type ErrorName<TFieldValues> = FieldName<FieldValuesFromFieldErrors<Partial<FieldErrorsImpl<DeepRequired<TFieldValues>>>>>; 

const ErrorRender = ({message}: {message: string}) => <p className={s.formError}>{message}</p>

export default function FormErrorMessage<TFieldValues extends FieldValues,TName extends Path<TFieldValues>>(
  { name, errors }: FormErrorMessageProps<TFieldValues,TName>) 
{
  return <ErrorMessage errors={errors} name={name as unknown as ErrorName<TFieldValues>} render={ErrorRender} />;
}