import { DateTime } from "luxon";
import Meal from "../../../utils/data-classes/Meal";
import MedLog from "../../../utils/data-classes/MedLog";
import Ppm from "../../../utils/data-classes/Ppm";
import Sleep from "../../../utils/data-classes/Sleep";
import Stress from "../../../utils/data-classes/Stress";
import Symptom from "../../../utils/data-classes/Symptom";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import { RPMDigest } from "./useRPMDigest";
import { TREATMENT_WINDOW_HEADERS, RPMConfig } from "./rpm-excel-util";
import _groupBy from "lodash/groupBy";

/**
 * Iterates through med logs from front and back and builds a map of tradeName to the start end end time of drug consumption
 */
export function getStartAndEndTimesByMedTradeName(medLogs: MedLog[], config: RPMConfig) {
  const startTimes = new Map<string, string>();
  const endTimes = new Map<string, string>();
  let i = 0;
  let j = medLogs.length - 1;
  while (i < j || startTimes.size !== endTimes.size) {
    const start = medLogs[j]; // medLogs are returned from the backend descending chronological order of medConsumedOn, so the pointer i moving from start to end hits the latest logs while j moving from end to start hits the earlier logs
    const end = medLogs[i];
    if (start.tradeName && !startTimes.has(start.tradeName)) startTimes.set(start.tradeName, start.medConsumedOn);
    if (end.tradeName && !endTimes.has(end.tradeName)) endTimes.set(end.tradeName, end.medConsumedOn);
    i++;
    j--;
  }
  const result = new Map<string, SplitDigestTimes>();
  Array.from(startTimes.keys()).forEach((key) => {
    const startTime = startTimes.get(key);
    const endTime = endTimes.get(key);
    if (!startTime || !endTime) return;
    result.set(key, createSplitDigestTimes([startTime, endTime], config));
  });
  return result;
}

function createSplitDigestTimes([startISO, endISO]: [string, string], config: RPMConfig): SplitDigestTimes {
  const { preTreatmentDays, postTreatmentDays } = config.treatementWindow;
  const preTreatmentStart = DateTime.fromISO(startISO).minus({ days: preTreatmentDays }).toISODate();
  const treatmentStart = DateTime.fromISO(startISO).toISODate();
  const treatmentEnd = DateTime.fromISO(endISO).toISODate();
  const postTreatmentEnd = DateTime.fromISO(endISO).plus({ days: postTreatmentDays }).toISODate();
  return [preTreatmentStart, treatmentStart, treatmentEnd, postTreatmentEnd];
}

type SplitDigestTimes = [string, string, string, string];

export function splitDigestOnMedStartAndEndTimes(digest: RPMDigest, config: RPMConfig): Map<string, SplitDigest> {
  const startAndEndTimes = getStartAndEndTimesByMedTradeName(digest.meds ?? [], config);
  const result = new Map<string,SplitDigest>(
    Array.from(startAndEndTimes.entries()).map(([key, [preStart, start, end, postEnd]]) => [
      key,
      {
        preTreatment: createInitialPeriodDigest(TREATMENT_WINDOW_HEADERS.preTreatment, [preStart, start]),
        treatment: createInitialPeriodDigest(TREATMENT_WINDOW_HEADERS.treatment, [start, end]),
        postTreatment: createInitialPeriodDigest(TREATMENT_WINDOW_HEADERS.postTreatment, [end, postEnd]),
      },
    ])
  );
  Array.from(startAndEndTimes.entries()).forEach(([tradeName, digestTimes]) => {
    const { ppms, symptoms, meds, meals, sleeps, stress } = digest;
    const [preTreatmentStart, treatmentStart, treatmentEnd, postTreatmentEnd] = digestTimes;
    const splitDigest = result.get(tradeName);
    if (!splitDigest) return;
    const push = <T extends RPMDigestVal>(obj: T) => {
      const iso = roundTimeToNearestMinute(getDateIso(obj));
      const { preTreatment, treatment, postTreatment } = getArrs(splitDigest, obj);
      if (iso >= preTreatmentStart && iso < treatmentStart) preTreatment.push(obj);
      if (iso >= treatmentStart && iso <= treatmentEnd) treatment.push(obj);
      if (iso > treatmentEnd && iso <= postTreatmentEnd) postTreatment.push(obj);
    };
    ppms.forEach(push);
    symptoms.forEach(push);
    meds.forEach(push);
    meals.forEach(push);
    sleeps.forEach(push);
    stress.forEach(push);
  });
  return result;
}

function getArrs<T extends RPMDigestVal>(
  digest: SplitDigest,
  obj: T
): { preTreatment: T[]; treatment: T[]; postTreatment: T[] };
function getArrs<T extends RPMDigestVal>(digest: SplitDigest, obj: T): any {
  return {
    preTreatment: getArr(digest.preTreatment, obj),
    treatment: getArr(digest.treatment, obj),
    postTreatment: getArr(digest.postTreatment, obj),
  };
}

function getArr<T extends RPMDigestVal>(digest: RPMDigest, obj: T): T[];
function getArr<T extends RPMDigestVal>(digest: RPMDigest, obj: T): any {
  return digest[getKey(obj)];
}

function getKey<T extends RPMDigestVal>(obj: T): keyof RPMDigest {
  if (obj instanceof Ppm) return "ppms";
  if (obj instanceof Symptom) return "symptoms";
  if (obj instanceof Meal) return "meals";
  if (obj instanceof MedLog) return "meds";
  if (obj instanceof Stress) return "stress";
  if (obj instanceof Sleep) return "sleeps";
  throw new TypeError("Obj is not a DigestVal");
}

function getDateIso(obj: RPMDigestVal): string {
  if (obj instanceof Ppm || obj instanceof Challenge) return obj.createdOn;
  if (obj instanceof Meal) return obj.consumedOn;
  if (obj instanceof Symptom || obj instanceof Sleep || obj instanceof Stress) return obj.pertainsTo;
  if (obj instanceof MedLog) return obj.medConsumedOn;
  throw new TypeError("obj must by Ppm, Meal, Symptom, Sleep, Stress, or MedLog");
}

function createInitialPeriodDigest(title: string, dateRange: [string, string]): RPMWindowDigest {
  return {
    title,
    dateRange,
    ppms: [],
    symptoms: [],
    meds: [],
    meals: [],
    sleeps: [],
    stress: [],
  };
}

export function roundTimeToNearestMinute(iso: string): string {
  return DateTime.fromISO(iso).startOf("minute").toISO();
}

export interface SplitDigest {
  preTreatment: RPMWindowDigest;
  treatment: RPMWindowDigest;
  postTreatment: RPMWindowDigest;
}

export type RPMDigestVal = Ppm | Symptom | MedLog | Meal | Sleep | Stress;

export interface RPMWindowDigest extends RPMDigest {
  title: string;
  dateRange: [string, string];
  ppms: Ppm[];
  symptoms: Symptom[];
  meds: MedLog[];
  meals: Meal[];
  sleeps: Sleep[];
  stress: Stress[];
}