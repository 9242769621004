import React from "react";
import { FieldValues, Path, UseFormRegisterReturn } from "react-hook-form";
import s from "./RoundLabelledCheckbox.module.scss";

type RoundLabelledCheckboxProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = & Omit<React.HTMLProps<HTMLInputElement>, "id" | "type"> &
{
  id: string;
  reg: UseFormRegisterReturn<TName>;
  children: React.ReactNode;
};

export default function RoundLabelledCheckbox<TFieldValues extends FieldValues, TName extends Path<TFieldValues>>({ id, reg, children, ...rest }: RoundLabelledCheckboxProps<TFieldValues, TName>): JSX.Element {
  return (
    <div className={s.roundLabelledCheckboxContainer}>
      <div className={s.roundCheckbox}>
        <input type="checkbox" id={id} {...rest} {...reg} />
        <label htmlFor={id} />
      </div>
      <label htmlFor={id}>{children}</label>
    </div>
  );
}
