import tz from "../tz";

export default class MedLog {
  readonly medCreatedOn: string;
  readonly medLoggedOn: string;
  readonly medConsumedOn: string;
  readonly uid: number;
  readonly isDeleted: boolean|undefined;
  readonly label: string|undefined;
  readonly medId: number;
  readonly tradeName: string|undefined;
  readonly chemicalName: string|undefined;
  readonly description: string|undefined;
  readonly dose: number;
  readonly defaultDose: number|undefined;
  readonly unitsId: number|undefined;
  readonly units: string|undefined;
  readonly unitsLabel: string|undefined;
  readonly defaultUnitsId: number|undefined
  readonly defaultUnits: string|undefined
  readonly defaultUnitsLabel: string|undefined
  readonly dosesPerInterval: number|undefined;
  readonly intervalHrs: number|undefined;
  readonly regimen: MedRegimen | undefined;

  constructor(
    medCreatedOnMs: number,
    medLoggedOnMs: number,
    medConsumedOnMs: number,
    uid: number,
    isDeleted: boolean,
    label: string,
    medId: number,
    tradeName: string,
    chemicalName: string,
    description: string,
    dose: number,
    defaultDose: number,
    unitsId: number,
    units: string,
    unitsLabel: string,
    defaultUnitsId: number,
    defaultUnits: string,
    defaultUnitsLabel: string,
    dosesPerInterval: number,
    intervalHrs: number,
    regimen: MedRegimen | undefined,
  ) {
    this.medCreatedOn = tz.toLocal(medCreatedOnMs).toISO();
    this.medLoggedOn = tz.toLocal(medLoggedOnMs).toISO();
    this.medConsumedOn = tz.toLocal(medConsumedOnMs).toISO();
    this.uid = uid;
    this.isDeleted = isDeleted;
    this.label = label;
    this.medId = medId;
    this.tradeName = tradeName;
    this.chemicalName = chemicalName;
    this.description = description;
    this.defaultDose = defaultDose;
    this.dose = dose;
    this.unitsId = unitsId;
    this.units = units;
    this.unitsLabel = unitsLabel;
    this.defaultUnitsId = defaultUnitsId;
    this.defaultUnits = defaultUnits;
    this.defaultUnitsLabel = defaultUnitsLabel;
    this.dosesPerInterval = dosesPerInterval;
    this.intervalHrs = intervalHrs;
    this.regimen = regimen;
  }

  dosageString(): string {
    if (this.units) return `${this.dose} ${this.units}`;
    if (this.regimen) return `${this.regimen.dose} ${this.regimen.units}`;
    return `${this.defaultDose} ${this.defaultUnits}`;
  }
}

interface MedUnits {
  unitsId: number;
  units: string;
  label: string;
}

export interface MedRegimen {
  mrId : number;
  uid : number;
  regCreatedOnMs: number;
  medCreatedOnMs: number;
  dose : number|undefined;
  unitsId : number|undefined;
  units : string|undefined;
  unitsLabel : string|undefined;
  intervalHrs: number|undefined;
  dosesPerInterval: number|undefined;
  courseLengthDays: number|undefined;
  courseLengthDoses: number|undefined;
  regStartedOnMs : number;
  regEndedOnMs: number;
  regStartedOn: string;
  regEndedOn: string;
  regCreatedOn: string;
  medCreatedOn: string;
}

export const createMedLog = (data: any) => {
  const { medCreatedOnMs, medLoggedOnMs, medConsumedOnMs, uid, isDeleted, label, medId, tradeName, chemicalName, description, dose, defaultDose, unitsId, units, unitsLabel, defaultUnitsId, defaultUnits, defaultUnitsLabel, dosesPerInterval, intervalHrs, regimen } = data;
  return new MedLog(
    medCreatedOnMs,
    medLoggedOnMs,
    medConsumedOnMs,
    uid,
    isDeleted,
    label,
    medId,
    tradeName,
    chemicalName,
    description,
    dose,
    defaultDose,
    unitsId, 
    units, 
    unitsLabel,
    defaultUnitsId,
    defaultUnits,
    defaultUnitsLabel,
    dosesPerInterval,
    intervalHrs,
    regimen,
  );
}