import { DateTime } from "luxon";
import { authFetch, dateFormat } from "../../utils";
import { createDosageString } from "../../dosage";
import Gas from "../Gas";
import { Provider } from "../Provider";
import { createTest, Test } from "../Test";
import { HydrogenHandler } from "./HydrogenHandler";
import { MethaneHandler } from "./MethaneHandler";

export class Challenge {
  readonly tests: Test[];
  settings: ChallengeSettings;
  readonly h2: HydrogenHandler;
  readonly ch4: MethaneHandler;

  readonly isPositive: boolean;
  readonly isBorderline: boolean;
  readonly isNegative: boolean;

  static RISE_PERIOD_MINUTES = {
    GENERIC: 180,
    SIBO: 90,
  };
  static SIBO_LABELS = ["lactulose", "glucose"];
  constructor(tests: Test[], settings: ChallengeSettings) {
    this.tests = tests;
    this.settings = settings;
    this.h2 = new HydrogenHandler(this);
    this.ch4 = new MethaneHandler(this);

    this.isPositive = this.h2.isPositive() || this.ch4.isPositive();
    this.isBorderline = this.h2.isBorderline() || this.ch4.isBorderline();
    this.isNegative = !this.isPositive && !this.isBorderline;
  }

  updateSettings({ isReviewed }: Partial<ChallengeSettings>) {
    const newSettings = { ...this.settings };
    if (isReviewed !== undefined) newSettings.isReviewed = isReviewed;
    this.settings = newSettings;
  }

  get length() {
    return this.tests.length;
  }

  get firstTest() {
    return this.tests[0];
  }

  get lastTest() {
    return this.tests[this.length - 1];
  }

  get label() {
    return this.firstTest.label;
  }

  get createdOn() {
    return this.firstTest.chalCreatedOn;
  }

  get epochMs() {
    return DateTime.fromISO(this.createdOn).toMillis();
  }

  get dose() {
    return createDosageString(this.firstTest);
  }

  get baselineTest() {
    return this.firstTest;
  }

  get timeRangeISO(): [string, string] {
    return [this.firstTest.testCreatedOn, this.lastTest.testCreatedOn];
  }

  get duration() {
    return this.lastTest.dateTime.diff(this.firstTest.dateTime);
  }

  get createdOnDateTimeUTC() {
    const { chalCreatedOn } = this.firstTest;
    const result = DateTime.fromISO(chalCreatedOn);
    return result;
  }

  ppmHandler(gas: Gas): HydrogenHandler | MethaneHandler {
    switch (gas) {
      case Gas.H2: return this.h2;
      case Gas.CH4: return this.ch4;
      default: throw new TypeError("Invalid gas");
    }
  }

  date(provider: Provider): string {
    const { chalCreatedOn } = this.firstTest;
    const result = DateTime.fromISO(chalCreatedOn).toFormat(dateFormat(provider).date);
    return result;
  }

  timeRangeDateTime(): [DateTime, DateTime] {
    const [startISO, endISO] = this.timeRangeISO;
    return [DateTime.fromISO(startISO), DateTime.fromISO(endISO)];
  }

  isSIBO(): boolean {
    return Challenge.SIBO_LABELS.includes(this.label.toLowerCase());
  }

  hasGas(gas: Gas): boolean {
    return this.tests.filter((test) => test.hasGas(gas)).length !== 0;
  }
}

export const createChallenge = ({ tests: rawTests, settings }: { tests: any[]; settings: any }) => {
  return new Challenge(
    rawTests.map((rawTest) => createTest(rawTest)),
    createChallengeSettings(settings)
  );
};

const createChallengeSettings = (settings: any): ChallengeSettings => {
  return {
    isReviewed: settings?.isReviewed ?? false,
    notes: settings?.notes ?? undefined,
  };
};

export const createChallenges = (rawChallenges: any[]) => rawChallenges.map((rawTests) => createChallenge(rawTests));

export async function postChallengeSettings(loginToken: string, ptid: number, settings: { chalEpochMs: number; settings: Partial<ChallengeSettings>; }[], signal: AbortSignal) {
  await authFetch(loginToken, "/api/challenge-settings", {
    method: "POST",
    body: JSON.stringify({
      ptid,
      settings,
    }),
    signal,
  });
}

export interface ChallengeSettings {
  isReviewed: boolean;
  notes: string | undefined;
}


