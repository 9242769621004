import Fodmap from "./Fodmap";

class Food {
  readonly foodId: number;
  readonly label: string;
  readonly sublabel: string|undefined;
  readonly nuLabel: string|undefined;
  readonly amount: number|undefined;
  readonly servingSize: number|undefined;
  readonly unitsId: number|undefined;
  readonly fodmaps: Fodmap[];
  private readonly fodmapNameMap: Partial<{ [fodmapLabel: string]: Fodmap }>;
  /**
   * @param {number} foodId 
   * @param {string} label 
   * @param {string} sublabel 
   * @param {string} nuLabel 
   * @param {number} amount 
   * @param {number} servingSize 
   * @param {number} unitsId 
   * @param {Fodmap[]} fodmaps 
   */
  constructor(foodId: number, label: string, sublabel: string|null|undefined, nuLabel: string|null|undefined, amount: number|null|undefined, servingSize: number|null|undefined, unitsId: number|null|undefined, fodmaps: Fodmap[]) {
    this.foodId = typeof(foodId) === "number" ? foodId : parseInt(foodId);  
    this.label = label; 
    this.sublabel = sublabel ?? undefined;  
    this.nuLabel = nuLabel ?? undefined;  
    this.amount = amount ?? undefined;  
    this.servingSize = servingSize ?? undefined;  
    this.unitsId = unitsId ?? undefined;  
    this.fodmaps = fodmaps; 

    this.fodmapNameMap = Object.fromEntries(this.fodmaps.map(fodmap => [fodmap.label,fodmap]));
  }
  
  getFodmap(name: string): Fodmap|undefined {
    return this.fodmapNameMap[name];
  }

  totalFodmaps(): number {
    return this.fodmaps.reduce((sum,fodmap) => sum + fodmap.value,0);
  }
}

interface RawFood {
  amount: number|null|undefined;
  label: string;
  servingSize: number|null|undefined;
  sublabel: string|null|undefined;
  nuLabel: string|null|undefined;
  unitsId: number|null|undefined,
  fodmaps: unknown[],
}

export const createFood = (foodId: number,{amount,label,nuLabel,servingSize,sublabel,unitsId,fodmaps}: RawFood) => {
  const _fodmaps = Object.entries(fodmaps).map(([name,value]) => new Fodmap(name,value as number)).filter(f => f.label !== "null");
  return new Food(foodId,label,sublabel,nuLabel,amount,servingSize,unitsId,_fodmaps);
}

export const createFoods = (foodsObj: { [foodId: number]: RawFood }) => {
  return Object.entries(foodsObj).map(([foodId,foodObj]) => createFood(parseInt(foodId),foodObj));
}

export default Food;