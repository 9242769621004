import { FormProvider, useForm, useFormContext } from "react-hook-form";
import PreloginLayout from "../../../../layouts/prelogin/PreloginLayout";
import s from "./RequestOrder.module.scss";
import { useContext, useEffect } from "react";
import {
  RequestOrderContext,
  RequestOrderContextProvider,
  RequestOrderFormFieldValuesBase,
} from "./RequestOrderContext";
import { useRequestOrderFormContext } from "./useRequestOrderFormContext";
import { AppError, authFetch } from "../../../../utils/utils";
import _FormErrorMessage from "../../../../components/errors/FormErrorMessage/FormErrorMessage";
import FormErrorMessageGeneric from "../../../../components/errors/FormErrorMessage/FormErrorMessageGeneric";
import useSubmitReducer from "../../../../hooks/useSubmitReducer";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import ProviderSelect from "./inputs/ProviderSelect";
import StaffSelect from "./inputs/StaffSelect";
import SkuSelect from "./inputs/SkuSelect";
import QuantityInput from "./inputs/QuantityInput";
import { DateTime } from "luxon";
import { OrderedOnDateInput } from "./inputs/OrderedOnDateInput";
import OrderItemsBasket from "./OrderItemsBasket";

export default function RequestOrderArrears() {
  const form = useForm<RequestOrderFormFieldValues>({
    defaultValues: {
      prid: undefined,
      quantity: 1,
      skuId: undefined,
      stfid: undefined,
      orderedOn: DateTime.now().toISODate(),
      items: [],
    },
  });
  return (
    <PreloginLayout>
      <div className={s.dashAdminAddOrder}>
        <FormProvider {...form}>
          <RequestOrderContextProvider isArrears={true}>
            <h1>Request Arrears Order</h1>
            <RequestOrderForm />
          </RequestOrderContextProvider>
        </FormProvider>
      </div>
    </PreloginLayout>
  );
}

function RequestOrderForm() {
  const {
    state: { loginToken },
  } = useContext(LoginContext);
  const form = useRequestOrderFormContext();
  const [{ complete, processing, error }, dispatch] = useSubmitReducer(form);
  const { order } = useContext(RequestOrderContext).state;
  const onSubmit = form.handleSubmit(async (data) => {
    if (!loginToken) return;
    dispatch({ type: "PROCESSING" });
    try {
      await makeOrderRequest(loginToken, data);
      dispatch({ type: "COMPLETE" });
    } catch (err) {
      dispatch({ type: "ERROR", payload: err });
    }
  });

  useEffect(() => {
    form.setValue(
      "items",
      order?.orderItems.map((item) => ({
        skuId: item.sku.skuId,
        quantity: item.quantity,
      })) ?? []
    );
  }, [order]);

  if (error) return <Err error={error} />;
  if (complete) return <Complete />;
  return (
    <form onSubmit={onSubmit}>
      <div className={s.formInputs}>
        <ProviderSelect />
        <FormErrorMessage path="prid" />
        <StaffSelect />
        <FormErrorMessage path="stfid" />
        <OrderedOnDateInput />
        <FormErrorMessage path="orderedOn" />
        <ItemWidget />
      </div>
      <OrderItemsBasket processing={processing} canSubmit={!!order?.orderItems.length} />
    </form>
  );
}

function ItemWidget() {
  const { watch } = useFormContext();
  const { state, dispatch } = useContext(RequestOrderContext);
  const { getPrice } = state;

  const price = getPrice(watch("skuId"),watch("quantity"));

  const addItem = () => dispatch({ type: "ADD_ITEM", payload: price });

  return (
    <div className={s.itemWidget}>
      <h4>Order Item</h4>
      <SkuSelect />
      <FormErrorMessage path="skuId" />
      <QuantityInput />
      <FormErrorMessage path="quantity" />
      <button className={s.btnPrimary} type="button" onClick={addItem}>
        Add Item
      </button>
    </div>
  );
}

function Complete() {
  return (
    <div className={s.complete}>
      <h2>Order Requested</h2>
      <button onClick={() => window.location.reload()}>Return</button>
    </div>
  );
}

function Err({ error }: { error: AppError }) {
  return (
    <div className={s.error}>
      <h2>Error {error.code}</h2>
      <p>{error.message}</p>
    </div>
  );
}

const FormErrorMessage = FormErrorMessageGeneric<RequestOrderFormFieldValues>;

async function makeOrderRequest(loginToken: string, data: RequestOrderFormFieldValues) {
  const res = await authFetch(loginToken, "/api/order-request/arrears", {
    method: "POST",
    body: JSON.stringify({
      prid: data.prid,
      stfid: data.stfid,
      orderedOn: data.orderedOn,
      items: data.items,
    }),
  });
  const { error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return;
}

export interface RequestOrderFormFieldValues extends RequestOrderFormFieldValuesBase {
  items: {
    skuId: number;
    quantity: number;
  }[];
}
