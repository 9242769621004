import React, { useRef } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import useHiddenTitleOnMouseover from "../../../hooks/useHiddenTitleOnMousover";

export type TooltipButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  tooltipMessage: string;
};

export default function TooltipButton({ tooltipMessage, ...rest }: TooltipButtonProps) {
  const ref = useRef(null);
  const title = rest.title ?? tooltipMessage;
  useHiddenTitleOnMouseover(ref, title);
  return (
    <Tippy content={<span>{tooltipMessage}</span>}>
      <div>
        <button ref={ref} title={title} {...rest} />
      </div>
    </Tippy>
  );
}
