import React from 'react';
import { Challenge } from '../../../utils/data-classes/challenge/Challenge';
import { symptomScoresByTest } from '../../../utils/data-classes/challenge/challenge-util';
import { HydrogenHandler } from '../../../utils/data-classes/challenge/HydrogenHandler';
import { MethaneHandler } from '../../../utils/data-classes/challenge/MethaneHandler';
import { DateTime } from 'luxon';
import Symptom from '../../../utils/data-classes/Symptom';
import _round from 'lodash/round';
import { splitSymptoms, sortSymptomsByMax } from '../../../pdf-generator/templates/ChallengeReport/challenge-report-fns';
import { Test } from '../../../utils/data-classes/Test';
import Gas from '../../../utils/data-classes/Gas';
import s from './MiniChallenges.module.scss';

export default function MiniChallengesDataTable({ challenge, symptoms }: { challenge: Challenge; symptoms: Symptom[] }) {
  const h2Handler = challenge.h2;
  const ch4Handler = challenge.ch4;
  const topSymptoms = sortSymptomsByMax(splitSymptoms(symptoms)).slice(0, 3);

  return (
    <div className={s.dataTable}>
      <table>
        <thead>
          <tr>
            <th>Breath No.</th>
            {challenge.tests.map((_, i) => (
              <th key={i}>{i === 0 ? 'base' : i}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <DataTimeRow challenge={challenge} />
          {h2Handler && <DataPpmRow ppmHandler={h2Handler} />}
          {ch4Handler && <DataPpmRow ppmHandler={ch4Handler} />}
          {topSymptoms.map((s, i) => (
            <DataSymptomRow key={i} challenge={challenge} symptoms={s} />
          ))}
        </tbody>
      </table>
      {topSymptoms.length > 0 && <div className={s.dataTableDesc}>Symptoms are on a 0-10 scale</div>}
    </div>
  );
}

function DataTimeRow({ challenge }: { challenge: Challenge }) {
  return (
    <tr>
      <td>Time (mins)</td>
      {challenge.tests.map((test, i) => (
        <td key={i}>{testMinute(challenge, test)}</td>
      ))}
    </tr>
  );
}

function testMinute(challenge: Challenge, test: Test) {
  const initialTime = DateTime.fromISO(challenge.firstTest.testCreatedOn);
  const testTime = DateTime.fromISO(test.testCreatedOn);
  return Math.round(testTime.diff(initialTime).as('minutes'));
}

function DataPpmRow({ ppmHandler }: { ppmHandler: HydrogenHandler | MethaneHandler }) {
  const isPositive = !!ppmHandler.positiveRise();
  const ppms = ppmHandler.challenge.tests.map((test) => test.ppm(ppmHandler.gas)?.toFixed(0) ?? '');
  return (
    <tr>
      <td>{ppmHandler.gas === Gas.H2 ? 'H₂' : 'CH₄'} (ppm)</td>
      {ppms.map((ppm, i) => (
        <td key={i} className={isPositive ? s.positiveCell : s.negativeCell}>{ppm}</td>
      ))}
    </tr>
  );
}

function DataSymptomRow({ challenge, symptoms }: { challenge: Challenge; symptoms: Symptom[] }) {
  const scores = symptomScoresByTest(challenge.tests, symptoms).map((score) => _round(score ?? 0));
  return (
    <tr>
      <td>{symptoms[0].label}</td>
      {scores.map((score, i) => (
        <td key={i}>{score}</td>
      ))}
    </tr>
  );
}
