import tz from "../tz";

export default class Sleep {
  readonly pertainsTo: string;
  readonly score: number;
  constructor(pertainsToMs: number,score: number) {
    this.pertainsTo = tz.toLocal(pertainsToMs).toISO();
    this.score = score;
  }
}

export const createSleep = ({pertainsToMs,score}: {pertainsToMs: number, score: number}): Sleep => {
  return new Sleep(pertainsToMs,score);
}