import { createContext, useContext, useEffect, useReducer } from "react";
import { AppError } from "../../utils/utils";
import { OrderingFormFieldValues } from "./Ordering";
import { OrderingPrices, createOrderingPrice } from "./OrderingPrice";
import useBilling from "./useBilling";
import useDiscountSchemes from "./useDiscountSchemes";
import DiscountScheme from "./DiscountScheme";
import usePricings, { Pricing } from "./usePricings";
import useShipping, { ShippingError } from "./useShipping";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";

export interface OrderingItem {
  name: keyof OrderingFormFieldValues,
  skuId: number,
  label: string,
}

export const ORDERING_ITEMS: Record<keyof OrderingFormFieldValues,OrderingItem> = {
  medaire2: {
    name: "medaire2",
    skuId: 89,
    label: "Hydrogen and methane device",
  },
  fodmaps: {
    name: "fodmaps",
    skuId: 7,
    label: "Food Intolerance kit (4 tests)",
  },
  glucose: {
    name: "glucose",
    skuId: 94,
    label: "Glucose (2 packets)",
  }
}

export type BulkOrderContextState = {
  ordering: boolean,
  processing: boolean;
  error: AppError | undefined;
  complete: boolean;
  pricing: OrderingPrices | undefined;
  discountSchemes: {[skuId: number]: DiscountScheme|undefined} | undefined;
  quantities: OrderingFormFieldValues;
}

const initialState: BulkOrderContextState = {
  ordering: false,
  complete: false,
  processing: false,
  error: undefined,
  pricing: undefined,
  discountSchemes: undefined,
  quantities: {
    medaire2: 0,
    fodmaps: 0,
    glucose: 0,
  }
}

export type BulkOrderContextAction = 
  | { type: "SET_ORDERING", payload: boolean }
  | { type: "SET_PROCESSING", payload: boolean }
  | { type: "SET_ERROR", payload: AppError | undefined }
  | { type: "SET_COMPLETE", payload: boolean }
  | { type: "SET_QUANTITIES", payload: OrderingFormFieldValues }
  | { type: "SET_PRICING", payload: OrderingPrices }
  | { type: "SET_DISCOUNT_SCHEMES", payload: {[skuId: number]: DiscountScheme|undefined} }

const reducer = (state: BulkOrderContextState, action: BulkOrderContextAction): BulkOrderContextState => {
  switch (action.type) {
    case "SET_ORDERING": {
      return { ...state, ordering: action.payload }
    }
    case "SET_PROCESSING": {
      return { ...state, processing: action.payload }
    }
    case "SET_COMPLETE": {
      return { ...state, complete: action.payload }
    }
    case "SET_ERROR": {
      return { ...state, error: action.payload }
    }
    case "SET_QUANTITIES": {
      return { ...state, quantities: action.payload }
    }
    case "SET_PRICING": {
      return { ...state, pricing: action.payload }
    }
    case "SET_DISCOUNT_SCHEMES": {
      return { ...state, discountSchemes: action.payload }
    }
  }
}


interface BulkOrderContextProviderProps {
  children: React.ReactNode,
}

export interface BulkOrderContextType {
  state: BulkOrderContextState, 
  dispatch: React.Dispatch<BulkOrderContextAction>
}

export const BulkOrderContext = createContext<BulkOrderContextType>({state: initialState, dispatch: () => {}});

export const BulkOrderContextProvider = ({children}: BulkOrderContextProviderProps) => {
  const [state,dispatch] = useReducer<React.Reducer<BulkOrderContextState,BulkOrderContextAction>>(reducer,initialState);

  const { state: { support, preferences } } = useContext(LoginContext);

  const { pricings } = usePricings();
  const { discountSchemes } = useDiscountSchemes();
  const { error: shippingError } = useShipping();
  const { error: billingError } = useBilling();

  useEffect(() => {
    if (!support) return;
    if (!support.bulkOrdering) dispatch({type: "SET_ERROR", payload: new AppError(403,"Bulk Ordering is not available in your country")});
  },[support]);

  useEffect(() => {
    if (!shippingError) return;
    dispatch({type: "SET_ERROR", payload: shippingError});
  },[shippingError]);

  useEffect(() => {
    if (!preferences) return;
    if (!billingError) return;
    if (state.error instanceof ShippingError && state.error.code === 404) return;
    if (billingError.code === 404 && preferences.provider.bulkOrderingInArrears) return; // Billing 404 error allowed if arrears ordering is enabled
    dispatch({type: "SET_ERROR", payload: billingError});
  },[billingError,preferences]);

  useEffect(() => {
    if (!discountSchemes) return;
    dispatch({type: "SET_DISCOUNT_SCHEMES", payload: discountSchemes});
  },[discountSchemes]);

  useEffect(() => {
    if (!pricings || !state.discountSchemes) return;
    dispatch({type: "SET_PRICING", payload: createOrderingPrices(state,pricings,state.discountSchemes)});
  },[state.quantities,pricings,state.discountSchemes]);

  useEffect(() => {
    if (state.ordering) return;
    dispatch({type: "SET_PROCESSING", payload: false});
    dispatch({type: "SET_COMPLETE", payload: false});
  },[state.ordering]);

  return <BulkOrderContext.Provider value={{state,dispatch}}>{children}</BulkOrderContext.Provider>;
};

const createOrderingPrices = (state: BulkOrderContextState, pricings: {[skuId: number]: Pricing},discounters: {[skuId: number]: DiscountScheme|undefined}): OrderingPrices=> {
  const { medaire2, fodmaps, glucose } = ORDERING_ITEMS;
  return {
    medaire2: createOrderingPrice(medaire2,state.quantities.medaire2,pricings,discounters),
    fodmaps: createOrderingPrice(fodmaps,state.quantities.fodmaps,pricings,discounters),
    glucose: createOrderingPrice(glucose,state.quantities.glucose,pricings,discounters),
  }
}