import { AppError } from "../../../utils/utils";
import s from "./ErrorP.module.scss";

interface ErrPProps {
  error: AppError|undefined;
}

export default function ErrorP({error}: ErrPProps): JSX.Element {
  if (!error) return <></>;
  return <p className={s.errP}>Error {error.code}: {error.message}</p>
}