import s from "./ForgotPassword.module.scss"; //Broke standards here by not including a ForgotPassword.scss, I tried to include the file and import the contents of Login.module.scss but could not get it to work

const RecognisedEmail = () => {
  return (
    <div className={s.recognisedEmail}>
      <h2>Success!</h2>
      <p>
        We have sent a link to your email address. Please allow a few minutes
        for it to reach your inbox, then follow the link to reset your password.
      </p>
    </div>
  );
};
export default RecognisedEmail;
