import React from "react";
import { sumQuantity, sumEarnings, currencyString } from "../sales-util";
import { Sale } from "../useSales";
import s from "../Sales.module.scss";

export default function SalesOverviewTable({sales}: {sales: Sale[]}) {
  return (
    <div className={s.salesOverviewTable}>
      <Header />
      <SubHeader />
      {binSalesByMonth(sales).map((s,i) => <Row key={i} sales={s} />)}
    </div>
  )
}

const Header = () => (
  <div className={s.header}>
    <span></span>
    <span>Dashboard</span>
    <span>foodmarble.com</span>
    <span>Total</span>
  </div>
);

const SubHeader = () => (
  <div className={s.subheader}>
    <span>Month</span>
    <span>Orders</span>
    <span>Earnings</span>
    <span>Orders</span>
    <span>Earnings</span>
    <span>Orders</span>
    <span>Earnings</span>
  </div>
);

const Row = ({sales}: {sales: Sale[]}) => (
  <div>
    <span>{monthAndYear(sales[0])}</span>
    <span>{sumQuantity(sales,"dashboard")}</span>
    <span>{currencyString(sumEarnings(sales,"dashboard"),sales[0].currency)}</span>
    <span>{sumQuantity(sales,"foodmarble.com")}</span>
    <span>{currencyString(sumEarnings(sales,"foodmarble.com"),sales[0].currency)}</span>
    <span>{sumQuantity(sales)}</span>
    <span>{currencyString(sumEarnings(sales),sales[0].currency)}</span>
  </div>
)

const monthAndYear = (sale: Sale): string => sale.occurredOn.toFormat("MMM-yyyy"); 

const binSalesByMonth = (sales: Sale[]): Sale[][] => {
  return Object.values(sales.reduce<Record<string,Sale[]>>((acc,cur) => {
    const key = monthAndYear(cur);
    if (!acc[key]) acc[key] = [];
    acc[key].push(cur);
    return acc;
  },{})).sort((a,b) => a[0].occurredOn < b[0].occurredOn ? -1 : 1);
};