import React, { Suspense } from "react";
import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import { AppError, parseURLSearchParams } from "../../../utils/utils";
import useProvider from "../../../hooks/useProvider";
import s from "./GettingStarted.module.scss";
import PreLoginError from "../../../components/errors/PreLoginError/PreLoginError";
import useTranslation from "../../../hooks/useTranslation";

const HELP_EMAIL = "healthcare@foodmarble.com";
const DEFAULT_VIDEO_LINK = "https://www.youtube.com/watch?v=NOaMGVvCpP0";
const RECIPE_ARTICLES_LINK = "https://foodmarble.com/articles/";
const preChallengeBlogLink = "https://foodmarble.com/articles/pre-challenge-meals/";

const base = getLinkBase();

const LOW_FODMAP_HANDOUT_LINK = `${base}/api/pdfs/Low-FODMAP-Food-List.pdf`;

const GettingStarted = ({history}) => (
  <Suspense fallback={<PreloginLayout />}>
    <_GettingStarted history={history} />
  </Suspense>
)

const _GettingStarted = ({history}) => {
  const { t } = useTranslation();

  const { prid } = parseURLSearchParams(history.location.search);
  const { provider, error } = useProvider(prid);

  if (!prid) return <PreLoginError error={new AppError(400,"Bad Request")} />

  if (!provider) return <PreloginLayout/>

  if (error) return <PreLoginError error={error} />

  return (
    <PreloginLayout>
      <div className={s.gettingStarted}>
        <h2>{t("gettingStarted.meta.subject")}</h2>
        <p>{t("gettingStarted.intro")}</p>
        <h3>{t("gettingStarted.whatIsAChallenge.title")}</h3>
        <p>{t("gettingStarted.whatIsAChallenge.content")}</p>
        <h3>{t("gettingStarted.whatsInYourKit.title")}</h3>
        <p>{t("gettingStarted.whatsInYourKit.device")}</p>
        <p>{t("gettingStarted.whatsInYourKit.testSugar")}</p>
        <p>{t("gettingStarted.whatsInYourKit.guidance")}</p>
        <p>{t("gettingStarted.whatsInYourKit.contact",{helpEmail: HELP_EMAIL})}</p>
        <h3>{t("gettingStarted.whatsFirst.title")}</h3>
        <ul>
            <li>{t("gettingStarted.whatsFirst.list.0")}</li>
            <li>{t("gettingStarted.whatsFirst.list.1")}</li>
            <li>{t("gettingStarted.whatsFirst.list.2")}</li>
            <li>{t("gettingStarted.whatsFirst.list.3.content")}
                <ol>
                    <li>{t("gettingStarted.whatsFirst.list.3.list.0")}</li>
                    <li>{t("gettingStarted.whatsFirst.list.3.list.1")}</li>
                    <li>{t("gettingStarted.whatsFirst.list.3.list.2")}</li>
                    <li>{t("gettingStarted.whatsFirst.list.3.list.3")}</li>
                </ol>
            </li>
        </ul>
        <h3>{t("gettingStarted.theDayBefore.title")}</h3>
        <ul>
            <li><a href={provider.videoLink ?? DEFAULT_VIDEO_LINK}>{t("gettingStarted.theDayBefore.list.0",{videoLink: provider.videoLink ?? DEFAULT_VIDEO_LINK})}</a></li>
            <li>{t("gettingStarted.theDayBefore.list.1.content")}
                <ul>
                    <li><a href={preChallengeBlogLink}>{t("gettingStarted.theDayBefore.list.1.list.0",{preChallengeBlogLink})}</a></li> 
                    <li>{t("gettingStarted.theDayBefore.list.1.list.1")}</li>
                    <li><a href={LOW_FODMAP_HANDOUT_LINK}>{t("gettingStarted.theDayBefore.list.1.list.2",{LOW_FODMAP_HANDOUT_LINK})}</a></li>
                </ul>
            </li>  
            <li>{t("gettingStarted.theDayBefore.list.2")}</li>
            <li>{t("gettingStarted.theDayBefore.list.3")}</li>
            <li>{t("gettingStarted.theDayBefore.list.4")}</li>
        </ul>
        <h3>{t("gettingStarted.theDayOf.title")}</h3>
        <ul>
            <li>{t("gettingStarted.theDayOf.list.0")}</li>
            <li>{t("gettingStarted.theDayOf.list.1")}</li>
            <li>{t("gettingStarted.theDayOf.list.2")}</li>
            <li>{t("gettingStarted.theDayOf.list.3")}</li>
        </ul>
        <h3>{t("gettingStarted.whenWillIGetResults.title")}</h3>
        <p>{t("gettingStarted.whenWillIGetResults.sentTo")}</p>
        <p>{t("gettingStarted.whenWillIGetResults.help",{helpEmail: HELP_EMAIL})}</p>
        <p>{t("gettingStarted.farewell.farewell")}</p>
        <p>{t("gettingStarted.farewell.sender")}</p>
      </div>
    </PreloginLayout>
  )
}

export default GettingStarted;

export function getLinkBase() {
  return process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_ENV === "prod"
      ? "https://clinical.foodmarble.com"
      : "https://clinical-test.foodmarble.com"
    : "http://127.0.0.1:7000";
}
