import { SIBO_LABELS } from "../consts";

export class ClinicianRequestType {
  static SIBO_LABELS = [];

  readonly crtId: number;
  readonly addedOnMs: number | undefined;
  readonly crType: number | undefined;
  readonly label: string | undefined;
  readonly description: string | undefined;

  constructor(crtId: number, addedOnMs: number | undefined | null, crType: number | undefined | null, label: string | undefined | null, description: string | undefined | null) {
    this.crtId = crtId;
    this.addedOnMs = addedOnMs ?? undefined;
    this.crType = crType ?? undefined;
    this.label = label ?? undefined;
    this.description = description ?? undefined;
  }

  isSIBO(): boolean {
    return !!(this.label && SIBO_LABELS.includes(this.label.toLowerCase()));
  }
}
