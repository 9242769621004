import { useFormContext } from "react-hook-form";
import { RequestOrderFormFieldValuesBase } from "../RequestOrderContext";
import IFTAInput from "../../../../register/IFTAElements/IFTAInput/IFTAInput";

export function OrderedOnDateInput() {
  const { register } = useFormContext<RequestOrderFormFieldValuesBase>();
  return <IFTAInput
    type="date"
    id="ordered-on"
    label="Ordered on"
    reg={register("orderedOn", {
      required: "Ordered on date is required",
    })} />;
}
