import { useContext, useEffect, useState } from "react";
import { AppError, authFetch } from "../../../utils/utils";
import { Pricing } from "../../bulk-ordering/usePricings";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import useSkus from "../../../hooks/useSkus";
import { Sku } from "../../../hooks/useSkus";
import useDiscountSchemesForProvider from "../../bulk-ordering/useDiscountSchemesForProvider";
import { Price, createPrice } from "../../bulk-ordering/Price";
import DiscountScheme from "../../bulk-ordering/DiscountScheme";
import useErrorState from "../../../hooks/useErrorState";


export default function useOrderRequestPricingFunctions(prid: number | undefined): UseOrderRequestPricingsReturn {
  const {
    state: { loginToken },
  } = useContext(LoginContext);
  const [pricings, setPricings] = useState<{ [skuId: number]: Pricing }>();
  const [skuPricings, setSkuPricings] = useState<SkuPricing[]>();
  const [pricingFunctions, setPricingFunctions] = useState<Map<number,PricingFunction>>(new Map());
  const [error, setError] = useErrorState();
  const { skus } = useSkus();
  const { schemes } = useDiscountSchemesForProvider(prid);

  useEffect(() => {
    if (!loginToken || !prid) return;
    const abortController = new AbortController();
    fetchOrderRequestPricings(loginToken, prid, abortController.signal)
      .then((pricings) => setPricings(Object.fromEntries(pricings.map((p) => [p.skuId, p]))))
      .catch(setError);
    return () => abortController.abort();
  }, [loginToken, prid]);

  useEffect(() => {
    if (!skus || !pricings) return;
    setSkuPricings(formatSkuPricings(skus, pricings));
  }, [skus, pricings]);

  useEffect(() => {
    if (!skuPricings || !schemes) return;
    setPricingFunctions(new Map(skuPricings.map(({ sku, pricing }) => [sku.skuId, createSkuFullPriceFunction(sku, pricing, schemes[sku.skuId])])));
  }, [skuPricings, schemes]);

  return { pricingFunctions, error };
}

type PricingFunction = (quantity: number) => SkuFullPrice;

function createSkuFullPriceFunction(sku: Sku, pricing: Pricing, scheme: DiscountScheme): PricingFunction {
  return (quantity: number) => createSkuFullPrice(sku,pricing,scheme,quantity);
}

function createSkuFullPrice(sku: Sku, pricing: Pricing, scheme: DiscountScheme, quantity: number): SkuFullPrice {
  const q = isNaN(quantity) ? 0 : quantity;
  return {
    sku,
    base: pricing,
    price: createPrice(sku.sku || "", q, pricing, scheme),
  };
}

function formatSkuPricings(skus: Sku[], pricings: { [skuId: number]: Pricing }): SkuPricing[] {
  return skus.reduce<SkuPricing[]>((acc, sku) => {
    const pricing = pricings[sku.skuId];
    if (pricing) acc.push({ sku, pricing });
    return acc;
  }, []);
}

async function fetchOrderRequestPricings(token: string, prid: number, signal: AbortSignal): Promise<Pricing[]> {
  const res = await authFetch(token, `/api/pricings-by-prid/${prid}`, {
    method: "GET",
    signal,
  });
  const { pricings, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return pricings;
}

export interface UseOrderRequestPricingsReturn {
  pricingFunctions: Map<number,PricingFunction>;
  error: AppError | undefined;
}

interface SkuPricing {
  sku: Sku;
  pricing: Pricing;
}

export interface SkuFullPrice {
  sku: Sku;
  base: Pricing;
  price: Price<any>;
}

