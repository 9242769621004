import useDigest, { Digest, UseDigestMethod } from "../../../hooks/useDigest";
import { DateRange } from "../../../contexts/DateRangeContext/DateRangeContext";
import Symptom, { Stool, SymptomLabel } from "../../../utils/data-classes/Symptom";
import Meal from "../../../utils/data-classes/Meal";
import Ppm from "../../../utils/data-classes/Ppm";
import Stress from "../../../utils/data-classes/Stress";
import Sleep from "../../../utils/data-classes/Sleep";
import MedLog from "../../../utils/data-classes/MedLog";
import { AppError } from "../../../utils/utils";

export function useDayToDayDigest(method: UseDigestMethod, range: DateRange): UseDayToDayDigestReturn {
  const { digest, error } = useDigest({}, method, {
    range,
    content: ["ppms", "symptoms", "meals", "sleeps", "stress", "meds"],
  });
  return {
    digest: createDayToDayDigest(digest),
    error,
  };
}

function splitStoolAndNonStoolSymptoms(symptoms: Symptom[] | undefined) {
  const nonNullishSymptoms = symptoms?.map((s) => s);
  const nonStoolSymptoms = nonNullishSymptoms?.filter((s) => s.label !== SymptomLabel.STOOL_FORM);
  const stools = nonNullishSymptoms
    ?.filter((s) => s.label === SymptomLabel.STOOL_FORM)
    ?.map((s) => Stool.fromSymptom(s));
  return {
    symptoms: nonStoolSymptoms,
    stools,
  };
}

function createDayToDayDigest(digest: Digest) {
  const { meals, ppms, sleeps, stress, meds } = digest;
  const { symptoms, stools } = splitStoolAndNonStoolSymptoms(digest.symptoms);
  return {
    meals: meals,
    ppms: ppms,
    symptoms: symptoms,
    sleeps: sleeps,
    stress: stress,
    meds: meds,
    stools: stools,
  };
}

interface UseDayToDayDigestReturn {
  digest: DayToDayDigest;
  error: AppError | undefined;
}

export interface DayToDayDigest {
  meals: Meal[] | undefined;
  ppms: Ppm[] | undefined;
  symptoms: Symptom[] | undefined;
  stools: Stool[] | undefined;
  sleeps: Sleep[] | undefined;
  stress: Stress[] | undefined;
  meds: MedLog[] | undefined;
}
