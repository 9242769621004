import styles from "./digest.module.scss";

type DigestColor =
  | "h2Blue"
  | "ch4Blue"
  | "ppmBlue"
  | "chartFontBlack"
  | "tooltipBackground"
  | "stoolOrange"
  | "borderLowestPreceeding"
  | "borderRisePositive"
  | "borderRiseNone"
  | "backgroundRisePositive"
  | "backgroundRiseNone"
  | "mealBackground"
  | "mealBackgroundHover"
  | "mealBorder"
  | "mealGreen"
  | "sleepBlue"
  | "sleepBlueHover"
  | "symptomPurple"
  | "flatulencePurple"
  | "bloatingYellow"
  | "abdominalBlue";

const _styles = styles as Record<DigestColor,string>;

export default _styles;