import Chart from "chart.js";
import { DateTime } from "luxon";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import Gas from "../../../utils/data-classes/Gas";
import { COLORS, gasColorHex, hexToRgb } from "../charts/chart-funcs";
import { configureChallengeCharts } from "../charts/chart-options";
import s from "../_styles";
import tinycolor from "tinycolor2";

const ChallengeChartOptions = (challenge: Challenge) => {
  const H2 = Gas.H2;
  const CH4 = Gas.CH4;

  return new Object({
    type: "line",
    data: {
      labels: xLabels(challenge),
      datasets: [
        gasDataset(challenge, H2),
        gasDataset(challenge, CH4),
        positiveCriteriaDataset(challenge, H2),
        positiveCriteriaDataset(challenge, CH4),
      ],
      usePointStyle: true,
    },
    options: configureChallengeCharts(challenge.label, xAndYLabels(challenge, H2)),
  });
};

const xLabels = (challenge: Challenge): string[] => {
  const start = DateTime.fromISO(challenge.tests[0].testCreatedOn);
  return challenge.tests.map((test) => DateTime.fromISO(test.testCreatedOn).diff(start).toFormat("hh:mm"));
};

const yLabels = (challenge: Challenge, gas: Gas): (number | null)[] => {
  return challenge.tests.map((test) => test.ppm(gas));
};

const xAndYLabels = (challenge: Challenge, gas: Gas): { xLabels: string[]; yLabels: (number | null)[] } => ({
  xLabels: xLabels(challenge),
  yLabels: yLabels(challenge, gas),
});

type GasDataset = Chart.ChartDataSets & {
  gas: Gas;
};

const gasDataset = (challenge: Challenge, gas: Gas): GasDataset => {
  return {
    gas: gas,
    label: DATASET_LABEL[gas.gas],
    data: yLabels(challenge, gas),
    borderColor: gasColorHex(gas),
    borderWidth: 3,
    backgroundColor: hexToRgb(gasColorHex(gas))?.toString(),
    hoverBackgroundColor: gasColorHex(gas),
    pointRadius: 5,
    pointHoverRadius: 8,
    lineTension: 0.3,
    fill: false,
  };
};

type NamedDataset = Chart.ChartDataSets & {
  name: string;
};

/**
 * @param {[Test]} tests
 * @param {Gas} gas
 * @returns
 */
const lowestPrecedingValueDataset = (tests: Challenge, gas: Gas): NamedDataset => ({
  name: "LOWEST_PRECEDING_VALUE",
  label: "Lowest Preceding Value",
  data: new Array(xLabels(tests).length).fill(tests.baselineTest.ppm(gas)),
  borderColor: s.borderLowestPreceeding,
  borderWidth: 2,
  borderDash: [8, 8],
  backgroundColor: "transparent",
  hoverBackgroundColor: "transparent",
  pointRadius: 0,
  pointHoverRadius: 0,
  lineTension: 1,
  pointStyle: "line",
});

const positiveCriteriaDataset = (challenge: Challenge, gas: Gas): NamedDataset => {
  const ppmHandler = challenge.ppmHandler(gas);
  const color = COLORS[gas.gas as "H2" | "CH4"] || "";
  return {
    name: `POSITIVE_CRITERIA_${gas.fancy}`,
    label: `${gas.fancy} Positive Criteria`,
    data: new Array(xLabels(challenge).length).fill(ppmHandler?.positiveCriteria()),
    borderColor: tinycolor(color).setAlpha(0.6).toHex8String(),
    borderWidth: 2,
    borderDash: ppmHandler?.positiveRise() ? [8, 6] : [8, 8],
    backgroundColor: ppmHandler?.positiveRise() ? s.backgroundRisePositive : s.backgroundRiseNone,
    hoverBackgroundColor: "transparent",
    pointRadius: 0,
    pointHoverRadius: 0,
    lineTension: 1,
    fill: "none",
    pointStyle: "line",
  };
};

function label(gas: Gas) {
  return ` ${gas.fancy}`;
}

export const DATASET_LABEL = {
  H2: label(Gas.H2),
  CH4: label(Gas.CH4),
};

export default ChallengeChartOptions;
