import { Language, LanguageCode, languageOrder } from "../../../utils/data-classes/Language";
import useTranslation from "../../../hooks/useTranslation";
import { Control, Controller, ControllerProps, FieldPathByValue, FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import { uniqueId } from "lodash";
import s from "./LanguageSelect.module.scss";
import { Suspense, useEffect, useState } from "react";
import { Us, Es } from "react-flags-select";

const I18NextLanguageSelect = ({ languages }: { languages: Language[]; }) => (
  <Suspense fallback={<></>}>
    <_I18NextLanguageSelect languages={languages} />
  </Suspense>
)

const _I18NextLanguageSelect = ({ languages }: { languages: Language[]; }) => {
  const { t,i18n } = useTranslation();

  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    setLang(i18n.language);
  },[i18n.language]);

  return (
    <div className={s.I18NextLanguageSelect}>
      <label htmlFor="language-select">{t("header.language")}:</label>
      <div className={s.languageSelect}>
        <span>{flags[lang as LanguageCode]}</span>
        <span>
          <select id="language-select" value={lang} onChange={e => i18n.changeLanguage(e.target.value)} >
            {languages.sort((a, b) => a.name < b.name ? -1 : 1).map((l, i) => (
              <option key={i} value={l.code} title={l.name}>{l.name}</option>)
            )}
          </select>
        </span>
      </div>
    </div>
  );
};

type XxNewLanguageSelectProps = Omit<React.HTMLProps<HTMLSelectElement>,"defaultValue"|"selected"> & {
  languages: Language[],
  selected: LanguageCode,
  defaultValue?: LanguageCode,
};

export const LanguageSelect = ({ languages, selected, ...rest }: XxNewLanguageSelectProps) => {
  return (
    <div className={s.languageSelect}>
      <span>{flags[selected]}</span>
      <span>
        <select {...rest}>
          {languages.map((l, i) => <option key={i} value={l.code} title={l.name}>{l.name}</option>)}
        </select> 
      </span>
    </div>
  )
}

type ControlledLanguageSelectProps<TFieldValues extends FieldValues,TName extends FieldPathByValue<TFieldValues,LanguageCode>> = 
  & Omit<XxNewLanguageSelectProps,"control"|"name"|"shouldUnregister"|"onChange"|"value"> 
  & Omit<ControllerProps<TFieldValues,TName>,"render"|"defaultValue">;

export function ControlledLanguageSelect<TFieldValues extends FieldValues,TName extends FieldPathByValue<TFieldValues,LanguageCode>>(
  { languages, control, name, rules, shouldUnregister, defaultValue, key, ...rest }: ControlledLanguageSelectProps<TFieldValues,TName>) 
{
  const controlProps = {control,name,rules,key,defaultValue};
  return (
    <Controller
      render={({field: { value, onChange }}) => (
        <LanguageSelect languages={languages} value={value} onChange={onChange} {...rest} />
      )}
      {...controlProps}
      defaultValue={defaultValue as PathValue<TFieldValues, TName>|undefined}
    />
  );
}

export function LanguageSelectWidget<TFieldValues extends FieldValues,TName extends FieldPathByValue<TFieldValues,LanguageCode>>(
  props: Omit<ControlledLanguageSelectProps<TFieldValues,TName>,"id"|"languages">
) {
  const id = uniqueId("language-select");
  const { t } = useTranslation();
  return (
    <label htmlFor={id} className={s.languageSelectWidget}>
      <label htmlFor={id}>{t("header.language")}:</label>
      <ControlledLanguageSelect id={id} languages={languageOrder} {...props} />
    </label>
  )
} 

const flags: Record<LanguageCode,JSX.Element> = {
  "en": <Us/>,
  "es": <Es/>,
}

export default I18NextLanguageSelect;