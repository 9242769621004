import { s } from "../arrears-invoice-style";
import { Th, Thead, Tr } from "./markdown";

export function DeviceHeader() {
  return (
    <Thead>
      <Tr>
        <Th style={s.ptTable.date}>Test Date</Th>
        <Th style={s.ptTable.item}>Item</Th>
        <Th style={s.ptTable.unitPrice}>Unit Price</Th>
        <Th style={s.ptTable.total}>Total</Th>
      </Tr>
    </Thead>
  )
}