/**
 * Handles processing, completion, and submit logic for a generic form.
 * Avoids having to add logic individually for every simple form component.
 */

import { useReducer } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { AppError } from "../utils/utils";
import { DEFAULT_ERROR, createErrorToDisplay, devErrorLog } from "./useErrorState";

export default function useSubmitReducer<TFieldValues extends FieldValues>(form: UseFormReturn<TFieldValues,any>) {
  return useReducer<React.Reducer<SubmitState,SubmitAction>>(createReducer(form),submitReducerInitialState);
}

function createReducer<TFieldValues extends FieldValues>(form: UseFormReturn<TFieldValues,any>): SubmitReducer {
  return (state,action) => {
    switch (action.type) {
      case "PROCESSING": 
        return { processing: true, complete: false, error: undefined }
      case "COMPLETE":
        return { processing: false, complete: true, error: undefined }
      case "RESET":
        form.reset();
        return { processing: false, complete: false, error: undefined }
      case "ERROR":
        const error = createErrorToDisplay(action.payload,DEFAULT_ERROR);
        if (action.payload && error) devErrorLog(action.payload,error);
        return { processing: false, complete: false, error }
    }
  }
}

type SubmitReducer = (state: SubmitState, action: SubmitAction) => SubmitState

export const submitReducerInitialState: SubmitState = {
  processing: false,
  complete: false,
  error: undefined,
}

export type SubmitState = 
  | { processing: false, complete: false, error: undefined }
  | { processing: true, complete: false, error: undefined }
  | { processing: false, complete: true, error: undefined }
  | { processing: false, complete: false, error: AppError }

export type SubmitAction = 
  | { type: "PROCESSING" }
  | { type: "COMPLETE" }
  | { type: "RESET" }
  | { type: "ERROR", payload: AppError | unknown } 