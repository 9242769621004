import React, { useContext, useEffect, useState } from "react"
import OrderContext, { ShippingType } from "../../contexts/OrderContext/OrderContext"
import { AppError, devLog } from "../../utils/utils";
import { RegistrationContext } from "../../contexts/RegisterContext/RegisterContext";
import { OrderContextState, OrderContextAction, OrderStage } from "../../contexts/OrderContext/OrderContext";
import { Sku } from "../../hooks/useSkus";
import { APIRequest, DashAPIRoute } from "../../utils/apis";
import s from "./Register.module.scss";
import { BillingForm, DeliveryForm } from "./LocationForms";
import { Elements } from "@stripe/react-stripe-js";
import SelfPay from "../../components/orders/SelfPay/SelfPay";
import { SelfPayConflictError } from "../../hooks/usePatientRegistrationDetails";
import { HELP_EMAIL } from "../../utils/consts";
import STRIPE_PROMISE from "./StripePromise";
import useTranslation from "../../hooks/useTranslation";
import loadingPng from "../../assets/loading.png";
import { Information } from "../../components/tooltips/Information/Information";
import { OrderContentsType, orderContentsType } from "./sku-contents-type";

const stripeElementsFonts = [
  { cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:100,200,200i,400' }
]

const OrderForms = () => {
  const { state, dispatch: orderDispatch } = useContext(OrderContext);
  const { state: { patient, provider, staff, orderInfo } } = useContext(RegistrationContext);
  const order = orderInfo?.order;
  const sku = orderInfo?.sku;

  const { t } = useTranslation();

  const [processing, setProcessing] = useState<boolean>(false);

  const dispatchError = (error: Error|AppError) => {
    const appError = error instanceof AppError ? error : new AppError(400,error.message);
    orderDispatch({ type: "SET_ERROR", payload: appError});
  }


  /**
   * Post-order proccing
   *  - insert billing info if a payment has been made
   *  - update order with shipping info 
   */
  const onComplete = async () => {
    const { paymentMethod, paymentIntent } = state;
    if (!patient) return;
    if (order) {
      if (order.isSelfPay && (!paymentMethod && !paymentIntent?.client_secret)) throw new Error("Missing payment information needed to insert billing");
      const blid = order.isSelfPay ? await insertBilling(state) : undefined;
      await updateOrder(patient.code,order.orid,state,blid);
    }
  }

  useEffect(() => {
    const { delivery, billing, paymentIntent, paymentMethod, stripeCustomerId } = state;
    if (!order) return;
    if (!delivery.address) return;
    if (order.isSelfPay && (!paymentIntent || !paymentIntent || !stripeCustomerId || !billing.address)) return;
    setProcessing(true);
    onComplete()
      .then(() => {
        setProcessing(false)
        if (order) orderDispatch({type: "SET_ORDER_COMPLETE", payload: true});
      })
      .catch(dispatchError);
  },[state.delivery,state.paymentIntent,state.paymentMethod,state.stripeCustomerId]);

  if (!patient || !order || !provider || !staff) return <></>;

  if (processing) return <p><img src={loadingPng} className={s.globalLoadingSpinner}/></p>

  return (
    <>
      <OrderDescription isSelfPay={order.isSelfPay} />
      { state.orderStage === OrderStage.DELIVERY && <DeliveryForm /> }
      { order?.isSelfPay && state.orderStage === OrderStage.BILLING && <BillingForm /> }
      { order?.isSelfPay && state.orderStage === OrderStage.PAYMENT && (
        <Elements stripe={STRIPE_PROMISE}>
          <h2>{t("order.payment")}</h2>
          <SelfPay orid={order.orid} stfid={staff.stfid} patientCode={patient.code} />
        </Elements>
      )}
    </>
  )
}

const OrderDescription = ({isSelfPay}: {isSelfPay: boolean}) => {
  if (isSelfPay) return <OrderDescriptionWithSelfPay />;
  return <OrderDescriptionWithoutSelfPay />;
}

const OrderDescriptionWithSelfPay = () => {
  const { state: { order, localPrices } } = useContext(OrderContext);
  const { shipping, currencySymbol } = localPrices;

  const { t } = useTranslation();

  const tShippingType = (type: ShippingType): string => {
    switch (type) {
      case "express": 
        return t("order.shipping.expressShipping");
      case "free":
        return t("order.shipping.freeShipping");
    }
  }

  function itemHeading(sku: Sku,type: OrderContentsType): string {
    switch (type) {
      case "device":
        return `FoodMarble ${deviceLabels[sku.skuId] || ""}`;
      default:
        return t(`order.orderCopy.${type}.heading`);
    }
  }

  function itemCopy(section:"heading"|"subHeading"|"tooltip") {
    const sku = order.sku;
    if (!sku) return ""
    const type = orderContentsType(sku.skuId);
    if (section === "heading") return itemHeading(sku,type);
    return t(`order.orderCopy.${type}.${section}`);
  }

  const { unitPriceNet, unitDiscount, quantity } = useOrderItemPrices();
  const priceBase = (unitPriceNet + unitDiscount) * quantity;
  const priceNet = unitPriceNet * quantity;
  const isDiscounted = priceNet < priceBase;

  return (
    <div className={s.orderDesc}>
      <h2>{t("order.title")}</h2>
      <div className={s.orderItem}>
        <div>
          <span>{itemCopy("heading")}</span>
          <span>
            <span>{itemCopy("subHeading")}</span>
            <span><Information data={itemCopy("tooltip")} /></span>
          </span>
        </div>
        <div>
          {isDiscounted && <span>{priceString(priceBase,currencySymbol)}</span>}
          <span>{priceString(priceNet,currencySymbol)}</span>
        </div>
      </div>
      <div>
        <div>
          <span>{tShippingType(order.shipping)}</span>
        </div>
        <div>
          <span>{priceString(shipping.final, currencySymbol)}</span>
        </div>
      </div>
      <div className={s.total}>
        <div>
          <span>{t("order.total")}</span>
        </div>
        <div>
          {isDiscounted && <span>{priceString(priceBase,currencySymbol)}</span>}
          <span>{priceString(priceNet,currencySymbol)}</span>
        </div>
      </div>
    </div>
  );
}

const deviceLabels: Partial<Record<number,string>> = {
  72: "AIRE",
  68: "AIRE 2",
  89: "MedAIRE 2",
}

function useOrderItemPrices() {
  const orderItem = useContext(RegistrationContext).state.orderInfo?.orderItem
  return {
    quantity: orderItem?.quantity ?? 0,
    unitPriceNet: orderItem?.unitPriceNet ?? 0,
    unitDiscount: orderItem?.unitDiscount ?? 0,
    unitPriceGross: orderItem?.unitPriceGross ?? 0,
    unitTax: orderItem?.unitTax ?? 0,
  }
}

const OrderDescriptionWithoutSelfPay = () => {
  const { state: { order } } = useContext(OrderContext);
  const { t } = useTranslation();
  return (
    <div className={s.orderDesc}>
      <h2>{t("order.title")}</h2>
      <div className={s.itemPrice}>
        <span>FoodMarble {order.sku?.shortLabel}</span>
        <span></span>
      </div>
    </div>
  )
}

const priceString = (price: number|undefined, symbol: string|undefined) => {
  if (price === undefined || symbol === undefined) return "";
  return `${symbol}${price.toFixed(2)}`;
}

interface UpdateOrderPaymentData {
  blid: number
  total: number;
  taxTotal: number;
  stripeIntentId: string;
  stripeMethodId: string;
  currency: string;
}

interface UpdateOrderPostBody {
  shippingDetails: {
    address: string,
    city: string,
    zip: string,
    country: string,
    state: string,
    name: string,
    email: string,
    phone: string,
  },
  orid: number,
  code: string,
  paymentData?: UpdateOrderPaymentData,
}

const updateOrder = async (code: string, orid: number, ctx: OrderContextState, blid?: number|undefined): Promise<number> => {
  const { delivery, paymentIntent, paymentMethod, stripeCustomerId } = ctx;
  const { address, city, zipCode, countryCode, stateCode, firstName, lastName, email, phone } = delivery;
  const { total, taxTotal, currency } = ctx.order;
  if (!address || !city || !zipCode || !countryCode || !stateCode || !firstName || !lastName || !email || !phone) {
    throw new TypeError("Missing delivery information");
  }

  const body: UpdateOrderPostBody = {
    shippingDetails: {
      address,
      city,
      zip: zipCode,
      country: countryCode,
      state: stateCode,
      name: `${firstName} ${lastName}`,
      email,
      phone,
    },
    orid,
    code,
  }

  let paymentData: UpdateOrderPaymentData | undefined;

  if (blid) {
    if (!paymentIntent || !paymentMethod || !total || !currency) throw Error("Payment made but information missing to update order");
    paymentData = {
      blid,
      total,
      taxTotal: taxTotal ?? 0,
      stripeIntentId: paymentIntent.id,
      stripeMethodId: paymentMethod.id,
      currency,
    }
    body.paymentData = paymentData;
  } 
  devLog("Updating Order");
  const { orid: returnedOrid, error } = await new APIRequest(DashAPIRoute.UPDATE_ORDER).post(body);
  if (error) throw error;
  return orid
}

const insertBilling = async (ctx: OrderContextState): Promise<number> => {
  if (!ctx.billing.firstName || !ctx.delivery.firstName || !ctx.stripeCustomerId) throw Error("Missing information to insert billing");
  devLog("Inserting billing");
  const { blid, error } = await new APIRequest(DashAPIRoute.INSERT_BILLING).post({
    billing: ctx.billing,
    delivery: ctx.delivery,
    stripeCustomerId: ctx.stripeCustomerId,
  });
  if (error) throw error;
  return blid;
}

export default OrderForms;

