import InterfaceButton from "../../../components/buttons/InterfaceButton/InterfaceButton";
import { Patient } from "../../../utils/data-classes/Patient";
import { ReactComponent as Icon } from "../../../assets/icons/forward-document.svg";
import { useContext, useEffect, useState } from "react";
import { AppError, authFetch } from "../../../utils/utils";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import _variables from "../../../styles/_variables/variables";
import useErrorState from "../../../hooks/useErrorState";
import Modal from "../../../components/dialogs/Modal/Modal";
import s from "./Challenges.module.scss";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import { isFoodIntoleranceSubstrate } from "../../../utils/data-classes/substrates";

interface FoodIntoleranceGuidanceButtonProps {
  patient: Patient;
  challenge: Challenge;
}

export default function FoodIntoleranceGuidanceButton({ patient, challenge }: FoodIntoleranceGuidanceButtonProps) {
  const substrate = challenge.label.toLowerCase();
  const { loginToken } = useContext(LoginContext).state;
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useErrorState();
  const [success, setSuccess] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<URL>();

  const abortController = new AbortController();
  useEffect(() => () => abortController.abort(), []);

  const onClick = async () => {
    setProcessing(true);
    setError(undefined);
    try {
      if (!loginToken) return;
      const pdf = await sendAdviceEmail(loginToken, patient, substrate, abortController.signal);
      setPdfUrl(pdf);
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setProcessing(false);
    }
  };

  const closeModal = () => setPdfUrl(undefined);

  if (!isFoodIntoleranceSubstrate(substrate) || !challenge.isPositive) return <></>;

  return (
    <>
      <SuccessModal url={pdfUrl} close={closeModal} />
      <InterfaceButton
        type="button"
        title={`Send ${substrate} intolerance guidance email to patient ${patient.name}`}
        icon={<Icon />}
        disabled={success}
        onClick={onClick}
        loading={!patient || processing}
      >
        <p style={{ color: color(error) }}>
          {error && error.message}
          {!error && "Send Guidance"}
        </p>
      </InterfaceButton>
    </>
  );
}

interface SuccessModalProps {
  url: URL | undefined;
  close: () => void;
}

function SuccessModal({ url, close }: SuccessModalProps) {
  return (
    <Modal isOpen={!!url} onAfterClose={close}>
      <div className={s.modalContent}>
        <h2>Dietary advice sent</h2>
        <p>
          Guidance document "<PdfLink url={url} />" has been emailed to the patient.
        </p>
        <button type="button" className={s.btnPrimary} onClick={close}>
          Return
        </button>
      </div>
    </Modal>
  );
}

function PdfLink({ url }: { url: URL | undefined }) {
  return (
    <a href={url?.href} target="_blank">
      {getFilename(url)}
    </a>
  );
}

function getFilename(url: URL | undefined): string {
  if (!url) return "";
  const parts = url.href.split("/");
  return parts[parts.length - 1];
}

async function sendAdviceEmail(loginToken: string, patient: Patient, substrate: string, signal: AbortSignal) {
  const res = await authFetch(loginToken, "/api/food-intolerance-advice/send-email", {
    method: "POST",
    body: JSON.stringify({
      ptid: patient.ptid,
      substrate,
    }),
    signal,
  });
  const json = await res.json();
  const error: string | undefined = json.error;
  if (error !== undefined) throw new AppError(res.status, json.error);
  const pdfLink: string = json.pdfLink;
  return new URL(pdfLink);
}

function color(error: AppError | undefined) {
  if (error) return _variables.errRed;
  return "";
}
