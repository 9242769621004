import { diff } from "../../utils";
import Gas from "../Gas";
import Symptom from "../Symptom";
import { Test } from "../Test";
import { Challenge } from "./Challenge";
import { HydrogenHandler } from "./HydrogenHandler";
import { MethaneHandler } from "./MethaneHandler";

export function isPositiveChallenge(challenge: Challenge): boolean {
  const handlers = [new HydrogenHandler(challenge),new MethaneHandler(challenge)];
  const result = !!handlers.find(handler => !!handler.positiveRise());
  return result;
}

export const symptomsForChallenge = (challenge: Challenge, symptoms: Symptom[]) => {
  const result = symptoms
    .filter((symptom) => _isSymptomForChallenge(symptom, challenge))
    .sort((a, b) => diff(a.pertainsTo, b.pertainsTo, "milliseconds").as("milliseconds"));
  return result;
};

function _isSymptomForChallenge(symptom: Symptom, challenge: Challenge) {
  if (!symptom.score) return false;
  const [start, end] = challenge.timeRangeDateTime();
  if (symptom.pertainsTo < start.toISO()) return false;
  if (symptom.pertainsTo > end.plus({ minutes: undefined }).toISO()) return false;
  return true;
}

/**
 * Recursively builds array matching symptoms to associated tests based on time, padded with nulls
 * @param tests - sorted by testCreatedOn
 * @param symptoms - sorted by pertainsTo
 * @param i - index of current test
 * @param j - index of current symptom
 * @param result
 * @returns
 */
export const symptomScoresByTest = (
  tests: Test[],
  symptoms: Symptom[],
  i = 0,
  j = 0,
  result: (number | null)[] = []
): (number | null)[] => {
  if (i === tests.length) return result;

  const score = tests[i].symptomScoreForTest(symptoms[j], tests[i + 1]);
  result.push(score);

  return symptomScoresByTest(tests, symptoms, i + 1, score === null ? j : j + 1, result);
};

export const maxTestAndIndex = (tests: Test[], gas: Gas) => {
  let maxTest = tests.length > 0 ? tests.reduce((t1, t2) => ((t1.ppm(gas) ?? 0) > (t2.ppm(gas) ?? 0) ? t1 : t2)) : null;
  let maxTestIndex = maxTest ? tests.indexOf(maxTest) : null;
  return { test: maxTest, index: maxTestIndex };
};

/**
 * @param {number[]} x
 * @param {number[]} y
 * @returns {number}
 */
export const areaUnderCurve = (x: number[], y: number[]) => {
  let result = 0;
  for (let i = 1; i < x.length; i++) result += ((x[i] - x[i - 1]) * (y[i - 1] + y[i])) / 2;
  return result;
};

