import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { Patient } from "../../../utils/data-classes/Patient";
import Modal from "../../../components/dialogs/Modal/Modal";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import { UseFormReturn, useForm } from "react-hook-form";
import IFTAPhoneInput from "../../register/IFTAElements/IFTAPhoneInput/IFTAPhoneInput";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import DialogNav from "../../../components/dialogs/DialogNav/DialogNav";
import { AppError, authFetch } from "../../../utils/utils";
import useSubmitReducer, { SubmitAction, SubmitState } from "../../../hooks/useSubmitReducer";
import s from "./Patient.module.scss";
import { validatePatientPhoneNumber } from "../../home/add-patient/add-patient-util/add-patient-util";

export function PatientEditDetailsModal({patient,isOpen,close}:{ patient: Patient; isOpen: boolean; close: () => void; }) {
  const form = useForm<EditPatientDetailsFormFieldValues>({
    defaultValues: {
      firstName: patient.firstName,
      lastName: patient.lastName,
      phone: patient.phone,
      dateOfBirth: patient.dateOfBirth ?? "",
    }
  });

  /** 
   * Workaround for weird rendering bug where phone number reverts to default when fully deleted
   */
  useEffect(() => {
    if (!form.watch("phone")) form.setValue("phone","");
  },[form.watch("phone")]);

  const [state,dispatch] = useSubmitReducer(form);
  return (
    <ModalContext.Provider value={{patient,form,state,dispatch,isOpen,close}}>
      <_PatientEditDetailsModal />
    </ModalContext.Provider>
  );
}

function _PatientEditDetailsModal() {
  const { state: { complete, error }, isOpen, close } = useContext(ModalContext);
  return (
    <Modal isOpen={isOpen} onRequestClose={close} onAfterClose={() => (error || complete) && window.location.reload()}>
      <div className={s.modalContent}>
        <DialogNav onCloseButtonClick={close} />
        <Form />
        <Success />
        <Err />
      </div>
    </Modal>
  );
}

function Form() {
  const { state: { loginToken, provider, support } } = useContext(LoginContext);
  const { patient, form, dispatch, state: { processing, error, complete } } = useContext(ModalContext);

  if (!patient || !form || !loginToken || !provider || !support) return <></>;
  if (error || complete) return <></>;

  const { register, formState: { errors },  handleSubmit } = form;

  const onSubmit = async (data: EditPatientDetailsFormFieldValues) => {
    dispatch({ type: "PROCESSING" });
    updatePatientDetails(loginToken, patient, data)
      .then(() => dispatch({ type: "COMPLETE" }))
      .catch(err => dispatch({ type: "ERROR", payload: err }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}> 
      <h1>Edit Patient Details</h1>
      <IFTAInput id="first-name" label="First Name" reg={register("firstName")} />
      <FormErrorMessage errors={errors} name="firstName" />
      <IFTAInput id="last-name" label="Last Name" reg={register("lastName")} />
      <FormErrorMessage errors={errors} name="lastName" />
      {support.sms && (
        <IFTAPhoneInput 
          id="phone" label="Phone Number" form={form} name="phone" defaultCountry={provider.country} 
          defaultValue=""
          rules={{validate: (phoneNumber) => validatePatientPhoneNumber(phoneNumber)}} 
        />
      )}
      <FormErrorMessage errors={errors} name="phone" />
      <IFTAInput type="date" id="date-of-birth" label="Date of Birth" reg={register("dateOfBirth")} />
      <FormErrorMessage errors={errors} name="dateOfBirth" />
      <button type="submit" className={s.btnPrimary} disabled={processing}>{processing ? "Processing..." : "Submit"}</button>
    </form>
  )
}

function Success() {
  const { state: { complete } } = useContext(ModalContext);
  if (!complete) return <></>;
  return (
    <div>
      <h1>Success</h1>
      <p>Patient details updated successfully</p>
      <ReturnButton />
    </div>
  )
}

function Err() {
  const { state: { error } } = useContext(ModalContext);
  if (!error) return <></>;
  return (
    <div>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
      <ReturnButton />
    </div>
  )
}

function ReturnButton() {
  const { close } = useContext(ModalContext);
  return <button type="button" className={s.btnPrimary} onClick={close}>Return</button>;
}

interface BodyJSON {
  firstName: string,
  lastName: string,
  phone?: string,
  dateOfBirth?: string,
}

function body({firstName,lastName,phone,dateOfBirth}: EditPatientDetailsFormFieldValues): string {
  const result: BodyJSON = {
    firstName,
    lastName,
  }
  if (phone) result.phone = phone;
  if (dateOfBirth) result.dateOfBirth = dateOfBirth;
  return JSON.stringify(result);
}

async function updatePatientDetails(loginToken: string, patient: Patient, data: EditPatientDetailsFormFieldValues): Promise<void> {
  const res = await authFetch(loginToken, `/api/patient/${patient.ptid}`, {
    method: "PUT",
    body: body(data),
  });
  const { error }: { error: string | undefined; } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return;
}

const ModalContext = createContext<TModalContext>({
  patient: undefined,
  form: undefined,
  state: {
    complete: false,
    processing: false,
    error: undefined,
  },
  dispatch: () => {},
  isOpen: false,
  close: () => {},
});

interface EditPatientDetailsFormFieldValues {
  firstName: string;
  lastName: string;
  phone: string;
  dateOfBirth: string;
}

interface TModalContext {
  patient: Patient | undefined,
  form: UseFormReturn<EditPatientDetailsFormFieldValues,any> | undefined;
  state: SubmitState;
  dispatch: React.Dispatch<SubmitAction>;
  isOpen: boolean,
  close: () => void,
}