import { buildChallengesURL } from "../digest/challenges/Challenges";
import { Link } from "react-router-dom";
import { Staff } from "../../utils/data-classes/Staff";
import PosNegMarker from "../../components/data-display/PosNegMarker/PosNegMarker";
import { Challenge } from "../../utils/data-classes/challenge/Challenge";
import { DateTime } from "luxon";
import s from "./SiteOverview.module.scss";
import { Patient } from "../../utils/data-classes/Patient";
import InviteButton from "../home/ResendPatientInviteButton";
import ChallengePromptModalButton from "../../components/buttons/ChallengePromptModalButton/ChallengePromptModalButton";
import { buildDashURL } from "../../utils/utils";
import { LoginRoutes } from "../../routers/DashRouter";
import { isPositiveChallenge } from "../../utils/data-classes/challenge/challenge-util";
import { useDateFormat } from "../../hooks/useDateFormat";
import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg";
import { RPMProgram } from "./RPMProgram"
import RPMButton from "./RPMButton";
import { useContext } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import Tippy from "@tippyjs/react";

interface SiteOverviewRowProps {
  patientNo: number,
  patient: Patient,
  staff: Staff,
  challenge?: Challenge,
  challengeOnly: boolean,
  accessible: boolean,
  requestedChallenge?: string | undefined,
  rpm: RPMProgram | undefined;
  showRPM: boolean | undefined;
}

const buildRowURL = (patient: Patient, challenge: Challenge|undefined) => {
  const active = !!patient.uid;
  if (!active) return buildDashURL(LoginRoutes.PATIENT.path,new URLSearchParams({ptid: patient.ptid.toString()}));
  return buildChallengesURL(patient.ptid,challenge?.epochMs);
}

const SiteOverviewRow = (props: SiteOverviewRowProps) => {
  const {patientNo,patient,staff,challenge,challengeOnly,accessible,rpm,requestedChallenge=undefined, showRPM} = props;
  const disabled = !patient.uid || !accessible;
  return (
    <Link to={buildRowURL(patient,challenge)}>
      <div 
        className={`${s.row} ${patientNo % 2 === 0 ? s.even : s.odd} ${disabled ? s.disabledRow : ""}`}
        title={title(patient,challenge,accessible,useDateFormat("date"))}
      >
        <div className={s.patientsCol}>
            <span>{!challengeOnly && nameAbbreviated(patient)}</span>
            <span>{!challengeOnly && nameAbbreviated(staff)}</span>
            <span>{!challengeOnly && patientActivity(patient,rpm)}</span>
        </div>
        {!requestedChallenge && <ChallengesColCells {...props} /> }
        {requestedChallenge && <ChallengesColCellsWithRequestedChal {...props} />}
        {showRPM && <RPMCells {...props} />}
      </div>
    </Link>
  )
}

const ChallengesColCells = (props: SiteOverviewRowProps) => {
  const { challenge } = props;
  const dateFormat = useDateFormat("date");
  return (
    <div className={s.challengesCol}>
      <span className={s.challengeDate}>{challenge && challenge.createdOnDateTimeUTC.toFormat(dateFormat)}</span>
      <span>{challenge && challenge.label}</span>
      <span>{challenge && <span className={s.resultsWrapper}><PosNegMarker isPositive={isPositiveChallenge(challenge)} /></span>}</span>
      <ActionButtons {...props} />
    </div>
  )
}

const ChallengesColCellsWithRequestedChal = (props: SiteOverviewRowProps) => {
  const label = props.requestedChallenge;
  return (
    <div className={s.challengesCol}>
      <span></span>
      <span>{label}</span>
      <span></span>
      <ActionButtons {...props} />
    </div>
  )
}

function ActionButtons({patient,challenge,requestedChallenge,challengeOnly}: SiteOverviewRowProps) {
  return (
    <>
      <span>
        {!challengeOnly && (<NotificationModalsButton patient={patient} requestedChallenge={requestedChallenge} />)}
      </span>
      <span>
        {requestedChallenge && <ChallengePromptModalButton action="CANCEL" patient={patient} requestedChallenge={requestedChallenge} />}
        {challenge?.settings.isReviewed && <ReportReviewedMarker />}
      </span>
    </>
  )
}

function ReportReviewedMarker() {
  const title = "Report reviewed";
  return (
    <Tippy content={<span>{title}</span>}>
      <TickIcon title={title} />
    </Tippy>
  )
}

const title = (patient: Patient,challenge: Challenge|undefined,hasAccess: boolean,dateFormat: string) => {
  if (!patient.uid) return `Go to patient details page for ${patient.name}`;
  if (!hasAccess) return "You do not have access to this patient";
  if (challenge) return `View challenge performed on ${DateTime.fromISO(challenge.createdOn).toFormat(dateFormat)}`;
  return `Go to challenges page for ${patient.name}`;
}

interface NotificationModalsButtonProps {
  patient: Patient,
  requestedChallenge: string|undefined,
}

const NotificationModalsButton = ({patient,requestedChallenge}: NotificationModalsButtonProps) => {
  const canInvite = !patient.uid;
  const canChallengePrompt = !!patient.uid && !patient.isAnonymous();
  if (canInvite) return <InviteButton patient={patient} />
  if (canChallengePrompt) return <ChallengePromptModalButton 
    action={requestedChallenge ? "REMIND" : "CREATE"} 
    patient={patient} 
    requestedChallenge={requestedChallenge} 
  />
  return <></>
}

function RPMCells(props: SiteOverviewRowProps) {
  const { preferences } = useContext(LoginContext).state;
  return (
    <div className={s.rpmCol}>
      <span>{canInitRpm() && <RPMButton {...props} />}</span>
    </div>
  );
  function canInitRpm() {
    if (!preferences?.provider.hasRPM) return false;
    if (!props.patient.uid || props.challengeOnly) return false;
    return true;
  }
}

const nameAbbreviated = (person: Staff | Patient) => {
  if (person instanceof Staff) return staffNameAbbreviated(person);
  if (person instanceof Patient) return patientNameAbbreviated(person);
}

/**
 * @example
 * // returns "J. Kirk"
 * patientNameAbbreviated(Patient { firstName: James, lastName: Kirk})
 */
 const patientNameAbbreviated = (patient: Patient) => {
  if (patient.firstName && patient.lastName) return `${patient.firstName[0]}. ${patient.lastName}`;
  return patient.name;
}

/**
 * @example
 * // returns L. McCoy
 * staffNameAbbreviated(Staff {name: Leonard, surname: McCoy})
 */
const staffNameAbbreviated = (staff: Staff) => {
  if (staff.name && staff.surname) return `${staff.name[0]}. ${staff.surname}`;
  return staff.displayName;
}

const patientActivity = (patient: Patient,rpm: RPMProgram|undefined) => {
  if (!patient.uid) return "Invited";
  if (!rpm) return "Active";
  return "RPM";
}

export default SiteOverviewRow;