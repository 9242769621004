import { DateTime } from "luxon";
import React, { useContext, useState, useEffect } from "react";
import DatePicker from "../../components/datepicker/DatePicker";
import { DateRangeContext } from "../../contexts/DateRangeContext/DateRangeContext";
import DashLayout from "../../layouts/dash/DashLayout";
import { PERIODS } from "../../utils/consts";
import { SiteOverviewRowData } from "./useSiteOverview";
import s from "./SiteOverview.module.scss";
import { ChallengePromptModal } from "../../components/dialogs/ChallengePromptModal/ChallengePromptForm";
import SiteOverviewTable from "./SiteOverviewTable";
import AddPatientModal from "../home/add-patient/AddPatient";
import ErrorPage from "../../components/errors/ErrorPage/ErrorPage";
import Fuse from "fuse.js";
import { ChallengePromptContextProvider } from "../../components/dialogs/ChallengePromptModal/ChallengePromptContext";
import { Challenge } from "../../utils/data-classes/challenge/Challenge";
import { useDateFormat } from "../../hooks/useDateFormat";
import { SiteOverviewContext, SiteOverviewContextProvider } from "./SiteOverviewContext";

enum ChallengeLabel {
  LACTULOSE = "lactulose",
  GLUCOSE = "glucose",
  FRUCTOSE = "fructose",
  MANNITOL = "mannitol",
  SORBITOL = "sorbitol",
  LACTOSE = "lactose",
  INULIN = "inulin",
}

type Substrate = ChallengeLabel | "custom" | "show all";

const SiteOverview = () => {
  return (
    <SiteOverviewContextProvider>
      <_SiteOverview />
    </SiteOverviewContextProvider>
  )
}

const _SiteOverview = () => {
  const { rows, error } = useContext(SiteOverviewContext).state;
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState<boolean>(false);
  const [substrate, setSubstrate] = useState<Substrate|undefined>();
  const [query,setQuery] = useState("");
  const [fuse, setFuse] = useState<Fuse<SiteOverviewRowData>>();

  const { state: { period } } = useContext(DateRangeContext);  

  const [searchFilteredRows, setSearchFilteredRows] = useState(rows);
  const [rowsToDisplay, setRowsToDisplay] = useState(rows);

  useEffect(() => {
    if (!rows) return;
    setFuse(new Fuse(rows,SiteOverviewSearchOptions));
  },[rows]);

  useEffect(() => {
    if (!fuse) return;
    setSearchFilteredRows(!query ? rows : (fuse.search(query).map(({item}) => item) || []));
  },[fuse,query]);

  useEffect(() => {
    if (!searchFilteredRows) return;
    setRowsToDisplay(searchFilteredRows
      .map(row => ({ ...row, challenges: row.challenges?.filter((chal) => shouldDisplayChallenge(chal,substrate)) }) as SiteOverviewRowData)
      .filter(row => shouldDisplayRow(row,substrate))
    );
  },[searchFilteredRows,substrate,period]);

  if (error) return <ErrorPage code={error.code} message={error.message} />;

  return (
    <DashLayout className={s.siteOverviewLayout}>
      <Desc />
      <div className={s.siteOverviewWrapper}>
        <div className={s.filterWidgets}>
          <div>
            <span>Filter Challenges by Date:</span>
            <span>
              <DatePicker
                defaultPeriod={PERIODS.ALL}
                defaultEarliestTime={DateTime.fromISO("2020-08-01")}
                disabledPeriods={[PERIODS.DAY,PERIODS.WEEK,PERIODS.MONTH]}
              />
            </span>
          </div>
          <div>
            <span>Filter Challenges by Substrate:</span>
            <span><SubstrateSelect dispatch={setSubstrate} /></span>
          </div>
        </div>
        <label htmlFor="search" style={{display: "none"}}>Search Patients or Clinicians</label>
        <input
          id="search"
          className={s.searchInput}
          value={query}
          onChange={e => setQuery(e.target.value)}
          disabled={!fuse}
          placeholder="Search Patients or Clinicians"
        />
        <ChallengePromptContextProvider>
          <SiteOverviewTable rows={rowsToDisplay} setIsAddPatientModalOpen={setIsAddPatientModalOpen} />
          <ChallengePromptModal />
        </ChallengePromptContextProvider>
        <AddPatientModal isOpen={isAddPatientModalOpen} setIsOpen={setIsAddPatientModalOpen} />
      </div>
    </DashLayout>
  );
}

function Desc() {
  return (
    <div className={s.desc}>
      <h1>Site Overview <span>{DateTime.local().toFormat(useDateFormat("date"))}</span></h1>
      <p>This page shows all the patients linked to your site.</p>
      <p>Select a patient to view their data.</p>
    </div>
  )
}

function SubstrateSelect({dispatch}: {dispatch: React.Dispatch<ChallengeLabel|undefined>}) {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
      if (value === "") {
        dispatch(undefined);
        return;
      }
      dispatch(e.target.value as ChallengeLabel);
  }

  return (
    <select className={s.chalLabelSelect} onChange={onChange} defaultValue="">
      <Option value="" disabled >Select Substrate</Option>
      <Option value="show all">Show All</Option>
      <Option value="custom">Custom Challenge</Option>
      {Object.values(ChallengeLabel).sort((a,b) => a < b ? -1 : 1).map(
        (label,i) => <Option key={i+2} value={label}>{label}</Option>
      )}
    </select>
  );
}

type TypedOptionProps<T extends string> = {value: T} & Omit<React.HTMLProps<HTMLOptionElement>,'value'>

const Option = (props: TypedOptionProps<""|Substrate>) => <option {...props} />

const KNOWN_CHAL_LABELS = Object.fromEntries(Object.values(ChallengeLabel).map(val => [val,1]));

const shouldDisplayChallenge = (chal: Challenge,substrate: Substrate|undefined): boolean => {
  if (!substrate || substrate === "show all") return true;
  if (substrate === "custom") return !KNOWN_CHAL_LABELS[chal.label.toLowerCase()];
  return chal.label.toLowerCase() === substrate.toLowerCase();
}

const shouldDisplayRow = (row: SiteOverviewRowData, substrate: Substrate|undefined): boolean => {
  if (PERIODS.ALL && (!substrate || substrate === "show all")) return true;
  if (row.challenges && row.challenges.length > 0) return true;
  return false;
}

const SiteOverviewSearchOptions: Fuse.IFuseOptions<SiteOverviewRowData> = {
  keys: [
    "patient.firstName",
    "patient.lastName",
    "patient.email",
    "patient.identifier",
    "staff.name",
    "staff.surname",
    "staff.email",
  ]
};

export default SiteOverview;