import useDataAuth from "../../../../hooks/useDataAuth";
import { AppError, authFetch } from "../../../../utils/utils";

export default function useOrderStatuses() {
  const { data, error } = useDataAuth(fetchOrderStatuses);
  return { orderStatuses: data, error };
}

async function fetchOrderStatuses(loginToken: string, signal: AbortSignal): Promise<OrderStatus[]> {
  const res = await authFetch(loginToken,"/api/order-statuses",{signal});
  const json = await res.json();
  const orderStatuses: any[] = json.orderStatuses;
  const error = json.error;
  if (error !== undefined) throw new AppError(res.status,error);
  return orderStatuses.map(createOrderStatus);
}

function createOrderStatus(data: any): OrderStatus {
  return {
    orderStatus: data.orderStatus,
    label: data.label,
    countAsSale: data.countAsSale,
  }
}

export interface OrderStatus {
  orderStatus: number;
  label: string;
  countAsSale: boolean;
}