import React, { useEffect, useState } from "react";
import { AppError, TAppError, createAppError } from "../utils/utils";

/**
 * Hook for processing and logging of errors that occur in data fetching hooks.
 * * Returns error as a sanitised AppError if error is actually a non-operational error that occurred on the client-side.
 * * In production: logs sanitised AppError
 * * In development: logs actual error that occurred
 * @param defaultError
 * @returns
 */
function useErrorState(defaultError: TAppError = DEFAULT_ERROR): [AppError | undefined, React.Dispatch<any>] {
  const [realError, setRealError] = useState<any>();
  const [displayError, setDisplayError] = useState<AppError>();

  useEffect(() => {
    setDisplayError(createErrorToDisplay(realError, defaultError));
  }, [realError]);

  useEffect(() => {
    if (!realError || !displayError) return;
    devErrorLog(realError,displayError);
  }, [realError, displayError]);

  const error = displayError;
  const setError = setRealError;

  return [error, setError];
}

export const DEFAULT_ERROR: TAppError = {
  code: 400,
  message: "Something went wrong",
  isOperational: false,
};

export function createErrorToDisplay(error: any, defaultError: TAppError): AppError | undefined {
  if (!error) return undefined;
  if (error instanceof AppError) return error;
  return createAppError(defaultError);
}

function getErrorToLog(realError: any, displayError: AppError): any {
  if (process.env.NODE_ENV !== "development") return displayError;
  return realError;
}

export function devErrorLog(realError: any, displayError: AppError): void {
  console.error(getErrorToLog(realError,displayError));
}

export default useErrorState;
