import { useContext, useEffect, useState } from "react";
import { AppError, authFetch } from "../../../../utils/utils";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import { OrderRequest } from "./OrderRequest";
import { createOrderRequest } from "./OrderRequest";
import useErrorState from "../../../../hooks/useErrorState";

export default function useOrderRequest(requestToken: string): UseOrderRequestReturn {
  const [request, setRequest] = useState<OrderRequest>();
  const [error, setError] = useErrorState();

  const {
    state: { loginToken },
  } = useContext(LoginContext);

  useEffect(() => {
    if (!loginToken || !requestToken) return;
    const abortController = new AbortController();
    fetchOrderRequest(loginToken, requestToken, abortController.signal)
      .then(setRequest)
      .catch(setError);
    return () => abortController.abort();
  }, [requestToken]);

  return { request, error };
}

async function fetchOrderRequest(loginToken: string, requestToken: string, signal: AbortSignal) {
  const res = await authFetch(loginToken, `/api/order-request/${requestToken}`, {
    method: "GET",
    signal,
  });
  const { orderRequest, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return createOrderRequest(orderRequest);
}

interface UseOrderRequestReturn {
  request: OrderRequest | undefined;
  error: AppError | undefined;
}


