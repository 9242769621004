import Food from "../../../utils/data-classes/Food";
import Gas from "../../../utils/data-classes/Gas";
import Meal from "../../../utils/data-classes/Meal";
import MedLog from "../../../utils/data-classes/MedLog";
import Ppm from "../../../utils/data-classes/Ppm";
import Symptom from "../../../utils/data-classes/Symptom";
import {
  getUnitLabel,
  createDosageString
} from "../../../utils/dosage";

function createFoodString(foods: Food[]) {
  let contains = "";
  Object.entries(foods).forEach(([foodKey, foodObj]) => {
    const dose = createDosageString(foodObj);
    contains += `${dose} ${foodObj.label}`;
    if (foodObj.sublabel) {
      contains += " (" + foodObj.sublabel + ")";
    }
    contains += " + ";
  });
  contains = contains.replace(",", "");
  contains = contains.slice(0, -3);
  return contains;
}

function getTotalFodmap(foods: Food[], fodmapName: string) {
  let total = 0;
  Object.entries(foods).forEach(([foodKey, foodObj]) => {
    const fodmap = foodObj.getFodmap(fodmapName);
    if (fodmap) {
      total += fodmap.value;
    }
  });

  return total;
}

function dateToLocaleString(date: Date): string {
  return date.toLocaleString('en-US', { 
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
}

const excelFuncs = {
  /**
   * @returns 2d array of time, H2 and CH4 ppms sorted by time
   */
  getBreathsArray: (breathData: Ppm[]) => {
    /**
     * Adds time, h2 ppms, and ch4 ppms to ppmsByTime object, returning ppmsByTime
     */
    const addBreath = (ppmsByTime: {[time: string]: {time: Date,H2: number|undefined, CH4: number|undefined}},breath: Ppm) => {
      const time = breath.createdOn;
      if (!ppmsByTime[time]) ppmsByTime[time] = {
        time: new Date(time),
        H2: undefined,
        CH4: undefined,
      }

      const test = ppmsByTime[time];
      if (Gas.H2.isGas(breath.gasId)) test.H2 = breath.ppm;
      if (Gas.CH4.isGas(breath.gasId)) test.CH4 = breath.ppm;
      return ppmsByTime;
    }

    const header = ["time", "H2 (ppm)", "CH4 (ppm)"];

    const breathsByTime = Object.values(breathData.reduce(addBreath,{}));
    const resultData = breathsByTime
      .sort((a,b) => a.time < b.time ? 1 : -1)
      .map(({time,H2,CH4}) => [
        dateToLocaleString(time),
        H2,
        CH4
      ]);

    const result = [ header, ...resultData ];

    return result;
  },
  getMealsArray: (mealData: Meal[]) => {
    let meals: ExcelRow[] = [
      [
        "consumed on",
        "label",
        "sublabel",
        "contains",
        "lactose",
        "fructose",
        "inulin",
        "fos",
        "gos",
        "sorbitol",
        "mannitol",
        "total",
      ],
    ];

    mealData.forEach((meal) => {
      let { consumedOn, label, sublabel, foods } = meal;

      label = label ? label.trim() : "--";
      sublabel = sublabel ? sublabel.trim() : "--";
      /**
       * @todo Check that native Date works with new datetime system
       */
      let consumedOnOffset = new Date(consumedOn); // native Date object for excel compatibility
      let contains = createFoodString(foods);
      let lactose = getTotalFodmap(foods, "Lactose");
      let fructose = getTotalFodmap(foods, "Excess Fructose");
      let inulin = getTotalFodmap(foods, "Inulin");
      let fos = getTotalFodmap(foods, "FOS");
      let gos = getTotalFodmap(foods, "GOS");
      let sorbitol = getTotalFodmap(foods, "Sorbitol");
      let mannitol = getTotalFodmap(foods, "Mannitol");
      let total = lactose + fructose + inulin + fos + gos + sorbitol + mannitol;

      meals.push([
        dateToLocaleString(consumedOnOffset),
        label,
        sublabel,
        contains,
        lactose,
        fructose,
        inulin,
        fos,
        gos,
        sorbitol,
        mannitol,
        total,
      ]);
    });

    return meals;
  },
  getIngredientsArray: (mealData: Meal[]) => {
    // first row in the excel sheet will be the titles
    let ingredients: ExcelRow[] = [
      [
        "consumed on",
        "belongs to",
        "label",
        "sublabel",
        "amount",
        "unit",
        "lactose",
        "fructose",
        "inulin",
        "fos",
        "gos",
        "sorbitol",
        "mannitol",
        "total",
      ],
    ];

    // loop over meals, get meal name and consumption time
    mealData.forEach((m) => {
      /**
       * @todo Check that native Date works with new datetime system
       */
      let consumedOn = new Date(m.consumedOn); // dt object for excel
      let belongsTo = m.label.trim();

      // if user did not log any ingredients for this meal, log a row to indicate this
      if (Object.entries(m.foods).length <= 0) {
        ingredients.push([
          dateToLocaleString(consumedOn),
          belongsTo,
          "No ingredients logged",
          "--",
          "--",
          "--",
          "--",
          "--",
          "--",
          "--",
          "--",
          "--",
          "--",
          "--",
        ]);
        return; // move to the next meal
      }

      // if there are ingredients, loop over them, get their details and create a log for each
      Object.entries(m.foods).forEach(([foodKey, foodObj]) => {
        let label = "--";
        if (foodObj.label) {
          label = foodObj.label.trim();
        }

        let sublabel = "--";
        if (foodObj.label) {
          sublabel = foodObj.label.trim();
        }

        let amount = foodObj.amount ?? 0;
        const unit = getUnitLabel(foodObj);

        // If the fodmap object contains relevant fodmap key, log the fodmap value, or else log it as 0
        const fValue = (label: string) => foodObj.getFodmap(label)?.value ?? 0;

        let lactose = fValue("Lactose");
        let fructose = fValue("Excess Fructose");
        let inulin = fValue("Inulin");
        let fos = fValue("FOS");
        let gos = fValue("GOS");
        let sorbitol = fValue("Sorbitol");
        let mannitol = fValue("Mannitol");
        let total =
          lactose + fructose + inulin + fos + gos + sorbitol + mannitol;

        ingredients.push([
          dateToLocaleString(consumedOn),
          belongsTo,
          label,
          sublabel,
          amount,
          unit,
          lactose,
          fructose,
          inulin,
          fos,
          gos,
          sorbitol,
          mannitol,
          total,
        ]);
      });
    });

    return ingredients;
  },
  getSymptomsArray: (symptomData: Symptom[]) => {
    let symptoms: ExcelRow[] = [["occured on", "symptom", "score"]];
    /**
     * @todo Check that native Date works with new datetime system
     */
    symptomData.forEach((s) =>
      symptoms.push([
        dateToLocaleString(new Date(s.pertainsTo)),
        s.label,
        s.score,
      ])
    );
    return symptoms;
  },
  getMedLogsArray: (medLogs: MedLog[]) => {
    let result: ExcelRow[] = [["consumed on", "medication", "dose", "units"]];
    /**
     * @todo Check that native Date works with new datetime system
     */
    medLogs.forEach((log) => result.push([
      dateToLocaleString(new Date(log.medConsumedOn)),
      log.tradeName ?? "",
      (log.regimen ? log.regimen.dose : log.defaultDose) ?? "",
      (log.regimen ? log.regimen.units : log.units) ?? "",
    ]));
    return result;
  }
};

type ExcelCell = string | number;
type ExcelRow = ExcelCell[];


export default excelFuncs;
