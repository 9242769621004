import React, { createContext, useContext, useEffect, useReducer } from "react";
import { LocationDetails } from "../../../contexts/OrderContext/OrderContext";
import { AppError } from "../../../utils/utils";
import { AddCardAddressFormFieldValues } from "./AddCard";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";

export interface AddCardContextState {
  processing: boolean,
  complete: boolean,
  error: AppError | undefined
  billing: AddCardAddressFormFieldValues | undefined,
}

const initialState: AddCardContextState = { 
  processing: false,
  complete: false,
  error: undefined,
  billing: undefined,
}

export type AddCardContextAction = 
  | { type: "SET_PROCESSING", payload: boolean }
  | { type: "SET_COMPLETE", payload: boolean }
  | { type: "SET_ERROR", payload: AppError | undefined }
  | { type: "SET_BILLING", payload: AddCardAddressFormFieldValues | undefined }

const reducer = (state: AddCardContextState, action: AddCardContextAction): AddCardContextState => {
  switch (action.type) {
    case "SET_PROCESSING": {
      return { ...state, processing: action.payload }
    }
    case "SET_COMPLETE": {
      return { ...state, complete: action.payload }
    }
    case "SET_ERROR": {
      return { ...state, error: action.payload }
    }
    case "SET_BILLING": {
      return { ...state, billing: action.payload }
    }
  }
}

export interface AddCardContextType {
  state: AddCardContextState,
  dispatch: React.Dispatch<AddCardContextAction>,
}

export const AddCardContext = createContext<AddCardContextType>({state: initialState, dispatch: () => {}});

export const AddCardContextProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
  const { state: { preferences } } = useContext(LoginContext);

  const [state,dispatch] = useReducer<React.Reducer<AddCardContextState,AddCardContextAction>>(reducer,initialState);

  useEffect(() => {
    if (!state.complete) return;
    dispatch({type: "SET_PROCESSING", payload: false});
    dispatch({type: "SET_ERROR", payload: undefined});
  },[state.complete]);

  useEffect(() => {
    if (!preferences) return;
    if (preferences.provider.bulkOrderingInArrears) dispatch({type: "SET_ERROR", payload: new AppError(403,"Providers with arrears ordering cannot provide payment details")});
  },[preferences]);

  return (
    <AddCardContext.Provider value={{state,dispatch}}>
      {children}
    </AddCardContext.Provider>
  );
}