import Gas from "../../../utils/data-classes/Gas";
import { RPMWindowDigest, SplitDigest } from "./rpm-digest-split";
import { RPMDigest } from "./useRPMDigest";
import { SymptomLabel } from "../../../utils/data-classes/Symptom";
import { DateTime } from "luxon";
import _groupBy from "lodash/groupBy";
import { getH2Eq } from "./rpm-excel-util";
import { FODMAP_ORDER } from "./rpm-excel-util";

export function createDigestSummaries(splitDigest: SplitDigest): DigestSummaries {
  return {
    preTreatment: createDigestSummary(splitDigest.preTreatment),
    treatment: createDigestSummary(splitDigest.treatment),
    postTreatment: createDigestSummary(splitDigest.postTreatment),
  };
}

export function createDigestSummary(digest: RPMWindowDigest): DigestSummary {
  return {
    title: digest.title,
    dateRange: digest.dateRange,
    average: createAverageDaily(digest, "AVERAGE"),
    averageDailyMax: createAverageDaily(digest, "PEAK"),
    averageStool: getDailyAverageStoolScore(digest),
    dailyStoolFrequency: getDailyAverageNumberOfStools(digest),
  };
}

function createAverageDaily(digest: RPMDigest, method: AverageDailyMethod): DigestSummaryAggregateData {
  const { h2, ch4, h2Eq } = getDailyAveragePpms(digest, method);
  const { symptomAverages, aggSymptoms } = getDailyAverageSymptoms(digest, method);
  const { fodmaps, totalFodmaps } = getDailyAverageFodmaps(digest, method);

  return {
    h2,
    ch4,
    h2Eq,
    symptoms: symptomAverages,
    aggSymptoms,
    fodmaps,
    totalFodmaps,
  };
}

function groupByDate<T>(arr: T[], isoProp: KeysOfValue<T, string>) {
  return _groupBy(arr, (v) => DateTime.fromISO(String(v[isoProp])).toISODate());
}

function getDailyAverageStoolScore(digest: RPMDigest) {
  const result = getAverageDaily(
    digest.symptoms.filter((s) => s.label === SymptomLabel.STOOL_FORM),
    "pertainsTo",
    "score",
    "AVERAGE",
    false
  );
  return result;
}

function getDailyAverageNumberOfStools(digest: RPMDigest) {
  const stoolsByDate = Object.values(
    groupByDate(
      digest.symptoms.filter((s) => s.label === SymptomLabel.STOOL_FORM),
      "pertainsTo"
    )
  );
  const nDays = stoolsByDate.length;
  return stoolsByDate.map((stools) => stools.length).reduce((acc, cur) => acc + cur, 0) / nDays;
}

function getDailyAverageFodmaps(digest: RPMDigest, method: AverageDailyMethod) {
  if (method === "PEAK") return {
    fodmaps: new Map(),
    totalFodmaps: undefined,
  }
  const nDays = new Set(digest.meals.map(m => DateTime.fromISO(m.consumedOn).toISODate())).size;
  const fodmaps = new Map(FODMAP_ORDER.map((f) => [f, 0]));
  const flatFodmaps = digest.meals
    .map((meal) =>
      Object.values(meal.foods).map((food) =>
        Object.values(
          food.fodmaps.map((fodmap) => ({
            consumedOn: DateTime.fromISO(meal.consumedOn).toISODate(),
            name: fodmap.name.toLowerCase(),
            value: fodmap.value,
          }))
        )
      )
    )
    .flat(2);
  const fodmapsByLabel = _groupBy(flatFodmaps, (fodmap) => fodmap.name);
  for (const label in fodmapsByLabel) {
    fodmaps.set(label, fodmapsByLabel[label].reduce((acc,cur) => acc + cur.value,0)/nDays);
  }
  const totalFodmaps = flatFodmaps.reduce((acc,cur) => acc + cur.value,0)/nDays;
  return { fodmaps, totalFodmaps };
}

function getDailyAverageSymptoms(digest: RPMDigest, method: AverageDailyMethod) {
  const nonStoolSymptoms = digest.symptoms.filter((s) => s.label !== SymptomLabel.STOOL_FORM);

  const symptomAverages = new Map<string, number>();
  const symptomsByLabel = _groupBy(nonStoolSymptoms, (symptom) => symptom.label);
  for (const label in symptomsByLabel) {
    symptomAverages.set(label, getAverageDaily(symptomsByLabel[label], "pertainsTo", "score", method, false));
  }
  const aggSymptoms = Array.from(symptomAverages.values()).reduce((acc, cur) => acc + cur, 0);
  return { symptomAverages, aggSymptoms };
}

function getDailyAveragePpms(digest: RPMDigest, method: AverageDailyMethod) {
  const ppmVals = getPpmVals(digest);

  const h2 = getAverageDailyPpm("h2");
  const ch4 = getAverageDailyPpm("ch4");
  const h2Eq = getAverageDailyPpm("h2Eq");
  return { h2, ch4, h2Eq };

  function getAverageDailyPpm(gas: KeysOfValue<PpmVal, number | undefined>) {
    return getAverageDaily(ppmVals, "createdOn", gas, method, false);
  }
}

interface PpmVal {
  createdOn: string;
  h2: number | undefined;
  ch4: number | undefined;
  h2Eq: number | undefined;
}

function getPpmVals(digest: RPMDigest) {
  const result: PpmVal[] = [];
  const map = digest.ppms.reduce((acc, cur) => {
    if (!acc.has(cur.createdOn))
      acc.set(cur.createdOn, {
        createdOn: cur.createdOn,
        h2: undefined,
        ch4: undefined,
        h2Eq: undefined,
      });
    const ppmVals = acc.get(cur.createdOn);
    if (!ppmVals) return acc;

    ppmVals.createdOn = cur.createdOn;
    if (cur.gasId === Gas.H2.gasId) ppmVals.h2 = cur.ppm;
    if (cur.gasId === Gas.CH4.gasId) ppmVals.ch4 = cur.ppm;
    return acc;
  }, new Map<string, PpmVal>());
  map.forEach((ppmVal) => result.push({ ...ppmVal, h2Eq: getH2Eq(ppmVal.h2, ppmVal.ch4) }));
  return result;
}

function getAverageDaily<T>(
  arr: T[],
  isoProp: KeysOfValue<T, string>,
  valueProp: KeysOfValue<T, number | undefined>,
  method: "AVERAGE" | "PEAK",
  includeEmptyDays: boolean
): number {
  const valueFn = method === "AVERAGE" ? averageOfProp : peakOfProp;
  const aggregatedValues: number[] = [];
  const objsByDate = _groupBy(arr, (val) => DateTime.fromISO(String(val[isoProp])).toISODate());
  for (const isoDate in objsByDate) {
    const objs = objsByDate[isoDate];
    if (objs.length > 0 || includeEmptyDays) {
      aggregatedValues.push(valueFn(objs, valueProp));
    }
  }
  const reduced = aggregatedValues.reduce((acc, cur) => acc + cur, 0);
  const average = aggregatedValues.length > 0 ? reduced / aggregatedValues.length : 0;
  return average;
}

function averageOfProp<T>(objs: T[], valueProp: KeysOfValue<T, number | undefined>): number {
  return (
    objs.reduce((acc, cur) => {
      const value = cur[valueProp] ? Number(cur[valueProp]) : 0;
      return acc + value;
    }, 0) / objs.length
  );
}

function peakOfProp<T>(objs: T[], valueProp: KeysOfValue<T, number | undefined>): number {
  const init = objs[0][valueProp] ? Number(objs[0][valueProp]) : 0;
  return objs.reduce((acc, cur) => {
    const value = cur[valueProp] ? Number(cur[valueProp]) : 0;
    return value > acc ? value : acc;
  }, init);
}

type AverageDailyMethod = "AVERAGE" | "PEAK";

type KeysOfValue<T, TCondition> = {
  [K in keyof T]: T[K] extends TCondition ? K : never;
}[keyof T];

export interface DigestSummaries {
  preTreatment: DigestSummary;
  treatment: DigestSummary;
  postTreatment: DigestSummary;
}

export interface DigestSummary {
  title: string;
  dateRange: [string, string];
  average: DigestSummaryAggregateData;
  averageDailyMax: DigestSummaryAggregateData;
  averageStool: number | undefined;
  dailyStoolFrequency: number | undefined;
}

export type DigestSummaryAggregateData = {
  h2: number;
  ch4: number;
  h2Eq: number;
  symptoms: Map<string, number>;
  aggSymptoms: number;
  fodmaps: Map<string, number>;
  totalFodmaps: number | undefined;
};
