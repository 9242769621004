import { ReactComponent as PDFIcon } from "./pdf-icon.svg";
import InterfaceButton, { InterfaceButtonProps } from "../InterfaceButton/InterfaceButton";
import { downloadDocument } from "../../../utils/utils";

export default function PdfDownloadButton(props: PdfDownloadButtonProps) {
  const { document, filename, isDocumentDataReady, onDownload, disabled, ...rest } = props;
  return (
    <InterfaceButton
      type="button"
      icon={<PDFIcon />}
      loading={!isDocumentDataReady}
      onClick={async () => {
        if (!filename) return;
        downloadDocument(filename, document);
        onDownload && onDownload();
      }}
      disabled={disabled || !filename}
      {...rest}
    />
  );
}

type PdfDownloadButtonProps = {
  document: JSX.Element;
  filename: string | undefined;
  isDocumentDataReady: boolean;
  children?: React.ReactNode;
  onDownload?: Function;
} & Omit<InterfaceButtonProps, "type" | "label" | "icon" | "loading" | "onClick">;
