import React, { useState } from "react";
import DashLayout from "../../../layouts/dash/DashLayout";
import DigestLogic from "../DigestLogic";
import s from "./Patient.module.scss";
import DigestHeader from "../../../components/digest-header/DigestHeader";
import ErrorPage from "../../../components/errors/ErrorPage/ErrorPage";
import { DateTime } from "luxon";
import { useContext, useEffect } from "react";
import { DateRangeContext } from "../../../contexts/DateRangeContext/DateRangeContext";
import { dispatchPatientDateRangeToDateRangeContext } from "../digest-fns";
import { Digest } from "../../../hooks/useDigest";
import Ppm from "../../../utils/data-classes/Ppm";
import PatientOrderHistory from "./PatientOrderHistory";
import { Patient as _Patient } from "../../../utils/data-classes/Patient";
import { parsePhoneNumber } from "libphonenumber-js/min";
import { PatientEditDetailsModal } from "./PatientEditDetailsModal";
import { ReactComponent as EditSVG } from "../../../assets/icons/edit.svg";
import { parseTime } from "../../../utils/utils";
import { useDateFormat } from "../../../hooks/useDateFormat";

const Patient = () => {
  const { dispatch: rangeDispatch } = useContext(DateRangeContext);  
  const { patient, digest, error } = DigestLogic(["ppms"]);

  // Set date range to start at patient createdOn date to grab most recent test time
  useEffect(() => {
    if (!patient) return;
    dispatchPatientDateRangeToDateRangeContext(rangeDispatch);
  },[patient])

  if (error) return <ErrorPage code={error.code} message={error.message} />

  if (!patient) return <></>

  return (
    <DashLayout className={s.patientLayout}>
      <div className={s.patient}>
        <PageHeaderArea patient={patient} />
        <Content patient={patient} digest={digest} />
        <PatientOrderHistory patient={patient} />
      </div>
    </DashLayout>
  );
};

function PageHeaderArea({patient}: {patient: _Patient}) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className={s.headerArea}>
      <DigestHeader patient={patient} />
      <div className={s.headerAreaContent}>
        <div>
          <p>This page shows the account details for this patient.</p>
          <p>To edit details click the icon to the right.</p>
        </div>
        <div>
          <button onClick={() => setIsEditing(true)}>
            <span>Edit patient details</span><span><EditSVG /></span>
          </button>
        </div>
      </div>
      <PatientEditDetailsModal patient={patient} isOpen={isEditing} close={() => setIsEditing(false)} />
    </div>
  )
}

function Content({patient,digest}: {patient: _Patient,digest: Digest}) {
  const dateFormat = useDateFormat("all");
  return (
    <div className={s.content}>
      <PatientInfoItem label={(patient.firstName || patient.lastName) ? "Name" : "Identifier"}>{patient.name}</PatientInfoItem>
      <PatientInfoItem label="Email"><a href={`mailto: ${patient.email}`}>{patient.email}</a></PatientInfoItem>
      <PatientInfoItem label="Phone Number">{formatPhoneNumber(patient.phone)}</PatientInfoItem>
      <PatientInfoItem label="Date of Birth">{formatDate(patient.dateOfBirth,dateFormat.date)}</PatientInfoItem>
      <PatientInfoItem label="User Created">{formatDate(patient.createdOn,dateFormat.datetime)}</PatientInfoItem>
      <StatusIndicator patient={patient} />
      { digest.ppms && <PatientInfoItem label="Last Test Taken">{formatDate(getLatestPpm(digest)?.createdOn,dateFormat.datetime)}</PatientInfoItem> }
    </div>
  );
}

function PatientInfoItem({label,children}: {label: string, children: React.ReactNode}) {
  return (
    <div className={s.patientInfoItem}>
      <span>{label}:</span>
      <span>{children}</span>
    </div>
  )
}

function StatusIndicator({patient}: {patient: _Patient}) {
  const active = !!patient.uid;
  return (
    <div className={s.patientInfoItem}>
      <span>Status:</span>
      {active && <span>Active</span>}
      {!active && <span style={{color: "red"}}>Invited - Awaiting Patient Registration</span>}
    </div>
  )
}

function getLatestPpm({ppms}: Digest): Ppm | undefined {
  if (!ppms) return undefined;
  return ppms.reduce((latest,ppm) => ppm.createdOn > latest.createdOn ? ppm : latest,ppms[0]);
}

function formatDate(date: DateTime|string|undefined,format: string) {
  if (date === undefined) return "N/A";
  return parseTime(date).toFormat(format);
}

function formatPhoneNumber(phone: string|undefined): string {
  if (!phone) return "N/A";
  return parsePhoneNumber(phone).formatInternational();
}

export default Patient;