import React, { useContext, useEffect } from "react";
import s from "./AddPatient.module.scss";
import { LoginContext, LoginContextState } from "../../../contexts/LoginContext/LoginContext";
import { UseFormReturn } from "react-hook-form";
import LabelledCheckbox from "../../../components/inputs/LabelledCheckbox/LabelledCheckbox";
import { AddPatientContext } from "../../../contexts/AddPatientContext/AddPatientContext";
import LabelledRadioButton from "../../../components/inputs/typed-inputs/LabelledRadioButton";
import { Information } from "../../../components/tooltips/Information/Information";
import { PaymentMethod, AddPatientFormFieldValues } from "./AddPatient";
import { useAddPatientFormContext } from "./add-patient-util/useAddPatientFormContext";
import useStaffRole from "./add-patient-util/useStaffRole";

export const AddPatientOrderDevice = () => {
  const { state: loginState } = useContext(LoginContext);
  const { provider } = loginState;
  const {
    state: { processing, skus, orderOptions },
  } = useContext(AddPatientContext);
  const isAffiliate = useStaffRole() === "affiliate";
  const form = useAddPatientFormContext();
  const { watch } = form;

  useEffect(() => {
    registerPaymentMethod(form, selfPayRequirement(loginState));
  }, [loginState]);

  if (!provider || !skus || !orderOptions) return <></>;

  form.register("deviceModel", { required: "Device model is required" });

  return (
    <>
      <h1>Order Device</h1>
      <p>
        Please select the specifics of your device order for this patient. Should you order a FoodMarble MedAIRE device,
        you have the option of including 4 FODMAPs or 2 glucose for initial testing.
      </p>
      <div className={s.orderDevice}>
        <LabelledCheckbox form={form} name="device" disabled={isAffiliate}>
          FoodMarble Device
        </LabelledCheckbox>
        {watch("device") && (
          <>
            <h3>Device Model</h3>
            <DeviceModelOptions />
            <h3>Substrates</h3>
            <SubstrateOptions />
            {selfPayRequirement(loginState) === "optional" && <PaymentOptions />}
            { isAffiliate && <AddPatientWithoutDeviceCheckbox />}
          </>
        )}
      </div>
      <div className={s.btnGroup}>
        <button className={s.btnPrimary} type="submit" disabled={processing}>
          {processing ? "Processing..." : "Submit"}
        </button>
      </div>
    </>
  );
};

export const AddPatientOrderDevicePrompt = () => {
  const {
    state: { processing },
    dispatch,
  } = useContext(AddPatientContext);
  const form = useAddPatientFormContext();
  const onYesClick = () => {
    form.setValue("device",true);
    dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE" })
  };

  return (
    <>
      <h1>Order Device</h1>
      <p className={s.orderPrompt}>Would you like to order a device for this patient?</p>
      <div className={s.btnGroup}>
        <button className={s.btnSecondary} type="submit" disabled={processing}>
          {processing ? "Processing..." : "No"}
        </button>
        <button className={s.btnPrimary} onClick={onYesClick}>
          Yes
        </button>
      </div>
    </>
  );
};

function PaymentOptions() {
  const form = useAddPatientFormContext();
  return (
    <>
      <h3>Payment Method</h3>
      <ul className={s.radioButtons}>
        <li>
          <LabelledRadioButton form={form} path="paymentMethod" value="patient-self-pay">
            Patient self-pay
          </LabelledRadioButton>
          <span>
            <Information data="Select this option if your patient is paying for the order. We will email your patient for their billing details. The order will be sent to the patient." />
          </span>
        </li>
        <li>
          <LabelledRadioButton form={form} path="paymentMethod" value="individual-arrears">
            Clinician billed in arrears
          </LabelledRadioButton>
          <span>
            <Information data="Select this option if you are paying for the order. We will email your patient for their shipping details. The order will be sent to the patient. The order amount will be added to your next invoice." />
          </span>
        </li>
      </ul>
    </>
  );
}
type TSelfPayRequirement = "required" | "forbidden" | "optional" | undefined;

const selfPayRequirement = ({ staff, support }: LoginContextState): TSelfPayRequirement => {
  if (!staff || !support) return undefined;
  if (!support.selfPay) return "forbidden";
  if (staff.role === "affiliate") return "required";
  return "optional";
};

const defaultPaymentMethod = (req: TSelfPayRequirement): PaymentMethod => {
  if (req === "forbidden") return "individual-arrears";
  return "patient-self-pay";
};

const registerPaymentMethod = (form: UseFormReturn<AddPatientFormFieldValues, any>, req: TSelfPayRequirement) => {
  form.setValue("paymentMethod", defaultPaymentMethod(req));
  if (req !== "required" && req !== "forbidden") return;
  form.register("paymentMethod", {
    validate: (val) => val === defaultPaymentMethod(req) || `Self pay is ${req}`,
  });
};

function DeviceModelOptions() {
  const form = useAddPatientFormContext();
  const {
    state: { orderOptions },
  } = useContext(AddPatientContext);
  const hydrogenMethane = orderOptions?.hydrogenMethane.device;
  return (
    <ul className={s.radioButtons}>
      {hydrogenMethane?.isActive && (
        <li>
          <LabelledRadioButton form={form} path="deviceModel" value="2">
            Hydrogen + Methane
          </LabelledRadioButton>
        </li>
      )}
    </ul>
  );
}

function SubstrateOptions() {
  const {
    state: { orderOptions },
  } = useContext(AddPatientContext);
  const form = useAddPatientFormContext();
  const device = "hydrogenMethane";
  const fodmaps = device ? orderOptions?.[device].fodmaps : undefined;
  const glucose = device ? orderOptions?.[device].glucose : undefined;
  return (
    <ul>
      {fodmaps?.isActive && (
        <li>
          <LabelledCheckbox form={form} name="fodmaps">
            Food Intolerance Kit (lactose, fructose, inulin & sorbitol)
          </LabelledCheckbox>
        </li>
      )}
      {glucose?.isActive && (
        <li>
          <LabelledCheckbox form={form} name="glucose">
            SIBO Test (Glucose × 2)
          </LabelledCheckbox>
        </li>
      )}
    </ul>
  );
}

function AddPatientWithoutDeviceCheckbox() {
  const { register } = useAddPatientFormContext();
  const id = "no-device-checkbox";
  return (
    <div className={s.noDevice}>
      <input id={id} type="checkbox" {...register("addPatientWithoutDevice")} />
      <div>
        <label htmlFor={id}>Add patient without ordering device</label>
        <label htmlFor={id}><strong>Important:</strong> Devices cannot be ordered from the dashboard once patients are added.</label>
      </div>
    </div>
  )
}