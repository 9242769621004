import React from "react";
import s from "./LabelledCheckbox.module.scss";
import { FieldName, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { UseRegisterRules } from "../ControlledInputs/ControlledInputs";

export type LabelledCheckboxProps<TFieldValues extends FieldValues,TFieldName extends Path<TFieldValues>> = {
  form: UseFormReturn<TFieldValues,object>;
  name: TFieldName;
  rules?: UseRegisterRules<TFieldValues>;
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'form' | 'name' | 'type'>;

export default function LabelledCheckbox<TFieldValues extends FieldValues,TFieldName extends Path<TFieldValues>>(
  { form, name, rules = {},children, ...rest }: LabelledCheckboxProps<TFieldValues,TFieldName>
) {
  const { register } = form;
  return (
    <div className={s.labelledCheckbox}>
      <input id={name} type="checkbox" {...rest} {...register(name, rules)} />
      <label htmlFor={name}>{children}</label>
    </div>
  );
}
