import ChartDataPoint from "./ChartDataPoint";
import Ppm from "./Ppm";

class PpmDatapoint extends ChartDataPoint {
  readonly ppm: Ppm;
  constructor(ppm: Ppm) {
    super(ppm.createdOn,ppm.ppm);
    this.ppm = ppm;
  }
}

export default PpmDatapoint;