/**
 * Hook for get requests with an auth header.
 * Provide a type and the returned data will be assumed to be of that type.
 */

import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function useAuthGet<TJson extends { error: string }|{ error: undefined }>(input: RequestInfo): UseAuthGetReturn<TJson> {
  const { data, error } = useDataAuth(authGet<TJson>(input));
  return { data, error }
}

interface UseAuthGetReturn<TJson> { 
  data: Omit<TJson,"error">|undefined;
  error: AppError|undefined; 
};

function authGet<TResponse extends { error: string }|{ error: undefined }>(input: RequestInfo) {
  return async (loginToken: string, signal: AbortSignal): Promise<Omit<TResponse,"error">> => {
    const res = await authFetch(loginToken,input,{signal});
    const { error, ...rest }: TResponse = await res.json();
    if (error !== undefined) throw new AppError(res.status,error);
    return rest;
  }
}



