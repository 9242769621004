import { Text, View } from "@react-pdf/renderer";
import { ReactNode, useContext } from "react";
import { s } from "../arrears-invoice-style";
import { H1, Strong, Table, Th, Thead, Tr } from "./markdown";
import { INNER_BORDER } from "../arrears-invoice-style";
import { date } from "../util";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { ArrearsInvoicePage } from "./ArrearsInvoicePage";
import { DateTime } from "luxon";
import useCurrency from "../../../../contexts/CurrencyContext/useCurrency";
import ArrearsInvoiceContext from "../ArrearsInvoiceContext";

export function SummaryPage() {
  return (
    <ArrearsInvoicePage>
      <Meta />
      <Summary />
    </ArrearsInvoicePage>
  );
}

function Meta() {
  return (
    <View style={{ display: "flex", flexDirection: "row", marginBottom: "50px" }}>
      <Billing />
      <InvoiceDetails />
    </View>
  );
}

function Billing() {
  const { billing } = useContext(ArrearsInvoiceContext);
  return (
    <View style={s.meta.item}>
      <Strong>Billing to:</Strong>
      <Text>{billing.billingName}</Text>
      <Text>{billing.billingAddress1}</Text>
      <Text>{billing.billingCity}</Text>
      <Text>{billing.billingState}</Text>
      <Text>{billing.billingZip}</Text>
      <Text>{billing.billingCountry}</Text>
      <Text>
        <Strong>Email:</Strong> {billing.billingEmail}
      </Text>
      <Text>
        <Strong>Phone:</Strong> {billing.billingPhone}
      </Text>
    </View>
  );
}

/**
 * @todo THINK ABOUT TIMEZONES
 */
function InvoiceDetails() {
  const invoice = useContext(ArrearsInvoiceContext);
  return (
    <View style={{ ...s.meta.item, textAlign: "right" }}>
      <DetailsItem>
        <Strong>Invoice no.</Strong>
        <Text>{invoice.invoiceId}</Text>
      </DetailsItem>
      <DetailsItem>
        <Strong>Date of issue:</Strong>
        <Text>{date(invoice.createdOnMs, useDateFormat("date"))}</Text>
      </DetailsItem>
      <BillingPeriod />
    </View>
  );
}

function BillingPeriod() {
  const invoice = useContext(ArrearsInvoiceContext);
  const fmt = useDateFormat("date");
  if (!invoice.periodStartedOnMs || !invoice.periodEndedOnMs) return <></>;
  return (
    <DetailsItem>
      <Strong>Billing period:</Strong>
      <Text>{billingPeriod(fmt, [invoice.periodStartedOnMs, invoice.periodEndedOnMs])}</Text>
    </DetailsItem>
  );
}

const DetailsItem = ({ children }: { children: ReactNode }) => {
  return <View style={{ marginBottom: "10px" }}>{children}</View>;
};

function billingPeriod(fmt: string, range: [number, number]) {
  const startedOn = DateTime.fromMillis(range[0]);
  const endedOn = DateTime.fromMillis(range[1]).minus({ days: 1 });
  return `${startedOn.toFormat(fmt)} to ${endedOn.toFormat(fmt)}`;
}

function Summary() {
  return (
    <View>
      <H1>Summary</H1>
      <SummaryTable />
    </View>
  );
}

function SummaryTable() {
  const invoice = useContext(ArrearsInvoiceContext);
  const nPatientDeviceOrders = invoice.items.devices.shippedToPatient.length;
  const nBulkDeviceOrders = invoice.items.devices.shippedToProvider.length;
  const nSubstrateOrders = invoice.items.substrates.length;
  return (
    <Table>
      <SummaryHeader />
      <SummaryRow
        label="Direct to Patient Orders"
        quantity={nPatientDeviceOrders}
        total={invoice.shippedToPatientTotal()}
      />
      <SummaryRow label="Bulk Substrate Orders" quantity={nSubstrateOrders} total={invoice.substrateTotal()} />
      <SummaryRow label="Bulk Device Orders" quantity={nBulkDeviceOrders} total={invoice.shippedToProviderTotal()} />
      <SummaryFooter />
    </Table>
  );
}

function SummaryHeader() {
  return (
    <Thead>
      <Tr>
        <Th style={s.summaryTable.label}></Th>
        <Th style={s.summaryTable.quantity}>Quantity</Th>
        <Th style={s.summaryTable.total}>Total</Th>
      </Tr>
    </Thead>
  );
}

function SummaryRow({ label, quantity, total }: SummaryRowProps) {
  return (
    <Tr>
      <Text style={s.summaryTable.label}>{label}</Text>
      <Text style={s.summaryTable.quantity}>{quantity ?? ""}</Text>
      <Text style={s.summaryTable.total}>{useCurrency(total)}</Text>
    </Tr>
  );
}

function SummaryFooter() {
  const invoice = useContext(ArrearsInvoiceContext);
  return (
    <View style={{ ...s.general.row, borderTop: INNER_BORDER }}>
      <Text style={s.summaryTable.label}></Text>
      <Text style={s.summaryTable.quantity}>
        <Strong>Total Due</Strong>
      </Text>
      <Text style={s.summaryTable.total}>{useCurrency(invoice.total ?? 0)}</Text>
    </View>
  );
}

interface SummaryRowProps {
  label: string;
  quantity: number | undefined;
  total: number;
}
