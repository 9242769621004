import { DateTime } from "luxon";
import { currencies, Currency, OrderCurrencyCode } from "../../contexts/OrderContext/OrderContext";
import { Sku } from "../../hooks/useSkus";
import { AppError } from "../../utils/utils";
import tz from "../../utils/tz";
import useAuthGet from "../../hooks/useAuthGet";

export default function useSales(): UseSalesReturn {
  const { data, error } = useAuthGet<FetchSalesResJSON>("/api/sales");
  return { sales: createSales(data?.sales), error };
}

const createSales = (sales: SaleBackend[]|undefined): Sale[]|undefined => {
  if (!sales) return undefined;
  return sales.map(s => ({
    occurredOn: tz.toLocal(s.occurredOnMs),
    sku: s.sku,
    quantity: s.quantity,
    value: s.value,
    earnings: s.earnings,
    currency: currencies[s.currency],
    source: s.source,
  }));
}

export interface Sale {
  occurredOn: DateTime;
  sku: Sku;
  quantity: number;
  value: number;
  earnings: number;
  currency: Currency;
  source: string;
}

interface SaleBackend {
  occurredOnMs: number;
  sku: Sku;
  quantity: number;
  value: number;
  earnings: number;
  currency: OrderCurrencyCode;
  source: string;
}

interface UseSalesReturn {
  sales: Sale[]|undefined;
  error: AppError|undefined;
}

type ResponseJSON<T> = (T & {error: undefined}) | ({[Property in keyof T]: undefined} & { error: string })

type FetchSalesResJSON = 
  | { sales: SaleBackend[], error: undefined }
  | { sales: undefined, error: string }