import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react"
import { DateRange } from "../../contexts/DateRangeContext/DateRangeContext";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { DashAPIRoute } from "../../utils/apis";
import { Challenge, createChallenges } from "../../utils/data-classes/challenge/Challenge";
import { createPatient, Patient } from "../../utils/data-classes/Patient";
import { createStaff, Staff } from "../../utils/data-classes/Staff";
import { AppError, authFetch } from "../../utils/utils";
import useErrorState from "../../hooks/useErrorState";
import { createRPMProgram, RPMProgram } from "./RPMProgram";

export interface SiteOverviewRowData {
  patient: Patient,
  staff: Staff,
  challenges: Challenge[] | null,
  accessible: boolean,
  requestedChallenge: string | undefined,
  rpm: RPMProgram | undefined;
}

export interface SiteOverview {
  rows: SiteOverviewRowData[] | undefined,
  error: AppError | undefined
}

/**
 * @param {[DateTime,DateTime]} range 
 * @returns {{rows: SiteOverviewRowData[]|null, error: AppError}}
 */
const useSiteOverview = (range: DateRange): SiteOverview => {
  const [startDT,endDT] = range;
  const { state: { loginToken } } = useContext(LoginContext);

  const [rows, setRows] = useState<SiteOverviewRowData[]>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (!loginToken || !startDT || !endDT) return;
    fetchSiteOverview(loginToken,startDT,endDT)
      .then((rows) => setRows(rows.map((row: any) => createRow(row))))
      .catch(setError);
  }, [range]);

  return { rows, error };
}

const createRow = (rawRow: { patient: any; staff: any; challenges: any[] | undefined; accessible: any; requestedChallenge: any; rpm: any;}): SiteOverviewRowData => {
  return {
    patient: createPatient(rawRow.patient),
    staff: createStaff(rawRow.staff),
    challenges: rawRow.challenges ? createChallenges(rawRow.challenges) : [],
    accessible: rawRow.accessible,
    requestedChallenge: rawRow.requestedChallenge ?? undefined,
    rpm: rawRow.rpm ? createRPMProgram(rawRow.rpm) : undefined,
  }
}

async function fetchSiteOverview(token: string, startDT: DateTime, endDT: DateTime): Promise<any[]> {
  const res = await authFetch(token,DashAPIRoute.GET_SITE_OVERVIEW.path,{
    method: "POST",
    body: JSON.stringify({
      startISO: startDT.toISO(),
      endISO: endDT.toISO(),
    }),
  })
  const result = await res.json(); 
  if (result.error !== undefined) throw new AppError(res.status,result.error);
  return result.rows;
}

export default useSiteOverview;

