import { createContext, useEffect, useReducer } from "react";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import { AppError } from "../../../utils/utils";
import useDigest from "../../../hooks/useDigest";
import { DateRange } from "../../../contexts/DateRangeContext/DateRangeContext";
import { DateTime } from "luxon";
import Symptom from "../../../utils/data-classes/Symptom";

interface MiniChallengesContextState {
  fetching: boolean;
  challenges: Challenge[];
  challenge: Challenge | undefined;
  symptoms: Symptom[];
  dateRange: DateRange,
  error: AppError | undefined;
}

type MiniChallengesContextAction =
  | { type: "INIT" }
  | { type: "SET_CHALLENGES"; payload: Challenge[] }
  | { type: "SET_CHALLENGE"; payload: Challenge }
  | { type: "SET_SYMPTOMS"; payload: Symptom[] }
  | { type: "SET_ERROR"; payload: AppError | undefined };

const reducer = (
  state: MiniChallengesContextState,
  action: MiniChallengesContextAction
): MiniChallengesContextState => {
  switch (action.type) {
    case "INIT": {
      return { 
        ...state,
        fetching: true,
        dateRange: [
          DateTime.fromISO("2018-01-01"),
          DateTime.now(),
        ],
      }
    }
    case "SET_CHALLENGES": {
      return { ...state, fetching: false, challenges: action.payload }
    }
    case "SET_CHALLENGE":
      return { ...state, challenge: action.payload };
    case "SET_SYMPTOMS":
      return { ...state, symptoms: action.payload };
    case "SET_ERROR": {
      return { ...state, error: action.payload };
    }
  }
};

interface MiniChallengesContextType {
  state: MiniChallengesContextState;
  dispatch: React.Dispatch<MiniChallengesContextAction>;
}

const initialState: MiniChallengesContextState = {
  fetching: true,
  challenges: [],
  challenge: undefined,
  symptoms: [],
  dateRange: [undefined,undefined],
  error: undefined,
};

export const MiniChallengesContext = createContext<MiniChallengesContextType>({
  state: initialState,
  dispatch: () => {},
});

export const MiniChallengesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer<React.Reducer<MiniChallengesContextState, MiniChallengesContextAction>>(
    reducer,
    initialState
  );

  const { digest, error } = useDigest({},"CHALLENGES_TOKEN",{
    content: ["challenges", "symptoms"],
    range: state.dateRange,
  });

  useEffect(() => {
    dispatch({type: "INIT"});
  }, []);

  useEffect(() => {
    if (!digest.challenges || !digest.symptoms) return;
    dispatch({ type: "SET_CHALLENGES", payload: digest.challenges });
    dispatch({ type: "SET_SYMPTOMS", payload: digest.symptoms });
  },[digest]);

  useEffect(() => {
    if (!error) return;
    dispatch({ type: "SET_ERROR", payload: error });
  },[error]);

  useEffect(() => {
    if (state.challenges.length === 0) return;
    dispatch({ type: "SET_CHALLENGE", payload: getInitialChallenge(state) });
  }, [state.challenges]);

  return <MiniChallengesContext.Provider value={{ state, dispatch }}>{children}</MiniChallengesContext.Provider>;
};

function getInitialChallenge(state: MiniChallengesContextState): Challenge {
  return state.challenges.reduce((acc, cur) => (cur.createdOn > acc.createdOn ? cur : acc));
}