import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn, PathValue, FieldPathByValue } from "react-hook-form";
import { Staff } from "../../../utils/data-classes/Staff";
import _difference from "lodash/difference";
import { UseRegisterRules } from "../../inputs/ControlledInputs/ControlledInputs";
import s from "./ClinicianLinkMenu.module.scss";
import SimpleIFTASelect from "../../../pages/register/IFTAElements/IFTASelect/SimpleIFTASelect";

export interface ClinicianLinkMenuProps<TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, number[]>> {
  id: string;
  label: string;
  form: UseFormReturn<TFieldValues, any>;
  path: TPath;
  clinicians: Staff[];
  rules?: UseRegisterRules<TFieldValues>;
}

export default function P<TFieldValues extends FieldValues, TPath extends FieldPathByValue<TFieldValues, number[]>>(
  { id, label, form, path, clinicians, rules = {} }: ClinicianLinkMenuProps<TFieldValues, TPath>
) {
  const stfidToClinicianMap: { [stfid: number]: Staff; } = Object.fromEntries(clinicians.map(c => [c.stfid, c]));
  const [selectedClinicians, setSelectedClinicians] = useState<Staff[]>([]);
  const [nonSelectedClinicians, setNonSelectedClinicians] = useState<Staff[]>(clinicians.sort(compareStaff));
  const [selectValue, setSelectValue] = useState("");

  const { register } = form;

  const getValues = (path: TPath): number[] => form.getValues(path);

  const setValue = (path: TPath, numbers: number[]) => form.setValue(path,numbers as PathValue<TFieldValues, TPath>)

  const addClinician = (stfid: number) => {
    const oldStfids = getValues(path);
    setValue(path, [...oldStfids, stfid]);
    setSelectedClinicians([...selectedClinicians, stfidToClinicianMap[stfid]]);
  };

  const removeClinician = (stfid: number) => {
    const oldStfids = getValues(path);
    setValue(path, oldStfids.filter(v => v !== stfid));
    setSelectedClinicians(selectedClinicians.filter(c => c.stfid !== stfid));
  };

  useEffect(() => {
    setNonSelectedClinicians(_difference(clinicians, selectedClinicians).sort(compareStaff));
  }, [selectedClinicians]);

  useEffect(() => {
    if (!selectValue) return;
    addClinician(parseInt(selectValue));
    setSelectValue("");
  }, [selectValue]);

  register(path, rules);

  return (
    <div className={s.clinicianLinkMenu}>
      <SimpleIFTASelect id={id} label={label} onChange={(e:React.ChangeEvent<HTMLSelectElement>) => setSelectValue(e.target.value)}>
        <option value="">Select Clinician</option>
        {nonSelectedClinicians.map((c,i) => <option value={c.stfid} key={i}>{fullName(c)}</option>)}
      </SimpleIFTASelect>
      { selectedClinicians.length > 0 && <SelectedClinicians clinicians={selectedClinicians} clinicianDeleter={removeClinician} /> }
    </div>
  );
}

const SelectedClinicians = ({clinicians,clinicianDeleter}: {clinicians: Staff[], clinicianDeleter: (stfid: number) => void}) => {
  return (
    <div className={s.selected}>
      <div><span>Selected:</span></div>
      <div>
        <ul>
          {clinicians.map((c, i) => (
            <li key={i}>
              <span>{fullName(c)}</span>
              <span onClick={() => clinicianDeleter(c.stfid)} title="Remove Selection" >x</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const compareStaff = (a: Staff, b: Staff) => (a.surname ?? a.name) < (b.surname ?? b.name) ? -1 : 1;

const fullName = (staff: Staff) => `${staff.salutation ?? ""} ${staff.name} ${staff.surname}`.trim();