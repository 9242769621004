import React, { createContext, useContext, useEffect, useReducer } from "react";
import { RPMProgram } from "./RPMProgram";
import useSiteOverview, { SiteOverviewRowData } from "./useSiteOverview";
import { AppError } from "../../utils/utils";
import { DateRangeContext } from "../../contexts/DateRangeContext/DateRangeContext";

export interface SiteOverviewContextState {
  rows: SiteOverviewRowData[] | undefined;
  error: AppError | undefined;
}

export type SiteOverviewContextAction =
  | { type: "SET_ROW_DATA"; payload: SiteOverviewRowData[] | undefined }
  | { type: "UPDATE_RPM"; payload: { ptid: number; rpm: RPMProgram | undefined } }
  | { type: "SET_ERROR"; payload: AppError | undefined };

export interface SiteOverviewContextType {
  state: SiteOverviewContextState;
  dispatch: React.Dispatch<SiteOverviewContextAction>;
}

function reducer(state: SiteOverviewContextState, action: SiteOverviewContextAction): SiteOverviewContextState {
  switch (action.type) {
    case "SET_ROW_DATA": {
      return { ...state, rows: action.payload };
    }
    case "UPDATE_RPM": {
      return updateRpm(state, action.payload.ptid, action.payload.rpm);
    }
    case "SET_ERROR": {
      return { ...state, error: action.payload };
    }
  }
}

function updateRpm(
  state: SiteOverviewContextState,
  ptid: number,
  rpm: RPMProgram | undefined
): SiteOverviewContextState {
  const { rows } = state; 
  if (!rows) return state; 
  const index = rows.findIndex((r) => r.patient.ptid === ptid);
  if (index === -1) return state;
  const newRow: SiteOverviewRowData = { ...rows[index], rpm };
  const newRows = [...rows.slice(0,index),newRow,...rows.slice(index+1,rows.length)];
  return { ...state, rows: newRows };
}

const initialState: SiteOverviewContextState = {
  rows: undefined,
  error: undefined,
};

export const SiteOverviewContext = createContext<SiteOverviewContextType>({
  state: initialState,
  dispatch: () => {},
});

export function SiteOverviewContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer<React.Reducer<SiteOverviewContextState, SiteOverviewContextAction>>(
    reducer,
    initialState
  );

  const {
    state: { range },
  } = useContext(DateRangeContext);

  const { rows, error } = useSiteOverview(range);

  useEffect(() => {
    dispatch({ type: "SET_ROW_DATA", payload: rows });
  }, [rows]);

  useEffect(() => {
    dispatch({ type: "SET_ERROR", payload: error });
  }, [error]);

  return <SiteOverviewContext.Provider value={{ state, dispatch }}>{children}</SiteOverviewContext.Provider>;
}
