export enum CdAction {
  VISIT_HOME = 1,
  CREATE_NEW_PATIENT_BY_EMAIL = 2,
  CREATE_NEW_PATIENT_BY_ID = 3,
  CREATE_NEW_PATIENT_EXISTING_USER = 4,
  GET_PATIENTS_SUMMARY = 5,
  RESEND_PATIENT_INVITE = 6,
  GET_CHALLENGE_DATA = 7,
  GET_DAY_TO_DAY_DATA = 8,
  GET_WEEKLY_REPORT_DATA = 9,
  GET_PATIENT_INFO = 10,
  LOGOUT = 11,
  LOGIN = 12,
  DOWNLOAD_CHALLENGE_REPORT = 13,
  DOWNLOAD_COMBINED_CHALLENGE_REPORT = 14,
  DOWNLOAD_EXCEL_SUMMARY = 15,
  DOWNLOAD_WEEKLY_REPORT = 16,
  VISIT_DASHBOARD_ADMIN = 17,
  INVITE_ADMIN = 18,
  INVITE_CLINICIAN = 19,
  RESET_PATIENTS_PASSWORD = 20,
  GET_ALL_PATIENT_DATA = 21,
  SEND_EMAIL = 22,
  PUBLISH_SMS = 23,
  RESET_STAFF_PASSWORD = 24,
  GET_PATIENT_SUMMARIES = 25,
  FAILED_LOGIN = 26,
  VISIT_REGISTRATION_PAGE = 27,
  ACCESS_SELF_PAY_FORM = 28,
  CREATE_CLINICIAN_REQUEST = 29,
  LOGIN_REQUEST = 30,
  DOWNLOAD_RPM_EXCEL = 38,
}