import _round from "lodash/round";
import { currencies, Currency, OrderCurrencyCode } from "../../contexts/OrderContext/OrderContext";
import DiscountScheme from "./DiscountScheme";
import { Pricing } from "./usePricings";

interface TPrice<K extends string> {
  name: K;
  unitPriceGross: number;
  unitTax: number;
  unitDiscount: number;
  currency: Currency;
  quantity: number;
}

export class Price<K extends string> implements TPrice<K> {
  readonly name: K;
  readonly unitPriceGross: number;
  readonly unitTax: number;
  readonly unitDiscount: number;
  readonly currency: Currency;
  readonly quantity: number;

  constructor({ name, unitPriceGross, unitTax, unitDiscount, currency, quantity }: TPrice<K>) {
    this.name = name;
    this.unitPriceGross = unitPriceGross;
    this.unitTax = unitTax;
    this.unitDiscount = unitDiscount;
    this.currency = currency;
    this.quantity = quantity;
  }

  unitPriceNet(): number {
    return this.unitPriceGross + this.unitTax - this.unitDiscount;
  }

  totalPriceNet(): number {
    if (this.quantity === 0)
      return 0;
    return this.quantity * (this.unitPriceGross + this.unitTax - this.unitDiscount);
  }
}

export function createPrice<K extends string>(name: K, quantity: number, pricing: Pricing, discountScheme: DiscountScheme|undefined): Price<K> {
  return new Price({
    name: name,
    currency: currencies[pricing.currency as OrderCurrencyCode],
    unitPriceGross: (pricing.price ?? 0) - (pricing.taxAmount ?? 0),
    unitTax: _round(pricing.price - (pricing.price/(1 + pricing.taxAmount)),2),
    unitDiscount: discountScheme?.unitDiscount(quantity) ?? 0,
    quantity: quantity,
  })
} 



