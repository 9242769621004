/*
 * ErrorBoundary is a wrapper component for catching errors that occur in the UI:
 * https://reactjs.org/docs/error-boundaries.html
 *
 * This boundary will catch any UI crashes in its children components
 * When a UI crash happens
 *    - the boundary will log the error details
 *    - it will display a custom error component instead, e.g. <DashError />
 *
 * It is a class component because as of yet there are no hook versions of this feature (Jan 2021)
 */

import React from "react";
import { withRouter } from "react-router-dom";
import DashError from "./DashError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    /* 
      Most of the routes in <DashRouter> is wrapped with <ErrorBoundary>
      This causes a buggy behavior where errors practically disables the router 
      When an error occurs, `hasError` gets set to true and thus all navigation renders the fallback error UI
      The following code fixes this by resetting state (removing error message) when user navigates to another page
      https://stackoverflow.com/a/49341596/7228779
    */
    this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // TODO: Sentry Logging
    console.error("ERROR: ", error.toString().toUpperCase(), {
      errorInfo,
      extra: this.props.loginInfo,
    });
    // console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <DashError />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
