/**
 * Functions for generating ChartOptions for charts.js charts
 */

import Chart from "chart.js"
import { DateTime } from "luxon"
import { isISODateWithoutTime } from "../../../utils/utils";
import { fodmapYAxis, paddingYAxis, ppmYAxis, stoolYAxis, symptomYAxis, timeSeriesXAxis, topBottomLabelYAxis } from "./chart-axes"
import { getChartByTitle, isDayToDayAggregateView } from "./chart-funcs";
import { genericTooltips, breathCallbacks, fodmapsCallbacks, noFodmapsCallbacks, combinedFodmapsCallbacks, challengeBreathCallbacks, positiveCriteriaCallbacks, getFromTooltip, isTooltipFor } from "./chart-tooltips"
import styles from "../_styles";

const DIGEST_MULTICHART_TITLE = "Digest MultiChart"

export const CHART_TITLES = {
  DIGEST_MULTICHART: "Digest MultiChart",
  SYMPTOM_MULTICHART: "Symptom MultiChart",
  STOOL_MULTICHART: "Stool MultiChart",
}

/**
 * @param {[DateTime,DateTime]} param0 
 * @returns {Chart.ChartOptions}
 */
export const stoolMultichartOptions = ([startDT,endDT]) => {
  return {
    title: {
      text: CHART_TITLES.STOOL_MULTICHART
    },
    scales: {
      xAxes: [
        timeSeriesXAxis(startDT,endDT,true)
      ],
      yAxes: [
        stoolYAxis,
        topBottomLabelYAxis([{label: "Liquid", limit: 7},{label: "Solid", limit: 1}], "right"),
      ],
    },
    tooltips: {
      ...genericTooltips,
      callbacks: {
        afterTitle: getFromTooltip.formattedTime,
        title: (tooltipItems,data) => getFromTooltip.label(tooltipItems[0],data),
        label: (tooltipItem) => `Stool Form: ${parseFloat(tooltipItem.value).toFixed(1)}`,
      }
    },
    legend: {
      labels: {
        filter: legendFilterCallback,
      },
      onClick: getOnMultichartLegendClickCallback(CHART_TITLES.STOOL_MULTICHART),
    },
  }
}

/**
 * @param {[DateTime,DateTime]} param0 
 * @returns {Chart.ChartOptions}
 */
export const symptomMultichartOptions = ([startDT,endDT]) => ({
  title: {
    display: false,
    text: CHART_TITLES.SYMPTOM_MULTICHART,
  },
  scales: {
    xAxes: [
      timeSeriesXAxis(startDT,endDT,false),
    ],
    yAxes: [
      symptomYAxis,
      topBottomLabelYAxis([{limit:10, label: "Severe"},{limit: 0, label: "None"}],"right"),
    ]
  },
  tooltips: {
    ...genericTooltips,
    callbacks: {
      afterTitle: getFromTooltip.formattedTime,
      title: (tooltipItems,data) => {
        const dataset = getFromTooltip.dataset(tooltipItems[0],data);
        const label = getFromTooltip.label(tooltipItems[0],data);
        if (label.toLowerCase().includes("max")) return `Daily ${label}`;
        if (dataset.showLine) return `Daily Max ${label}`;
        return label;
      },
      label: (tooltipItem) => {
        const label = "Symptom Score";
        const score = parseFloat(tooltipItem.value).toFixed(1);
        return `${label}: ${score}`;
      },
    },
  },
  legend: {
    labels: {
      filter: legendFilterCallback
    },
    onClick: getOnMultichartLegendClickCallback(CHART_TITLES.SYMPTOM_MULTICHART),
  }
});
/**
 * @param {[DateTime,DateTime]} param0 
 * @returns {Chart.ChartOptions} 
 */
export const breathsMealsMultichartOptions = ([startDT, endDT]) => {
  /* 
    Sets the config for the multichart (breath tests, meals and symptoms)
      - scales: one x axis (time) and two y axes (for breath tests and for fodmaps)
        - y axis for breath is static. It's usually 0-50 (unless there is a higher breath test, then it adjusts the high end)
          symptoms are also scaled to this chart. symptoms scale is 1-10 so we multiply them with 5 on the background to match
          the y axis, and then divide them with 5 again when showing the score in tooltips
        - y axis for fodmaps on the other hand is dynamic. it adjusts according to the breath axis and also to the highest
          fodmap content available. The minimum range for this axis is 0-2g (it fixes to 0-2g even if no fodmap data exists)
      - tooltips: toopltip options for data points. through "callbacks" we specify how we want to display data on the tooltips.
  */
  return {
    title: {
      display: false,
      text: DIGEST_MULTICHART_TITLE,
    },
    scales: {
      xAxes: [
        timeSeriesXAxis(startDT,endDT)
      ],
      yAxes: [
        ppmYAxis,
        {
          ...fodmapYAxis,
          stacked: !isDayToDayAggregateView([startDT,endDT]),
        },
      ],
    },
    tooltips: {
      ...genericTooltips,
      callbacks: {
        afterTitle: getFromTooltip.formattedTime,
        title: (tooltipItems, data) => {
          if (isTooltipFor.combinedFodmaps(tooltipItems[0],data)) return combinedFodmapsCallbacks.title(tooltipItems,data);
          if (isTooltipFor.fodmaps(tooltipItems[0],data)) return fodmapsCallbacks.title(tooltipItems,data);
          if (isTooltipFor.noFodmaps(tooltipItems[0],data)) return fodmapsCallbacks.title(tooltipItems,data);
          if (isTooltipFor.breaths(tooltipItems[0],data)) return breathCallbacks.title(tooltipItems,data);
        },
        label: (tooltipItem, data) => {
          if (isTooltipFor.noFodmaps(tooltipItem,data)) return noFodmapsCallbacks.label(tooltipItem,data);
          if (isTooltipFor.fodmaps(tooltipItem,data)) return fodmapsCallbacks.label(tooltipItem,data);
          if (isTooltipFor.combinedFodmaps(tooltipItem,data)) return combinedFodmapsCallbacks.label(tooltipItem,data);
        },
        // afterLabel: (tooltipItem,data) => {
        //   if (isTooltipFor.fodmaps(tooltipItem,data)) return fodmapsCallbacks.afterLabel(tooltipItem,data);
        // },
        footer: (tooltipItems,data) => {
          if (isTooltipFor.combinedFodmaps(tooltipItems[0],data)) return combinedFodmapsCallbacks.footer(tooltipItems,data);
          if (isTooltipFor.fodmaps(tooltipItems[0],data)) return fodmapsCallbacks.footer(tooltipItems,data);
        },
      },

    },
    legend: {
      display: true,
      labels: {
        filter: legendFilterCallback,
        usePointStyle: true,
      },
      onClick: getOnMultichartLegendClickCallback(DIGEST_MULTICHART_TITLE),
    },
  }
}

/**
 * Filters dataasets from the chart legend if the dataset has hideInLegendAndTooltip set to true
 * @param {Chart.ChartLegendLabelItem} legendItem 
 * @param {Chart.ChartData} chartData 
 * @returns 
 */
const legendFilterCallback = (legendItem,chartData) => {
  const dataset = chartData.datasets[legendItem.datasetIndex];
  // hideInLegendAndTooltip prop of ChartDataSets does not appear to do anything itself
  // We can use it as a flag for independently filtering datasets from legend or tooltips using chart options callbacks
  const { hideInLegendAndTooltip } = chartData.datasets[legendItem.datasetIndex];
  const result = dataset.label && !hideInLegendAndTooltip;

  return result;
}

/**
 * Iterate through datasets and toggle datasets with same label on legend click
 *  - Necesarry due to ppm data being broken into seperate datasets with seperate legends.
 *  - With this function the extra legends can be hidden and all data with a particular label will be toggled 
 *    on related legend click
 * @param {string} chartTitle 
 */
const getOnMultichartLegendClickCallback = (chartTitle) => 
/**
 * @param {MouseEvent} e 
 * @param {Chart.ChartLegendLabelItem} chartLegendLabelItem 
 */
(e,chartLegendLabelItem) => {
  const chart = getChartByTitle(chartTitle);
  const { datasets } = chart.data;
  const { label } = datasets[chartLegendLabelItem.datasetIndex];
  toggleDatasetsWithLabel(datasets,label);
  chart.update();
}

/**
 * @param {Chart.ChartDataSets[]} datasets 
 * @param {string} label 
 */
const toggleDatasetsWithLabel = (datasets,label) => {
  datasets.forEach(dataset => {
    if (dataset.label === label) dataset.hidden = dataset.hidden ? null : true; 
  });
}

/**
 * @param {[DateTime,DateTime]} DateRange
 * @param {Chart.ChartOptions} options 
 * @returns {Chart.ChartOptions}
 */
export const barChartOptions = ([startDT, endDT],displayXLabels=true,yLabelAxis,options={}) => {
  // Sets the config for bar charts (currently sleep and stress)
  let diff = endDT.diff(startDT, "days").as("days");

  return {
    scales: {
      xAxes: [
        timeSeriesXAxis(startDT,endDT,displayXLabels)
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Score",
            fontSize: 14,
            fontColor: styles.chartFontBlack,
          },
          ticks: {
            beginAtZero: true,
            fontSize: 14,
            max: 10,
            stepSize: 2.5,
          },
        },
        yLabelAxis || paddingYAxis("right"),
      ],
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem) =>
          DateTime.fromISO(tooltipItem[0].label).toFormat("LLL dd, yyyy"),
      },
    },
    ...options,
  };
}

/**
 * 
 * @param {string} label 
 * @param {*} dataset 
 * @param {*} chart 
 * @returns {Chart.ChartOptions}
 */
export const configureChallengeCharts = (label, dataset, chart = null) => {
  // Returns an "options" object for challenge charts
  return {
    title: {
      display: false,
      text: label + " Challenge",
    },
    legend: {
      display: true,
      labels: {
        boxWidth: 20,
        padding: 30,
        usePointStyle: true,
      },
    },
    layout: { padding: { top: "-15", left: "15", right: "15", bottom: "0" } },
    scales: {
      xAxes: [
        {
          type: "time",
          scaleLabel: {
            display: true,
            labelString: "Minutes Elapsed",
            fontSize: 14,
            fontColor: styles.chartFontBlack,
          },
          time: {
            unit: "minute",
            parser: "HH:mm",
          },
          ticks: {
            source: "labels", // create ticks only where breath tests are
            fontSize: 14,
            min: dataset.xLabels[0],
            max: dataset.xLabels[dataset.xLabels.length - 1],
            callback: (tickTime) => {
              // display the x-axis ticks as "Minutes Elapsed"
              const tickDateTime = DateTime.fromFormat(tickTime.toUpperCase(), "h:mm a");
              const firstTestDateTime = DateTime.fromFormat(dataset.xLabels[0], "HH:mm");
              const minutes = tickDateTime.diff(firstTestDateTime,"minutes").as("minutes");
              const result = Math.round(minutes);
              return result;
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Gas Concentration (ppm)",
            fontSize: 14,
            fontColor: styles.chartFontBlack,
          },
          ticks: {
            fontSize: 14,
            beginAtZero: true,
            suggestedMax: 50,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: styles.tooltipBackground,
      displayColors: false,
      caretPadding: 10,
      titleMarginBottom: 12,
      callbacks: {
        title: (tooltipItems, data)  => {
          if (isTooltipFor.positiveCriteria(tooltipItems[0],data)) return positiveCriteriaCallbacks.title(tooltipItems,data);
          if (isTooltipFor.challengeBreaths(tooltipItems[0],data)) return challengeBreathCallbacks.title(tooltipItems,data);
        },
        label: (tooltipItem, data) => {
          if (isTooltipFor.positiveCriteria(tooltipItem,data)) return positiveCriteriaCallbacks.label(tooltipItem,data);
          if (isTooltipFor.challengeBreaths(tooltipItem,data)) return challengeBreathCallbacks.label(tooltipItem,data);
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
}
