import s from "./ReviewOrder.module.scss";
import { useParams } from "react-router-dom";
import PreloginLayout from "../../../../layouts/prelogin/PreloginLayout";
import useOrderRequest from "./useOrderRequest";
import { OrderRequestStatus } from "./OrderRequest";
import { OrderRequest } from "./OrderRequest";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { FormEvent, useContext, useEffect } from "react";
import { SkuFullPrice } from "../useOrderRequestPricingFunctions";
import { currencies } from "../../../../contexts/OrderContext/OrderContext";
import { Price } from "../../../bulk-ordering/Price";
import { useForm, useFormContext as _useFormContext, FormProvider } from "react-hook-form";
import IFTAInput from "../../../register/IFTAElements/IFTAInput/IFTAInput";
import FormErrorMessageGeneric from "../../../../components/errors/FormErrorMessage/FormErrorMessageGeneric";
import { AppError, authFetch } from "../../../../utils/utils";
import useSubmitReducer from "../../../../hooks/useSubmitReducer";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import useProvider from "../../../../hooks/useProvider";
import useProviderShipping from "../../../../hooks/useProviderShipping";
import useOrderStatuses from "./useOrderStatuses";
import { DateTime } from "luxon";
import useOrderRequestPricingFunctions from "../useOrderRequestPricingFunctions";
import useSkus, { Sku } from "../../../../hooks/useSkus";
import { fmtCurrency } from "../order-request-util";
import { ProviderShipping } from "../../../../utils/data-classes/ProviderShipping";

export default function ReviewOrder() {
  const form = useForm<FormFieldValues>({
    defaultValues: {
      status: "REQUESTED",
      orderStatusInfo: {
        isReadyForFulfillment: false,
        isShipped: false,
        trackingNo: undefined,
        shippedOnDate: DateTime.now().toISODate(),
        shippedOnTime: "14:00",
      },
      stripe: {
        paymentMethod: "",
        paymentIntent: "",
      },
    },
  });

  const shippedOnDate = form.watch("orderStatusInfo.shippedOnDate");
  const shippedOnTime = form.watch("orderStatusInfo.shippedOnTime");
  useEffect(() => {
    form.setValue("orderStatusInfo.shippedOn",parseDateTime(shippedOnDate,shippedOnTime).toISO());
  },[shippedOnDate,shippedOnTime]);

  return (
    <PreloginLayout>
      <div className={s.dashAdmin}>
        <FormProvider {...form}>
          <Form />
        </FormProvider>
      </div>
    </PreloginLayout>
  );
}

function Summary() {
  const { token }: { token: string } = useParams();

  const { request } = useOrderRequest(token);

  const { provider } = useProvider(request?.prid);
  const prices = useOrderRequestItemPrices(request);
  const total = prices.reduce((acc,cur) => acc + cur.price.totalPriceNet(),0)
  if (!request || !provider || prices.length === 0) return <LoadingSpinner />
  return (
    <div className={s.orderRequestItemsTable}>
      <h4>Order Request Items</h4>
      <table>
        <thead>
          <tr>
            <th>Provider:</th>
            <td>{provider.label}</td>
          </tr>
          <tr>
            <th>Currency:</th>
            <td>{request.currency}</td>
          </tr>
          <tr>
            <th>Sku</th>
            <th>Label</th>
            <th>Quantity</th>
            <th>Unit Price Gross</th>
            <th>Unit Discount</th>
            <th>Unit Tax</th>
            <th>Unit Price Net</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {prices.map((price,i) => (
            <tr key={i}>
              <td>{price.sku.sku}</td>
              <td>{price.sku.shortLabel}</td>
              <td>{price.price.quantity}</td>
              <td>{fmtCurrency(price.price.unitPriceGross)}</td>
              <td>{fmtCurrency(price.price.unitDiscount)}</td>
              <td>{fmtCurrency(price.price.unitTax)}</td>
              <td>{fmtCurrency(price.price.unitPriceNet())}</td>
              <td>{fmtCurrency(price.price.totalPriceNet())}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}>{fmtCurrency(total)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

function ProviderShippingTable({providerShipping}: { providerShipping: ProviderShipping | null }) {
  return (
    <div className={s.shippingDetailsSummary}>
      <h4>Shipping Details</h4>
      <table>
        <tr>
          <td>Name</td>
          <td colSpan={2}>{providerShipping?.shippingName}</td>
        </tr>
        <tr>
          <td>Address</td>
          <td>{providerShipping?.shippingAddress1}</td>
        </tr>
        <tr>
          <td>City</td>
          <td>{providerShipping?.shippingCity}</td>
        </tr>
        <tr>
          <td>State</td>
          <td>{providerShipping?.shippingState} </td>
        </tr>
        <tr>
          <td>Country</td>
          <td>{providerShipping?.shippingCountry} </td>
        </tr>
        <tr>
          <td>Zip/Postcode</td>
          <td>{providerShipping?.shippingZip} </td>
        </tr>
        <tr>
          <td>email</td>
          <td>{providerShipping?.shippingEmail} </td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>{providerShipping?.shippingPhone} </td>
        </tr>
        <tr>
          <td>(ship_id:  {providerShipping?.shipId})</td>
        </tr>
      </table>
    </div>
  );
}

interface FormFieldValues {
  status: OrderRequestStatus;
  orderStatusInfo: {
    isReadyForFulfillment: boolean;
    isShipped: boolean;
    trackingNo: number;
    shippedOnDate: string;
    shippedOnTime: string;
    shippedOn: string;
  };
  stripe: {
    paymentMethod: string;
    paymentIntent: string;
  };
}

const FormErrorMessage = FormErrorMessageGeneric<FormFieldValues>;

const useFormContext = _useFormContext<FormFieldValues>;

function Form() {
  const { token }: { token: string } = useParams();
  const {
    state: { loginToken },
  } = useContext(LoginContext);

  const { request, error: orderRequestError } = useOrderRequest(token);
  const { error: orderStatusesError } = useOrderStatuses();
  const { providerShipping } = useProviderShipping(request?.prid);

  const form = useFormContext();
  const { handleSubmit } = form;

  const [{ processing, complete, error }, dispatch] = useSubmitReducer(form);

  useEffect(() => {
    if (!orderRequestError) return;
    dispatch({ type: "ERROR", payload: orderRequestError });
  }, [orderRequestError]);

  useEffect(() => {
    if (!orderStatusesError) return;
    dispatch({type: "ERROR", payload: orderStatusesError});
  },[orderStatusesError]);

  useEffect(() => {
    if (!complete) return;
    window.location.reload();
  }, [complete]);

  if (error) return <Err error={error} />

  if (!request || !loginToken) return <LoadingSpinner />;

  if (request.status === "CONFIRMED" || request.status === "DECLINED") return <Complete status={request.status} />;

  const onSubmit = async (data: FormFieldValues) => {
    dispatch({ type: "PROCESSING" });
    processOrderRequest(loginToken, request, data)
      .then(() => dispatch({ type: "COMPLETE" }))
      .catch((err) => dispatch({ type: "ERROR", payload: err }));
  };

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
      <Summary />
      <div className={s.shippingAndInputs}>
        <ProviderShippingTable providerShipping={providerShipping} />
        <div className={s.inputs}>
          {request.isPrePaid() && <PrePaidInputs />}
          <OrderStatusInputs />
          <div className={s.submitButtons}>
            <SubmitButton status="CONFIRMED" disabled={processing}>
              {processing ? "Processing" : "Confirm"}
            </SubmitButton>
            <SubmitButton status="DECLINED" disabled={processing}>
              {processing ? "Processing" : "Decline"}
            </SubmitButton>
          </div>
        </div>
      </div>
    </form>
  );
}

function PrePaidInputs() {
  const form = useFormContext();
  const { register } = form;
  return (
    <>
      <IFTAInput
        id="stripe-payment-method"
        label="Stripe Payment Method"
        reg={register("stripe.paymentMethod", {
          validate: {
            isRequired: (val) => {
              if (form.getValues("status") === "DECLINED") return true;
              if (!!val) return true
              return "Stripe payment method is required";
            }
          }
        })}
      />
      <FormErrorMessage path="stripe.paymentMethod" />
      <IFTAInput
        id="stripe-payment-intent"
        label="Stripe Payment Intent"
        reg={register("stripe.paymentIntent", {
          validate: {
            isRequired: (val) => {
              if (form.getValues("status") === "DECLINED") return true;
              if (!!val) return true
              return "Stripe payment intent is required";
            }
          }
        })}
      />
      <FormErrorMessage path="stripe.paymentIntent" />
    </>
  )
}

function Err({error}: {error: AppError}) {
  return (
    <div>
      <h2>Error {error.code}</h2>
      <p>{error.message}</p>
    </div>
  )
}

function SubmitButton({
  status,
  ...rest
}: Omit<React.HTMLProps<HTMLButtonElement>, "onClick" | "type"> & { status: OrderRequestStatus }) {
  const { setValue } = useFormContext();
  const onClick = (e: FormEvent) => setValue("status", status);
  return <button onClick={onClick} type="submit" {...rest} />;
}

async function processOrderRequest(loginToken: string, request: OrderRequest, data: FormFieldValues) {
  const res = await authFetch(loginToken, "/api/order-request", {
    method: "PUT",
    body: JSON.stringify(body(request, data)),
  });
  const { error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return;
}

const body = (request: OrderRequest, data: FormFieldValues) => {
  const shippedOn = data.orderStatusInfo.isShipped ? data.orderStatusInfo.shippedOn : undefined;
  if (!request.isPrePaid()) return {
      token: request.token,
      status: data.status,
      isReadyForFulfillment: data.orderStatusInfo.isReadyForFulfillment,
      shippedOn: shippedOn,
      trackingNo: data.orderStatusInfo.trackingNo || null,
    };
  return {
    token: request.token,
    status: data.status,
    isReadyForFulfillment: data.orderStatusInfo.isReadyForFulfillment,
    shippedOn: shippedOn,
    trackingNo: data.orderStatusInfo.trackingNo || null,
    stripeMethod: data.stripe.paymentMethod,
    stripeIntent: data.stripe.paymentIntent,
  };
};

type SkuPrice = Omit<SkuFullPrice, "base">;

function Complete({ status }: { status: OrderRequestStatus }) {
  return <h2>Order {status.toLowerCase()}</h2>;
}

function ShippedOnDateInput() {
  const { register } = useFormContext();
  return <IFTAInput
    type="date"
    id="shipped-on-date"
    label="Shipped-on date"
    reg={register("orderStatusInfo.shippedOnDate", {
      required: "Shipped-on date is required"
    })}
  />
}

function ShippedOnTimeInput() {
  const { register } = useFormContext();
  return <IFTAInput
    type="time"
    id="shipped-on-time"
    label="Shipped-on time"
    reg={register("orderStatusInfo.shippedOnTime", {
      required: "Shipped-on time is required"
    })}
  />
}

function OrderStatusInputs() {
  const { register, watch, setValue } = useFormContext();
  useEffect(() => {
    setValue("orderStatusInfo.isReadyForFulfillment",watch("orderStatusInfo.isShipped"));
  },[watch("orderStatusInfo.isShipped")]);
  return (
    <div className={s.orderStatus}>
      <div>
        <input id="fulfillment-checkbox" type="checkbox" disabled={!!watch("orderStatusInfo.isShipped")} {...register("orderStatusInfo.isReadyForFulfillment")} />
        <label htmlFor="fulfillment-checkbox">Ready for fulfillment?</label>
      </div>
      <div>
        <input id="shipped-checkbox" type="checkbox" {...register("orderStatusInfo.isShipped")}/>
        <label htmlFor="shipped-checkbox">Is shipped?</label>
      </div>
      { watch("orderStatusInfo.isShipped") && <ShippingInputs />}
    </div>
  )
}

function ShippingInputs() {
  const { register } = useFormContext();
  return (
    <>
      <ShippedOnDateAndTimeInputs />
      <IFTAInput id="tracking-no" type="text" label="Tracking number" reg={register("orderStatusInfo.trackingNo")}/>
    </>
  )
}

function ShippedOnDateAndTimeInputs() {
  return (
    <>
      <div className={s.sideBySideInputs}>
        <ShippedOnDateInput />
        <ShippedOnTimeInput />
      </div>
      <FormErrorMessage path="orderStatusInfo.shippedOnDate" />
      <FormErrorMessage path="orderStatusInfo.shippedOnTime" />
    </>
  )
}

function parseDateTime(date: string,time: string) {
  const result = DateTime.fromFormat(`${date}-${time}`,"yyyy-MM-dd-HH:mm").setZone("Europe/Dublin");
  return result;
}

function useOrderRequestItemPrices(request: OrderRequest|undefined): SkuPrice[] {
  const getFullPrice = useGetFullPrice(request?.prid);
  const skus = useSkuMap();
  if (!request) return [];
  if (!skus) return [];
  if (request.isPrePaid()) return createSkuPricesFromOrderRequest(skus,request);
  return getUpdatedSkuPricesForOrderRequest(request, getFullPrice);
}

function getUpdatedSkuPricesForOrderRequest(request: OrderRequest, pricingFn: (skuId: number, quantity: number) => SkuFullPrice | undefined): SkuPrice[] {
  return request.items.reduce<SkuPrice[]>((acc, item) => {
    const fullPrice = pricingFn(item.skuId, item.quantity);
    if (fullPrice) acc.push({
      sku: fullPrice.sku,
      price: fullPrice.price,
    });
    return acc;
  }, []);
}

function createSkuPricesFromOrderRequest(skus: Map<number,Sku>, request: OrderRequest) {
  return request.items.map(item => ({
    sku: skus?.get(item.skuId) as Sku,
    price: new Price({
      name: "",
      currency: currencies[request.currency],
      ...item,
    })
  }));
}

function useSkuMap() {
  const { skus } = useSkus();
  if (!skus) return undefined;
  return new Map(skus.map(s => [s.skuId,s]));
} 

function useGetFullPrice(prid: number|undefined) {
  const { pricingFunctions } = useOrderRequestPricingFunctions(prid);
  return (skuId: number, quantity: number) => {
    if (!pricingFunctions) return undefined;
    const getPrice =  pricingFunctions.get(skuId);
    if (!getPrice) return undefined;
    return getPrice(quantity);
  }
}