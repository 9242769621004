import { Elements, useStripe } from "@stripe/react-stripe-js";
import { SetupIntent } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import DashLayout from "../../../layouts/dash/DashLayout";
import { AppError, authFetch } from "../../../utils/utils";
import STRIPE_PROMISE from "../../register/StripePromise";
import s from "./AddCard.module.scss";

export default function SetupComplete() {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'setup_intent_client_secret'
  );

  if (!clientSecret) return <></>

  return (
    <Elements stripe={STRIPE_PROMISE} options={{clientSecret}}>
      <DashLayout>
        <PaymentStatus clientSecret={clientSecret}/>
      </DashLayout>
    </Elements>
  )
}

const PaymentStatus = ({clientSecret}: {clientSecret: string}) => {
  const stripe = useStripe();

  const [setupIntent, setSetupIntent] = useState<SetupIntent>();
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();

  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!stripe || !clientSecret) return;

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({setupIntent}) => setSetupIntent(setupIntent));
  }, [stripe]);

  useEffect(() => {
    if (!setupIntent || !loginToken) return;
    onIntent(loginToken,setupIntent).then(() => {
      setTitle(getSetupIntentTitle(setupIntent));
      setMessage(getSetupIntentMessage(setupIntent));  
    });
  },[setupIntent]);

  return (
    <div className={s.setupComplete}>
      {!title || !message && <LoadingSpinner />}
      {title && message && (
        <>
          <h1>{title}</h1>
          <p>{message}</p>
          {setupIntent?.status === "succeeded" && <OrderingLink />}
        </>
      )}
    </div>
  );
}

const insertBilling = async (token: string, setupIntentId: string): Promise<number> => {
  const res = await authFetch(token,"/api/insert-billing-after-card-setup",{
    method: "POST",
    body: JSON.stringify({setupIntentId}),
  });
  const { blid, error }: { blid: number, error: undefined } | { blid: undefined, error: string} = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return blid
}

const onIntent = async (loginToken: string,setupIntent: SetupIntent) => {
  if (setupIntent.status === "succeeded" || setupIntent.status === "processing") {
    await insertBilling(loginToken,setupIntent.id);
  }
}

const getSetupIntentTitle = (setupIntent: SetupIntent): string => {
  switch (setupIntent.status) {
    case 'succeeded':
      return "Payment Succeeded";
    case 'processing':
      return "Payment Processing";
    default:
      return "Payment Failed";
  }
}

const getSetupIntentMessage = (setupIntent: SetupIntent): string => {
  switch(setupIntent.status) {
    case 'succeeded':
      return 'Success! Your payment method has been saved.';
    case 'processing':
      return "Processing payment details. We'll update you when processing is complete.";
    default:
      return 'Failed to process payment details. Please try another payment method.';
  }
}

const OrderingLink = () => {
  return <Link to="/orders"><button type="button" className={s.btnPrimary}>Go To Device Ordering</button></Link>
}