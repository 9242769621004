import { isInternalDeployment } from "./internal-deployment-warning-util";
import s from "./InternalDeploymentWarning.module.scss";

/**
 * Banner for warning users that they are accessing an internal testing deployment and not the main site
 */
export default function InternalDeploymentWarningBanner() {
  if (!isInternalDeployment()) return null;
  return (
    <div className={s.warningBanner}>
      <div>
        <div className={s.warningSymbol}>⚠</div>
        <div className={s.textContent}><span>This site is for internal testing purposes only</span></div>
      </div>
    </div>
  )
}