import { useFormContext } from "react-hook-form";
import IFTASelect from "../../../../register/IFTAElements/IFTASelect/IFTASelect";
import { useContext } from "react";
import { RequestOrderContext, RequestOrderFormFieldValuesBase } from "../RequestOrderContext";

export default function ProviderSelect() {
  const { register } = useFormContext<RequestOrderFormFieldValuesBase>();
  const { state } = useContext(RequestOrderContext);
  const providers = state.providers.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));
  return (
    <IFTASelect
      id="select-providers"
      label="Provider"
      defaultValue=""
      disabled={!providers}
      reg={register("prid", {
        required: "Provider is required",
        valueAsNumber: true,
      })}
    >
      <option value="">Select Provider</option>
      {providers.map((p, i) => (
        <option key={i + 1} value={p.prid}>
          {p.label}
        </option>
      ))}
    </IFTASelect>
  );
}
