import React, { Suspense, useContext } from "react";
import DashLayout from "../../layouts/dash/DashLayout";
import s from "./PrivacyPolicyPage.module.scss";
import PrivacyPolicy from "../../components/privacy-policy/PrivacyPolicy";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";

export default function PrivacyPolicyPage() {
  const { state: { loginSuccess } } = useContext(LoginContext);
  const Layout = loginSuccess ? DashLayout : PreloginLayout;
  return (
    <Suspense fallback={<></>}>
      <Layout>
        <div className={!loginSuccess ? s.preLogin : ""}>
          <PrivacyPolicy />
        </div>
      </Layout>
    </Suspense>
  )
}