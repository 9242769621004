import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { Patient } from "../../../utils/data-classes/Patient";
import Symptom from "../../../utils/data-classes/Symptom";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import { symptomsForChallenge } from "../../../utils/data-classes/challenge/challenge-util";
import ChartHandler from "../ChartHandler";
import DigestLogic from "../DigestLogic";
import { AppError, diff, parseURLSearchParams } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { DateRangeContext } from "../../../contexts/DateRangeContext/DateRangeContext";
import { dispatchPatientDateRangeToDateRangeContext } from "../digest-fns";
import ChallengeChartOptions from "./ChallengeChartOptions";
import { PdfLogoSrc, usePdfLogoSrc } from "../../../pdf-generator/ProviderLogo";
import { PatientPreferences } from "../../../hooks/usePatientPreferences";
import tz from "../../../utils/tz";
import useChallengeFollowonContent, { ChallengeFollowonContentRecord } from "../../../hooks/useChallengeFollowonContent";

export interface ChallengesContextState {
  initial: boolean;
  fetching: boolean;
  linkedCard: ChalCardData | undefined;
  patient: Patient | undefined;
  cards: ChalCardData[];
  preferences: PatientPreferences | undefined;
  chosen: ChosenCards; // Object containing currently id: challenge pairs for currently active challenge cards
  pdfLogoSrc: PdfLogoSrc | undefined;
  followonContent: ChallengeFollowonContentRecord | undefined;
  error: AppError | undefined;
}

const initialState: ChallengesContextState = {
  initial: true,
  fetching: false,
  linkedCard: undefined,
  patient: undefined,
  cards: [],
  preferences: undefined,
  chosen: {},
  pdfLogoSrc: undefined,
  followonContent: undefined,
  error: undefined,
};

export interface ChalCardData {
  index: number;
  challenge: Challenge;
  symptoms: Symptom[];
  chartHandler: ChartHandler;
}

export type ChallengesContextAction =
  | { type: "INIT_FETCH" }
  | { type: "END_FETCH" }
  | { type: "SET_LINKED_CARD"; payload: ChalCardData | undefined }
  | {
      type: "SET_DATA";
      payload: {
        patient: Patient;
        challenges: Challenge[];
        symptoms: Symptom[];
        preferences: PatientPreferences;
      };
    }
  | { type: "SET_CHOSEN_CARDS"; payload: ChosenCards }
  | { type: "TOGGLE_CARD"; payload: ChalCardData }
  | { type: "SET_REVIEW_STATUS"; payload: { cards: ChalCardData[]; status: boolean } }
  | { type: "SET_PDF_LOGO_SRC"; payload: PdfLogoSrc | undefined }
  | { type: "SET_FOLLOWON_CONTENT"; payload: ChallengeFollowonContentRecord }
  | { type: "SET_ERROR"; payload: AppError | undefined };

function reducer(state: ChallengesContextState, action: ChallengesContextAction): ChallengesContextState {
  switch (action.type) {
    case "INIT_FETCH":
      return { ...state, initial: false, fetching: true };
    case "END_FETCH":
      return { ...state, fetching: false };
    case "SET_LINKED_CARD":
      return { ...state, linkedCard: action.payload };
    case "SET_DATA": {
      const { patient, preferences } = action.payload;
      const challenges = action.payload.challenges.sort((a, b) =>
        diff(b.tests[0].chalCreatedOn, a.tests[0].chalCreatedOn, "milliseconds").toMillis()
      );
      const symptoms = action.payload.symptoms.sort((a, b) =>
        diff(b.pertainsTo, a.pertainsTo, "milliseconds").toMillis()
      );
      const chartHandlers = challenges.map((tests) => new ChartHandler(ChallengeChartOptions(tests)));
      const cards: ChalCardData[] = challenges.map((challenge, i) => ({
        index: i,
        challenge,
        chartHandler: chartHandlers[i],
        symptoms: symptomsForChallenge(challenge, symptoms),
      }));
      return { ...state, patient, preferences, cards };
    }
    case "SET_CHOSEN_CARDS": {
      return { ...state, chosen: action.payload };
    }
    case "TOGGLE_CARD":
      const i = state.cards.indexOf(action.payload);
      if (i === undefined) return state;
      return { ...state, chosen: updatedCards(i, state.chosen) };
    case "SET_REVIEW_STATUS": {
      const cards = state.cards.map((card) => ({ ...card }));
      action.payload.cards.forEach((card) => card.challenge.updateSettings({isReviewed: action.payload.status}));
      return { ...state, cards };
    }
    case "SET_PDF_LOGO_SRC":
      return { ...state, pdfLogoSrc: action.payload };
    case "SET_FOLLOWON_CONTENT": {
      return { ...state, followonContent: action.payload };
    }
    case "SET_ERROR":
      return { ...state, error: action.payload };
  }
}

export interface ChallengesContextType {
  state: ChallengesContextState;
  dispatch: React.Dispatch<ChallengesContextAction>;
}

export const ChallengesContext = createContext<ChallengesContextType>({ state: initialState, dispatch: () => {} });

export function ChallengesContextProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer<React.Reducer<ChallengesContextState, ChallengesContextAction>>(
    reducer,
    initialState
  );

  const { patient, preferences, digest, error } = DigestLogic(["challenges", "symptoms"]);
  const { content: followonContent } = useChallengeFollowonContent();
  const pdfLogoSrc = usePdfLogoSrc();
  const { chalEpochMs } = useParams();
  const { dispatch: rangeDispatch } = useContext(DateRangeContext);

  useEffect(() => {
    if (state.linkedCard !== undefined || !chalEpochMs || state.cards.length === 0) return;
    dispatch({
      type: "SET_LINKED_CARD",
      payload: getCardFromEpochMs(state.cards, chalEpochMs),
    });
  }, [chalEpochMs, state.cards]);

  useEffect(() => {
    dispatch({ type: "SET_ERROR", payload: error });
  }, [error]);

  /*
    On initial render, dispatch full patient date range to date range context
  */
  useEffect(() => {
    if (!state.initial) return;
    dispatchPatientDateRangeToDateRangeContext(rangeDispatch);
    dispatch({ type: "INIT_FETCH" });
  });

  useEffect(() => {
    const { challenges, symptoms } = digest;
    if (state.initial || !patient || !challenges || !symptoms || !preferences) return;
    dispatch({
      type: "SET_DATA",
      payload: {
        patient: patient,
        challenges,
        symptoms,
        preferences,
      },
    });
  }, [digest]);

  /**
   * Update chosen card indicies if cards array is changed
   */
  useEffect(() => {
    dispatch({
      type: "SET_CHOSEN_CARDS",
      payload: state.cards.reduce<ChosenCards>((acc, cur) => {
        if (!!state.chosen[cur.index]) acc[cur.index] = true;
        return acc;
      }, {}),
    });
  }, [state.cards]);

  useEffect(() => {
    if (state.initial || !state.patient) return;
    dispatch({ type: "END_FETCH" });
  }, [state.patient]);

  useEffect(() => {
    if (state.pdfLogoSrc) return;
    dispatch({ type: "SET_PDF_LOGO_SRC", payload: pdfLogoSrc });
  }, [pdfLogoSrc]);

  useEffect(() => {
    if (!!state.followonContent || !followonContent) return;
    dispatch({ type: "SET_FOLLOWON_CONTENT", payload: followonContent });
  },[followonContent]);

  return <ChallengesContext.Provider value={{ state, dispatch }}>{children}</ChallengesContext.Provider>;
}

const getCardFromEpochMs = (cards: ChalCardData[], chalEpochMs: number): ChalCardData | undefined => {
  return cards.find((card) => tz.toUTC(card.challenge.createdOn).toMillis() === chalEpochMs);
};

const useParams = () => {
  const history = useHistory();
  const params = parseURLSearchParams(history.location.search);
  return {
    ptid: parseInt(params.ptid),
    chalEpochMs: parseInt(params.chalEpochMs) || undefined,
  };
};

export interface ChosenCards extends Partial<Record<number, true>> {}

const updatedCards = (i: number, chosenCards: ChosenCards): ChosenCards => {
  const newCards = { ...chosenCards };
  newCards[i] = i in chosenCards ? undefined : true;
  if (!newCards[i]) delete newCards[i];
  return newCards;
};

export function useChosenCards() {
  const [result,setResult] = useState<ChalCardData[]>([]);

  const { cards, chosen } = useContext(ChallengesContext).state;

  useEffect(() => {
    setResult(cards.filter((card) => !!chosen[card.index]));
  },[cards,chosen]);

  return result;
}