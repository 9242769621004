import { Image, View } from "@react-pdf/renderer";
import { s } from "../challenge-report-styles";
import ChartHandler from "../../../../pages/digest/ChartHandler";

export default function ChartBox({ chartHandler }: { chartHandler: ChartHandler; }) {
  return (
    <View style={s.chart.main}>
      <Image src={chartHandler.getImage()} />
    </View>
  );
}
