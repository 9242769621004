/*
 * Component to create the chart that have breath, meals and symptoms data together
 *
 * Receives two props:
 *  - range : the query range (from DateRangeContext via parent component)
 *  - data : ppms, meals and data (from useDigest hook via parent component)
 *
 * There is a delay between
 *  range updates (client sends an api request)
 *  and data updates (client receives a respond from server)
 *
 * Accordingly, there are two useEffect hooks:
 *  One runs on range updates and gets the chart ready for an update
 *  The other runs on data updates and updates the chart with new data
 */

import React from "react";
import s from "../DayToDay.module.scss";
import Chart from "chart.js";
import Gas from "../../../../utils/data-classes/Gas";
import { DateTime } from "luxon";
import MultiChartRenderer from "../../../../components/charts/MultiChartRenderer";
import { breathsMealsMultichartOptions } from "../../charts/chart-options";
import { isDayToDayAggregateView, setDefaultConfig } from "../../charts/chart-funcs";
import { createDatasetsForAveragePpms, createDatasetForNoFodmapMeals, createDatasetForPpms, createDatasetsForSeparateFodmaps, createDatasetsForCombinedFodmps } from "../../charts/chart-datasets";
import Ppm from "../../../../utils/data-classes/Ppm";
import Meal from "../../../../utils/data-classes/Meal";
import Symptom from "../../../../utils/data-classes/Symptom";

const { H2, CH4 } = Gas;

setDefaultConfig(Chart);

/** 
 * @param {Object} props
 * @param {MultiChartData} props.data
 * @param {import("../../../../contexts/DateRangeContext/DateRangeContext").DateRange} props.range 
 */
const MultiChart = ({ data, range }) => {
  const dataSetsArray = isDataAvailable(data) ? createChartData(data,range) : null;
  const options = range && range.length ? breathsMealsMultichartOptions(range) : null;
  
  return <MultiChartRenderer id={s.multichartCanvasWrapper} dataSetsArray={dataSetsArray} options={options} />;
} 

/**
 * @typedef MultiChartData
 * @property {Ppm[]|undefined} ppms 
 * @property {Symptom[]|undefined} symptoms 
 * @property {Meal[]|undefined} meals
 */

/**
 * @param {MultiChartData} data 
 * @param {[DateTime,DateTime]} range
 * @returns
 */
const createChartData = (data,range) => {
  const { ppms, meals } = data;
  return [
    ...ppmsToRender(ppms,range),
    ...mealsToRender(meals,range),
  ]
}

/**
 * @param {Meal[]} meals 
 * @param {[DateTime,DateTime]} range 
 */
const mealsToRender = (meals,range) => {
  return isDayToDayAggregateView(range) ? createDatasetsForCombinedFodmps(meals,range) : [
    ...createDatasetsForSeparateFodmaps(meals),
    createDatasetForNoFodmapMeals(meals),
  ];
}

/**
 * @param {Ppm[]} ppms 
 * @param {[DateTime,DateTime]} range 
 */
const ppmsToRender = (ppms,range) => {
  const result = [];
  const showRawPpmLine = isDayToDayAggregateView(range);

  const averagePpmDatasetsH2 = createDatasetsForAveragePpms(ppms,H2,!isDayToDayAggregateView(range));
  const averagePpmDatasetsCH4 = createDatasetsForAveragePpms(ppms,CH4,!isDayToDayAggregateView(range));
  const ppmsDatasetH2 = createDatasetForPpms(ppms,H2,showRawPpmLine,shouldHideRawData(averagePpmDatasetsH2,range));
  const ppmsDatasetCH4 = createDatasetForPpms(ppms,CH4,showRawPpmLine,shouldHideRawData(averagePpmDatasetsCH4,range));

  if (shouldRenderAggregate(averagePpmDatasetsH2)) result.push(...averagePpmDatasetsH2);
  if (shouldRenderAggregate(averagePpmDatasetsCH4)) result.push(...averagePpmDatasetsCH4);
  if (ppmsDatasetH2?.data?.length) result.push(ppmsDatasetH2);
  if (ppmsDatasetCH4?.data?.length) result.push(ppmsDatasetCH4);

  return result;
}

/**
 * @param {MultiChartData} data 
 * @returns 
 */
const isDataAvailable = (data) => {
  return !!(data.ppms || data.meals || data.symptoms);
}

/**
 * @param {Chart.ChartDataSets[]} datasets 
 * @returns
 */
const oneDataPointOrFewer = (datasets) => {
  return datasets.reduce((count,dataset) => count + dataset.data.length,0) <= 1;
}

/**
 * @param {Chart.ChartDataSets[]} datasets 
 * @returns
 */
const shouldRenderAggregate = (datasets) => !oneDataPointOrFewer(datasets);

/**
 * @param {Chart.ChartDataSets[]} aggregateDatasets 
 * @param {[DateTime,DateTime]} range 
 * @returns 
 */
const shouldHideRawData = (aggregateDatasets,range) => {
   if (!shouldRenderAggregate(aggregateDatasets)) return false;
   if (isDayToDayAggregateView(range)) return true;
   return false;
 }

export default MultiChart;
