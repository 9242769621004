import { useEffect, useState } from "react"
import { AppError } from "../utils/utils";
import useErrorState from "./useErrorState";

const useSkus = (): {skus: Sku[]|undefined, error:AppError|undefined} => {
  const [skus, setSkus] = useState<Sku[]|undefined>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (skus) return;
    fetchSkus().then(setSkus).catch(setError);
  },[skus]);

  return { skus, error };
}

const fetchSkus = async (): Promise<Sku[]> => {
  const res = await fetch("/api/skus?all=true");
  const { skus, error }: {skus: any[], error: string} = await res.json();
  if (error) throw new AppError(res.status,error);
  return skus.map(createSku);
}

export interface Sku {
  skuId: number;
  sku: string | undefined;
  notes: string | undefined;
  ean: string | undefined;
  upc: string | undefined;
  label: string | undefined;
  isActive: boolean | undefined;
  shortLabel: string | undefined;
}

export function createSku(sku: Sku): Sku {
  return {
    skuId: sku.skuId,
    sku: sku.sku ?? undefined,
    notes: sku.notes ?? undefined,
    ean: sku.ean ?? undefined,
    upc: sku.upc ?? undefined,
    label: sku.label ?? undefined,
    isActive: sku.isActive ?? undefined,
    shortLabel: sku.shortLabel ?? undefined,
  }
}

export default useSkus;