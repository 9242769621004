/**
 * Reimplements chart rendering of ChartRenderer for MedLogs chart
 *  - Destroys chart on change of data rather than updating due to known charts.js bug causing chart crash
 */

import Chart, { ChartPoint } from "chart.js";
import { DateTime } from "luxon";
import MedLog, { MedRegimen } from "../../../../utils/data-classes/MedLog";
import { timeSeriesXAxis } from "../../charts/chart-axes";
import s from "../DayToDay.module.scss";
import digest_styles from "../../_styles";
import { useContext, useEffect, useRef, useState } from "react";
import tinycolor from "tinycolor2";
import { CHART_DATE_FORMAT } from "../../charts/chart-tooltips";
import { DateRangeContext } from "../../../../contexts/DateRangeContext/DateRangeContext";
import { binByProp } from "../../../../utils/data-classes/new-generics";
import ChartCanvas from "./ChartCanvas";

const NAMED_COLORS: Partial<Record<string,string>> = {
  "rifaximin": "#CB78BC",
}
const CUSTOM_COLORS: readonly string[] = ["#D55E00","#57B4E9","#0173B2","#DE9006","#ECE134","#C99161","#FBAFE4","#949494","#009E73",];

const MedsChart = ({meds}: {meds: MedLog[]}) => {
  const { state: { range: [startDT,endDT] } } = useContext(DateRangeContext);
  const [chart, setChart] = useState<Chart>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!startDT || !endDT || !canvasRef.current) return;
    if (chart) chart.destroy();
    setChart(createChart(canvasRef.current,[startDT,endDT],meds));
  },[canvasRef.current,meds,startDT,endDT]);

  setCanvasHeight(canvasRef,getNUniqueMeds(meds)); // Set canvas height on rerender based on number of unique meds

  return <ChartCanvas id={s.medsCanvasWrapper} canvasRef={canvasRef} fetching={!meds} />
}

const setCanvasHeight = (canvasRef: React.RefObject<HTMLCanvasElement>,nMeds: number): void => {
  if (!canvasRef.current) return;
  canvasRef.current.style.height = `${(130 + 5*nMeds).toFixed(0)}px`;
}

const createChart = (context: HTMLCanvasElement, range: [DateTime,DateTime], medLogs: MedLog[]|undefined): Chart => {
  return new Chart(context,{
    type: "scatter",
    data: {
      datasets: medLogs ? createDatasets(medLogs) : emptyDatasets,
    },
    options: {
      title: {
        text: "Medication Tracking",
      },
      scales: {
        xAxes: [
          timeSeriesXAxis(...range),
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Medication",
              fontSize: 14,
              fontColor: digest_styles.chartFontBlack,
            },
            gridLines: {
              display: false,
            },
            ticks: {
              display: true,
              callback: () => "",
              padding: 18,
              min: -1,
              max: getNUniqueMeds(medLogs),
            },
          }
        ]
      },
      tooltips: {
        caretPadding: 10,
        backgroundColor: digest_styles.tooltipBackground,
        displayColors: false,
        titleMarginBottom: 12,
        callbacks: {
          title: (items,{datasets}) => {
            const { datasetIndex, index } = items[0];
            const chartPoint: MedChartPoint | undefined = datasets?.[datasetIndex ?? 0]?.data?.[index ?? 0] as MedChartPoint;
            const dataItem = chartPoint?.x;
            if (!dataItem) return "";
            return DateTime.fromISO(dataItem.toString()).toFormat(CHART_DATE_FORMAT.LONG);
          },
          afterTitle: (items,{datasets}) => {
            const { datasetIndex } = items[0];
            const tradeName = datasets?.[datasetIndex ?? 0]?.label ?? "";
            return tradeName;
          },
          label: ({datasetIndex,index},{datasets}) => {
            const chartPoint: MedChartPoint | undefined = datasets?.[datasetIndex ?? 0]?.data?.[index ?? 0] as MedChartPoint;
            if (!chartPoint) return "";
            const { med } = chartPoint;
            return [
              dosageLabel(med),
            ];
          }
        }
      }
    },
  })
};

/* MED FUNCITIONS */

const dosageType = (med: MedLog): "Custom" | "Regimen" | "Default" => {
  if (med.units) return "Custom";
  if (med.regimen) return "Regimen";
  return "Default";
}

const dosageLabel = (med: MedLog): string => {
  switch (dosageType(med)) {
    case "Default":
      return `Default dose: ${med.defaultDose ? `${med.defaultDose} ${med.defaultUnits}` : "unknown"}`;
    case "Regimen":
      return `Dose: ${med.regimen?.dose && med.regimen.units ? `${med.regimen.dose} ${med.regimen.units}` : "unknown"}`;
    case "Custom":
      return `Dose: ${med.dose && med.units ? `${med.dose} ${med.units}` : "unknown"}`;
  }
}

const getNUniqueMeds = (meds: MedLog[]|undefined) => meds ? Object.values(binByProp(meds,"medId")).length : 0; 

const getMedLabel = (medLog: MedLog, index?: number): string => {
  return medLog.tradeName ?? medLog.chemicalName ?? (index ? `Med ${index+1} (unknown)` : "Unknown Medication");
}

/* DATASET FUNCTIONS */

const emptyDatasets: Chart.ChartDataSets[] = [{data: []}];

const createDatasets = (meds: MedLog[]): Chart.ChartDataSets[] => {
  const backupColors = [...CUSTOM_COLORS].reverse();
  return Object.values(binByProp(meds,"medId")).map((medLogs,i) => {
    const label = getMedLabel(medLogs[0],i);
    return createDataset(medLogs,label,getColorForDataset(NAMED_COLORS,label,backupColors),i);
  });
}

export const getColorForDataset = (fixedColorMap: Partial<Record<string,string>>,label: string,mutableBackupColors: string[]): string => {
  return fixedColorMap[label.toLowerCase()] ?? mutableBackupColors.pop() ?? tinycolor.random().toHexString();
}

const createDataset = (meds: MedLog[],label: string,color: string,yValue: number) => ({
  label,
  data: meds.map((med): MedChartPoint => ({x: med.medConsumedOn, y: yValue, med: med})),
  backgroundColor: color,
  hoverBackgroundColor: color,
  pointRadius: 3,
  pointHoverRadius: 10,
  borderColor: color,
  hoverBorderColor: color,
  borderWidth: 2,
  fill: false,
});

interface MedChartPoint extends ChartPoint {
  med: MedLog,
}

export default MedsChart;