import React from "react";
import { useContext, useLayoutEffect } from "react";
import { DateRangeContext } from "../../../contexts/DateRangeContext/DateRangeContext";
import s from "./DayToDay.module.scss";
import DigestLogic from "../DigestLogic";
import DashLayout from "../../../layouts/dash/DashLayout";
import DatePicker from "../../../components/datepicker/DatePicker";
import MultiChart from "./charts/MultiChart";
import SleepChart from "./charts/SleepChart";
import { PERIODS } from "../../../utils/consts";
import StressChart from "./charts/StressChart";
import DigestHeader from "../../../components/digest-header/DigestHeader";
import ExportToExcelButton from "./ExportToExcelButton";
import ErrorPage from "../../../components/errors/ErrorPage/ErrorPage";
import StoolMultiChart from "./charts/StoolMultiChart";
import { Stool, SymptomLabel } from "../../../utils/data-classes/Symptom";
import SymptomMultiChart from "./charts/SymptomMultiChart";
import { destroyAllCharts } from "../charts/chart-funcs";
import MedsChart from "./charts/MedChart";

const DayToDay = () => {
  let { patient, digest, error } = DigestLogic(["meals","ppms","symptoms","sleeps","stress","meds"]);

  let { meals, ppms, symptoms: _symptoms, sleeps, stress, meds } = digest;

  const allSymptoms = _symptoms?.map(s => s);
  // Separate stool from other symptoms as they are shown in a separate chart
  const symptoms = allSymptoms?.filter(s => s.label !== SymptomLabel.STOOL_FORM);
  const stools = allSymptoms?.filter(s => s.label === SymptomLabel.STOOL_FORM)?.map(s => Stool.fromSymptom(s));

  const { state: rangeState } = useContext(DateRangeContext);
  const { range } = rangeState;

  // Charts must be removed from DOM on DayToDay derender
  //  -> Some chart legends fetch charts by their title to control multiple datasets with the same label from one legend item
  //  -> Charts must be destroyed on teardown to avoid chart-title conflict on rendering another day-to-day page
  useLayoutEffect(() => {
    destroyAllCharts();
  },[]);

  if (error) return <ErrorPage code={error.code} message={error.message}/>

  return (
    <DashLayout>
      <DigestHeader patient={patient} />

      <div className={s.dayToDay}>
        <p>
          The charts below show all of the data logged by the patient. <br/>
          Hover over the data points to see more information. <br/>
          You can also change the date range or choose a monthly, weekly or daily view on the right. <br/>
        </p>

        <div className={s.dayToDayNav}>
          <div className={s.excelExportContainer}>
            <ExportToExcelButton data={digest} patient={patient} range={range} />
          </div>
          <div className={s.datePicker}>
            <DatePicker
              defaultPeriod={PERIODS.WEEK}
              defaultEarliestTime={patient.createdOn}
              disabledPeriods={PERIODS.ALL}
            />
          </div>
        </div>

        <div className={s.charts}>
          <MultiChart data={{ meals, ppms, symptoms }} range={range} />
          <SymptomMultiChart symptoms={symptoms} range={range} />
          <MedsChart meds={meds} />
          <StoolMultiChart stools={stools} range={range} />
          <SleepChart sleeps={sleeps} range={range} />
          <StressChart stress={stress} range={range} />
        </div>
      </div>
    </DashLayout>
  );
};

const isLoaded = (digest) => {
  return digest && digest.meals && digest.ppms && digest.symptoms;
}

export default DayToDay;
