import { LanguageCode } from "../../../utils/data-classes/Language";
import { Patient } from "../../../utils/data-classes/Patient";
import { AppError, authFetch } from "../../../utils/utils";
import { ChallengePromptFormFieldValues } from "./ChallengePromptForm";

export const sendChallengePrompt = async (token: string, patient: Patient, data: ChallengePromptFormFieldValues, signal: AbortSignal): Promise<void> => {
  const body: SendChallengePromptPostBody = {
    ptid: patient.ptid,
    ...data,
  };
  const res = await authFetch(token,`/api/challenge-requests/${patient.ptid}`, {
    method: "POST",
    body: JSON.stringify(body),
    signal,
  });
  const { error }: { error: string | undefined; } = await res.json();
  if (error !== undefined)
    throw new AppError(res.status, error);
  return;
};

interface SendChallengePromptPostBody {
  ptid: number;
  crtId: number;
  lang: LanguageCode;
  durationMins: number;
  intervalMins: number;
  finishChalOnTwoSuccessivePositive: boolean,
}

export const sendChallengeReminder = async (token: string, patient: Patient, requestedChallenge: string, signal: AbortSignal): Promise<void> => {
  const res = await authFetch(token,`/api/challenge-requests/${patient.ptid}/${requestedChallenge.toLowerCase()}/reminders`,{
    method: "POST",
    body: JSON.stringify({
      ptid: patient.ptid,
    }),
    signal,
  });
  const { error }: { error: string | undefined } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
};

export const deleteChallengeRequest = async (token: string, patient: Patient, requestedChallenge: string, signal: AbortSignal): Promise<void> => {
  console.log(requestedChallenge);
  const res = await authFetch(token,`/api/challenge-requests/${patient.ptid}/${requestedChallenge.toLowerCase()}`,{
    method: "DELETE",
    signal,
  });
  const { error } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return
}