import { Text, View } from "@react-pdf/renderer";
import { Challenge } from "../../../../utils/data-classes/challenge/Challenge";
import { shared, s } from "../challenge-report-styles";

export interface Reference {
  index: number;
  content: string;
}

type ReferenceKey = "rezaie" | "hammer";

const references: Record<"rezaie" | "hammer", Reference> = {
  rezaie: {
    index: 1,
    content: 'Ali Rezaie, et al. "Hydrogen and methane based breath testing in gastrointestinal disorders: The North American consensus", American Journal of Gastroenterology, 2017, 112:775-784.',
  },
  hammer: {
    index: 2,
    content: 'Heinz F. Hammer et al., "European guideline on indications, performance, and clinical impact of hydrogen and methane breath tests in adult and pediatric patients: European Association for Gastroenterology, Endoscopy and Nutrition, European Society of Neurogastroenterology and Motility, and European Society for Paediatric Gastroenterology Hepatology and Nutrition consensus." United European Gastroenterology Journal, 2022, 10(1):15-40.',
  }
};

export function citation(k: ReferenceKey) {
  return `[${references[k].index}]`;
}

export function References({ challenge }: { challenge: Challenge; }) {
  return (
    <View style={s.references.main} wrap={false}>
      <Text style={shared.header}>References</Text>
      <View style={s.references.body}>
        <ReferenceItem reference={references.rezaie} />
        {!challenge.isSIBO() && <ReferenceItem reference={references.hammer} />}
      </View>
    </View>
  );
}

function ReferenceItem({ reference }: { reference: Reference; }) {
  return (
    <View style={s.references.referenceItem}>
      <Text style={s.references.index}>[{reference.index}]</Text>
      <Text style={s.references.reference}>{reference.content}</Text>
    </View>
  );
}
