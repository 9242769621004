import { DateFormatOptions } from "../../../hooks/useDateFormat";
import { CellObject } from "xlsx-js-style";

export const FODMAP_ORDER = ["lactose", "fructose", "inulin", "fos", "gos", "sorbitol", "mannitol"];// HEADERS

export const TREATMENT_WINDOW_HEADERS = {
  preTreatment: "Pre-treatment",
  treatment: "Treatment",
  postTreatment: "Post-treatment",
};

export function isTreatmentWindowHeader(row: Row) {
  return !!Object.values(TREATMENT_WINDOW_HEADERS).find(header => isCellValueEqualTo(row[0],header));
}

/**
 * @todo Get Actual H2Eq equation
 */
export function getH2Eq(h2: number | undefined, ch4: number | undefined) {
  if (h2 === undefined && ch4 === undefined) return undefined;
  return (h2 ?? 0) + 4 * (ch4 ?? 0);
}

const PRE_TREATMENT_DAYS = 7;
const POST_TREATMENT_DAYS = 7 * 6;

export const RPM_CONFIG: RPMConfig = {
  dateFormat: {
    date: "MM/dd/yyyy",
    datetime: "MM/dd/yyyy HH:mm",
  },
  treatementWindow: {
    preTreatmentDays: PRE_TREATMENT_DAYS,
    postTreatmentDays: POST_TREATMENT_DAYS,
  },
};

export interface RPMConfig {
  dateFormat: DateFormatOptions;
  treatementWindow: {
    preTreatmentDays: number;
    postTreatmentDays: number;
  };
}

export const isCellValueEqualTo = (cell: string | CellObject | undefined, value: string) => {
  if (cell === undefined) return false;
  if (typeof cell === "string") return cell === value;
  return cell?.v === value;
};

export type Row = (string|CellObject)[];
