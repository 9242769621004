import { useEffect, useState } from "react";
import Symptom, { createSymptoms } from "../../../../utils/data-classes/Symptom";
import { Challenge, createChallenge } from "../../../../utils/data-classes/challenge/Challenge";
import { AppError } from "../../../../utils/utils";
import { Patient, createPatient } from "../../../../utils/data-classes/Patient";
import { ReferringClinician, createPatientPreferences } from "../../../../hooks/usePatientPreferences";
import { Provider, createProvider } from "../../../../utils/data-classes/Provider";
import useData from "../../../../hooks/useData";
import { PdfLogoSrc, fetchPdfLogoSrc } from "../../../../pdf-generator/ProviderLogo";
import useChallengeFollowonContent, {
  ChallengeFollowonContentRecord,
} from "../../../../hooks/useChallengeFollowonContent";

export default function useChallengeReportData(challengeToken: string): UseChallengeDataReturn {
  const { data, error: chalDataError } = useData(fetchChallengeData(challengeToken));
  const { content, error: cdContentError } = useChallengeFollowonContent();
  const logoSrc = useProviderPdfLogoSrc(data?.provider);
  const error = chalDataError || cdContentError;
  return {
    data:
      data && content && logoSrc && !error
        ? {
            provider: data.provider,
            patient: data.patient,
            challenge: data.challenge,
            symptoms: data.symptoms,
            logoSrc,
            referringClinician: data.preferences.referringClinician,
            followonContent: content,
          }
        : undefined,
    error,
  };
}

function useProviderPdfLogoSrc(provider: Provider | undefined): PdfLogoSrc | undefined {
  const [result, setResult] = useState<PdfLogoSrc>();

  useEffect(() => {
    if (!provider) return;
    provider
      .fetchLogoImage()
      .then((logo) => fetchPdfLogoSrc(logo))
      .then(setResult);
  }, [provider]);

  return result;
}

const fetchChallengeData = (challengeToken: string) => async (signal: AbortSignal) => {
  const res = await fetch(`/api/challenge-report-data/${challengeToken}`, {
    method: "GET",
    signal,
  });
  const { challenge, symptoms, patient, preferences, provider, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  return {
    challenge: createChallenge(challenge),
    symptoms: createSymptoms(symptoms),
    patient: createPatient(patient),
    preferences: createPatientPreferences(preferences),
    provider: createProvider(provider),
  };
};

interface UseChallengeDataReturn {
  data: ChallengeReportData | undefined;
  error: AppError | undefined;
}

export interface ChallengeReportData {
  challenge: Challenge;
  symptoms: Symptom[];
  patient: Patient;
  provider: Provider;
  logoSrc: PdfLogoSrc;
  followonContent: ChallengeFollowonContentRecord;
  referringClinician: ReferringClinician | undefined;
}
