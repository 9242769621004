import React from "react";
import ReactPDF, { Page, Text, View, StyleSheet, Image, Font,  } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { Currency } from "../../../contexts/OrderContext/OrderContext";
import Invoice, { InvoiceItem, InvoiceAddress } from "./Invoice";
import FoodMarbleHealthcareLogo from "../../../assets/provider-logos/foodmarble-healthcare.png";
import { useDateFormat } from "../../../hooks/useDateFormat";

const CLINICAL_GREEN = "#0e585f";

Font.register({
  family: 'Open Sans',
  fonts: [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 900 }
]
});

export default function InvoicePage({invoice}: {invoice: Invoice}) {
  return (
    <Page style={s.body}>
      <InvoiceHeader />
      <View style={s.infoView}>
        <Billing invoice={invoice} />
        <Details invoice={invoice} />
      </View>
      <InvoiceItemsTable invoice={invoice} />
      <Summary invoice={invoice} />
      <Footer />
    </Page>
  )
}

const Details = ({invoice}: {invoice: Invoice}) => {
  const issueDate = DateTime.fromMillis(invoice.createdOnMs,{ zone: 'utc' });
  return (
    <View style={{ display: "flex", flexDirection: "column", width: "50%"}}>
      <DetailsItem label="Invoice number">{invoice.id}</DetailsItem>
      <DetailsItem label="Date of issue">{issueDate.toFormat(useDateFormat("date"))}</DetailsItem>
      <BillingPeriod invoice={invoice} />
    </View> 
  );
}

const BillingPeriod = ({ invoice: { periodStartedOnMs, periodEndedOnMs } }: { invoice: Invoice }): JSX.Element => {
  const format = useDateFormat("date");
  if (!periodStartedOnMs || !periodEndedOnMs) return <></>;
  const start = DateTime.fromMillis(periodStartedOnMs,{zone: 'utc'}).toFormat(format);
  const end = DateTime.fromMillis(periodStartedOnMs,{zone: 'utc'}).toFormat(format);
  return <DetailsItem label="Billing Period">{start} to {end}</DetailsItem>
}

const DetailsItem = ({label, children}: {label: string, children: React.ReactNode}) => {
  return (
    <View>
      <Text style={{textAlign: "right", marginBottom: "5px", ...s.h1}}>{label}:</Text>
      <Text style={{textAlign: "right", marginBottom: "5px"}}>{children}</Text>
    </View>
  )
}

const Billing = ({invoice}: {invoice: Invoice}) => {
  const { customer: { billing } } = invoice;
  return (
    <View style={s.billing}>
      <Text style={s.h1}>Billing to:</Text>
      <Text>{billing.name}</Text>
      <Text>{billing.address}</Text>
      <Text>{billing.city}</Text>
      <Text>{billing.zipCode}</Text>
      <Text>{billing.country}</Text>
      <Text><Text style={{fontWeight: 600}}>Email: </Text>{billing.email}</Text>
      <Text><Text style={{fontWeight: 600}}>Phone: </Text>{billing.phone}</Text>
    </View>
  )
}

const Footer = () => {
  return (
    <View style={s.footer}>
      <Text style={s.footerItem}>FoodMarble Digestive Health Ltd., Guinness Enterprise Centre, Taylor's Lane, D08 YE0P, Dublin 8, Ireland.</Text>
      <Text style={s.footerItem}>Email: clinical@foodmarble.com</Text>
    </View>
  )
}

const InvoiceItemsTable = ({invoice: {items,currency}}: {invoice: Invoice}) => (
  <View style={{display: "flex", flexDirection: "column"}}>
    <InvoiceItemsHeader />
    { items.map((item,i) => <InvoiceItemRow key={i} item={item} currency={currency} />)}
  </View>
)

const InvoiceItemsHeader = () => (
  <View style={{
    ...s.row, 
    backgroundColor: CLINICAL_GREEN, 
    color: "#FFFFFF", 
    paddingTop: "10px", 
    paddingBottom: "10px", 
    fontWeight: 900
  }}>
    <Text style={s.descCell}>Description</Text>
    <Text style={s.quantityCell}>Qty</Text>
    <Text style={s.unitCostCell}>Unit Price</Text>
    <Text style={s.subTotalCell}>Subtotal</Text>
  </View>
);

const InvoiceItemRow = ({item,currency}: {item: InvoiceItem, currency: Currency}) => {
  const { description, quantity, unitPriceNet: unitPrice } = item;
  const priceSum = unitPrice !== undefined && quantity !== undefined ? unitPrice * quantity : undefined;
  return (
    <View style={s.row}>
      <Text style={s.descCell}>{description}</Text>
      <Text style={s.quantityCell}>{quantity}</Text>
      <Text style={s.unitCostCell}>{currency.parse(unitPrice) ?? "Unknown"}</Text>
      <Text style={s.subTotalCell}>{currency.parse(priceSum) ?? "Unknown"}</Text>
    </View>
  );
}

const InvoiceHeader = () => <View style={{height: "130px", width: "300px", paddingLeft: "40px", paddingTop: "34px"}}><Image src={FoodMarbleHealthcareLogo} /></View>

const Summary = ({invoice}: {invoice: Invoice}) => {
  const rates = Array.from(new Set(invoice.items.map((item) => item.taxRate)));
  return (
    <View style={s.summaryContainer}>
      <View style={s.summary}>
        <SubTotal invoice={invoice} />
        <Shipping invoice={invoice} />
        <Misc invoice={invoice} />
        {rates.map((rate,i) => <TaxTotal key={i} invoice={invoice} rate={rate} />)}
        <Total invoice={invoice} />
      </View>
    </View>
  )
}

const SubTotal = ({invoice}: {invoice: Invoice}) => (
  <View style={s.summaryItem}>
    <Text style={{...s.summaryLabel, fontWeight: 900}}>Subtotal</Text>
    <Text style={s.summaryContent}>{invoice.currency.parse(invoice.subTotal())}</Text>
  </View>
);

const TaxTotal = ({invoice, rate}: {invoice: Invoice, rate: number|undefined}) => {
  if (!rate) return <></>;
  return (
    <View style={s.summaryItem}>
      <Text style={s.summaryLabel}>VAT ({(rate * 100).toFixed(1)}%)</Text>
      <Text style={s.summaryContent}>{invoice.currency.parse(invoice.taxTotal(rate))}</Text>
    </View>
  );
}

const Shipping = ({invoice}: {invoice: Invoice}) => {
  return (
    <View style={s.summaryItem}>
      <Text style={s.summaryLabel}>Shipping</Text>
      <Text style={s.summaryContent}>{invoice.currency.parse(0)}</Text>
    </View>
  );
}

const Misc = ({invoice}: {invoice: Invoice}) => {
  return (
    <View style={s.summaryItem}>
      <Text style={s.summaryLabel}>Misc</Text>
      <Text style={s.summaryContent}>{invoice.currency.parse(0)}</Text>
    </View>
  )
}

const Total = ({invoice}: {invoice: Invoice}) => (
  <View style={s.summaryItem}>
    <Text style={{...s.summaryLabel, fontWeight: 900}}>Total</Text>
    <Text style={s.summaryContent}>{invoice.currency.parse(invoice.total())}</Text>
  </View>
);

const BASE_MARGIN = "30px"

const g = StyleSheet.create({
  a: {

  }
})

const s = StyleSheet.create({
  body: {
    color: CLINICAL_GREEN,
    fontFamily: 'Open Sans',
    fontSize: 12,
  },
  h1: {
    fontSize: 14,
    fontWeight: 900,
  },
  row: {
    display: "flex", 
    flexDirection: "row",
    paddingLeft: BASE_MARGIN,
    paddingRight: BASE_MARGIN,
    paddingTop: "17px",
  },
  descCell: {
    width: "45%",
    textAlign: "left",
  },
  quantityCell: {
    width: "5%",
    textAlign: "center",
  },
  unitCostCell: {
    width: "25%",
    textAlign: "right",
  },
  subTotalCell: {
    width: "25%",
    textAlign: "right",
  },
  numeric: {
    textAlign: "right"
  },
  summaryItem: {
    display: "flex", 
    flexDirection: "row",
    marginBottom: "15px"
  },
  summaryLabel: {
    width: "50%",
    textAlign: "right"
  },
  summaryContent: {
    width: "50%",
    textAlign: "right",
  },
  billing: {
    display: "flex", 
    flexDirection: "column", 
    width: "50%", 
    marginLeft: "10px"
  },
  footer: {
    display: "flex", 
    flexDirection: "column", 
    marginTop: "auto", 
    marginBottom: "18px", 
    fontSize: 10,
  },
  footerItem: {
    textAlign: "center", 
    marginBottom: "7px",
  },
  summaryContainer: {
    marginTop: "20px", 
    paddingTop: "50px", 
    marginLeft: BASE_MARGIN, 
    marginRight: BASE_MARGIN, 
    borderTop: `2px solid ${CLINICAL_GREEN}`
  },
  summary: {
    display: "flex", 
    flexDirection: "column", 
    width: "40%", 
    alignSelf: "flex-end"
  },
  infoView: {
    display: "flex", 
    flexDirection: "row", 
    marginLeft: BASE_MARGIN, 
    marginRight: BASE_MARGIN, 
    marginBottom: "20px"
  },
});