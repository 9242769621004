import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { createStaff, Staff } from "../utils/data-classes/Staff";
import { AppError, authFetch } from "../utils/utils";
import useErrorState from "./useErrorState";

interface UseLinkedCliniciansReturn {
  clinicians: Staff[] | undefined;
  error: AppError | undefined;
}

export default function useLinkedClinicians(): UseLinkedCliniciansReturn {
  const [clinicians, setClinicians] = useState<Staff[]>();
  const [error, setError] = useErrorState();

  const { state: { loginToken, staff } } = useContext(LoginContext);

  useEffect(() => {
    if (clinicians || error) return;
    if (!loginToken || !staff || !staff.permissions.admin) return;
    const abortController = new AbortController();
    fetchLinkedClinicians(loginToken,abortController.signal)
      .then(setClinicians)
      .catch(setError);
  },[loginToken,staff,clinicians,error]);

  return { clinicians, error }
}

type FetchLinkedCliniciansJSON = 
  | { clinicians: any[], error: undefined }
  | { clinicians: undefined, error: string }

const fetchLinkedClinicians = async (loginToken: string, signal: AbortSignal): Promise<Staff[]> => {
  const res = await authFetch(loginToken,"/api/linked-clinicians",{
    method: "GET",
    signal,
  });
  const { clinicians, error }: FetchLinkedCliniciansJSON = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return clinicians.map(c => createStaff(c));
}