import React, { useContext, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { Link } from "react-router-dom";
import { Information } from "../../components/tooltips/Information/Information";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import DashLayout from "../../layouts/dash/DashLayout";
import { AppError } from "../../utils/utils";
import { BulkOrderContext, BulkOrderContextProvider, ORDERING_ITEMS, OrderingItem } from "./BulkOrderContext";
import PageTitle from "../../components/PageTitle/PageTitle";
import s from "./Ordering.module.scss";
import { BulkOrderingModal } from "./BulkOrderingModal";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";
import { Currency } from "../../contexts/OrderContext/OrderContext";
import { getTotal } from "./ordering-utils";
import { ShippingError } from "./useShipping";
import { BillingError } from "./useBilling";

export default function Orders() {
  return (
    <DashLayout className={s.layout}>
      <BulkOrderContextProvider>
        <Ordering />
      </BulkOrderContextProvider>
    </DashLayout>
  );
}

const Ordering = () => {
  const { state: { pricing, error } } = useContext(BulkOrderContext);

  return (
    <div className={s.ordering}>
      {!pricing && !error && <LoadingSpinner />}
      {error && <Error error={error} />}
      {pricing && !error && (
        <>
          <OrderingDescription />
          <OrderingWidget />
          <BulkOrderingModal />
        </>
      )}
    </div>
  )
}

const Error = ({error}: {error: AppError}) => {
  const emailLink = "clinical@foodmarble.com";
  const isShipping404 = error instanceof ShippingError && error.code === 404;
  const isBilling404 = error instanceof BillingError && error.code === 404;
  return (
    <div className={s.error}>
      <h1>Error {error.code}</h1>
      {isShipping404 && (
        <>
          <p>Site shipping address not found.</p>
          <p>
            If you would like to avail of the bulk ordering feature and order devices directly to your site, 
            please contact <a href={`mailto: ${emailLink}`}>{emailLink}</a> to discussing setting up your site for orders
          </p>
        </>
      )}
      {isBilling404 && (
        <>
          <p>Provider payment method not found.</p>
          <p>In order to avail of device ordering, your site must add a payment method.</p>
          <Link to="/orders/add-card"><button type="button" className={s.btnPrimary}>Add Payment Method</button></Link>
        </>
      )}
      {!isShipping404 && !isBilling404 && <p>{error.message}</p>}
    </div>
  );
} 

const OrderingDescription = () => (
  <div className={s.desc}>
    <PageTitle>Orders</PageTitle>
    <div>
      <p>
        This page allows you to order devices and substrates to your site. 
        Select the type of device and substrate you would like to order. 
        Enter the quantity of each and your order total will be updated. 
        Hit the Next button to the right to proceed.
      </p>
      <div className={s.information}>
        <InformationRow data="Selecting the type of device and substrate will display the un-discounted unit price of each in the ‘unit price’ column. Discounted prices are displayed in the ‘Discounted Unit Price’ column. Discounts are applied based on order quantity. All orders placed within a 12 month window after your initial order will count towards a cumulative discount. After your initial order, subsequent orders will be discounted according to your agreed tiered pricing structure.">How pricing works</InformationRow>
      </div>
    </div>
  </div>
)

const InformationRow = ({data,children}: {data: string, children: React.ReactNode}) => (
  <div>
    <span>{children}</span>
    <span><Information data={data} /></span>
  </div>
)

function OrderingWidget(): JSX.Element {
  const { state: { pricing }, dispatch } = useContext(BulkOrderContext);

  const form = useForm<OrderingFormFieldValues>({
    defaultValues: {
      medaire2: 0,
      fodmaps: 0,
      glucose: 0,
    }
  });

  const { handleSubmit, watch, formState: { errors } } = form;

  const currency = pricing?.medaire2.currency;

  const onSubmit = (data: OrderingFormFieldValues) => dispatch({type: "SET_ORDERING", payload: true});

  useEffect(() => {
    dispatch({type: "SET_QUANTITIES", payload: {
      medaire2: watch("medaire2"),
      fodmaps: watch("fodmaps"),
      glucose: watch("glucose"),
    }});
  },[,watch("medaire2"),watch("fodmaps"),watch("glucose")])

  if (!pricing || !currency) return <></>

  return (
    <form className={s.orderForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={s.orderFormRows}>
        <OrderWidgetHeader />
        <OrderItemRow form={form} items={deviceItems} />
        <FormErrorMessage errors={errors} name="medaire2" />
        <OrderItemRow form={form} items={substrateItems} />
        <FormErrorMessage errors={errors} name="fodmaps" />
      </div>
      <div className={s.orderSummaryRows}>
        <OrderWidgetTotalRow form={form} currency={currency} />
        <OrderingWidgetNextButtonRow form={form} />
      </div>
    </form>
  );
}

const OrderWidgetTotalRow = ({form,currency}: {form: UseFormReturn<OrderingFormFieldValues,any>,currency: Currency}) => {
  const { state: { pricing } } = useContext(BulkOrderContext);
  return (
    <div className={s.orderWidgetSummaryRow}>
      <span></span>
      <span></span>
      <span></span>
      <span>Total</span>
      <span>{pricing && currency?.parse(getTotal(pricing))}</span>
    </div>
  )
}

const OrderingWidgetNextButtonRow = ({form}: {form: UseFormReturn<OrderingFormFieldValues,any>}) => {
  const { state: { ordering } } = useContext(BulkOrderContext);
  return (
    <div className={s.orderWidgetSummaryRow}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span><button type="submit" className={s.btnPrimary} disabled={!hasOrder(form) || ordering}>Next</button></span>
    </div>
  )
}

const hasOrder = (form: UseFormReturn<OrderingFormFieldValues,any>): boolean => {
  return Object.values(form.watch()).reduce<number>((acc,val: number) => acc + val,0) > 0;
}

const deviceItems: OrderingItem[] = [
  ORDERING_ITEMS.medaire2,
];

const substrateItems: OrderingItem[] = [
  ORDERING_ITEMS.fodmaps,
  ORDERING_ITEMS.glucose,
];

export interface OrderingFormFieldValues {
  medaire2: number,
  fodmaps: number,
  glucose: number,
}

function OrderWidgetHeader() {
  return (
    <div className={s.orderWidgetHeader}>
      <span>Select device and substrate</span>
      <span>Enter quantity</span>
      <span>Unit Price</span>
      <span>Discounted Unit Price</span>
      <span>Total</span>
    </div>
  )
}

function OrderItemRow({form,items}: {form: UseFormReturn<OrderingFormFieldValues,any>, items: OrderingItem[]}) {
  const { state: { pricing, } } = useContext(BulkOrderContext);

  const { watch } = form;

  const [selected, setSelected] = useState<keyof OrderingFormFieldValues>(items[0].name);

  const price = pricing?.[selected];

  useEffect(() => {
    updateRowItemQuantities(form,items,selected);
  },[selected]);

  if (!price) return <></>

  const isDiscounted = (price.unitPriceGross + price.unitTax) > price.unitPriceNet();

  return (
    <div className={s.orderItemRow}>
      <span>
        <select onChange={(e) => setSelected(e.target.value as keyof OrderingFormFieldValues)}>
          {items.map((item,i) => <option key={i} value={item.name}>{item.label}</option> )}
        </select>
      </span>
      <span><ItemQuantityInputs form={form} items={items} selected={selected} /></span>
      <span className={isDiscounted ? s.discounted : ""}>{price.currency.parse(price.unitPriceGross + price.unitTax)}</span> 
      <span>{price.currency.parse(price.unitPriceNet())}</span>
      <span>{price.currency.parse(price.unitPriceNet() * watch(price.name))}</span>
    </div>
  )
}

/**
 * This is ugly.
 * For reasons that are not immediately clear, the current quantity is not preserved when switching item
 * using the select unless the inputs are rendered in a list, even though only one item in the list will be an
 * input.
 */
const ItemQuantityInputs = ({form,items,selected}: {form: UseFormReturn<OrderingFormFieldValues,any>,items: OrderingItem[],selected: keyof OrderingFormFieldValues}) => {
  return (
    <>
      {items.map((item,i) => selected === item.name 
        ? <ItemQuantityInput key={i} form={form} item={item} /> 
        : <React.Fragment key={i} />
      )}
    </>
  );
}

/**
 * Row with item select, quantity input, and resulting pricing.
 * Set up to prevent submission of orders with a negative total price due to discounting. 
 */
const ItemQuantityInput = ({form,item}: {form: UseFormReturn<OrderingFormFieldValues,any>,item: OrderingItem}) => {
  const { state: { pricing } } = useContext(BulkOrderContext)

  return (
    <input
      type="number"
      min={0}
      {...form.register(item.name, {
        required: true,
        valueAsNumber: true,
        min: {
          value: 0,
          message: "Minimum quantity is 0",
        },
        validate: {
          hasOrder: () => {
            return hasOrder(form) || "Order must include more than 0 items";
          },
          orderTotalPositive: () => {
            return (!!pricing && getTotal(pricing) > 0) || "Order total price must be greater than 0";
          }
        }
      })}
    /> 
  );
}

/**
 * On changing selected item, set newly selected item quantity to quantity of old item, and set other item quantities to 0
 */
 const updateRowItemQuantities = (form: UseFormReturn<OrderingFormFieldValues,any>,items: OrderingItem[],selected: keyof OrderingFormFieldValues) => {
  const oldItem = items.find(item => form.getValues(item.name));
  const oldValue = oldItem ? form.getValues(oldItem.name) : 0;
  items.forEach(item => form.setValue(item.name,item.name === selected ? oldValue : 0));
}
