import { useContext } from "react";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { Provider } from "../utils/data-classes/Provider";

/**
 * Hook for getting appropriate date format fora given provider.
 * If no provider is provided it will use the provider taken from LoginContext.
 * 'all' option will return all formatting options for the provider.
 */
export function useDateFormat<TType extends Option>(type: TType, provider?: Provider): Format<TType> {
  const { state } = useContext(LoginContext);
  const result = getDateFormat(type, provider ?? state.provider);
  return result;
}

export function dateFormat(provider: Provider | undefined): DateFormatOptions {
  if (!provider || provider.country === "US") return DATE_FORMATS.US;
  return DATE_FORMATS.DEFAULT;
}

export const DATE_FORMATS: Record<"US" | "DEFAULT", DateFormatOptions> = {
  US: {
    date: "MM-dd-yyyy",
    datetime: "MM-dd-yyyy HH:mm",
  },
  DEFAULT: {
    date: "dd-MMM-yyyy",
    datetime: "dd-MMM-yyyy HH:mm",
  },
};

function getDateFormat<TType extends Option>(type: TType, provider: Provider | undefined): Format<TType>;
function getDateFormat<TType extends Option>(type: TType, provider: Provider | undefined): DateFormatOptions | string {
  const format = dateFormat(provider);
  if (type === "all") return format;
  return dateFormat(provider)[type as keyof DateFormatOptions];
}

export interface DateFormatOptions {
  date: string;
  datetime: string;
}

type Option = keyof DateFormatOptions | "all";
type Format<T> = T extends "all" ? DateFormatOptions : T extends keyof DateFormatOptions ? string : never;
