import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { Patient } from "../utils/data-classes/Patient";
import { parseURLSearchParams } from "../utils/utils";

/**
 * Returns the patient available to logged in staff based on ptid in url search params
 */
export default function usePatientFromURLSearchParams(): Patient | undefined {
  let history = useHistory();
  let ptid = parseInt(parseURLSearchParams(history.location.search).ptid);
  const { state: { patients } } = useContext(LoginContext);
  const patient = patients?.find(p => p.ptid === ptid);
  return patient;
}