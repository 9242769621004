import { Provider } from "../../utils/data-classes/Provider";
import s from "./AffiliateContract.module.scss";

const AffiliateContract = ({provider, fullName, affiliateEmail, commission}: {provider: Provider, fullName: string, affiliateEmail: string, commission: number}) => {
  const today = new Date();
  const month = today.getMonth()+1;
  const year = today.getFullYear();
  const day = today.getDate();
  const providerCountry = provider.country;
  var formattedDate = "";
  if (providerCountry === "US") {
    formattedDate = month + "-" + day + "-" + year;
  }
  else {
    formattedDate = day + "-" + month + "-" + year;
  }

  const providerName = provider.emailLabel || provider.label;
  return (
    <div className={s.contract}>
      <h1>FoodMarble Clinical Affiliate Agreement</h1>
      <em>This agreement is dated {formattedDate}</em>
      <br />
      <br />
      <p>
        <span className={s.sameLineTitle}>FoodMarble Digestive Health Limited</span>
         a private company limited by shares, incorporated under the laws of Ireland, 
        with registered address at Guinness Enterprise Centre, Taylor’s Lane, Dublin 8, Ireland (the “
            <span className={s.thick}>Company</span>
            ") 
      </p>
      <p>and</p>
      <p>(the "<span className={s.thick}>Affiliate</span>")</p>
      <table>
        <tr>
          <th >AFFILIATE DETAILS</th><td ></td>
        </tr>
        <tr>
          <th>NAME</th><td className={s.odd}>{fullName} </td>
        </tr>
        <tr>
          <th>COMPANY/PRACTICE</th><td className={s.even}>{providerName}</td>
        </tr>
        <tr>
          <th>EMAIL</th><td className={s.odd}>{affiliateEmail}</td>
        </tr>
      </table>
      <p>
        <span className={s.sameLineTitle}>The Company and the Affiliate agree as follows:</span>
        <br />
        
      </p>
      <p className={s.listStart}>1. The Agreement</p>
      <ol className={s.test}>
        <li className={s.alpha}>The Affiliate agrees to refer and link to the Products and Services available on <a href="https://foodmarble.com">https://foodmarble.com</a> when considered a suitable option for their clients.</li>
        <li className={s.alpha}>The Affiliate undertakes not to refer their clients to the Company’s affiliate program if the services provided are to be reimbursed by federal healthcare programs leading to financial compensation from a state body to the Affiliate.</li>
        <li className={s.alpha}>This Agreement does not create an exclusive agreement between The Company and the Affiliate. Both parties will have the right to recommend similar products and services of third parties and to work with other parties in connection with the design, sale, installation, implementation and use of similar services and products of third parties.</li>
        <li className={s.alpha}>The Affiliate shall ensure that the content shared is in keeping with and does not harm the reputation of the Company’s brand. The Company reserves an express right to immediately terminate this Agreement at any time if it is felt the actions of the Affiliate have negatively impacted its brand (in the sole discretion of the Company).</li>
        <li className={s.alpha}>Creative and text content pieces must be approved by email by the company before it is posted by the Affiliate.</li>
        <li className={s.alpha}>This agreement shall be governed by and construed in accordance with the laws of Ireland and the courts of Ireland shall have exclusive jurisdiction to deal with all disputes arising from or touching upon this agreement.</li>
        {(commission > 0) &&
          <div>
        <li className={s.alpha}>Payment terms:
            <ol>
                <li className={s.roman}>The Affiliate will be paid {commission} of the sales value of the sales that are tracked from their promotion of the Company’s products.</li>
                <li className={s.roman}>Payments will be processed quarterly, where commission is outstanding for at least 10 devices. Any unpaid commission will roll-forward to subsequent quarters.
                <ol>
                    <li className={s.num}>When the contract expires, the Affiliate has the right to invoice the Company for any outstanding balance.</li>
                    <li className={s.num}>All Invoices will be paid by the Company within 30 days of the invoice date.</li>
                </ol>
                </li>
            </ol>
        </li>
        </div>
        }
        <ol></ol>
        <li className={s.alpha}>The Affiliate is not an employee of the company and as such shall be responsible for their own income tax, social welfare contributions and other levies required by law to be paid by them.</li>
        <li className={s.alpha}>In the event of system failure, loss of access or deterioration in service regarding access to the Company’s website, the Company shall take all reasonable steps to restore or rectify the service. The Affiliate shall not be entitled to any form of compensation.</li>
      </ol>
      <p className={s.listStart}>2. Confidentiality</p>
      <ol>
        <li className={s.alpha}> In providing services to the Company pursuant to this Agreement, the Affiliate may acquire information that pertains to the Company's products, processes, equipment, programs, developments, or plans and that is both (i) disclosed or made known by the Company to the Affiliate and (ii) identified as "proprietary" by the Company at any time ("Proprietary Information"). The Affiliate agrees not to disclose any Proprietary Information to third parties or to use any Proprietary Information for any purpose other than performance of consultingservices pursuant to this Agreement, without prior written consent of the Company.</li>
      </ol>
      <p className={s.listStart}>3. Term and Termination</p>
      <ol>
        <li className={s.alpha}>This Agreement shall remain in force for a period of 12 months from the date of the Agreement above (the "Initial Term") and shall continue thereafter unless or until terminated by either party giving not less than 1 week notice in writing, such notice not to expire before the end of the Initial Term. </li>
        <li className={s.alpha}> The parties further agree that this Agreement may be terminated before the expiry of the Initial Term. </li>
      </ol>
      <p>The parties have executed this Agreement effective the date first stated above.</p>
      
    </div>
  )
}

export default AffiliateContract;