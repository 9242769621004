/*
 * This component creates a DatePicker (PeriodPicker included)
 * For this, it uses the React-Flatpickr library:
 * https://github.com/haoxins/react-flatpickr
 *
 * It receives optional props and updates the DateRange context
 * based on dashboard user's input
 */
import React from "react";
import { useContext, useEffect, useRef } from "react";
import "flatpickr/dist/themes/material_blue.css";
import s from "./DatePicker.module.scss";
import Flatpickr from "react-flatpickr";
import { DateTime } from "luxon";
import { DateRangeContext } from "../../contexts/DateRangeContext/DateRangeContext";
import {
  setEarliestTime,
  updateByPeriod,
  updateByRange
} from "../../contexts/DateRangeContext/DateRangeContextActions";

import { PERIODS } from "../../utils/consts";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { Provider } from "../../utils/data-classes/Provider";

const DatePicker = ({
  defaultPeriod,
  disabledPeriods,
  defaultEarliestTime,
}) => {
  // Get the relevant date-parsing tokens for flatpickr
  const { state: loginState } = useContext(LoginContext);
  const { provider } = loginState;

  // Get the date range context
  const { state: rangeState, dispatch: rangeDispatch } = useContext(
    DateRangeContext
  );

  /*
    DatePicker needs some defaults to set the DateRangeContext. 
    These defaults can come from props. If they are not specified by props, however, 
    we set the defaults below

    To avoid infinite loops of renders, make sure the following effect runs only once
  */
  let defaultsSet = useRef(false);
  useEffect(() => {
    if (defaultsSet.current) return;

    // earliestTime is used when determining what "ALL" means: from earliestTime to this date
    // Unless specified otherwise, set it to minus 5 years from today
    let earliestTime = defaultEarliestTime
      ? defaultEarliestTime
      : DateTime.local().minus({ year: 5 });
    rangeDispatch(setEarliestTime(earliestTime));

    // Unless specified otherwise, set default period to a week.
    // The default period cannot be "CUSTOM"
    let period = defaultPeriod ? defaultPeriod : PERIODS.WEEK;
    if (period === PERIODS.CUSTOM) {
      throw new Error("Default period cannot be CUSTOM");
    }

    // Set the initial date range based on the period
    // If ALL is selected, then cover the whole possible range (i.e. from earliestTime)
    let endDT = DateTime.local().plus({ day: 1 }).startOf("day");
    let startDT =
      period === PERIODS.ALL
        ? defaultEarliestTime
        : endDT.minus({ [period]: 1 }).startOf("day");
    rangeDispatch(updateByRange([startDT, endDT]));

    // Make sure this effect will only run once, on initial mount
    defaultsSet.current = true;
  }, [defaultPeriod, defaultEarliestTime, rangeDispatch]);

  // Not all periods are applicable to all components
  // ("ALL", for example, is not relevant to "/digest" pages)
  // Unless specified otherwise, we enable all periods
  disabledPeriods = disabledPeriods ? disabledPeriods : [];
  let availablePeriods = Object.values(PERIODS).filter(
    (p) => disabledPeriods.indexOf(p) === -1
  );

  /* 
    Function for date-picker arrow buttons 
    Receives a direction ("forward" or "backward")
    Moves the range by one period in that direction
   */
  const moveDays = (direction) => {
    // Get current range and period from context
    let [startDT, endDT] = rangeState.range;
    let period = rangeState.period;

    // When ALL is selected, the buttons should be disabled anyway
    // This is done in the JSX by a conditional disabled attribute
    if (period === PERIODS.ALL) {
      throw new Error("Range cannot be moved when ALL days are selected");
    }

    // To accommodate custom period moves, we'll keep a variable called moveBy
    // This will allow us to move the range by arbitrary days ({ day: 13 }) --for CUSTOM
    // as well as by one specific period ({ month: 1 }) --for DAY, WEEK, MONTH
    let moveBy = 1;
    if (period === PERIODS.CUSTOM) {
      period = PERIODS.DAY;
      moveBy = endDT.diff(startDT, "days").as("days");
    }

    if (direction === "forward") {
      startDT = startDT.plus({ [period]: moveBy });
      endDT = endDT.plus({ [period]: moveBy });
    }
    if (direction === "backward") {
      startDT = startDT.minus({ [period]: moveBy });
      endDT = endDT.minus({ [period]: moveBy });
    }

    rangeDispatch(updateByRange([startDT, endDT]));
  };

  return (
    <div className={s.datePicker}>
      <div className={s.periods}>
        <ul>
          {
            // Here we render range options that dash users can switch between by clicking.
            // "custom" shouldn't be a clickable range option thus we filter it out.
            availablePeriods
              .filter((p) => p !== PERIODS.CUSTOM)
              .map((period) => (
                <li
                  key={period}
                  className={period === rangeState.period ? s.active : ""}
                  onClick={() => rangeDispatch(updateByPeriod(period))}
                >
                  {period}
                </li>
              ))
          }
        </ul>
      </div>

      <div className={s.dates}>
        <button
          disabled={rangeState.period === PERIODS.ALL}
          onClick={() => moveDays("backward")}
        >
          ‹
        </button>
        <Flatpickr
          value={
            // The query range from context is an array of Luxon dates
            // Flatpickr does not recognize luxon, thus we'll convert them into JS dates
            rangeState.range.map((luxonDate) => luxonDate.toJSDate())
          }
          options={{
            mode: "range",
            dateFormat: dateFormat(provider), // display the dates in desired format
            disable: [
              (date) =>
                DateTime.fromJSDate(date) > DateTime.local().plus({ day: 1 }),
            ], // disable all future dates
          }}
          onChange={([startDateJS, endDateJS]) => {
            // onChange will run on just start date changes too
            // Do not update context until user chooses a full range
            if (!endDateJS) return;

            // Flatpickr returns native JS Dates. DateRangeContext works with Luxon.
            // We'll convert JS dates to Luxon dates before updating the context.
            let range = [
              DateTime.fromJSDate(startDateJS),
              DateTime.fromJSDate(endDateJS),
            ];

            // Don't update the dates if selected dates are the same with the existing query
            let sameDatesSelected =
              range[0].toISODate() === rangeState.range[0].toISODate() &&
              range[1].toISODate() === rangeState.range[1].toISODate();
            if (sameDatesSelected) return;

            rangeDispatch(
              updateByRange([
                DateTime.fromJSDate(startDateJS),
                DateTime.fromJSDate(endDateJS),
              ])
            );
          }}
        />
        <button
          disabled={rangeState.period === PERIODS.ALL}
          onClick={() => moveDays("forward")}
        >
          ›
        </button>
      </div>
    </div>
  );
};

/**
 * @param {Provider} provider 
 */
function dateFormat(provider) {
  if (!provider || provider.country === "US") return "m/d/Y";
  return "d/m/Y";
}

export default DatePicker;
