import React, { createContext, useEffect, useReducer } from "react"
import usePatientRegistrationDetails, { Order, OrderInfo } from "../../hooks/usePatientRegistrationDetails"
import { Patient } from "../../utils/data-classes/Patient"
import { Provider } from "../../utils/data-classes/Provider"
import { AppError } from "../../utils/utils"
import ClinicalOrderStatus from "../../pages/register/ClinicalOrderStatus"
import { Staff } from "../../utils/data-classes/Staff"
import { Country } from "../../hooks/useCountries"
import useSupportedOrderingCountries from "../../pages/register/useSupportedOrderingCountries"

export interface RegistrationContextType {
  state: RegistrationContextState,
  dispatch: React.Dispatch<RegistrationContextAction>,
}

export type RegistrationType = "new" | "existing"

export interface RegistrationContextState {
  isRegistered: boolean | undefined,
  type: RegistrationType | undefined,
  patient: Patient | undefined,
  user: Object | undefined,
  provider: Provider | undefined,
  staff: Staff | undefined,
  supportedCountries: Country[] | undefined,
  orderInfo: OrderInfo | undefined,
  requiresConsentForm: boolean | undefined,
  hasViewedConsentForm: boolean,
  error: AppError | undefined,
  processing: boolean,
}

const initialState: RegistrationContextState = {
  isRegistered: undefined,
  patient: undefined,
  user: undefined,
  provider: undefined,
  staff: undefined,
  supportedCountries: undefined,
  type: undefined,
  orderInfo: undefined,
  requiresConsentForm: undefined,
  hasViewedConsentForm: false,
  error: undefined,
  processing: false,
}

export type RegistrationContextAction = 
  | { type: "SET_IS_REGISTERED", payload: boolean } 
  | { type: "SET_SUPPORTED_COUNTRIES", payload: Country[] | undefined }
  | { type: "SET_TYPE", payload: RegistrationType }
  | { type: "SET_REGISTRATION_DETAILS", payload: RegistrationDetails }
  | { type: "SET_REGISTRATION_FORM_VALUES", payload: { password: string|undefined, privacyConsent: boolean }}
  | { type: "SET_ERROR", payload: AppError }
  | { type: "SET_PROCESSING", payload: boolean}
  | { type: "SET_HAS_VIEWED_CONSENT_FORM", payload: boolean }

interface RegistrationDetails {
  patient: Patient,
  user: Object | undefined,
  provider: Provider,
  staff: Staff,
  orderInfo: OrderInfo | undefined,
  requiresConsentForm: boolean | undefined,
}

const reducer = (state: RegistrationContextState,action: RegistrationContextAction): RegistrationContextState => {
  switch (action.type) {
    case "SET_IS_REGISTERED":
      return { ...state, isRegistered: action.payload }
    case "SET_SUPPORTED_COUNTRIES":
      return { ...state, supportedCountries: action.payload }
    case "SET_TYPE": 
      return { ...state, type: action.payload }
    case "SET_REGISTRATION_DETAILS":
      return { ...state, ...action.payload }
    case "SET_REGISTRATION_FORM_VALUES":
      return { ...state, ...action.payload }
    case "SET_ERROR":
      return { ...state, error: action.payload }
    case "SET_PROCESSING": 
      return { ...state, processing: action.payload }
    case "SET_HAS_VIEWED_CONSENT_FORM":
      return { ...state, hasViewedConsentForm: action.payload }
    default:
      return state;
  }
}

export const RegistrationContext = createContext<RegistrationContextType>({
  state: initialState,
  dispatch: () => {},
});

interface RegistrationContextProviderProps {
  children: React.ReactNode,
  patientCode: string,
}

export const RegistrationContextProvider = ({children,patientCode}: RegistrationContextProviderProps): JSX.Element => {
  const [state,dispatch] = useReducer<React.Reducer<RegistrationContextState,RegistrationContextAction>>(reducer,initialState);

  const { details: { patient, provider, staff, user, orderInfo: orderInfo, requiresConsentForm }, error } = usePatientRegistrationDetails(patientCode);


  const { countries, error: countriesError } = useSupportedOrderingCountries(state.orderInfo?.order.isSelfPay ?? undefined);

  useEffect(() => {
    if (!error) return;
    if (error) dispatch({ type: "SET_ERROR", payload: error });
  },[error]);

  useEffect(() => {
    if (!patient || !provider || !staff) return;
    dispatch({ type: "SET_TYPE", payload: user ? "existing" : "new"});
    dispatch({type: "SET_REGISTRATION_DETAILS", payload: { patient, provider, staff, orderInfo: orderInfo, user, requiresConsentForm }});
  },[patient,provider,user,orderInfo]);

  useEffect(() => {
    if (state.supportedCountries) return;
    if (countriesError) dispatch({type: "SET_ERROR", payload: countriesError});
    if (countries) dispatch({type: "SET_SUPPORTED_COUNTRIES", payload: countries});
  },[countries,countriesError]);

  // Set initial stage
  useEffect(() => {
    if (!patient) return;
    dispatch({type: "SET_IS_REGISTERED", payload: patient.uid ? patient.privacyConsent : false });
  },[state.patient]);

  useEffect(() => {
    if (state.isRegistered || state.error) dispatch({type: "SET_PROCESSING", payload: false});
  },[state.isRegistered,state.error]);

  return (
    <RegistrationContext.Provider value={{state,dispatch}}>
      {children}
    </RegistrationContext.Provider>
  );
}