import { DateTime, Duration, DurationLike } from "luxon";
import Gas from "../Gas";
import { Test } from "../Test";
import { Challenge } from "./Challenge";
import { maxTestAndIndex, areaUnderCurve } from "./challenge-util";

export abstract class ChallengePpmHandler {
  readonly challenge: Challenge;
  readonly gas: Gas;
  constructor(challenge: Challenge, gas: Gas) {
    this.challenge = challenge;
    this.gas = gas;
  }

  maxTest(): Test | null {
    const { test: result } = maxTestAndIndex(this.challenge.tests, this.gas);
    return result;
  }

  lowestBeforeMaxTest(): Test {
    // LOWEST BEFORE MAX (a.k.a. LOWEST PRECEDING)
    let { index: maxIndex } = maxTestAndIndex(this.challenge.tests, this.gas);

    if (!maxIndex) return this.challenge.firstTest;

    return this.challenge.tests.slice(0, maxIndex).reduce((t1, t2) => ((t1.ppm(this.gas) ?? 0) < (t2.ppm(this.gas) ?? 0) ? t1 : t2));
  }

  rise(amount: number): number {
    return (this.challenge.baselineTest.ppm(this.gas) ?? 0) + amount;
  }

  riseFromBaselineInDuration(duration: Duration): number {
    const startDT = DateTime.fromISO(this.challenge.firstTest.testCreatedOn);
    const testsBeforeMax = this.challenge.tests.filter((test) => test.testCreatedOn <= startDT.plus(duration).toISO());
    const { test: maxTest } = maxTestAndIndex(testsBeforeMax, this.gas);
    const maxPpm = maxTest?.ppm(this.gas) ?? null;
    const baselinePpm = this.challenge.baselineTest.ppm(this.gas);
    if (maxPpm === null || baselinePpm === null) return 0;
    return maxPpm - baselinePpm;
  }

  maxRisePeriodTime() {
    return this.challenge.firstTest.dateTime.plus({ minutes: this.risePeriodMinutes() });
  }

  testsInDurationUpToMax(duration: DurationLike) {
    const maxEnd = DateTime.fromISO(this.challenge.firstTest.testCreatedOn).plus(duration);
    const testsWithinDuration = this.challenge.tests.filter((t) => DateTime.fromISO(t.testCreatedOn) <= maxEnd);
    const { index: maxIndex } = maxTestAndIndex(testsWithinDuration, this.gas);
    const minIndex = this.challenge.tests.indexOf(this.challenge.baselineTest);
    const result = (maxIndex) ? this.challenge.tests.slice(minIndex, maxIndex + 1) : [];
    return result;
  }

  testsInPositiveRisePeriod() {
    // a rise can occur only after lowest-before-max
    return this.testsInDurationUpToMax({minutes: this.risePeriodMinutes()});
  };

  maxTestInDurationWithPositiveRise(duration: DurationLike) {
    /*
      A high rise (positive rise) is considered a 20ppm rise from the lowest-before-max value
      For SIBO challenges (Lactulose and Glucose) this rise has to happen within the first 90 mins
      This is called "positive criteria". If a challenge meets this criteria, it's considered a positive challenge
    */
    const rises = this.testsInDurationUpToMax(duration).filter((t) => (t.ppm(this.gas) ?? 0) >= this.positiveCriteria());
    const { test: result } = maxTestAndIndex(rises, this.gas);
    return result;
  }

  /**
   * Checks if a high rise is occured during the challenge.
   * @returns the test with first positive rise if there is one, or else null
   */
  maxRiseTest() {
    return this.maxTestInDurationWithPositiveRise({minutes: this.risePeriodMinutes()});
  };

  /**
   * Get rise in ppm from baseline, regardless of if it meets positve criteria
   * @returns
   */
  riseFromBaselineInPositiveRisePeriod() {
    return this.riseFromBaselineInDuration(Duration.fromObject({ minutes: this.risePeriodMinutes() }));
  }

  peakTestInPositiveRisePeriod() {
    const tests = this.challenge.tests.filter(test => test.testCreatedOn <= this.maxRisePeriodTime().toISO());
    const result = tests.reduce((max,test) => (test.ppm(this.gas) ?? 0) > (max.ppm(this.gas) ?? 0) ? test : max,tests[0]);
    return result;
  }

  areaUnderCurve(): number {
    const { ppms, minutes } = this.getPpmsWithMinutes();
    return areaUnderCurve(minutes, ppms);
  }

  private getPpmsWithMinutes(): { ppms: number[]; minutes: number[]; } {
    const result: { ppms: number[]; minutes: number[]; } = { ppms: [], minutes: [] };
    const t0 = this.challenge.firstTest.testCreatedOnMs;
    const getMinutes = (test: Test) => (test.testCreatedOnMs - t0) / (1000 * 60);
    return this.challenge.tests.reduce((acc, test) => {
      const ppm = test.ppm(this.gas);
      if (ppm !== null) {
        acc.ppms.push(ppm);
        acc.minutes.push(getMinutes(test));
      }
      return acc;
    }, result);
  }

  isPositive(): boolean {
    return !!this.positiveRise();
  }

  abstract isBorderline(): boolean;

  isNegative(): boolean {
    return !this.isPositive() && !this.isBorderline();
  }

  abstract risePeriodMinutes(): number;

  abstract positiveCriteria(): number;

  // POSITIVE RISE (a.k.a. RISE TO POSITIVE CRITERIA)
  abstract positiveRise(): Test | null;

  abstract highBaseline(): number;

  abstract result(): number | null;
}

export default ChallengePpmHandler;
