import React, { createContext, useContext, useEffect, useReducer } from "react";
import { Patient } from "../../utils/data-classes/Patient";
import { AppError } from "../../utils/utils";
import { By } from "../../pages/home/add-patient/By";
import { Sku } from "../../hooks/useSkus";
import useSkus from "../../hooks/useSkus";
import useLinkedClinicians from "../../hooks/useLinkedClinicians";
import { Staff } from "../../utils/data-classes/Staff";
import { LoginContext } from "../LoginContext/LoginContext";
import useIndividualOrderOptions, { IndividualOrderOptions } from "../../pages/home/add-patient/add-patient-util/useIndividualOrderOptions";

export type AddPatientStage = 
  | "PATIENT_DETAILS"
  | "LINK_CLINICIANS"
  | "ORDER_DEVICE_PROMPT"
  | "ORDER_DEVICE"
  | "SELECT_CHALLENGE_PROMPT"
  | "SELECT_CHALLENGE"
  | "SUCCESS"
  | "ERROR"

export interface AddPatientContextState {
  stage: AddPatientStage,
  by: By,
  patient: Patient | undefined,
  skus: Partial<Record<number,Sku>>|undefined,
  orderOptions: IndividualOrderOptions | undefined,
  linkedClinicians: Staff[]|undefined,
  error: AppError | undefined,
  orderComplete: boolean,
  processing: boolean,
}

export type AddPatientContextAction =
  | { type: "SET_STAGE", payload: AddPatientStage }
  | { type: "SET_BY", payload: By }
  | { type: "SET_PATIENT", payload: Patient | undefined }
  | { type: "SET_SKUS", payload: Partial<Record<string,Sku>>}
  | { type: "SET_ERROR", payload: AppError | undefined }
  | { type: "SET_PROCESSING", payload: boolean }
  | { type: "SET_ORDER_COMPLETE", payload: boolean }
  | { type: "SET_LINKED_CLINICIANS", payload: Staff[]|undefined }
  | { type: "SET_ORDER_OPTIONS", payload: IndividualOrderOptions | undefined }

export interface AddPatientContextType {
  state: AddPatientContextState,
  dispatch: React.Dispatch<AddPatientContextAction>,
}

function reducer(state: AddPatientContextState, action: AddPatientContextAction): AddPatientContextState {
  switch (action.type) {
    case "SET_STAGE": {
      return { ...state, stage: action.payload }
    }
    case "SET_BY": {
      return { ...state, by: action.payload }
    }
    case "SET_PATIENT": {
      return { ...state, patient: action.payload }
    }
    case "SET_SKUS": {
      return { ...state, skus: action.payload }
    }
    case "SET_ERROR": {
      return { ...state, error: action.payload }
    }
    case "SET_PROCESSING": {
      return { ...state, processing: action.payload }
    }
    case "SET_ORDER_COMPLETE": {
      return { ...state, orderComplete: action.payload}
    }
    case "SET_LINKED_CLINICIANS": {
      return { ...state, linkedClinicians: action.payload }
    }
    case "SET_ORDER_OPTIONS": {
      return { ...state, orderOptions: action.payload }
    }
  }
}

const initialState: AddPatientContextState = {
  stage: "PATIENT_DETAILS",
  by: "email",
  processing: false,
  orderComplete: false,
  patient: undefined,
  skus: undefined,
  orderOptions: undefined,
  linkedClinicians: undefined,
  error: undefined,
}

export const AddPatientContext = createContext<AddPatientContextType>({
  state: initialState,
  dispatch: () => {},
});

export const AddPatientContextProvider = ({children}: {children: React.ReactNode}) => {
  const [state,dispatch] = useReducer<React.Reducer<AddPatientContextState,AddPatientContextAction>>(reducer,initialState);

  const { state: { staff } } = useContext(LoginContext);

  const { skus, error: skuError } = useSkus();
  const { clinicians: linkedClinicians, error: linkedCliniciansError } = useLinkedClinicians();
  const { options: orderOptions, error: orderOptionsError } = useIndividualOrderOptions();

  useEffect(() => {
    if (!skus && !skuError) return;
    if (skuError) {
      dispatch({type: "SET_ERROR", payload: skuError});
      return;
    }
    if (skus) {
      dispatch({type: 'SET_SKUS', payload: Object.fromEntries(skus.map(sku => [sku.skuId,sku]))})
      return;
    }
  },[skus,skuError]);

  /**
   * Linked clinicians (needed for admin users to link new patient to connected clinicians)
   */
  useEffect(() => {
    if (!staff) return;
    if (linkedCliniciansError) {
      dispatch({type: "SET_ERROR", payload: linkedCliniciansError});
      return;
    }
    if (!linkedClinicians) return;
    if (staff.permissions.admin && linkedClinicians.length === 0) {
      dispatch({type: "SET_ERROR", payload: new AppError(404,"No clinicians found linked to admin user")});
      return;
    }
    dispatch({type: "SET_LINKED_CLINICIANS", payload: linkedClinicians});
  },[staff,linkedClinicians,linkedCliniciansError]);

  useEffect(() => {
    if (!orderOptions) return;
    dispatch({type: "SET_ORDER_OPTIONS", payload: orderOptions});
  },[orderOptions]);

  useEffect(() => {
    if (!state.patient && !state.orderComplete) return;
    dispatch({type: "SET_PROCESSING", payload: false});
    dispatch({type: "SET_STAGE", payload: "SELECT_CHALLENGE_PROMPT"});
  },[state.patient,state.orderComplete]);

  useEffect(() => {
    if (!state.processing) return;
    dispatch({type: "SET_ERROR", payload: undefined});
  },[state.processing]);

  useEffect(() => {
    if (!state.error) return;
    dispatch({type: "SET_PROCESSING", payload: false});
    dispatch({type: "SET_STAGE", payload: "ERROR"});
  },[state.error]);

  return (
    <AddPatientContext.Provider value={{state,dispatch}}>
      {children}
    </AddPatientContext.Provider>
  );
}