import s from "./Dialog.module.scss";
import Modal from 'react-modal';
import DialogNav from "../DialogNav/DialogNav";
import DialogBase from "../DialogBase/DialogBase";
import React from "react";

/**
 * @param {Object} props 
 * @param {boolean} props.isOpen
 * @param {() => void} props.onClose
 * @param {() => void} [props.afterOpen]
 * @param {string} [props.contentLabel]
 * @param {string} [props.appElement]
 * @param {React.ReactNode} props.children
 * @returns 
 */
const Dialog = (props) => {
	return (			
			<DialogBase
				isOpen={props.isOpen}
				afterOpen={props.afterOpen}
				onClose={props.onClose}
				contentLabel={props.contentLabel}
			>
				<div className={s.dialogContent}>
          {props.children}
        </div>
			</DialogBase>
	);
}

export default Dialog;