import { Text, View } from "@react-pdf/renderer";
import { ArrearsInvoice, PatientInvoiceItem } from "../../../../utils/data-classes/ArrearsInvoice";
import { DateTime } from "luxon";
import { s } from "../arrears-invoice-style";
import { H1, Table, Tr } from "./markdown";
import { isLast, getTotal } from "../util";
import { comparePatientInvoiceItems } from "../util";
import { ArrearsInvoicePage } from "./ArrearsInvoicePage";
import { TotalFooter } from "./DeviceFooter";
import { DeviceHeader } from "./DeviceHeader";
import { Sku } from "../../../../hooks/useSkus";
import useCurrency from "../../../../contexts/CurrencyContext/useCurrency";
import { useContext } from "react";
import ArrearsInvoiceContext from "../ArrearsInvoiceContext";

export function DirectToPatientPages() {
  const invoice = useContext(ArrearsInvoiceContext);
  const items = splitDirectPatientItems(invoice);
  if (items.length === 0) return <></>;
  return (
    <>
      {items.map((pageItems, i) => (
        <DirectToPatientPage key={i} items={pageItems} withFooter={isLast(i, items)} />
      ))}
    </>
  );
}

function DirectToPatientPage(props: DirectToPatientTableProps) {
  return (
    <ArrearsInvoicePage>
      <View>
        <H1>Direct to Patient Orders*</H1>
        <DirectToPatientTableShard {...props} />
        <Text>*A shipping charge is applied for each order sent directly to patients.</Text>
      </View>
    </ArrearsInvoicePage>
  );
}

function splitDirectPatientItems(invoice: ArrearsInvoice) {
  return invoice.items.devices.shippedToPatient.reduce<PatientInvoiceItem[][][]>((acc, cur, i) => {
    if (i === 0 || acc[acc.length - 1].length === 11) acc.push([]);
    acc[acc.length - 1].push(cur);
    return acc;
  }, []);
}

function DirectToPatientTableShard({ items, withFooter }: DirectToPatientTableProps) {
  const invoice = useContext(ArrearsInvoiceContext);
  return (
    <Table>
      <DeviceHeader />
      {items.map((items, i) => (
        <DirectToPatientRows key={i} items={items} />
      ))}
      {withFooter && <TotalFooter label="Direct to Patient Total" val={invoice.shippedToPatientTotal()} />}
    </Table>
  );
}

function DirectToPatientRows({ items }: DirectToPatientRowsProps) {
  const invoice = useContext(ArrearsInvoiceContext);
  const sortedItems = items.sort(comparePatientInvoiceItems);
  const { testedOnMs } = items[0];
  const date = DateTime.fromMillis(testedOnMs, { zone: "utc" }).toFormat("dd-MMM-yyyy");
  const total = useCurrency(getTotal(sortedItems));
  return (
    <View style={{ display: "flex", flexDirection: "column" }}>
      {sortedItems.map((item, i) => (
        <DirectToPatientSubRow
          key={i}
          invoice={invoice}
          sku={invoice.skus[item.skuId]}
          item={item}
          date={(i === 0 && date) || undefined}
          total={(i === sortedItems.length - 1 && total) || undefined}
        />
      ))}
    </View>
  );
}

function DirectToPatientSubRow({ sku, item, date, total }: DirectToPatientSubRowProps) {
  return (
    <Tr>
      <Text style={s.ptTable.date}>{date ?? ""}</Text>
      <Text style={s.ptTable.item}>{sku.shortLabel}</Text>
      <Text style={s.ptTable.unitPrice}>{useCurrency(item.unitPriceNet)}</Text>
      <Text style={s.ptTable.total}>{total ?? ""}</Text>
    </Tr>
  );
}

interface DirectToPatientRowsProps {
  items: PatientInvoiceItem[];
}

interface DirectToPatientSubRowProps {
  invoice: ArrearsInvoice;
  sku: Sku;
  item: PatientInvoiceItem;
  date: string | undefined;
  total: string | undefined;
}

interface DirectToPatientTableProps {
  withFooter: boolean;
  items: PatientInvoiceItem[][];
}
