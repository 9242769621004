import React, { useContext, useEffect } from "react";
import s from "./AddPatient.module.scss";
import { By } from "./By";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import HorizontalInputSelector from "../../../components/menus/HorizontalInputSelector/HorizontalInputSelector";
import { Path } from "react-hook-form";
import { validationRegEx } from "../../../utils/FormValidation";
import { CountryCode } from "libphonenumber-js/types";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import { AddPatientContext, AddPatientStage } from "../../../contexts/AddPatientContext/AddPatientContext";
import { LanguageSelectWidget } from "../../../components/menus/LanguageSelect/LanguageSelect";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import IFTAPhoneInput from "../../register/IFTAElements/IFTAPhoneInput/IFTAPhoneInput";
import { registrationFormOptions, AddPatientFormFieldValues } from "./AddPatient";
import { useAddPatientFormContext } from "./add-patient-util/useAddPatientFormContext";
import IFTASelect from "../../register/IFTAElements/IFTASelect/IFTASelect";
import { validatePatientPhoneNumber, getIdtMailFromIdentifier } from "./add-patient-util/add-patient-util";
import useStaffRole from "./add-patient-util/useStaffRole";

export default function AddPatientDetails() {
  const {
    state: { by }, dispatch,
  } = useContext(AddPatientContext);
  return (
    <>
      <h1>ADD NEW PATIENT</h1>
      <p className={s.explanation}>
        You may register a patient in one of two ways:
        <br />
        <br />
        <b>For simplicity</b>, you can register them with their email address and we will send them the next steps.
        <br />
        <br />
        <b>For maximum patient privacy</b>, you can enter an identifier (such as a patient number) and we will send you
        the patient instructions, which you must then forward to the patient.
      </p>
      <HorizontalInputSelector
        options={registrationFormOptions}
        selectedOption={by}
        setSelectedOption={(by: By) => dispatch({ type: "SET_BY", payload: by })} />
      <div className={s.newUser}>
        {by === "email" && <ByEmailFields />}
        {by === "identifier" && <ByIdtFields />}
      </div>
    </>
  );
}

const ByEmailFields = () => {
  const {
    state: { provider },
  } = useContext(LoginContext);
  const form = useAddPatientFormContext();
  const {
    formState: { errors },
    register,
    control,
    watch,
  } = form;

  return (
    <>
      <IFTAInput
        id="firstName"
        label="First Name"
        reg={register("firstName", { required: "First name is required" })}
      />
      <FormErrorMessage errors={errors} name="firstName" />
      <IFTAInput id="lastName" label="Last Name" reg={register("lastName", { required: "Last name is required" })} />
      <FormErrorMessage errors={errors} name="lastName" />
      <IFTAInput
        id="email"
        label="Email Address"
        type="email"
        reg={register("email", {
          required: "Email address is required",
          pattern: {
            value: validationRegEx.email,
            message: "Invalid email address",
          },
        })}
      />
      <FormErrorMessage errors={errors} name="email" />
      <IFTAInput type="date" id="dateOfBirth" label="Date of Birth" reg={register("dateOfBirth")} />
      <FormErrorMessage errors={errors} name="dateOfBirth" />
      {provider?.hasPhoneAccess && provider.countryCode && (
        <PhoneInput countryCode={provider.countryCode as CountryCode} />
      )}
      <LanguageSelectWidget control={control} selected={watch("lang")} name="lang" />
      <FormErrorMessage errors={errors} name="lang" />
      <div className={s.refCheckbox}>
        <input id="provide-referring-clinician" type="checkbox" {...register("provideReferringClinician")} />
        <label htmlFor="provide-referring-clinician">Add referring clinician?</label>
      </div>
      {watch("provideReferringClinician") && <RefferringClinicianInputs />}
      <ByEmailNextButton />
    </>
  );
};

const ByEmailNextButton = () => {
  const { dispatch } = useContext(AddPatientContext);
  const { trigger } = useAddPatientFormContext();
  const { provider } = useContext(LoginContext).state;
  const form = useAddPatientFormContext();
  const role = useStaffRole();

  const getNextStage = (): AddPatientStage => {
    switch (role) {
      case "admin": return "LINK_CLINICIANS";
      case "affiliate": return "ORDER_DEVICE";
      default: return "ORDER_DEVICE_PROMPT";
    }
  };

  const isValid = async () => {
    const fields: Path<AddPatientFormFieldValues>[] = ["email", "firstName", "lastName", "dateOfBirth"];
    if (provider?.hasPhoneAccess) fields.push("phone");
    return await trigger(fields);
  };

  const onClick = () => async () => {
    if (!(await isValid())) return;
    if (role === "affiliate") form.setValue("device",true);
    dispatch({ type: "SET_STAGE", payload: getNextStage() });
  };

  return (
    <button type="button" onClick={onClick()}>
      Next
    </button>
  );
};

const ByIdtFields = () => {
  return (
    <div className={s.patientDetailsIdt}>
      <InputIdt />
      <ByIdtNextButton />
    </div>
  );
};

const ByIdtNextButton = () => {
  const { trigger } = useAddPatientFormContext();
  const {
    state: { processing },
    dispatch,
  } = useContext(AddPatientContext);
  const isAdmin = useStaffRole() === "admin";

  const isValid = () => trigger(["identifier"]);

  const onClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!isAdmin) return; // Submit form
    event.preventDefault();
    if (!(await isValid())) return;
    dispatch({ type: "SET_STAGE", payload: "LINK_CLINICIANS" });
  };

  return (
    <button type="submit" disabled={processing} onClick={onClick}>
      {processing ? "Processing..." : isAdmin ? "Next" : "Submit"}
    </button>
  );
};

const PhoneInput = ({ countryCode }: { countryCode: CountryCode }) => {
  const form = useAddPatientFormContext();
  return (
    <>
      <IFTAPhoneInput
        form={form}
        name="phone"
        id="phone"
        label="Phone Number"
        style={{ width: "100%" }}
        defaultCountry={countryCode}
        rules={{
          validate: (phoneNumber) => validatePatientPhoneNumber(phoneNumber.toString()),
        }}
      />
      <FormErrorMessage errors={form.formState.errors} name="phone" />
    </>
  );
};

const InputIdt = () => {
  const {
    state: { provider },
  } = useContext(LoginContext);
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useAddPatientFormContext();

  const identifier = watch("identifier");

  useEffect(() => {
    if (!identifier) return;
    setValue("identifier", identifier.toLowerCase());
  }, [identifier]);

  if (!provider) return <></>;

  return (
    <>
      <IFTAInput
        type="text"
        id="identifier"
        label="Anonymous Identifier"
        reg={register("identifier", {
          required: "Anonymous identifier is required",
          pattern: {
            value: validationRegEx.emailPrefix,
            message:
              "Identifier must be a valid email prefix. Allowed characters: letters, numbers, and special characters '.', '_', '%', '+', and '-'",
          },
          maxLength: {
            value: 64,
            message: "Identifier must have fewr than 65 characters",
          },
          validate: (val) =>
            !!getIdtMailFromIdentifier(val, provider).match(validationRegEx.email) || "Invalid email address",
        })}
      />
      <div className={s.idtMail}>
        <p style={{ whiteSpace: "pre" }}>Email: {`${getIdtMailFromIdentifier(watch("identifier"), provider)}`}</p>
      </div>
      <FormErrorMessage errors={errors} name="identifier" />
    </>
  );
};

const SALUTATIONS = [
  "Dr",
  "Prof",
  "Mr",
  "Ms",
];

function RefferringClinicianInputs() {
  const { register, formState: { errors } } = useAddPatientFormContext();
  return (
    <>
      <SelectSalutation />
      <FormErrorMessage errors={errors} name="referringClinician.salutation" />
      <SelectPostNominal />
      <FormErrorMessage errors={errors} name="referringClinician.postNominal" />
      <IFTAInput id="name" label="First Name" reg={register("referringClinician.name", { required: "First name is required" })} />
      <FormErrorMessage errors={errors} name="referringClinician.name" />
      <IFTAInput id="surname" label="Last Name" reg={register("referringClinician.surname", { required: "Last name is required" })} />
      <FormErrorMessage errors={errors} name="referringClinician.surname" />
    </>
  );
}

const POST_NOMINALS = [
  "MD",
  "NP",
  "PA",
];

function SelectSalutation() {
  const { register } = useAddPatientFormContext();
  return (
    <IFTASelect id="salutation" label="Salutation" reg={register("referringClinician.salutation")}>
      <option value="">No Salutation</option>
      {SALUTATIONS.map((salutation, i) => <option key={i} value={salutation}>{salutation}</option>)}
    </IFTASelect>
  )
}

function SelectPostNominal() {
  const { register } = useAddPatientFormContext();
  return (
    <IFTASelect id="post-nominal" label="Post-nominal" reg={register("referringClinician.postNominal")}>
      <option value="">No Post-nominal</option>
      {POST_NOMINALS.map((postNominal,i) => <option key={i} value={postNominal}>{postNominal}</option>)}
    </IFTASelect>
  ) 
}

