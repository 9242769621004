import React, { useContext, useEffect, useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { AppError, hostUrl } from "../../../utils/utils";
import STRIPE_PROMISE from "../../register/StripePromise";
import s from "./AddCard.module.scss";
import { getCountry } from "../../register/LocationForms";
import DashLayout from "../../../layouts/dash/DashLayout";
import { AddCardContext, AddCardContextProvider } from "./AddCardContext";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import useNewCustomerIdAndClientSecret from "./useNewCustomerIdAndSetupIntent";
import PageTitle from "../../../components/PageTitle/PageTitle";
import useBilling from "../useBilling";
import { OrderCountryCode } from "../../../contexts/OrderContext/OrderContext";
import { validationRegEx, validator } from "../../../utils/FormValidation";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import IFTAPhoneInput from "../../register/IFTAElements/IFTAPhoneInput/IFTAPhoneInput";
import IFTACountryDropdown from "../../register/IFTAElements/IFTASelect/IFTACountrySelect";
import IFTARegionSelect from "../../register/IFTAElements/IFTASelect/IFTARegionSelect";
import useErrorState from "../../../hooks/useErrorState";

export default function AddCardPage(): JSX.Element {
  return (
    <DashLayout>
      <AddCardContextProvider>
        <AddCard />
        <ErrorPage />
      </AddCardContextProvider>
    </DashLayout>
  )
}

function AddCard(): JSX.Element {
  const { state: { billing, complete, error } } = useContext(AddCardContext);
  if (error) return <></>;
  return (
    <div className={s.addCard}>
      <div className={s.desc}>
        <PageTitle>Add Card</PageTitle>
        <div>
          Use this page to add billing information and card details for your site.
        </div>
      </div>
      <div className={s.addCardWidget}>
        <div className={s.widgetHeader}><BackButton /></div>
        { !billing && !complete && <AddressForm /> }
        { billing && !complete && <StripeSetup />}
        { complete && <Success />}
      </div>
    </div>
  )
}

function ErrorPage() {
  const { state: { error } } = useContext(AddCardContext);
  if (!error) return <></>;
  return (
    <div className={s.addCard}>
      <div className={s.desc}>
        <h1>Error {error.code}</h1>
        <div>
          {error.message}
        </div>
      </div>
    </div>
  )
}

const BackButton = () => {
  const { state: { billing, processing, complete }, dispatch } = useContext(AddCardContext);
  return (
    <button 
      className={s.btnTertiary}
      onClick={() => dispatch({type: "SET_BILLING", payload: undefined})}
      disabled={!billing || processing || complete}
    >
      Back
    </button>
  );
}

export interface Address {
  name: string,
  email: string,
  phone: string,
  address: string,
  city: string,
  countryCode: OrderCountryCode,
  zipCode: string,
  stateCode: string,
}

export interface AddCardAddressFormFieldValues extends Address {
  country: string,
}

function AddressForm(): JSX.Element {
  const { dispatch } = useContext(AddCardContext);

  const { billing: oldBilling } = useBilling();

  const form = useForm<AddCardAddressFormFieldValues>({
    defaultValues: {
      address: '',
      city: '',
      stateCode: '',
      country: '',
      countryCode: undefined,
      email: '',
      name: '',
      phone: '',
      zipCode: ''
    }
  });

  const { handleSubmit, register, formState: { errors }, watch, reset } = form;

  const onSubmit = (data: AddCardAddressFormFieldValues) => {
    dispatch({type: "SET_BILLING", payload: { ...data, country: getCountry(data.countryCode) } });
  }

  useEffect(() => {
    if (!oldBilling || !oldBilling.billingCountry) return;
    reset({
      name: oldBilling.billingName,
      address: oldBilling.billingAddress1,
      city: oldBilling.billingCity,
      zipCode: oldBilling.billingZip,
      stateCode: oldBilling.billingState,
      countryCode: oldBilling.billingCountry as OrderCountryCode,
      country: getCountry(oldBilling.billingCountry),
      email: oldBilling.billingEmail,
      phone: oldBilling.billingPhone,
    })
  },[oldBilling]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Billing Address</h2>
      <IFTAInput id="name" label="Full name" reg={register("name",{required: "Full name is required"})} />
      <FormErrorMessage errors={errors} name="name" />

      <IFTAInput id="email" label="Email address" reg={register("email", {
        required: "Email address is required",
        pattern: {
          value: validationRegEx.email,
          message: "Invalid email address",
        }
      })}/>
      <FormErrorMessage errors={errors} name="email" />

      <IFTAPhoneInput id="phone" label="Phone number" className={s.phoneInput} name="phone" form={form}  rules={{
        required: "Phone number is required",
        validate: {
          invalid: validator.phoneNumber("Invalid phone number"),
        }
      }}/>
      <FormErrorMessage errors={errors} name="phone" />

      <IFTAInput id="address" label="Address" reg={register("address",{required: "Address is required"})} />
      <FormErrorMessage errors={errors} name="address" />

      <IFTAInput id="city" label="City" reg={register("city",{required: "City is required"})} />
      <FormErrorMessage errors={errors} name="city" />

      <IFTAInput id="zipCode" label="Postal code" reg={register("zipCode",{
        required: "Zip code is required",
        pattern: watch("countryCode") === "US" ? {
          value: validationRegEx.zipCodeUS,
          message: "Invalid zip code",
        } : undefined,
      })}/>
      <FormErrorMessage errors={errors} name="zipCode" />

      <IFTACountryDropdown
        id="countryCode"
        label="Country"
        classes={s.roundInput}
        valueType="short"
        whitelist={["US", "CA", "GB", "IE", "NZ", "AU"]}
        form={form}
        name="countryCode"
        rules={{required: "Country is required"}}
      />
      <FormErrorMessage errors={errors} name="countryCode" />
      
      <IFTARegionSelect
        id="stateCode"
        label="State"
        classes={s.roundInput}
        country={watch("countryCode")} 
        countryValueType="short"
        valueType="short"
        defaultOptionLabel="Select State"
        form={form}
        name="stateCode"
        rules={{required: "State is required"}}
      />
      <FormErrorMessage errors={errors} name="stateCode" />
      <button className={s.btnPrimary}>Next</button>
    </form>
  )
}

function StripeSetup(): JSX.Element {
  const { state: { billing } } = useContext(AddCardContext);

  const { customerId, clientSecret } = useNewCustomerIdAndClientSecret(billing);

  const ready = customerId && clientSecret;

  return (
    <div className={s.card}>
      {!ready && <div className={s.loading}><LoadingSpinner /></div>}
      {ready && (
        <Elements stripe={STRIPE_PROMISE} options={{clientSecret}}>
          <AddCardForm />
        </Elements>
      )}
    </div>
  );
}

function AddCardForm(): JSX.Element {
  const { state: { loginToken } } = useContext(LoginContext);
  const { state: { billing, processing }, dispatch } = useContext(AddCardContext);

  const stripe = useStripe();
  const elements = useElements();

  const [ready, setReady] = useState(false);
  const [error, setError] = useErrorState();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements || !loginToken || !billing) return;

    dispatch({type: "SET_PROCESSING", payload: true});

    const { error: stripeError } = await stripe.confirmSetup({ 
      elements,
      confirmParams: {
        return_url: returnUrl().toString(),
      },
    });

    if (stripeError) {
      setError(new AppError(400,stripeError.message || "A Stripe error occurred"));
      return;
    }
  }

  useEffect(() => {
    if (!error) return;
    if (error) dispatch({type: "SET_PROCESSING", payload: false});
  },[error]);

  return (
    <form onSubmit={handleSubmit}>
      <h2>Card Details</h2>
      <PaymentElement onReady={() => setTimeout(() => setReady(true),1000)} />
      {ready && <button className={s.submitButton} disabled={!stripe}>{processing ? "Processing..." : "Submit"}</button>}
      {error && <div>{error.message}</div>}
    </form>
  );
}

const Success = () => {
  return (
    <div>
      <h1>Success</h1>
      <h2>You have sucessfully updated payment info for your site.</h2>
    </div>
  )
}

const returnUrl = (): URL => {
  return new URL("orders/add-card/setup-complete",hostUrl());
}