import React from "react";
import PageNotFound from "../components/errors/ErrorPages/PageNotFound";

class DashRoute {
  readonly path: string;
  readonly component: () => JSX.Element;
  readonly children: React.ReactNode;
  constructor(path: string,component: () => JSX.Element,children?: React.ReactNode) {
    this.path = path;
    this.component = component;
    this.children = children
  }
}

export const NOT_FOUND_ROUTE = new DashRoute("*",PageNotFound);

export default DashRoute;