import React from "react";
import s from "./Register.module.scss";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import { REG_TYPES } from "../../utils/consts";

import appStore from "../../assets/badges/app-store-badge.svg";
import playStore from "../../assets/badges/google-play-badge.png";
import useTranslation from "../../hooks/useTranslation";

const APP_LINK = {
  IOS: "https://apps.apple.com/ie/app/foodmarble-healthcare/id1579518441",
  ANDROID: "https://play.google.com/store/apps/details?id=com.foodmarble.healthcare",
}

const Success = ({ patient, registrationType }) => {
  const { t } = useTranslation();
  const { email } = patient;
  return (
    <div className={s.registerSuccess}>
      <h1>{t("register.success.title")}</h1>
      { registrationType === REG_TYPES.NEW && (
          <>
            <p>{t("register.success.new.content",{email})}</p>
            <p>{t("register.success.new.downloads.content")}</p>
            <div className={s.badges}>
              <div className={s.apple}>
                <b>iPhone: </b>
                <a
                  href={APP_LINK.IOS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStore} alt={t("register.success.downloads.ios")} />
                </a>
              </div>
              <div className={s.google}>
                <b>Android: </b>
                <a
                  href={APP_LINK.ANDROID}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={playStore} alt={t("register.success.downloads.android")} />
                </a>
              </div>
            </div>
          </>
        )
      }
      {registrationType === REG_TYPES.EXISTING && <p>{t("register.success.existing.content",{email})}</p>}
    </div>
  );
};

export default Success;
