import XLSX, { WorkSheet, Range } from "xlsx-js-style";
import { SplitDigest, splitDigestOnMedStartAndEndTimes } from "./rpm-digest-split";
import { RPMDigest } from "./useRPMDigest";
import { createAllRows, shouldMerge } from "./rpm-excel-rows";
import { RPMConfig, RPM_CONFIG } from "./rpm-excel-util";

export function createRPMWorkbook(digest: RPMDigest) {
  const workbook = XLSX.utils.book_new();
  const worksheets: Map<string, XLSX.WorkSheet> = createRpmWorksheets(digest);
  Array.from(worksheets.entries()).forEach(([tradeName, sheet]) =>
    XLSX.utils.book_append_sheet(workbook, sheet, tradeName)
  );
  return workbook;
}

export function createRpmWorksheets(digest: RPMDigest) {
  const result: Map<string, XLSX.WorkSheet> = new Map();
  const config = RPM_CONFIG;
  const digestDataByMedTradeName = splitDigestOnMedStartAndEndTimes(digest, config);
  const tradeNames = Array.from(digestDataByMedTradeName.keys());
  tradeNames.forEach((tradeName) => {
    const splitDigest = digestDataByMedTradeName.get(tradeName);
    if (!splitDigest) return;

    const sheet = createRpmWorksheet(tradeName, splitDigest, config);
    result.set(tradeName, sheet);
  });
  return result;
}

function createRpmWorksheet(tradeName: string, splitDigest: SplitDigest, config: RPMConfig) {
  const rows = createAllRows(tradeName, splitDigest, config);
  const sheet = XLSX.utils.aoa_to_sheet(rows);
  styleWorksheet(sheet, rows);
  return sheet;
}

function styleWorksheet(worksheet: WorkSheet, rows: any[][]) {
  setMaxWidths(worksheet, rows);
  mergeCells(worksheet, rows);
}

function mergeCells(worksheet: WorkSheet, rows: any[][]) {
  worksheet["!merges"] = getRangesToMerge(rows);
}

function getRangesToMerge(rows: any[][]) {
  return rows.reduce<Range[]>((acc, cur, i, arr) => {
    if (shouldMerge(cur, i, arr)) acc.push(getRangeConveringRowArr(rows, i));
    return acc;
  }, []);
}

function getRangeConveringRowArr(rows: any[][], i: number): Range {
  return {
    s: {
      c: 0,
      r: i,
    },
    e: {
      c: rows[i].length - 1,
      r: i,
    },
  };
}

function setMaxWidths(worksheet: WorkSheet, rows: any[][]) {
  const cols: { wch: number }[] = new Array(getNColumns(rows)).fill({ wch: 15 });
  worksheet["!cols"] = cols;
}

function getNColumns(rows: any[][]) {
  return rows.reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0);
}
