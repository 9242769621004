import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { Currency } from "../../contexts/OrderContext/OrderContext";
import { AppError, authFetch } from "../../utils/utils";
import DiscountScheme from "./DiscountScheme";
import useErrorState from "../../hooks/useErrorState";

export interface DiscountSchemeBackend {
  dsid: number;
  currency: Currency;
  prid: number;
  createdOnMs: number;
  startedOnMs: number;
  endedOnMs: number;
  skuId : number;
  description: string;
  bands: DiscountBandBackend[];
  backpayOwed: number;
}

interface DiscountBandBackend {
  discountBandId: number;
  createdOnMs: number;
  minUnits: number;
  maxUnits: number|null;
  unitDiscount: number;
  dsid: number;
  backpayOwed: number;
}

export type SkuIdDiscountSchemeMap = Record<number,DiscountScheme>;

interface UseDiscountSchemesReturn {
  discountSchemes: SkuIdDiscountSchemeMap | undefined;
  error: AppError | undefined;
}

export default function useDiscountSchemes(): UseDiscountSchemesReturn {
  const [discountSchemes, setDiscountSchemes] = useState<SkuIdDiscountSchemeMap>();
  const [error, setError] = useErrorState();

  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!loginToken || discountSchemes || error) return;
    const abortController = new AbortController();

    fetchDiscountSchemes(loginToken,abortController.signal)
      .then(setDiscountSchemes)
      .catch(setError);

    return () => abortController.abort();
  },[loginToken,discountSchemes,error]);

  return { discountSchemes, error }
} 

type FetchDiscountSchemesResJSON = 
  | { schemes: DiscountSchemeBackend[], error: undefined }
  | { schemes: undefined, error: string }

const fetchDiscountSchemes = async (token: string, signal: AbortSignal): Promise<SkuIdDiscountSchemeMap> => {
  const res = await authFetch(token,"/api/discount-scheme",{signal});
  const { schemes, error }: FetchDiscountSchemesResJSON = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return Object.fromEntries(schemes.map(scheme => [
    scheme.skuId,
    createDiscountScheme(scheme)
  ]));
}

export function createDiscountScheme(scheme: DiscountSchemeBackend): DiscountScheme {
  return new DiscountScheme({
    ...scheme,
    createdOnMs: scheme.createdOnMs ?? Infinity,
    bands: scheme.bands.map(band => ({
      ...band,
      maxUnits: band.maxUnits ?? Infinity,
    }))
  });
}