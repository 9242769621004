import React from "react";
import PreloginLayout from "../layouts/prelogin/PreloginLayout";

export const LoggedInForbidden = () => (
  <PreloginLayout>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "10%" }}>
      <h1 style={{ textAlign: "center" }}>Error 403: Forbidden</h1>
      <p>You are currently logged in to the dashboard as a clinician/admin.</p>
      <p>You must first log out of the dashboard in order to access this page.</p>
    </div>
  </PreloginLayout>
);
