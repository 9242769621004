import { useEffect, useState } from "react";
import ClinicalOrderStatus from "../pages/register/ClinicalOrderStatus";
import { createPatient, Patient } from "../utils/data-classes/Patient";
import { createProvider, Provider } from "../utils/data-classes/Provider";
import { createStaff, Staff } from "../utils/data-classes/Staff";
import { Support } from "../utils/data-classes/Support";
import { AppError } from "../utils/utils";
import { OrderItem } from "./useOrderHistory";
import { Sku } from "./useSkus";
import useErrorState from "./useErrorState";

export default function usePatientRegistrationDetails(patientCode: string): UsePatientRegistrationDetailsReturn {
  const [details, setDetails] = useState<PatientRegDetails>({
    patient: undefined,
    orderInfo: undefined,
    staff: undefined,
    provider: undefined, 
    user: undefined,
    support: undefined,
    requiresConsentForm: undefined,
  });
  const [error,setError] = useErrorState();
  
  useEffect(() => {
    if (!patientCode || details.patient || error) return;
    const abortController = new AbortController();
    fetchPatientRegDetails(patientCode,abortController.signal).then(setDetails).catch(setError);
    return () => abortController.abort();
  },[patientCode,details,error]);

  return { details, error };
}

const fetchPatientRegDetails = async (code: string,signal: AbortSignal): Promise<PatientRegDetails> => {
  const res = await fetch("/api/get-patient-registration-details",{
    method: "POST",
    body: JSON.stringify({code}),
    signal,
  });
  const { patient,user,orderInfo,provider,staff,support,requiresConsentForm,error } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  const result: PatientRegDetails = {
    patient: patient && createPatient(patient), 
    user, 
    orderInfo: createOrderInfo(orderInfo),
    provider: createProvider(provider), 
    staff: createStaff(staff),
    support,
    requiresConsentForm
  };
  if (result.orderInfo?.order.isSelfPay && !support.selfPay) throw new SelfPayConflictError();
  return result;
}

export class SelfPayConflictError extends AppError {
  constructor() {
    super(409,"Self pay has been selected for order but self pay is not supported for provider",false);
  }
}

interface User {
  uid: number,
  createdOn: Date,
  alias: string,
  email: string,
  password: string,
  isAdmin: boolean,
}

export interface Order {
  orid: number;
  stfid: number;
  ptid: number;
  prid: number;
  blid: number;
  createdOn: Date;
  shippingAddress1: string;
  shippingCity: string;
  shippingZip: string;
  shippingState: string;
  shippingCountry: string;
  shippingName: string;
  shippingEmail: string;
  shippingPhone: string;
  trackingNo: number;
  total: number;
  taxTotal: number;
  currency: string;
  stripeMethod: string;
  stripeIntent: string;
  isSelfPay: boolean;
  shipId: number;
  invoice: string;
  ortid: number;
}

export interface OrderInfo {
  order: Order;
  orderItem: OrderItem;
  orderStatus: ClinicalOrderStatus;
  sku: Sku;
}

function createOrderInfo({order,orderItem,orderStatus,sku,requiresConsentForm}: any): OrderInfo|undefined {
  if (!order) return undefined
  if (!orderItem || !orderStatus || !sku || requiresConsentForm === undefined) throw new Error("Missing order info");
  return {
    order,
    orderItem,
    orderStatus: ClinicalOrderStatus.fromJSON(orderStatus),
    sku,
  }
}

interface PatientRegDetails {
  patient: Patient | undefined;
  user: User | undefined;
  orderInfo: {
    order: Order;
    orderItem: OrderItem;
    orderStatus: ClinicalOrderStatus;
    sku: Sku;
  } | undefined;
  provider: Provider | undefined;
  staff: Staff | undefined;
  support : Support | undefined;
  requiresConsentForm: boolean | undefined;
}

interface UsePatientRegistrationDetailsReturn {
  details: PatientRegDetails;
  error: AppError|undefined;
}

type FetchPatientRegDetailsJSON = 
  | { patient: any; user: any | undefined; order: any | undefined; orderStatus: any | undefined; provider: any; staff: any; skuId: number; support: Support, requiresConsentForm: undefined, error: undefined }
  | { patient: undefined; user: undefined; order: undefined; orderStatus: undefined; provider: undefined; staff: undefined; skuId: undefined; support: undefined, requiresConsentForm: boolean|undefined, error: string }