import { useTranslation as _useTranslation, UseTranslationOptions, UseTranslationResponse as _UseTranslationResponse } from 'react-i18next';
import en from "../../public/locales/en/translation.json";
import es from "../../public/locales/es/translation.json";
import { LanguageCode } from "../utils/data-classes/Language";

// UTIL

type Join<K,P> = K extends string | number 
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8,9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]
];

export type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ? 
  { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : ""

// Translation type checking

type MaxDepth = 6;

export type ValidI18nKey = Leaves<typeof en,MaxDepth>;

// Typecheck to make sure translations all have the same keys
interface AllKeys extends Record<LanguageCode,ValidI18nKey> {
  en: Leaves<typeof en,MaxDepth>,
  es: Leaves<typeof es,MaxDepth>,
}

export type TranslateFunction = (
  message: ValidI18nKey,
  params?: { [key: string]: string|number },
) => string;

export type UseTranslationResponse = Omit<_UseTranslationResponse<"translation",undefined>,"t"> & {
  t: TranslateFunction
}

export default function useTranslation(ns="translation",options?: UseTranslationOptions): UseTranslationResponse {
  const response = _useTranslation(ns,options);
  const t = response.t as TranslateFunction;

  return { ...response, t }
}