import { useContext, useEffect, useState } from "react";
import useDataAuth from "../../hooks/useDataAuth";
import { AppError, authFetch } from "../../utils/utils";
import DiscountScheme from "./DiscountScheme";
import { DiscountSchemeBackend, SkuIdDiscountSchemeMap, createDiscountScheme } from "./useDiscountSchemes";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import useErrorState from "../../hooks/useErrorState";

export default function useDiscountSchemesForProvider(prid: number | undefined) {
  const {
    state: { loginToken },
  } = useContext(LoginContext);
  const [schemes, setSchemes] = useState<{ [skuId: number]: DiscountScheme }>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (!loginToken || !prid) return;
    const abortController = new AbortController();
    fetchDiscountSchemes(loginToken, prid, abortController.signal)
      .then(setSchemes)
      .catch(setError);
    return () => abortController.abort();
  }, [prid, loginToken]);

  return { schemes, error };
}

const fetchDiscountSchemes = async (
  loginToken: string,
  prid: number,
  signal: AbortSignal
): Promise<SkuIdDiscountSchemeMap | undefined> => {
  const res = await authFetch(loginToken, `/api/discount-scheme/${prid}`, { signal });
  const data = await res.json();
  const error = data.error;
  if (error !== undefined) throw new AppError(res.status, error);
  const schemes: DiscountSchemeBackend[] = data.schemes;
  return Object.fromEntries(schemes.map((scheme) => [scheme.skuId, createDiscountScheme(scheme)]));
};
