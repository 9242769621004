import { useContext, useEffect, useState } from "react";
import { Staff, createStaff } from "../../../../utils/data-classes/Staff";
import { AppError, authFetch } from "../../../../utils/utils";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import useErrorState from "../../../../hooks/useErrorState";

export default function useStaff(prid: number | undefined): UseStaffReturn {
  const [staff, setStaff] = useState<Staff[]>();
  const [error, setError] = useErrorState();
  const {
    state: { loginToken },
  } = useContext(LoginContext);

  useEffect(() => {
    if (!prid || isNaN(prid) || !loginToken) return;
    fetchStaff(loginToken, prid).then(setStaff).catch(setError);
  }, [prid, loginToken]);

  return { staff, error };
}

async function fetchStaff(loginToken: string, prid: number): Promise<Staff[]> {
  const res = await authFetch(loginToken, `/api/staff?prid=${prid}`);
  const json = await res.json();
  const error = json.error;
  if (error !== undefined) throw new AppError(res.status, error);
  const staff: any[] = json.staff;
  return staff.map(createStaff);
}

interface UseStaffReturn {
  staff: Staff[] | undefined;
  error: AppError | undefined;
}
