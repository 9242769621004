import { ProviderShipping, createProviderShipping } from "../utils/data-classes/ProviderShipping";
import { useState, useEffect } from "react";
import { devLog, AppError } from "../utils/utils";

function useProviderShipping(prid: number|undefined|null): UseProviderShippingReturn {
  const [providerShipping, setProviderShipping] = useState<ProviderShipping|null>(null);
  const [error, setError] = useState<AppError|null>(null);

  useEffect(() => {
    if (!prid) return;
    const abortController = new AbortController();
    fetchProviderShipping(prid,abortController.signal)
      .then(setProviderShipping)
      .catch(err => setError(err instanceof AppError ? err : new AppError(400,"Something went wrong")));
    return () => abortController.abort();
  },[prid]);

  return { providerShipping, error };
}

const fetchProviderShipping = async (prid: number, signal: AbortSignal): Promise<ProviderShipping> => {
  devLog(`useProvidersShipping: Fetching provider Shipping Address`);
  const res = await fetch(`/api/get-provider-shipping/${prid}`,{signal});
  const { providerShipping, error } = await res.json();

  if (error !== undefined) throw new AppError(res.status,error);

  devLog(`PROVIDER SHIPPING: `, providerShipping);
  return createProviderShipping(providerShipping);
}

interface UseProviderShippingReturn {
  providerShipping: ProviderShipping | null;
  error: AppError | null;
}

export default useProviderShipping;