import { DateTime } from "luxon";
import { LanguageCode } from "./Language";

export class Patient {
  readonly ptid: number; 
  readonly createdOn: DateTime; 
  readonly uid: number|undefined; 
  readonly firstName: string|undefined; 
  readonly lastName: string|undefined; 
  readonly gender: string|undefined; 
  readonly code: string; 
  readonly email: string; 
  readonly phone: string|undefined; 
  readonly identifier: string; 
  readonly privacyConsent: boolean; 
  readonly defaultLanguage: LanguageCode;
  readonly dateOfBirth: string|undefined;
  constructor(
    ptid: number,
    createdOn: string,
    uid: number|null|undefined,
    firstName: string|null|undefined,
    lastName:string|null|undefined,
    gender: string|null|undefined,
    code: string,
    email: string,
    phone: string|null|undefined,
    identifier: string,
    privacyConsent: boolean|null|undefined,
    defaultLanguage: LanguageCode,
    dateOfBirth: string|null|undefined,
  ) {
    this.ptid = ptid;
    this.createdOn = DateTime.fromISO(createdOn,{zone: "utc"});
    this.uid = uid ?? undefined;
    this.firstName = firstName ?? undefined;
    this.lastName = lastName ?? undefined;
    this.gender = gender ?? undefined;
    this.code = code;
    this.email = email;
    this.phone = phone ?? undefined;
    this.identifier = identifier;
    this.privacyConsent = privacyConsent ?? false;
    this.defaultLanguage = defaultLanguage;
    this.dateOfBirth = dateOfBirth ?? undefined;
  }

  /**
   * Returns a name to display for patient. Real names take precedence over identifiers. 
   */
  get name(): string {
    if (this.firstName && this.lastName) return `${this.firstName} ${this.lastName}`;
    if (this.firstName) return this.firstName;
    if (this.lastName) return this.lastName;
    return this.identifier;
  }

  get digestPath(): string | null {
    return this.uid ? `/digest/challenges?ptid=${this.ptid}` : null;
  }

  isAnonymous(): boolean {
    return !this.firstName && !this.lastName; 
  }

  firstNameInitial(): string {
    return this.firstName?.[0] || "";
  } 
}

export const createPatient = ({ptid,createdOn,uid,firstName,lastName,gender,code,email,phone,identifier,privacyConsent,defaultLanguage,dateOfBirth}: any): Patient => {
  return new Patient(ptid,createdOn,uid,firstName,lastName,gender,code,email,phone,identifier,privacyConsent,defaultLanguage,dateOfBirth);
}