import React, { useContext, useEffect } from "react";
import OrderContext, { LocationDetails, OrderContextType, OrderCountryCode, OrderStage } from "../../contexts/OrderContext/OrderContext";
import { useForm, UseFormReturn } from "react-hook-form";
import s from "./Register.module.scss";
import { CountryRegionData } from "react-country-region-selector";
import { validationRegEx, validator } from "../../utils/FormValidation";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";
import { RegistrationContext } from "../../contexts/RegisterContext/RegisterContext";
import 'react-phone-number-input/style.css';
import useTranslation from "../../hooks/useTranslation";
import IFTAInput from "./IFTAElements/IFTAInput/IFTAInput";
import IFTAPhoneInput from "./IFTAElements/IFTAPhoneInput/IFTAPhoneInput";
import IFTACountryDropdown from "./IFTAElements/IFTASelect/IFTACountrySelect";
import IFTARegionSelect from "./IFTAElements/IFTASelect/IFTARegionSelect";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { Country } from "../../hooks/useCountries";

const countryCodeToCountryMap: Record<string,string> = Object.fromEntries(CountryRegionData.map(([country,code]) => [code,country])); 
export const COUNTRY_CODES = Object.keys(countryCodeToCountryMap)

export const getCountry = (countryCode: string) => countryCodeToCountryMap[countryCode];

export const DeliveryForm = () => {
  const orderCtx = useContext(OrderContext);
  const { state: { delivery, sameBillingAsDelivery }, dispatch } = orderCtx;
  const { state: { orderInfo: order, supportedCountries } } = useContext(RegistrationContext);  

  const { t, i18n } = useTranslation();

  const form = useForm<LocationDetailsFormFieldValues>({
    defaultValues: delivery.firstName ? delivery : emptyLocationDetails,
  });
  const { handleSubmit } = form;

  const toggleSameAsDelivery = () => dispatch({ type: "SET_SAME_BILLING_AS_DELIVERY", payload: !sameBillingAsDelivery });

  const onSubmit = onAddressFormSubmit(orderCtx, "UPDATE_SHIPPING_ADDRESS", sameBillingAsDelivery ? OrderStage.PAYMENT : OrderStage.BILLING);

  // Re-trigger form validation on language change to translate error messages
  useEffect(() => {
    if (!form.formState.isSubmitted) return;
    form.trigger();
  },[i18n.language]);

  if (!supportedCountries) return <LoadingSpinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{t("shipping.title")}</h2>   

      <FirstNameInput form={form} />
      <LastNameInput form={form} />
      <EmailInput form={form} />
      <PhoneInput form={form} />
      <AddressInput form={form} />
      <CityInput form={form} />
      <ZipCodeInput form={form} />
      <CountryDropdown form={form} whitelist={supportedCountries} />
      <StateInputDropdown form={form} />

      <p>{t("shipping.poBoxDisclaimer")}</p>
      { order?.order.isSelfPay && (
        <label className={s.checkboxContainer}>
          <input id="sameBillingAsDelivery" type="checkbox" checked={sameBillingAsDelivery} onChange={toggleSameAsDelivery} />
          <label htmlFor="sameBillingAsDelivery">{t("billing.sameAsShipping")}</label>
        </label>
      )}
      <button className={s.btnPrimary} type="submit">{t(order?.order.isSelfPay ? "buttons.next" : "buttons.submit")}</button>
    </form>
  );
};

/**
 * Billing address form with checkbox:
 *  - When selected, form values default to delivery values from OrderContext
 */
export const BillingForm = () => {
  const orderCtx = useContext(OrderContext);
  const { state: { billing, sameBillingAsDelivery: sameAsDelivery } } = orderCtx;
  const { state: { orderInfo: order, supportedCountries } } = useContext(RegistrationContext);

  const { t } = useTranslation();

  const deliveryLocation = orderCtx.state.delivery;

  const defaultValues = getDefaultBillingValues(sameAsDelivery, deliveryLocation, billing);

  const form = useForm<LocationDetailsFormFieldValues>({
    defaultValues,
  });
  const { handleSubmit, reset } = form;

  const onSubmit = onAddressFormSubmit(orderCtx, "UPDATE_BILLING_ADDRESS", OrderStage.PAYMENT);

  // Reset form values on checkbox click
  useEffect(() => {
    if (sameAsDelivery) {
      reset(deliveryLocation);
      return;
    }
    reset(defaultValues);
  }, [sameAsDelivery]);

  if (!order) throw new Error("Order not found");

  if (!supportedCountries) return <LoadingSpinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Billing Address</h2>
      <FirstNameInput form={form} />
      <LastNameInput form={form} />
      <AddressInput form={form} />
      <CityInput form={form} />
      <ZipCodeInput form={form} />
      <CountryDropdown form={form} whitelist={supportedCountries} />
      <StateInputDropdown form={form} />
      <button type="submit">{t("buttons.next")}</button>
    </form>
  );
};

/**
 * Billing form defaults to deliveryLocation if checkbox clicked,
 * else will default to value already dispatched to OrderContext if it exists, otherwise empty form
 *  - email is set to email from deliveryLocation
 */
const getDefaultBillingValues = (sameAsDelivery: boolean, deliveryLocation: LocationDetails, billing: LocationDetails): LocationDetails => {
  if (sameAsDelivery)
    return deliveryLocation;
  if (billing.firstName)
    return billing;
  return { ...emptyLocationDetails, email: deliveryLocation.email, phone: deliveryLocation.phone };
};

/**
 * Dispatch to OrderContext: 
 *  - To update a delivery or billing field with a new location 
 *  - To set a new value stage of the order process
 */
const onAddressFormSubmit = ({state,dispatch}: OrderContextType, type: "UPDATE_SHIPPING_ADDRESS"|"UPDATE_BILLING_ADDRESS", nextStage: OrderStage) => async (formValues: LocationDetails) => {
  if (!formValues.countryCode) throw Error("Country code not defined");
  const location = { ...formValues, country: getCountry(formValues.countryCode) }
  dispatch({type, payload: location});
  if (state.sameBillingAsDelivery && type === "UPDATE_SHIPPING_ADDRESS") {
    dispatch({type: "UPDATE_BILLING_ADDRESS", payload: location});
  }
  dispatch({type: "SET_ORDER_STAGE", payload: nextStage});
}

export interface LocationDetailsFormFieldValues {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  address: string,
  city: string,
  country: string,
  countryCode: OrderCountryCode,
  zipCode: string,
  stateCode: string,
}

const emptyLocationDetails: LocationDetails = {
  firstName: "",
  lastName: "",
  address: "",
  email: "",
  city: "",
  phone: "",
  country: "",
  countryCode: undefined,
  stateCode: "",
  zipCode: "",
}

// INPUTS

const FirstNameInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAInput id="firstName" label={t("forms.label.firstName")} reg={form.register("firstName", {required: t("forms.errors.required.firstName")})}/>
      <FormErrorMessage errors={form.formState.errors} name="firstName" />
    </>
  );
}

const LastNameInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAInput id="lastName" label={t("forms.label.lastName")} reg={form.register("lastName", {required: t("forms.errors.required.lastName")})}/>
      <FormErrorMessage errors={form.formState.errors} name="lastName" />
    </>
  )
}

const EmailInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAInput id="email" label={t("forms.label.email")} reg={form.register("email", {
        required: t("forms.errors.required.email"),
        pattern: {
          value: validationRegEx.email,
          message: t("forms.errors.invalid.email"),
        },
      })}/>
      <FormErrorMessage errors={form.formState.errors} name="email" />
    </>
  );
}

const PhoneInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAPhoneInput id="phone" label={t("forms.label.phone")} className={s.phoneInput} form={form} name="phone" rules={{
        required: t("forms.errors.required.phone"),
        validate: {
          invalid: validator.phoneNumber(t("forms.errors.invalid.phone")),
        }
      }} />
      <FormErrorMessage errors={form.formState.errors} name="phone" />
    </>
  );
}

const AddressInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAInput id="address" label={t("forms.label.address")} reg={form.register("address", {
        required: t("forms.errors.required.address"),
      })}/>
      <FormErrorMessage errors={form.formState.errors} name="address" />
    </>
  );
}

const CityInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAInput id="city" label={t("forms.label.city")} reg={form.register("city", {
        required: t("forms.errors.required.city"),
      })}/>
      <FormErrorMessage errors={form.formState.errors} name="city" />
    </>
  );
}

const ZipCodeInput = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTAInput id="zipCode" label={t("forms.label.zipCode")} reg={form.register("zipCode", {
        required: t("forms.errors.required.zipCode"),
        pattern: form.watch("countryCode") === "US" ? {
          value: validationRegEx.zipCodeUS,
          message: t("forms.errors.invalid.zipCode"),
        } : undefined,
      })}/>
      <FormErrorMessage errors={form.formState.errors} name="zipCode" />
    </>
  );
}

const CountryDropdown = ({form,whitelist}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>,whitelist: Country[]}) => {
  const { t } = useTranslation();
  const { state: { orderInfo: order } } = useContext(RegistrationContext);
  if (!order) throw new Error("Order not found");
  return (
    <>
      <IFTACountryDropdown 
        id="countryCode"
        label={t("forms.label.country")}
        name="countryCode"
        form={form}
        classes={s.roundInput}
        rules={{required: t("forms.errors.required.country")}}
        whitelist={whitelist.map(c => c.country)}
        valueType="short"
        defaultOptionLabel={t("forms.selectLabel.country").toString()}
      />
      <FormErrorMessage errors={form.formState.errors} name="countryCode" />
    </>
  );
}

const StateInputDropdown = ({form}: {form: UseFormReturn<LocationDetailsFormFieldValues,any>}) => {
  const { t } = useTranslation();
  return (
    <>
      <IFTARegionSelect
        id="stateCode"
        label={t("forms.label.stateCode")}
        form={form}
        name="stateCode"      
        classes={s.roundInput}
        rules={{required: t("forms.errors.required.stateCode")}}
        country={form.watch("countryCode")} 
        countryValueType="short"
        valueType="short"
        defaultOptionLabel={t("forms.selectLabel.stateCode").toString()}
      />
      <FormErrorMessage errors={form.formState.errors} name="stateCode" />
    </>
  );
}