/*
 * General functions used in making Chart.js charts
 */

import Chart from "chart.js";
import { DateTime } from "luxon";
import Gas from "../../../utils/data-classes/Gas";
import styles from "../_styles";

export const COLORS = {
  H2: styles.h2Blue,
  CH4: styles.ch4Blue,
}

/**
 * @param {Chart.Chart} Chart 
 */
export const setDefaultConfig = (Chart) => {
  /* 
    Sets the default configs for chart.js charts.
    Should receive as param the Chart object (imported from `chart.js` package)
  */

  // Font
  Chart.defaults.global.defaultFontFamily = "Montserrat";

  // Title
  Chart.defaults.global.title.display = false;

  // Legends
  Chart.defaults.global.legend.display = true;
  Chart.defaults.global.legend.labels.boxWidth = 7; // show them small
  Chart.defaults.global.legend.labels.usePointStyle = true; // show them round
  Chart.defaults.global.legend.labels.filter = (item) => item.text; // hide "undefined"s

  // Lines
  Chart.defaults.global.elements.line.tension = 0;

  // Layout
  Chart.defaults.global.responsive = true;
  Chart.defaults.global.maintainAspectRatio = false;

  // Anchoring tooltips
  // see: https://stackoverflow.com/questions/54817379/chartjs-tooltip-anchor-point-position-on-bar-charts
  Chart.Tooltip.positioners.custom = (elements, eventPosition) => ({
    x: eventPosition.x,
    y: eventPosition.y,
  });

  // Increase spacing between chart and legend
  Chart.Legend.prototype.afterFit = function() {
    this.height = this.height + 10;
  };
}

/**
 * @param {String} hex 
 * @returns 
 */
export const hexToRgb = (hex) => {
  const X = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!X) return null;

  const [r,g,b] = [X[1], X[2], X[3]].map(x => parseInt(x,16));
  const toString = () => `rgba(${r},${g},${b})`;

  return {r, g, b, toString};
}

/**
 * @param {Gas} gas
 * @returns {String}
 */
export const gasColorHex = (gas) => COLORS[gas.gas];

/**
 * @param {[DateTime,DateTime]} range
 */
export const isDayToDayAggregateView = ([startDT,endDT]) => {
  const days = endDT.diff(startDT,"days").as("days");
  return days >= 7;
}

export const destroyAllCharts = () => {
  Object.values(Chart.instances).forEach(instance => {
    instance.destroy();
  })
}

/**
 * @param {string} title 
 * @returns 
 */
export const getChartByTitle = (title) => {
  for (const instance of Object.values(Chart.instances)) {
    if (instance.options.title.text === title) return instance;
  }
}