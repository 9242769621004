import { DateTime } from "luxon";
import { DateFormatOptions } from "../../../hooks/useDateFormat";
import { DigestSummaries, DigestSummary, createDigestSummaries } from "./rpm-excel-digest-summary";
import { DigestSummaryAggregateData } from "./rpm-excel-digest-summary";
import { FODMAP_ORDER, Row, getH2Eq, isTreatmentWindowHeader } from "./rpm-excel-util";
import { RPMWindowDigest, SplitDigest, roundTimeToNearestMinute } from "./rpm-digest-split";
import { RPMConfig } from "./rpm-excel-util";
import { RPMDigest } from "./useRPMDigest";
import Gas from "../../../utils/data-classes/Gas";
import Symptom, { SymptomLabel } from "../../../utils/data-classes/Symptom";
import { CellObject, CellStyle } from "xlsx-js-style";
import _round from "lodash/round";
import _escapeRegExp from "lodash/escapeRegExp";
import { isCellValueEqualTo } from "./rpm-excel-util";
import Meal from "../../../utils/data-classes/Meal";

// CONSTS

const PRECISION = {
  ppms: 2,
  fodmaps: 2,
  symptoms: 2,
  stools: 2,
};

const COLOR = {
  lightGrey: "a3a3a3",
  mediumGrey: "efefef",
  darkGray: "d0d0d0",
  white: "ffffff",
  black: "000000",
};

const NO_DATA = "No Data";
const TREATMENT_HEADER_LABEL = "Treatment";

export function createAllRows(tradeName: string, splitDigest: SplitDigest, config: RPMConfig): any[][] {
  const summaries = createDigestSummaries(splitDigest);
  const symptomOrder = getSymptomOrder(summaries);
  const mainHeader = createTreatmentHeader(tradeName);
  const summaryTableRows = createAllSummaryTableRows(summaries, config, symptomOrder);
  const rawTableRows = createRawTableRows(splitDigest, config, symptomOrder);
  const paddingRow = createPaddedEmptyRow(summaryTableRows[0].length);
  return [mainHeader, paddingRow, ...summaryTableRows, ...rawTableRows];
}

function createTreatmentHeader(tradeName: string): CellObject[] {
  const vals = [TREATMENT_HEADER_LABEL, tradeName];
  return createRow(vals, (_, i) => ({
    font: {
      bold: i === 0,
    },
  }));
}

function createRow<T = number | string | undefined>(
  values: T[],
  style: (v: T, i: number, arr: T[]) => CellStyle = () => ({})
): CellObject[] {
  return values.map((value, i, arr) => createCell(value, style(value, i, arr)));
}

function createCell<T = number | string | undefined>(value: T, s: CellStyle) {
  const v = (value ?? "") as string | number;
  return {
    v,
    t: cellType(v),
    s,
  };
}

function cellType(v: number | string): "n" | "s" {
  if (typeof v === "number") return "n";
  if (typeof v === "string") return "s";
  return "s";
}

/**
 * Get symptom labels in order of highest average value
 * @param summaries
 * @returns
 */
function getSymptomOrder(summaries: DigestSummaries) {
  const map = new Map<string, number>();
  const summariesArr = [summaries.preTreatment, summaries.treatment, summaries.postTreatment];
  summariesArr.forEach((summary) => {
    Array.from(summary.average.symptoms.entries()).map(([label, score]) => {
      const prev = map.get(label);
      if (prev === undefined || score > prev) map.set(label, score);
    });
  });
  return Array.from(map.entries())
    .sort(([_, a], [__, b]) => (a > b ? -1 : 1))
    .map(([label]) => label);
}

// SUMMARY TABLE

function createAllSummaryTableRows(summaries: DigestSummaries, config: RPMConfig, symptomOrder: string[]): any[][] {
  const result: any[][] = [];
  const tables = [
    createSummaryTableRows(summaries.preTreatment, config, symptomOrder),
    createSummaryTableRows(summaries.treatment, config, symptomOrder),
    createSummaryTableRows(summaries.postTreatment, config, symptomOrder),
  ];
  const padding = createPaddedEmptyRow(tables[0][0].length);
  tables.forEach((table) => {
    result.push(...table);
    result.push(padding);
  });
  return [
    ...createSummaryTableRows(summaries.preTreatment, config, symptomOrder),
    ...createSummaryTableRows(summaries.treatment, config, symptomOrder),
    ...createSummaryTableRows(summaries.postTreatment, config, symptomOrder),
  ];
}

function createDateRangeHeader(
  [startISO, endISO]: [string, string],
  dateFmt: DateFormatOptions,
  length: number
): CellObject[] {
  const result = new Array(length);
  result[0] = getDateRangeHeaderContent([startISO, endISO], dateFmt);
  result.fill("", 1);
  return createRow(result, (_, i, arr) => ({
    alignment: {
      horizontal: "center",
    },
    border: border(i, arr, {}),
  }));
}
function getDateRangeHeaderContent([startISO, endISO]: [string, string], dateFmt: DateFormatOptions) {
  const fmt = dateFmt.date;
  const start = DateTime.fromISO(startISO).toFormat(fmt);
  const finish = DateTime.fromISO(endISO).toFormat(fmt);
  const content = `Start: ${start}. Finish: ${finish}`;
  return content;
}

function createAverageBSSRow(summary: DigestSummary, dataHeader: CellObject[]) {
  return createStoolRow(["Average BSS", round(summary.averageStool, PRECISION.stools)], dataHeader);
}

function createDailyStoolFreqRow(summary: DigestSummary, dataHeader: CellObject[]) {
  return createStoolRow(["Daily stool freq.", round(summary.dailyStoolFrequency, PRECISION.stools)], dataHeader, true);
}

function createStoolRow(data: any[], dataHeader: CellObject[], isBottom = false): CellObject[] {
  const isTop = !isBottom;
  const items = rightPadRow(data, dataHeader.length);

  const isBlank = (i: number) => i > 1;
  const isEnd = (i: number) => i === items.length - 1;
  const isFirstBlank = (i: number) => i === 2;

  return createRow(items, (_, i) => {
    return {
      border: {
        left: {
          style: "thin",
          color: { rgb: !isBlank(i) || isFirstBlank(i) ? COLOR.black : COLOR.lightGrey },
        },
        right: {
          style: isEnd(i) ? "medium" : "thin",
          color: { rgb: !isBlank(i) || isEnd(i) ? COLOR.black : COLOR.lightGrey },
        },
        top: {
          style: "thin",
          color: { rgb: isTop || !isBlank(i) ? COLOR.black : COLOR.lightGrey },
        },
        bottom: {
          style: isBottom ? "medium" : "thin",
          color: { rgb: isBottom || !isBlank(i) ? COLOR.black : COLOR.lightGrey },
        },
      },
      fill: {
        patternType: "solid",
        fgColor: { rgb: isBlank(i) ? COLOR.white : COLOR.mediumGrey },
      },
    };
  });
}

/**
 * @todo WORRY ABOUT SYMPTOM SORTING
 */
function createDailyAverageRow(title: string, data: DigestSummaryAggregateData, symptomOrder: string[]) {
  const vals: Val[] = [
    { val: title, precision: undefined },
    { val: data.h2, precision: PRECISION.ppms },
    { val: data.ch4, precision: PRECISION.ppms },
    { val: data.h2Eq, precision: PRECISION.ppms },
    ...symptomOrder.map((label) => ({ val: data.symptoms.get(label), precision: PRECISION.symptoms })),
    { val: data.aggSymptoms, precision: PRECISION.symptoms },
    ...FODMAP_ORDER.map((label) => ({ val: data.fodmaps.get(label), precision: PRECISION.fodmaps })),
    { val: data.totalFodmaps, precision: PRECISION.fodmaps },
  ];
  return createRow(vals.map(roundVal), (_, i, arr) => ({
    numFmt: numFmt(vals[i].precision),
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: COLOR.mediumGrey,
      },
    },
    border: border(i, arr, {}),
  }));

  function roundVal({ val, precision }: Val) {
    if (val === undefined || typeof val === "string" || precision === undefined) return val;
    return round(val, precision);
  }

  type Val = {
    val: number | string | undefined;
    precision: number | undefined;
  };
}

function numFmt(precision: number | undefined): string | undefined {
  if (precision === undefined) return undefined;
  if (precision === 0) return "0";
  return `0.${new Array(precision).fill("0").join("")}`;
}

function round(n: number | undefined, precision: number): number | undefined {
  if (!n) return undefined;
  return _round(n, precision);
}

function createSummaryTableRows(summary: DigestSummary, config: RPMConfig, symptomOrder: string[]) {
  const dataHeader = createSummaryDataHeader(symptomOrder);
  const mainHeader: CellObject[] = createSummaryTableMainHeader(summary, dataHeader);
  const dateRangeHeader = createDateRangeHeader(summary.dateRange, config.dateFormat, dataHeader.length);
  const average = createDailyAverageRow("Average", summary.average, symptomOrder);
  const averageDailyMax = createDailyAverageRow("Avg. Daily Max", summary.averageDailyMax, symptomOrder);
  const averageBSS = createAverageBSSRow(summary, dataHeader);
  const dailyStoolFreq = createDailyStoolFreqRow(summary, dataHeader);
  const bottomPadding = createPaddedEmptyRow(dataHeader.length);
  return [mainHeader, dateRangeHeader, dataHeader, average, averageDailyMax, averageBSS, dailyStoolFreq, bottomPadding];
}

function createPaddedEmptyRow(length: number) {
  return Array(length).fill("");
}

function createSummaryTableMainHeader(summary: DigestSummary, dataHeader: CellObject[]): CellObject[] {
  return createRow(rightPadRow([summary.title], dataHeader.length), (_, i, arr) => ({
    alignment: {
      horizontal: "center",
    },
    border: border(i, arr, { isTop: true }),
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: COLOR.darkGray,
      },
    },
  }));
}

function createSummaryDataHeader(symptomOrder: string[]): CellObject[] {
  const vals = [
    "",
    "H2 (ppm)",
    "CH4 (ppm)",
    "H2 Eq. (ppm)",
    ...symptomOrder,
    "Agg. symptoms",
    ...FODMAP_ORDER.map(fodmapHeader),
    "total FODMAPs (g)",
  ];
  return createRow(vals, (_, i, arr) => ({
    border: border(i, arr, {}),
  }));
}

// RAW DATA TABLE ROWS

function createRawTableDataHeader(symptomOrder: string[]) {
  return [
    "time",
    "H2 (ppm)",
    "CH4 (ppm)",
    "H2 Eq. (ppm)",
    ...symptomOrder,
    "Stool",
    "Meal label",
    ...FODMAP_ORDER.map(fodmapHeader),
    "total FODMAPs (g)",
  ];
}

function border(i: number, arr: any[], { isTop = false, isBottom = false }): CellStyle["border"] {
  const color = { rgb: COLOR.black };
  return {
    left: {
      style: "thin",
      color,
    },
    right: {
      style: i === arr.length - 1 ? "medium" : "thin",
      color,
    },
    top: {
      style: isTop ? "medium" : "thin",
      color,
    },
    bottom: {
      style: isBottom ? "medium" : "thin",
      color,
    },
  };
}

function fodmapHeader(fodmapLabel: string): string {
  return `${fodmapLabel} (g)`;
}

function createRawTableRowsForTreatementPeriod(digest: RPMWindowDigest, config: RPMConfig, symptomOrder: string[]) {
  const dataHeader = createRawTableDataHeader(symptomOrder);
  const mainHeader = createRawTableMainHeader(digest, dataHeader);
  const result: any[][] = [mainHeader, dataHeader];

  if (!hasData(digest)) result.push(createNoDataRow(dataHeader));
  else createRawRowData(digest).forEach((rowData) => result.push(createRawTableRow(rowData, config, symptomOrder)));
  result.push(createPaddedEmptyRow(dataHeader.length));

  return result;
}

function hasData(digest: RPMWindowDigest) {
  const { ppms, symptoms, meals } = digest;
  const arrays = [ppms, symptoms, meals];
  return !!arrays.find((arr) => arr.length !== 0);
}

function createRawTableMainHeader(digest: RPMWindowDigest, dataHeader: string[]): CellObject[] {
  return createRow(rightPadRow([digest.title], dataHeader.length), () => ({
    alignment: {
      horizontal: "center",
    },
  }));
}

function rightPadRow(row: any[], targetLength: number) {
  while (row.length < targetLength) row.push("");
  return row;
}

function createRawTableRow(data: RawTableRowData, config: RPMConfig, symptomOrder: string[]) {
  return [
    DateTime.fromISO(data.timeISO).toFormat(config.dateFormat.datetime),
    data.h2,
    data.ch4,
    data.h2Eq,
    ...symptomOrder.map((symptomLabel) => data.symptoms.get(symptomLabel)),
    data.stool,
    data.mealLabel,
    ...FODMAP_ORDER.map((fodmapLabel) => data.fodmaps.get(fodmapLabel)),
    data.totalFodmaps,
  ];
}

function createRawTableRows(digest: SplitDigest, config: RPMConfig, symptomOrder: string[]) {
  const _result = [digest.preTreatment, digest.treatment, digest.postTreatment]
    .map((digest) => createRawTableRowsForTreatementPeriod(digest, config, symptomOrder))
    .flat();
  return _result;
}

function createRawRowData(digest: RPMWindowDigest): RawTableRowData[] {
  return Array.from(createTimeToDigestValuesMap(digest).values())
    .flat()
    .sort((a, b) => (a.timeISO < b.timeISO ? -1 : 1));
}

function createTimeToDigestValuesMap(digest: RPMDigest): Map<string, RawTableRowData[]> {
  const result = new Map<string, RawTableRowData[]>();

  digest.ppms.forEach((ppm) => {
    const row = addNewRowIfNeeded(ppm.createdOn);
    if (ppm.gasId === Gas.H2.gasId) row.h2 = ppm.ppm;
    if (ppm.gasId === Gas.CH4.gasId) row.ch4 = ppm.ppm;
  });
  Array.from(result.keys()).forEach((key) => {
    const rows = result.get(key);
    if (!rows) return;
    const row = rows[rows.length - 1];
    row.h2Eq = getH2Eq(row.h2, row.ch4);
  });
  digest.symptoms.forEach((symptom) => {
    const row = addNewRowIfNeeded(symptom.pertainsTo);
    if (isStool(symptom)) row.symptoms.set(symptom.label, symptom.score);
    else row.stool = symptom.score;
  });
  digest.meals.forEach((meal) => {
    const row = addNewRowIfNeededForMeal(meal);
    row.mealLabel = `${meal.label}, ${meal.sublabel}`;
    FODMAP_ORDER.forEach((label) => row.fodmaps.set(label, 0));
    row.totalFodmaps = 0;
    Object.values(meal.foods).forEach((food) => {
      Object.values(food.fodmaps).forEach((fodmap) => {
        const name = fodmap.name.toLowerCase();
        row.fodmaps.set(name, (row.fodmaps.get(name) ?? 0) + fodmap.value);
        row.totalFodmaps = (row.totalFodmaps ?? 0) + fodmap.value;
      });
    });
  });
  return result;

  function addNewRowIfNeeded(iso: string): RawTableRowData {
    const time = roundTimeToNearestMinute(iso);
    if (!result.has(time)) result.set(time, [initializeRowData(time)]);
    const rows = result.get(time);
    if (!rows) throw new Error("Rows not found");
    return rows[rows.length - 1];
  }

  function addNewRowIfNeededForMeal(meal: Meal) {
    const time = roundTimeToNearestMinute(meal.consumedOn);
    if (!result.has(time)) result.set(time, []);
    const rows = result.get(time);
    if (!rows) throw new Error("Rows not found");
    const hasMeal = !!rows[rows.length - 1]?.mealLabel;
    if (rows.length === 0 || hasMeal) rows.push(initializeRowData(time));
    const row = rows[rows.length - 1];
    return row;
  }
}

function isStool(symptom: Symptom) {
  return symptom.label !== SymptomLabel.STOOL_FORM;
}

function createNoDataRow(dataHeader: CellObject[] | string[]): CellObject[] {
  return createRow(rightPadRow([NO_DATA], dataHeader.length), () => ({
    alignment: {
      horizontal: "center",
    },
  }));
}

function initializeRowData(timeISO: string): RawTableRowData {
  return {
    timeISO: timeISO,
    h2: undefined,
    ch4: undefined,
    h2Eq: undefined,
    symptoms: new Map(),
    stool: undefined,
    mealLabel: undefined,
    fodmaps: new Map(),
    totalFodmaps: undefined,
  };
}

// UTIL

export function shouldMerge(row: Row, index: number, rows: Row[]) {
  const isTreatmentHeader = index === 0;
  const isInSummaryTables = 2 <= index && index <= 25;
  const isDateHeader = isInSummaryTables && isTreatmentWindowHeader(rows[index - 1]);
  const isNoDataRow = isCellValueEqualTo(row[0], NO_DATA);
  const isPaddedEmptyRow = !row.find((cell) => !isCellValueEqualTo(cell, ""));

  return (isTreatmentWindowHeader(row) && !isTreatmentHeader) || isDateHeader || isNoDataRow || isPaddedEmptyRow;
}

// TYPES

interface RawTableRowData {
  timeISO: string;
  h2: number | undefined;
  ch4: number | undefined;
  h2Eq: number | undefined;
  symptoms: Map<string, number>;
  stool: number | undefined;
  mealLabel: string | undefined;
  fodmaps: Map<string, number>;
  totalFodmaps: number | undefined;
}
