import { useContext, useEffect } from "react";
import {
  DateRange,
  DateRangeContext,
  DateRangeContextProvider,
  PERIOD,
} from "../../../contexts/DateRangeContext/DateRangeContext";
import { useDayToDayDigest } from "./useDayToDayDigest";
import s from "../../digest/day-to-day/DayToDay.module.scss";
import DatePicker from "../../../components/datepicker/DatePicker";
import { DateTime } from "luxon";
import MultiChart from "../../digest/day-to-day/charts/MultiChart";
import SymptomMultiChart from "../../digest/day-to-day/charts/SymptomMultiChart";
import MedsChart from "../../digest/day-to-day/charts/MedChart";
import StoolMultiChart from "../../digest/day-to-day/charts/StoolMultiChart";
import SleepChart from "../../digest/day-to-day/charts/SleepChart";
import StressChart from "../../digest/day-to-day/charts/StressChart";
import {
  DateRangeContextAction,
  setEarliestTime,
  updateByRange,
} from "../../../contexts/DateRangeContext/DateRangeContextActions";
import MiniLayout from "../../../layouts/mini/MiniLayout";
import ErrorPage from "../../../components/errors/ErrorPage/ErrorPage";

export default function MiniDayToDay() {
  return (
    <DateRangeContextProvider>
      <MiniDayToDayBody />
    </DateRangeContextProvider>
  );
}

function MiniDayToDayBody(): JSX.Element | null {
  const { state: rangeState, dispatch: rangeDispatch } = useContext(DateRangeContext);
  const { range } = rangeState;
  const { digest, error } = useDayToDayDigest("RPM_TOKEN", range);
  const { meals, ppms, symptoms, stools, sleeps, stress, meds } = digest;

  const earliestTime = DateTime.fromISO("2018-01-01");

  useEffect(() => {
    initDateRange(rangeDispatch, earliestTime);
  }, []);

  if (error) return <ErrorPage code={error.code} message={error.message} layout={MiniLayout}/>;

  if (!isDateRangeInitialised(range)) return null;

  return (
    <MiniLayout>
      <div className={s.miniDayToDay}>
        <div className={s.dayToDayNav}>
          <div className={s.datePicker}>
            <DatePicker defaultPeriod={PERIOD.WEEK} disabledPeriods={PERIOD.ALL} defaultEarliestTime={earliestTime} />
          </div>
        </div>
        <div className={s.charts}>
          <MultiChart data={{ meals, ppms, symptoms }} range={range} />
          <SymptomMultiChart symptoms={symptoms} range={range} />
          <MedsChart meds={meds ?? []} />
          <StoolMultiChart stools={stools} range={range} />
          <SleepChart sleeps={sleeps} range={range} />
          <StressChart stress={stress} range={range} />
        </div>
      </div>
    </MiniLayout>
  );
}

function isDateRangeInitialised([startDT, endDT]: DateRange): boolean {
  return startDT !== undefined && endDT !== undefined;
}

function startingDateRange(): [DateTime, DateTime] {
  const endDT = DateTime.fromISO(DateTime.now().plus({ days: 1 }).toISODate());
  const startDT = endDT.minus({ weeks: 1 });
  return [startDT, endDT];
}

function initDateRange(dispatch: React.Dispatch<DateRangeContextAction>, earliestTime: DateTime): void {
  dispatch(setEarliestTime(earliestTime));
  dispatch(updateByRange(startingDateRange()));
}
