import { createProvider, Provider } from "../utils/data-classes/Provider";
import { createStaff, Staff } from "../utils/data-classes/Staff";
import { AppError } from "../utils/utils";
import { useState, useEffect } from "react";
import { DashAPIRoute } from "../utils/apis";
import { LoginMethod } from "../contexts/LoginContext/LoginContext";
import useErrorState from "./useErrorState";

export interface Role {
  roleId: number;
  label: string;
  description: string;
}

export interface AffilInfo {
  caId: number;
  commission: number;
  discount: number;
}

export interface StaffSignupInfo {
  provider: Provider;
  clinicians: Staff[];
  role: Role;
  affiliateInfo: AffilInfo | undefined;
  authMethod: LoginMethod,
}

interface UseStaffSignupInfoReturn {
  staffSignupInfo: StaffSignupInfo | undefined;
  error: AppError | undefined;
}

const useStaffSignupInfo = (token: string): UseStaffSignupInfoReturn => {
  const [staffSignupInfo, setStaffSignupInfo] = useState<StaffSignupInfo>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (staffSignupInfo || error) return;

    const abortController = new AbortController();
    fetchStaffSignupInfo(token,abortController.signal)
      .then(setStaffSignupInfo)
      .catch(setError);

    // Cancel fetch request if the component is unmounted before fetch completes
    return () => abortController.abort();
  },[token]);

  return { staffSignupInfo, error };
}

async function fetchStaffSignupInfo(token: string, signal: AbortSignal): Promise<StaffSignupInfo> {
  const res = await fetch(DashAPIRoute.GET_STAFF_SIGNUP_INFO.path,{
    method: "POST",
    body: JSON.stringify({
      token,
    }),
    signal,
  });
  const { provider, role, clinicians, affiliateInfo, authMethod, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return {
    provider: createProvider(provider),
    role,
    clinicians: createClinicians(clinicians),
    affiliateInfo,
    authMethod,
  };
}

/**
 * Convert clinicians data from backend into instances of Staff data class
 */
const createClinicians = (rawClinicians: any[]) => rawClinicians
  .map(createStaff)
  .sort((a,b) => a.displayName < b.displayName ? 1 : -1);

export default useStaffSignupInfo;