import React from "react";
import s from "./FilledRadioButton.module.scss";

type FilledRadioButtonProps = Omit<React.HTMLProps<HTMLInputElement>, "type" | "id" | "label"> & {
  id: string;
  label: string;
};

export default function FilledRadioButton({ id, className, label, ...rest }: FilledRadioButtonProps): JSX.Element {
  return (
    <label htmlFor={id} className={className ?? s.filledRadioButton}>
      <input type="radio" id={id} {...rest} />
      <label htmlFor={id}>{label}</label>
      <div />
    </label>
  );
};
