import { Link } from "@react-pdf/renderer";
import { Challenge } from "../../../../../utils/data-classes/challenge/Challenge";
import { Substrate } from "../../../../../hooks/useChallengeFollowonContent";
import { ResultType } from "../../../../../hooks/useCdContent";
import { ChallengeReportProps } from "../../ChallengeReport";

export type ChallengeFollowonProps = Pick<ChallengeReportProps, "challenge" | "followonContent">;

export function ChallengeFollowonContent({ challenge, followonContent }: ChallengeFollowonProps) {
  const substrate = challenge.label.toLowerCase() as Substrate;
  const resultType = getResultType(challenge);
  const text = followonContent[substrate]?.[resultType] ?? "";
  return <>{wrapURLsInStringWithLinkTags(text)}</>;
}

function getResultType(challenge: Challenge): ResultType {
  if (challenge.isPositive) return "positive";
  if (challenge.isBorderline) return "borderline";
  return "negative";
}

function ContentPart({ content }: { content: string }) {
  if (!isURL(content)) return <>{content}</>;
  return <Link src={content}>{content}</Link>;
}

function wrapURLsInStringWithLinkTags(content: string) {
  const parts = splitOnURL(content).map((s, i) => <ContentPart key={i} content={s} />);
  return <>{parts}</>;
}

const REGEX = {
  isUrl:
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
  // slightly different regex used to include the captured url in the string.split result
  splitOnURL:
    /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$]))/gim,
};

function isURL(str: string) {
  const matches = str.match(REGEX.isUrl);
  if (!matches) return false;
  if (matches.length !== 1) return false;
  return matches[0] === str;
}

function splitOnURL(str: string) {
  return str.split(REGEX.splitOnURL);
}

export const challengeFollowonUtil = {
  REGEX,
  splitOnURL,
  isURL,
  wrapURLsInStringWithLinkTags,
};
