/*
	Component for rendering a horizontal input menu selector such as that
	in the add-patient dialog

	props:
		options [Array [Object]]: Array of menu item objects in display order
			option [Object] {
				name [str]: name of a given menu used to check for item selection
				content [jsx]: content to be inserted into div of a given menu item
			}
		selectedOption [str]: name of currently selected menu item
		setSelectedOption [str]: function for changing calling component
			state to indicate currently selected option
*/
import React from "react";
import s from "./HorizontalInputSelector.module.scss";

interface HorizontalInputSelectorOption<T> {
	name: T;
	content: string;
}

interface HorizontalInputSelectorProps<T> {
	options: HorizontalInputSelectorOption<T>[];
	selectedOption: T,
	setSelectedOption: (value: T) => void,
}

function HorizontalInputSelector<T>({options,selectedOption,setSelectedOption}: HorizontalInputSelectorProps<T>) {
	return (
		<div className={s.sections}>
			{options.map(({name,content},i) => (
				<div key={i} id={name === selectedOption ? s.currentSection : ""} onClick={() => setSelectedOption(name)}>
					{content}
				</div>
			))}
    </div>
	);
}

export default HorizontalInputSelector;
