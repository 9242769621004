import React from "react";

/**
 * Removes an elements title on mouseover and restore it on mouseout.
 * 
 * Useful for preventing duplicate display of information with components using Tippy tooltips, while maintaining built-in title for accessibility.
 */
export default function useHiddenTitleOnMouseover<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  title: string
) {
  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.addEventListener("mouseover", () => {
      if (!ref.current) return;
      ref.current.title = "";
    });
    ref.current.addEventListener("mouseout", () => {
      if (!ref.current) return;
      ref.current.title = title;
    });
  }, [ref]);
}
