import { Currency } from "../../contexts/OrderContext/OrderContext";


export interface TDiscountScheme {
  dsid: number;
  currency: Currency;
  prid: number;
  createdOnMs: number;
  startedOnMs: number;
  endedOnMs: number;
  skuId: number;
  description: string;
  bands: DiscountBand[];
}

export interface DiscountBand {
  discountBandId: number;
  createdOnMs: number;
  minUnits: number;
  maxUnits: number;
  unitDiscount: number;
  dsid: number;
  backpayOwed: number;
}

export default class DiscountScheme implements TDiscountScheme {
  readonly dsid: number;
  readonly currency: Currency;
  readonly prid: number;
  readonly createdOnMs: number;
  readonly startedOnMs: number;
  readonly endedOnMs: number;
  readonly skuId: number;
  readonly description: string;
  readonly bands: DiscountBand[];

  constructor({ dsid, currency, prid, createdOnMs, startedOnMs, endedOnMs, skuId, description, bands }: TDiscountScheme) {
    this.dsid = dsid;
    this.currency = currency;
    this.prid = prid;
    this.createdOnMs = createdOnMs;
    this.startedOnMs = startedOnMs;
    this.endedOnMs = endedOnMs;
    this.skuId = skuId;
    this.description = description;
    this.bands = bands;
  }

  band(nUnits: number): DiscountBand | undefined {
    return this.bands.find(band => band.minUnits <= nUnits && nUnits <= band.maxUnits);
  }

  total(nUnits: number): number {
    const band = this.band(nUnits);
    return nUnits * (band?.unitDiscount ?? 0);
  }

  unitDiscount(nUnits: number): number {
    return this.band(nUnits)?.unitDiscount ?? 0;
  }
}
