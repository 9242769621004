import { Text, View } from "@react-pdf/renderer";
import { ArrearsInvoice, PatientInvoiceItem, SubstrateInvoiceItem } from "../../../../utils/data-classes/ArrearsInvoice";
import { s } from "../arrears-invoice-style";
import { H1, H2, Table, Th, Thead, Tr } from "./markdown";
import { isLast, splitArrayOnThresholdLength } from "../util";
import { date } from "../util";
import { useDateFormat } from "../../../../hooks/useDateFormat";
import { ArrearsInvoicePage } from "./ArrearsInvoicePage";
import { TotalFooter } from "./DeviceFooter";
import { DeviceHeader } from "./DeviceHeader";
import { Sku } from "../../../../hooks/useSkus";
import useCurrency from "../../../../contexts/CurrencyContext/useCurrency";
import { useContext } from "react";
import ArrearsInvoiceContext from "../ArrearsInvoiceContext";

export function BulkOrdersPages() {
  const invoice = useContext(ArrearsInvoiceContext);
  if (!hasBulkOrders(invoice)) return <></>;
  if (shouldSplitPages(invoice)) return <SplitBulkOrderPages />;
  return <UnsplitBulkOrdersPage />;
}

function UnsplitBulkOrdersPage() {
  return (
    <ArrearsInvoicePage>
      <BulkOrders />
    </ArrearsInvoicePage>
  );
}

function SplitBulkOrderPages() {
  return (
    <>
      <SubstratePages />
      <BulkDevicesPages />
    </>
  );
}

function shouldSplitPages(invoice: ArrearsInvoice) {
  return nLinesBulkOrdersOnePage(invoice) > THRESHOLD_N_BULK_LINES;
}

function hasBulkOrders(invoice: ArrearsInvoice) {
  const { items } = invoice;
  return items.substrates.length > 0 || items.devices.shippedToProvider.length > 0;
}

function SubstratePages() {
  const invoice = useContext(ArrearsInvoiceContext);
  const items = splitBulkSubstrates(invoice);
  return (
    <>
      {items.map((pageItems, i) => (
        <SubstratePage key={i} items={pageItems} withFooter={isLast(i, items)} />
      ))}
    </>
  );
}

function BulkDevicesPages() {
  const invoice = useContext(ArrearsInvoiceContext);
  const items = splitBulkDevices(invoice);
  return (
    <>
      {items.map((pageItems, i) => (
        <BulkDevicesPage key={i} items={pageItems} withFooter={isLast(i, items)} />
      ))}
    </>
  );
}

function BulkOrders() {
  const invoice = useContext(ArrearsInvoiceContext);
  return (
    <View>
      <H1>Bulk Orders</H1>
      <Substrates items={invoice.items.substrates} withFooter={true} />
      <BulkDevices />
    </View>
  );
}

const THRESHOLD_N_BULK_LINES = 37;

function nLinesBulkOrdersOnePage(invoice: ArrearsInvoice) {
  return nLinesSubstrates(invoice) + nLinesBulkDevices(invoice) - 1;
}

function nLinesBulkDevices(invoice: ArrearsInvoice) {
  return 4 + 2 * invoice.items.devices.shippedToProvider.length;
}

function nLinesSubstrates(invoice: ArrearsInvoice) {
  return 4 + invoice.items.substrates.length;
}

function BulkDevices() {
  const invoice = useContext(ArrearsInvoiceContext);
  if (invoice.items.devices.shippedToProvider.length === 0) return <></>;
  return (
    <View>
      <H2>Devices</H2>
      <BulkDeviceTableShard items={invoice.items.devices.shippedToProvider} withFooter={true} />
    </View>
  );
}

const SUBSTRATE_LENGTH_THRESHOLD = THRESHOLD_N_BULK_LINES - 4;
const BULK_DEVICE_LENGTH_THRESHOLD = THRESHOLD_N_BULK_LINES / 2 - 4; // Combo sku labels take up twice as many lines

function splitBulkDevices(invoice: ArrearsInvoice) {
  return splitArrayOnThresholdLength(invoice.items.devices.shippedToProvider, BULK_DEVICE_LENGTH_THRESHOLD);
}

function splitBulkSubstrates(invoice: ArrearsInvoice) {
  return splitArrayOnThresholdLength(invoice.items.substrates, SUBSTRATE_LENGTH_THRESHOLD);
}

function BulkDevicesPage(props: { items: PatientInvoiceItem[]; withFooter: boolean }) {
  return (
    <ArrearsInvoicePage>
      <H1>Bulk Orders</H1>
      <H2>Devices</H2>
      <BulkDeviceTableShard {...props} />
    </ArrearsInvoicePage>
  );
}
interface BulkDeviceTableProps {
  items: PatientInvoiceItem[];
  withFooter: boolean;
}

function BulkDeviceTableShard({ items, withFooter }: BulkDeviceTableProps) {
  const invoice = useContext(ArrearsInvoiceContext);
  return (
    <Table>
      <DeviceHeader />
      {items.map((item, i) => (
        <BulkDeviceTableRow key={i} item={item} sku={invoice.skus[item.skuId]} />
      ))}
      {withFooter && <TotalFooter label="Bulk Device Total" val={invoice.shippedToProviderTotal()} />}
    </Table>
  );
}

function BulkDeviceTableRow({ item, sku }: { item: PatientInvoiceItem; sku: Sku }) {
  return (
    <Tr>
      <Text style={s.ptTable.date}>{date(item.testedOnMs, useDateFormat("date"))}</Text>
      <Text style={s.ptTable.item}>{sku.shortLabel}</Text>
      <Text style={s.ptTable.unitPrice}>{useCurrency(item.unitPriceNet)}</Text>
      <Text style={s.ptTable.total}>{useCurrency(item.unitPriceNet * item.quantity)}</Text>
    </Tr>
  );
}

function SubstratePage(props: SubstrateTableProps) {
  return (
    <ArrearsInvoicePage>
      <H1>Bulk Orders</H1>
      <H2>Substrates</H2>
      <SubstrateTableShard {...props} />
    </ArrearsInvoicePage>
  );
}

function Substrates(props: SubstrateTableProps) {
  const invoice = useContext(ArrearsInvoiceContext);
  if (invoice.items.substrates.length === 0) return <></>;
  return (
    <View>
      <H2>Substrates</H2>
      <SubstrateTableShard {...props} />
    </View>
  );
}

interface SubstrateTableProps  {
  items: SubstrateInvoiceItem[];
  withFooter: boolean;
}

function SubstrateTableShard({ items, withFooter }: SubstrateTableProps) {
  const invoice = useContext(ArrearsInvoiceContext);
  return (
    <Table>
      <SubstrateHeader />
      {items.map((item, i) => (
        <SubstrateRow key={i} item={item} sku={invoice.skus[item.skuId]} />
      ))}
      {withFooter && <TotalFooter label="Bulk Substrate Total" val={invoice.substrateTotal()} />}
    </Table>
  );
}

function SubstrateHeader() {
  return (
    <Thead>
      <Tr>
        <Th style={s.substrateTable.orderDate}>Order Date</Th>
        <Th style={s.substrateTable.item}>Item</Th>
        <Th style={s.substrateTable.quantity}>Quantity</Th>
        <Th style={s.substrateTable.unitPrice}>Unit Price</Th>
        <Th style={s.substrateTable.total}>Total</Th>
      </Tr>
    </Thead>
  );
}

function SubstrateRow({ item, sku }: { item: SubstrateInvoiceItem; sku: Sku }) {
  return (
    <Tr>
      <Text style={s.substrateTable.orderDate}>{date(item.orderedOnMs, useDateFormat("date"))}</Text>
      <Text style={s.substrateTable.item}>{sku.shortLabel}</Text>
      <Text style={s.substrateTable.quantity}>{item.quantity}</Text>
      <Text style={s.substrateTable.unitPrice}>{item.unitPriceNet}</Text>
      <Text style={s.substrateTable.total}>{useCurrency(item.quantity * item.unitPriceNet)}</Text>
    </Tr>
  );
}
