import React from "react";
import { Redirect } from "react-router-dom";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import ForgotPassword from "../pages/forgot-password/ForgotPassword";
import StaffSignup from "../pages/staff-signup/StaffSignup";
import ResetPassword from "../pages/reset-password/ResetPassword";
import GettingStarted from "../pages/help/getting-started/GettingStarted";
import DashRoute from "./DashRoute";
import PrivacyPolicyPage from "../pages/privacy-policy/PrivacyPolicyPage";
import MagicLinkLogin from "../pages/login/MagicLinkLogin";
import MiniChallenges from "../pages/xealth/challenges/MiniChallenges";
import MiniDayToDay from "../pages/xealth/day-to-day/MiniDayToDay";
import DownloadChallengeReportByToken from "../pages/digest/challenges/download-challenge-report-by-token/DownloadChallengeReportByToken";

export const PreLoginRoutes = {
  LOGIN: new DashRoute("/", Login),
  MAGIC_LINK: new DashRoute("/login/magic-link/:token",MagicLinkLogin),
  HOME: new DashRoute("/home", null, <Redirect to="/" />),
  HELP: new DashRoute("/help/getting-started", GettingStarted),
  FORGOT_PASSWORD: new DashRoute("/forgot-password", ForgotPassword),
  RESET_PASSWORD: new DashRoute("/reset-password", ResetPassword),
  REGISTER: new DashRoute("/register/:patientCode", Register),
  STAFF_SIGNUP: new DashRoute("/staff-signup/:token", StaffSignup),
  PRIVACY_POLICY: new DashRoute("/privacy-policy/",PrivacyPolicyPage),
  REGISTER_EXISTING: new DashRoute("/register-existing/:patientCode", null, <Redirect to="/register/:patientCode" />), // Legacy route for dashboard v1 signup links
  DOWNLOAD_CHALLENGE_REPORT_BY_TOKEN: new DashRoute("/digest/challenges/:token",DownloadChallengeReportByToken),

  XEALTH_CHALLENGES: new DashRoute("/xealth/challenges/:token",MiniChallenges),
  XEALTH_DAY_TO_DAY: new DashRoute("/xealth/day-to-day/:token",MiniDayToDay),
};