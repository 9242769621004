/**
 * Functions for operating on generic object based on any prop, or a specific prop common between various classes
 */

import { DateTime } from "luxon";

/**
 * @template {{createdOn: string}} T {createdOn: ISO date}
 * @param {T[]} objects
 * @returns {T[][]} Object arrays indexed by createdOn ISO date, sorted based on ascending flag
 */
export const createdOnDateBucketsArray = (objects,ascending=true) => {
  return Object.values(dateBuckets(objects,'createdOn',ascending)).sort(compareCreatedOn());
}
/**
 * @template {{consumedOn: string}} T {createdOn: ISO date}
 * @param {T[]} objects
 * @returns {T[][]} Object arrays indexed by createdOn ISO date, sorted based on ascending flag
 */
export const consumedOnDateBucketsArray = (objects,ascending=true) => {
  return Object.values(dateBuckets(objects,'consumedOn',ascending)).sort(compareConsumedOn());
}

/**
 * Sort an array of objects into buckets based on date extracted from a provided string property of the object with an ISO date format
 * @template T
 * @param {T[]} objects 
 * @param {string} ISOProp name of string prop with ISO format
 * @param {boolean} ascending 
 * @returns {T[][]}
 */
export const dateBuckets = (objects,ISOProp,ascending=true) => {
  const insert = (buckets,obj) => {
    const date = getISODate(obj,ISOProp);
    if (!buckets[date]) buckets[date] = [];
    buckets[date].push(obj); 
    return buckets;
  }
  const sorted = [...objects].sort(compareISOProp(ISOProp));
  const result = sorted.reduce((buckets,obj) => insert(buckets,obj),{});
  return result;
}

export const dateBucketsToArrays = (buckets, ISOProp, ascending=true) => {
  const result = Object.values(buckets).sort((a,b) => compareISOProp(ISOProp,ascending)(a[0],b[0]));
  return result;
}  

/**
 * @param {boolean} ascending 
 * @returns 
 */
export const compareCreatedOn = (ascending=true) => compareISOProp('createdOn',ascending);

export const compareConsumedOn = (ascending=true) => compareISOProp('consumedOn',ascending);

/**
 * @param {string} prop object string prop in ISO format
 * @param {boolean} ascending 
 * @returns 
 */
export const compareISOProp = (prop,ascending=true) => (a,b) => {
  // validate ISO format
  const [atime,btime] = [DateTime.fromISO(a[prop]),DateTime.fromISO(b[prop])];
  if (ascending) return a[prop] < b[prop] ? -1 : 1;
  return a[prop] < b[prop] ? 1 : -1;
}

/**
 * @param {*} obj
 * @param {string} ISOProp
 * @returns 
 */
const getISODate = (obj,ISOProp) => {
  return DateTime.fromISO(obj[ISOProp]).toISODate();
}

/**
 * @template T
 * @param {T[]} objects 
 * @param {string} ISOProp
 * @returns {string[]} unique createdOn dates from provided objects
 */
 export const ISODatesUnique = (objects,ISOProp) => {
  const allDates = objects.map(obj => DateTime.fromISO(obj[ISOProp]).toISODate());
  const result = [...new Set(allDates)];
  return result;  
}

/**
 * @template {{createdOn: string}} T {createdOn: ISO}
 * @param {T[]} objects 
 * @returns {string[]} unique createdOn dates from provided objects
 */
export const createdOnDatesUnique = (objects) => ISODatesUnique(objects,'createdOn');

/**
 * @example
 * // returns [[{t: "2020-08-01"},{t: "2020-08-02"}],[{t: "2020-08-04"}]]
 * const arr = [{t: "2020-08-01"},{t: "2020-08-02"},{t: "2020-08-04"}];
 * createArraysWithContinuousSequentialDays(arr);
 * @template T 
 * @param {T[]} arr
 * @param {string} ISOProp
 * @returns {T[][]}
 */
export const createArraysWithContinuousSequentialDays = (arr,ISOProp) => {
  const _arr = [...arr].sort(compareISOProp(ISOProp));
  const result = [];
  for (let i=0; i<_arr.length; i++) {
    const date = _arr[i][ISOProp];
    const prevDate = i === 0 ? null : _arr[i-1][ISOProp];
    if (!prevDate || DateTime.fromISO(date).diff(DateTime.fromISO(prevDate)).as("days") > 1) result.push([]);
    result[result.length-1].push(_arr[i]);
  }
  return result;
}

/**
 * Create array reordering values of color gradient array, alternating values from opposite extremes of gradient
 * @template T
 * @param {T[]} arr 
 */
export const createArrayAlternatingValuesFromOppositeEnds = (arr) => {
  // Non fixed colors exist in array alternating colors from two sides of spectrum, to make sure colors are maximally distinguishable
  const result = arr.map((val,i) => {
    if (i % 2 === 0) return arr[i];
    else return arr[arr.length-i];
  });
  return result;
}