import { useContext, useEffect, useState } from "react";
import InterfaceButton from "../InterfaceButton/InterfaceButton";
import { ReactComponent as ReportReviewedIcon } from "./report-reviewed.svg";
import { ReactComponent as ReportToReviewIcon } from "./report-to-review.svg";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import usePatientFromURLSearchParams from "../../../hooks/usePatientFromURLSearchParams";
import { Patient } from "../../../utils/data-classes/Patient";
import { AppError, devLog } from "../../../utils/utils";
import _variables from "../../../styles/_variables/variables";
import { ChallengesContext, ChalCardData } from "../../../pages/digest/challenges/ChallengesContext";
import useErrorState from "../../../hooks/useErrorState";
import { postChallengeSettings } from "../../../utils/data-classes/challenge/Challenge";

type ChallengeReviewButtonProps = {
  cards: ChalCardData[];
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export default function ChallengeReviewButton({ cards, className, disabled, title, ...rest }: ChallengeReviewButtonProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useErrorState();
  const [success, setSuccess] = useState(false);
  const marksAsReviewed = shouldMarkAsReviewed(cards);

  const {
    state: { loginToken },
  } = useContext(LoginContext);
  const patient = usePatientFromURLSearchParams();

  const { dispatch: challengesDispatch } = useContext(ChallengesContext);

  const abortController = new AbortController();
  useEffect(() => {
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (!success) return;
    challengesDispatch({ type: "SET_REVIEW_STATUS", payload: { cards, status: marksAsReviewed } });
    setSuccess(false);
  }, [success]);

  if (!loginToken || !patient) return <></>;

  const onClick = () => {
    setLoading(true);
    updateReviewStatus(loginToken, patient, cards, abortController.signal)
      .then(() => setSuccess(true))
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <InterfaceButton
      type="button"
      title={title ?? content(marksAsReviewed, error)}
      icon={<Icon marksReviewed={marksAsReviewed} />}
      disabled={disabled || cards.length === 0 || loading || !!error}
      onClick={onClick}
      loading={loading}
      {...rest}
    >
      <Label marksReviewed={marksAsReviewed} error={error} />
    </InterfaceButton>
  );
}

/**
 * @todo Connect to backend
 */
async function updateReviewStatus(loginToken: string, patient: Patient, cards: ChalCardData[], signal: AbortSignal) {
  devLog(`Setting review status to ${shouldMarkAsReviewed(cards)}`);
  const ptid = patient.ptid;
  const settings = cards.map((card) => ({
    chalEpochMs: card.challenge.epochMs,
    settings: {
      isReviewed: shouldMarkAsReviewed(cards),
    },
  }));
  await postChallengeSettings(loginToken, ptid, settings, signal);
}

function Label({ marksReviewed, error }: LabelProps) {
  return <p style={{ color: color(error) }}>{content(marksReviewed, error)}</p>;
}

function content(marksReviewed: boolean, error?: AppError) {
  if (error) return error.message;
  if (marksReviewed) return "Mark report as reviewed";
  return "Mark report as unreviewed";
}

function color(error: AppError | undefined) {
  if (error) return _variables.errRed;
  return "";
}

function Icon({ marksReviewed }: { marksReviewed: boolean }) {
  return marksReviewed ? <ReportReviewedIcon /> : <ReportToReviewIcon />;
}

interface LabelProps {
  marksReviewed: boolean;
  error: AppError | undefined;
}

function shouldMarkAsReviewed(cards: ChalCardData[]): boolean {
  if (cards.length === 0) return true;
  if (cards.every((card) => card.challenge.settings.isReviewed)) return false;
  return true;
}
