import useData from "./useData";
import { AppError } from "../utils/utils";

export default function useCountries(): UseCountriesReturn {
  const { data, error } = useData(fetchCountries);
  return { countries: data, error };
}

const fetchCountries = async (signal: AbortSignal): Promise<Country[]> => {
  const res = await fetch("/api/countries",{signal});
  const { countries, error }: { countries: any[], error: undefined } | { countries: undefined, error: string } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return countries.map(c => ({...c, currency: c.currency ?? undefined}));
} 

export interface Country {
  countryId: number;
  country: string;
  label: string;
  isStripeSupported: boolean;
  currency: string | undefined;
}

export interface UseCountriesReturn {
  countries: Country[]|undefined;
  error: AppError|undefined;
}