import { Text, View } from "@react-pdf/renderer";
import { Challenge } from "../../../../utils/data-classes/challenge/Challenge";
import { HydrogenHandler } from "../../../../utils/data-classes/challenge/HydrogenHandler";
import { MethaneHandler } from "../../../../utils/data-classes/challenge/MethaneHandler";
import { s, COLOR } from "../challenge-report-styles";
import { GasText } from "./ChallengeReportUtil";
import { interpretation, testCriteria } from "./ChallengeReportUtil";

export function SummaryTable({ challenge }: { challenge: Challenge; }) {
  const h2Handler = challenge.h2;
  const ch4Handler = challenge.ch4;
  return (
    <View style={s.summaryTable.main}>
      <SummaryTableHeader />
      {h2Handler && <SummaryRow ppmHandler={h2Handler} />}
      {ch4Handler && <SummaryRow ppmHandler={ch4Handler} />}
    </View>
  );
}

function SummaryTableHeader() {
  return (
    <View style={s.summaryTable.header}>
      <Text style={{ ...s.summaryTable.headerCell, ...s.summaryTable.gas }}>Gas</Text>
      <Text style={{ ...s.summaryTable.headerCell, ...s.summaryTable.baseline }}>Baseline</Text>
      <Text style={{ ...s.summaryTable.headerCell, ...s.summaryTable.result }}>Result</Text>
      <Text style={{ ...s.summaryTable.headerCell, ...s.summaryTable.interpretation }}>Interpretation</Text>
      <Text style={{ ...s.summaryTable.headerCell, ...s.summaryTable.criteria }}>Criteria</Text>
    </View>
  );
}

function PpmCell({ value, isPositive }: { value: number; isPositive: boolean; }) {
  const color = COLOR[isPositive ? "red" : "darkGray"];
  return (
    <View style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <Text style={{ width: "50%", textAlign: "right", marginRight: "2px", color }}>{value.toFixed(0)}</Text>
      <Text style={{ width: "50%", textAlign: "right", color }}>ppm</Text>
    </View>
  );
}

function SummaryRow({ ppmHandler }: { ppmHandler: HydrogenHandler | MethaneHandler; }): JSX.Element {
  const gas = ppmHandler.gas;
  const isSIBO = ppmHandler.challenge.isSIBO();
  const isPositive = !!ppmHandler.positiveRise();
  const baseline = ppmHandler.challenge.baselineTest.ppm(gas) ?? 0;
  const result = ppmHandler.result();
  if (result === null) return <></>;
  return (
    <View style={s.summaryTable.row}>
      <Text style={s.summaryTable.gas}><GasText gas={gas} /></Text>
      <View style={s.summaryTable.baseline}><PpmCell value={baseline} isPositive={baseline >= ppmHandler.highBaseline()} /></View>
      <View style={s.summaryTable.result}><PpmCell value={result} isPositive={isPositive} /></View>
      <Text style={{ ...s.summaryTable.interpretation, color: COLOR[isPositive ? "red" : "darkGray"], fontWeight: isPositive ? 600 : 300 }}>{interpretation(gas, isSIBO, isPositive)}</Text>
      <Text style={s.summaryTable.criteria}>{testCriteria(gas, isSIBO)}</Text>
    </View>
  );
}
