import { Text, View } from "@react-pdf/renderer";
import { Challenge } from "../../../../utils/data-classes/challenge/Challenge";
import { symptomScoresByTest } from "../../../../utils/data-classes/challenge/challenge-util";
import { HydrogenHandler } from "../../../../utils/data-classes/challenge/HydrogenHandler";
import { MethaneHandler } from "../../../../utils/data-classes/challenge/MethaneHandler";
import { DateTime } from "luxon";
import Symptom from "../../../../utils/data-classes/Symptom";
import _round from "lodash/round";
import {
  subdivideWidth,
  parsePercentage,
  splitSymptoms,
  sortSymptomsByMax,
} from "../challenge-report-fns";
import { s, COLOR } from "../challenge-report-styles";
import { GasText } from "./ChallengeReportUtil";
import { Test } from "../../../../utils/data-classes/Test";

export default function ChallengeDataTable({ challenge, symptoms }: { challenge: Challenge; symptoms: Symptom[] }) {
  const h2Handler = challenge.h2;
  const ch4Handler = challenge.ch4;
  const topSymptoms = sortSymptomsByMax(splitSymptoms(symptoms)).slice(0, 3);
  return (
    <View style={s.challengeData.main}>
      <View style={s.challengeData.table}>
        <ChallengeDataHeader challenge={challenge} />
        <ChallengeDataTimeRow challenge={challenge} />
        {h2Handler && <ChallengeDataPpmRow ppmHandler={h2Handler} />}
        {ch4Handler && <ChallengeDataPpmRow ppmHandler={ch4Handler} />}
        {topSymptoms.map((s, i) => (
          <ChallengeDataSymptomRow key={i} challenge={challenge} symptoms={s} />
        ))}
      </View>
      {topSymptoms.length > 0 && <ChallengeDataDesc />}
    </View>
  );
}

function ChallengeDataHeader({ challenge }: { challenge: Challenge }) {
  return (
    <View style={{ ...s.challengeData.row, backgroundColor: COLOR.clinicalGreen }}>
      <Text style={{ ...s.challengeData.sideHeader, ...s.challengeData.headerCell }}>Breath No.</Text>
      {challenge.tests.map((_, i) => (
        <View
          key={i}
          style={{
            ...s.challengeData.cell,
            ...s.challengeData.headerCell,
            width: columnWidth(challenge),
          }}
        >
          <Text>{i === 0 ? "base" : i}</Text>
        </View>
      ))}
      <Text style={{ ...s.challengeData.padding, ...s.challengeData.headerCell }}></Text>
    </View>
  );
}

function ChallengeDataRow({ label, children }: { label: string | React.ReactNode; children: React.ReactNode }) {
  return (
    <View style={s.challengeData.row}>
      <Text style={s.challengeData.sideHeader}>{label}</Text>
      {children}
      <Text style={s.challengeData.padding}></Text>
    </View>
  );
}

function ChallengeDataTimeRow({ challenge }: { challenge: Challenge }) {
  return (
    <ChallengeDataRow label="Time (mins)">
      {challenge.tests.map((test, i) => (
        <View key={i} style={{ ...s.challengeData.cell, width: columnWidth(challenge) }}>
          <Text>{testMinute(challenge, test)}</Text>
        </View>
      ))}
    </ChallengeDataRow>
  );
}

function testMinute(challenge: Challenge, test: Test) {
  const initialTime = DateTime.fromISO(challenge.firstTest.testCreatedOn);
  const testTime = DateTime.fromISO(test.testCreatedOn);
  return Math.round(testTime.diff(initialTime).as("minutes"));
}

function ChallengeDataPpmRow({ ppmHandler }: { ppmHandler: HydrogenHandler | MethaneHandler }) {
  const backgroundColor = COLOR[!!ppmHandler.positiveRise() ? "positivePink" : "white"];
  const ppms = ppmHandler.challenge.tests.map((test) => test.ppm(ppmHandler.gas)?.toFixed(0) ?? "");
  return (
    <ChallengeDataRow label={<ChallengeDataPpmRowLabel ppmHandler={ppmHandler} />}>
      {ppms.map((ppm, i) => (
        <View
          key={i}
          style={{
            ...s.challengeData.cell,
            width: columnWidth(ppmHandler.challenge),
            backgroundColor,
          }}
        >
          <Text>{ppm}</Text>
        </View>
      ))}
    </ChallengeDataRow>
  );
}

function ChallengeDataPpmRowLabel({ ppmHandler }: { ppmHandler: HydrogenHandler | MethaneHandler }) {
  return (
    <View>
      <GasText gas={ppmHandler.gas} />
      <Text> (ppm)</Text>
    </View>
  );
}

function ChallengeDataSymptomRow({ challenge, symptoms }: { challenge: Challenge; symptoms: Symptom[] }) {
  const scores = symptomScoresByTest(challenge.tests, symptoms).map((score) => _round(score ?? 0));
  return (
    <ChallengeDataRow label={symptoms[0].label}>
      {scores.map((score, i) => (
        <View key={i} style={{ ...s.challengeData.cell, width: columnWidth(challenge) }}>
          <Text>{score}</Text>
        </View>
      ))}
    </ChallengeDataRow>
  );
}

function ChallengeDataDesc() {
  return <Text style={s.challengeData.desc}>Symptoms are on a 0-10 scale</Text>;
}

function columnWidth(challenge: Challenge) {
  const headerWidth = parsePercentage(s.challengeData.sideHeader.width);
  const paddingWidth = parsePercentage(s.challengeData.padding.width);
  return subdivideWidth(headerWidth + paddingWidth, challenge.length);
}
