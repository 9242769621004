import { MouseEventHandler, useState } from "react";
import Modal from "../dialogs/Modal/Modal";
import s from "./InternalDeploymentWarning.module.scss";
import { isInternalDeployment, redirectToProductionDashboard } from "./internal-deployment-warning-util";

/**
 * Modal for declaring to users accessing clinical-test or other internal deplyments that they should be at the production site, including a redirect link
 */
export default function InternalDeploymentWarningModal() {
  const [isOpen, setIsOpen] = useState(isInternalDeployment());
  const close = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} onAfterClose={close}>
      <div className={s.clinicalTestWarning}>
        <h3>Warning: This dashboard is for internal testing purposes only</h3>
        <p>Your FoodMarble Clinical Dashboard login credentials will not allow access to this site.</p>
        <RedirectButton />
        <ContinueButton onClick={close} />
      </div>
    </Modal>
  );
}

function RedirectButton() {
  return (
      <button 
        type="button"
        onClick={redirectToProductionDashboard}
        className={s.redirect}
        title="Redirect to FoodMarble Clinical Dashboard"
      >
        Continue to FoodMarble Clinical Dashboard
      </button>
  )
}

function ContinueButton({onClick}: {onClick: MouseEventHandler<HTMLButtonElement>}) {
  return (
      <button 
        type="button" 
        className={s.continue}
        onClick={onClick}
        title="Continue to FoodMarble internal testing dashboard"
      >
        Access FoodMarble internal testing dashboard
      </button>
  )
}
