import React, { useContext } from "react";
import SiteOverviewRow from "./SiteOverviewRow";
import s from "./SiteOverview.module.scss";
import { SiteOverviewRowData } from "./useSiteOverview";
import loadingPng from "../../assets/loading.png";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";

interface SiteOverviewTableProps {
  rows: SiteOverviewRowData[] | undefined;
  setIsAddPatientModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SiteOverviewTable = ({ rows, setIsAddPatientModalOpen }: SiteOverviewTableProps) => {
  const { preferences } = useContext(LoginContext).state;
  const showRPM = preferences?.provider.hasRPM;
  const fetching = !rows;

  const renderMainHeader = () => (
    <div className={s.mainHeader}>
      <div className={`${s.patientsCol} ${s.patientMainHeader}`}>
        <span>Patients</span>
        <button onClick={() => setIsAddPatientModalOpen(true)}>Add Patient</button>
      </div>
      <div className={s.challengesCol}><span>Challenges</span></div>
      {showRPM && <div className={s.rpmCol}><span>RPM</span></div>}
    </div>
  );

  const renderTableHeader = () => (
    <div className={s.tableHeader}>
      <div className={s.patientsCol}>
        <span>Name</span>
        <span>Clinician</span>
        <span>Status</span>
      </div>
      <div className={s.challengesCol}>
        <span>Date</span>
        <span>Substrate</span>
        <span>Result</span>
        <span>Prompt</span>
        <span></span>
      </div>
      {showRPM && (
        <div className={s.rpmCol}>
          <span></span>
        </div>
      )}
    </div>
  );

  const renderLoadingIcon = () => (
    <div className={s.loadingIcon}>
      <img src={loadingPng} alt="Fetching patient data" />
    </div>
  );

  const renderSiteOverviewUberRow = (row: SiteOverviewRowData, patientNo: number) => {
    const { patient, staff, challenges, accessible, requestedChallenge, rpm } = row;
    const displayPatientData = (index: number) => {
      if (requestedChallenge) return false;
      return index === 0;
    };

    if (challenges?.length === 0) {
      return (
        <SiteOverviewRow
          accessible={accessible}
          patientNo={patientNo}
          patient={patient}
          staff={staff}
          challenge={undefined}
          challengeOnly={false}
          requestedChallenge={requestedChallenge}
          rpm={rpm}
          showRPM={showRPM}
        />
      );
    }

    return (
      <>
        {requestedChallenge && (
          <SiteOverviewRow
            accessible={accessible}
            patientNo={patientNo}
            patient={patient}
            staff={staff}
            challenge={undefined}
            challengeOnly={false}
            requestedChallenge={requestedChallenge}
            rpm={rpm}
            showRPM={showRPM}
          />
        )}
        {challenges?.map((challenge, j) => (
          <SiteOverviewRow
            key={j}
            accessible={accessible}
            patientNo={patientNo}
            patient={patient}
            staff={staff}
            challenge={challenge}
            challengeOnly={!displayPatientData(j)}
            rpm={rpm}
            showRPM={showRPM}
          />
        ))}
      </>
    );
  };

  return (
    <div className={s.siteOverview}>
      {renderMainHeader()}
      {renderTableHeader()}
      {fetching && renderLoadingIcon()}
      {!fetching && rows.map((row, i) => renderSiteOverviewUberRow(row, i))}
    </div>
  );
};

export default SiteOverviewTable;