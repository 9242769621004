import { DateTime } from "luxon";
import tz from "../tz";
import Gas from "./Gas";
import { createArraysWithContinuousSequentialDays, createdOnDateBucketsArray } from "./generic-fns";

class Ppm {
  readonly createdOn: string;
  readonly ppm: number;
  readonly did: number|undefined;
  readonly gasId: number;
  constructor(createdOnMs: number,ppm: number,did: number|undefined,gasId: number) {
    this.createdOn = tz.toLocal(createdOnMs).toISO();
    this.ppm = ppm < 0 ? 0 : ppm;
    this.did = did;
    this.gasId = gasId;
  }

  static fromJSON({createdOn,ppm,did,gasId}: any) {
    return new Ppm(createdOn,ppm,did,gasId);
  }
}

export const dailyMeanPpm = (ppms: Ppm[],ISODate: string,gas: Gas) => {
 // validate ISODate
 const time = DateTime.fromISO(ISODate);
 const createdOn = tz.toDateMillisUTC(ISODate);
 const gasId = gas.gasId;

 const ppmsOnDate = ppms.filter(ppm => isPpmOnDate(ppm,time,gas));
 const ppm = Math.round(ppmsOnDate.reduce((avg,ppm) => avg + ppm.ppm/ppmsOnDate.length,0));
 return new Ppm(createdOn,ppm,undefined,gasId);
}

const isPpmOnDate = (ppm: Ppm,time: DateTime,gas: Gas) => DateTime.fromISO(ppm.createdOn).toISODate() === time.toISODate() && ppm.gasId === gas.gasId;

export const createPpm = ({createdOnMs,ppm,did,gasId}: { createdOnMs: number, ppm: number, did: number, gasId: number}) => {
  return new Ppm(createdOnMs,ppm,did,gasId);
}

export const ppmsWithGas = (ppms: Ppm[],gas: Gas) => {
  return ppms.filter(ppm => ppm.gasId === gas.gasId);
}

export const ppmsByDay = (ppms: Ppm[]) => {
  return Object.values(createdOnDateBucketsArray(ppms));
}

export const dailyAveragePpms = (ppms: Ppm[],gas: Gas) => {
  return ppmsByDay(ppmsWithGas(ppms,gas)).map(arr => dailyMeanPpm(arr,arr[0].createdOn,gas));
}

/**
 * Get array of rolling averages for each day in set range
 *  - Each day is the average of that days average and the n preceeding averages
 * @param {Ppm[]} ppms
 * @param {Gas} gas
 * @param {number} n  number of days over which to compute rolling average
 * @returns 
 */
export const rollingAveragePpms = (ppms: Ppm[],gas: Gas,n: number): Ppm[] => {
  // Break array into continuous blocks of days to stop values before breaks in testing from affecting the value immediately following the break
  const continuousDailyAverageArrays = createArraysWithContinuousSequentialDays(dailyAveragePpms(ppms,gas),'createdOn');
  const rollingAverageArrays = continuousDailyAverageArrays.map(arr => createRollingAverageArray(arr,n));
  return rollingAverageArrays.flat();
}

/**
 * @param {Ppm[]} continousDailyAverages sorted by createdOn
 * @param {number} n number of preceding days over which to average
 */
const createRollingAverageArray = (continousDailyAverages: Ppm[],n: number): Ppm[] => {
  return continousDailyAverages.map((ppm,i) => {
    // compute average of first n - 1 days using as many values as are available 
    const toDivideBy = (i+1)/n >= 1 ? n : i + 1;
    const newPpmVal = rollingAverage(continousDailyAverages,i,toDivideBy)
    return new Ppm(tz.toUTC(ppm.createdOn).toMillis(),newPpmVal,ppm.did,ppm.gasId);
  });
}

const rollingAverage = (arr: Ppm[],i: number,n: number): number => Math.round(arr.slice(i-n+1,i+1).reduce((sum,ppm) => sum + ppm.ppm/n,0));

export default Ppm;