import useCountries, { Country, UseCountriesReturn } from "../../hooks/useCountries";

/**
 * Fetches array of countries from DB, and filters if the country is supported depending on if self-pay is selected.
 * Returns countries as undefined while fetching, and if isSelfPay is undefined.
 */
export default function useSupportedOrderingCountries(isSelfPay: boolean|undefined): UseCountriesReturn {
  const { countries, error } = useCountries();
  return {
    countries: supportedCountries(countries,isSelfPay),
    error,
  };
}

function supportedCountries(countries: Country[]|undefined, isSelfPay: boolean|undefined): Country[]|undefined {
  if (!countries) return undefined;
  if (isSelfPay === undefined) return undefined;
  if (!isSelfPay) return countries;
  return countries.filter(supportsSelfPay);
}

function supportsSelfPay(country: Country): boolean {
  return !!(country.isStripeSupported && country.currency)
}