/*
 * DateRange context is essentially for keeping track of query ranges
 * It provides a query range to its children components in the form of startDT - endDT
 * The children components then use this range to fetch the relevant data
 *
 * It works hand in hand with <DatePicker />:
 *   - DatePicker receives user's input and updates this context
 *   - The consumers of this context then update their data and UI accordingly
 *
 * There are multiple formats in which dates can be handled
 * To provide better visibility, I've used the following syntax when naming variables:
 *   - DT : DateTime object by Luxon.js
 *   - JS : Native JS Date object
 *   - TS : Unix Timestamp (integer)
 *   - ISO : ISO 8601 string
 *
 * This context receives and returns range as DT.
 * POST requests and database queries uses ISO.
 * DatePicker (flatPickr.js) requires JS.
 * TS is primarily used when adding and removing offsets to a date
 */

import { DateTime } from "luxon";
import React, { createContext, useReducer } from "react";
import { DateRangeContextAction } from "./DateRangeContextActions";
import dateRangeContextReducer from "./DateRangeContextReducer";

export enum PERIOD {
  CUSTOM = "custom",
  ALL = "all",
  MONTH = "month",
  WEEK = "week",
  DAY = "day",
};

export type DateRange = [DateTime|undefined,DateTime|undefined] | [DateTime|undefined] | [];

export interface DateRangeContextState {
  range: DateRange,
  period: PERIOD | undefined,
  earliestTime: DateTime | undefined,
}

export const initialState: DateRangeContextState = {
  range: [],
  period: undefined,
  earliestTime: undefined,
}

export interface DateRangeContextType {
  state: DateRangeContextState,
  dispatch: React.Dispatch<DateRangeContextAction>,
}

export const DateRangeContext = createContext<DateRangeContextType>({state: initialState, dispatch: () => {}})

export const DateRangeContextProvider = ({children}: {children: React.ReactNode}) => {
  const [state, dispatch] = useReducer<React.Reducer<DateRangeContextState,DateRangeContextAction>>(dateRangeContextReducer,initialState);

  return (
    <DateRangeContext.Provider value={{ state, dispatch }}>
      {children}
    </DateRangeContext.Provider>
  );
}