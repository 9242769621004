import ChartRenderer from "./ChartRenderer/ChartRenderer"

/**
 * @param {Object} props
 * @param {string} props.id 
 * @param {import("chart.js").ChartData} props.data 
 * @param {import("chart.js").ChartOptions} props.options
 * @returns 
 */
const SingleChartRenderer = ({id, data, options}) => {
  return <ChartRenderer id={id} data={data} options={options} type="line"/>
}

export default SingleChartRenderer;