import React, { createContext, useEffect, useReducer } from "react";
import useProviders from "../../../../hooks/useProviders";
import { Provider } from "../../../../utils/data-classes/Provider";
import useSkus, { Sku } from "../../../../hooks/useSkus";
import { Staff } from "../../../../utils/data-classes/Staff";
import useStaff from "./useStaff";
import { useFormContext } from "react-hook-form";
import useProviderPreferences from "../../../../hooks/useProviderPreferences";
import { ProviderPreferences } from "../../../../utils/data-classes/Preferences";
import useOrderRequestPricingFunctions, { SkuFullPrice } from "../useOrderRequestPricingFunctions";


export interface RequestOrderContextState {
  providers: Provider[];
  staffs: Staff[];
  skus: Sku[];
  getPrice: (skuId: number,quantity: number) => SkuFullPrice | undefined,
  provider: Provider|undefined;
  staff: Staff|undefined;
  item: SkuFullPrice|undefined;
  order: Order|undefined;
}

const initialState: RequestOrderContextState = {
  providers: [],
  staffs: [],
  skus: [],
  getPrice: (skuId, quantity) => undefined,
  provider: undefined,
  staff: undefined,
  item: undefined,
  order: undefined,
}

export interface Order {
  currency: string;
  total: number;
  taxTotal: number;
  discountTotal: number;
  orderItems: OrderItem[];
}

export interface OrderItem {
  sku: Sku,
  unitPriceNet: number;
  unitPriceGross: number;
  unitTax: number;
  unitDiscount: number;
  quantity: number;
}

type RequestOrderContextAction = 
  | { type: "SET_PROVIDERS", payload: Provider[] } 
  | { type: "SET_PROVIDER_DATA", payload: { staffs: Staff[], getPrice: (skuId: number, quantity: number) => SkuFullPrice | undefined, skus: Sku[] } }
  | { type: "SET_STAFF", payload: Staff | undefined }
  | { type: "SET_ITEM", payload: { skuId: number, quantity: number } }
  | { type: "ADD_ITEM", payload: SkuFullPrice | undefined }
  | { type: "REMOVE_ITEM", payload: OrderItem }

function reducer(state: RequestOrderContextState,action: RequestOrderContextAction): RequestOrderContextState {
  switch (action.type) {
    case "SET_PROVIDERS":{
      return { ...state, providers: action.payload };
    }
    case "SET_PROVIDER_DATA": {
      return { ...state, ...action.payload };
    }
    case "SET_STAFF": {
      return { ...state, staff: action.payload };
    }
    case "SET_ITEM": {
      return { ...state, item: state.getPrice(action.payload.skuId,action.payload.quantity)};
    }
    case "ADD_ITEM": {
      if (action.payload === undefined) return { ...state }
      return { 
        ...state, 
        order: createOrder(
          action.payload.price.currency.name,
          [...(state.order?.orderItems ?? []), createOrderItem(action.payload)]
        ) ,
      }
    }
    case "REMOVE_ITEM": {
      if (!state.order) return state;
      const orderItemIndex = state.order.orderItems.indexOf(action.payload);
      if (orderItemIndex === -1) return { ...state };
      return { ...state, order: createOrder(state.order.currency,state.order.orderItems.splice(orderItemIndex,1)) }
    }
  }
}

interface RequestOrderContextType {
  state: RequestOrderContextState;
  dispatch: React.Dispatch<RequestOrderContextAction>;
}

export const RequestOrderContext = createContext<RequestOrderContextType>({state: initialState, dispatch: () => {}});

export interface RequestOrderFormFieldValuesBase {
  prid: number;
  stfid: number;
  skuId: number;
  quantity: number;
  orderedOn: string;
}

export function RequestOrderContextProvider({isArrears,children}: { isArrears: boolean, children: React.ReactNode}) {
  const form = useFormContext<RequestOrderFormFieldValuesBase>();
  const prid = form.watch("prid");
  const stfid = form.watch("stfid");
  const skuId = form.watch("skuId");
  const quantity = form.watch("quantity");

  const providers = useProvidersForOrderType(isArrears);
  const { staff: staffs } = useStaff(prid);
  const { skus } = useSkus();
  const { pricingFunctions } = useOrderRequestPricingFunctions(prid);

  const getPrice = (skuId: number, quantity: number) => {
    const fn = pricingFunctions.get(skuId);
    if (!fn) return undefined;
    return fn(quantity);
  }

  const [state,dispatch] = useReducer<React.Reducer<RequestOrderContextState,RequestOrderContextAction>>(reducer,initialState);

  useEffect(() => {
    if (!providers || state.providers.length) return;
    dispatch({type: "SET_PROVIDERS", payload: providers});
  },[providers]);

  useEffect(() => {
    if (!staffs || !pricingFunctions || !skus) return;
    dispatch({type: "SET_PROVIDER_DATA", payload: { staffs, getPrice, skus }});
  },[staffs,pricingFunctions]);

  useEffect(() => {
    const staff = state.staffs.find(s => s.stfid === stfid);
    dispatch({type: "SET_STAFF", payload: staff});
  },[stfid]);

  useEffect(() => {
    if (skuId === undefined || quantity === undefined) return;
    dispatch({type :"SET_ITEM", payload: { skuId, quantity }});
  },[skuId,quantity]);

  return (
    <RequestOrderContext.Provider value={{state,dispatch}}>
      {children}
    </RequestOrderContext.Provider>
  )
}

const createOrder = (currency: string, orderItems: OrderItem[]): Order | undefined=> {
  return {
    currency,
    total: orderItems.reduce((acc,cur) => acc + cur.unitPriceNet * cur.quantity,0),
    taxTotal: orderItems.reduce((acc,cur) => acc + cur.unitTax * cur.quantity,0),
    discountTotal: orderItems.reduce((acc,cur) => acc + cur.unitDiscount * cur.quantity,0),
    orderItems,
  }
}

const createOrderItem = (price: SkuFullPrice): OrderItem => {
  return {
    sku: price.sku,
    unitPriceNet: price.price.unitPriceNet(),
    unitDiscount: price.price.unitDiscount,
    unitPriceGross: price.price.unitPriceGross,
    unitTax: price.price.unitTax,
    quantity: price.price.quantity,
  }
}

function useProvidersForOrderType(isArrears: boolean) {
  const { providers } = useProviders();
  const { preferences } = useProviderPreferences();
  if (!providers || !preferences) return undefined;
  return providers.filter(p => shouldDisplayProvider(preferences[p.prid],isArrears));
}

function shouldDisplayProvider(preferences: ProviderPreferences|undefined, isArrears: boolean) {
  const hasArrearsSetting = preferences?.bulkOrderingInArrears;
  if (isArrears) return hasArrearsSetting;
  return !hasArrearsSetting;
}
