/*
 * Component to create the chart for stress logs
 * Same functionality with <MultiChart />.js (same hooks, same flow)
 * Thus, refer to `MultiChart.js` for detailed documentation.
 */

import s from "../DayToDay.module.scss";
import { DateTime } from "luxon";
import SingleChartRenderer from "../../../../components/charts/SingleChartRenderer";
import { barChartOptions } from "../../charts/chart-options";
import { createDataForStress } from "../../charts/chart-datasets";
import { topBottomLabelYAxis } from "../../charts/chart-axes";

/**
 * @param {Object} props
 * @param {Stress[]|undefined} props.stress
 * @param {import("../../../../contexts/DateRangeContext/DateRangeContext").DateRange} props.range
 * @returns 
 */
const StressChart = ({ stress, range }) => {
  const data = stress ? createDataForStress(stress) : null;
  const options = range && range.length ? createChartOptions(range) : null;
  
  return <SingleChartRenderer id={s.stressCanvasWrapper} data={data} options={options} />;
};

/**
 * @param {[DateTime,DateTime]} range 
 */
const createChartOptions = (range) => {
  const result = barChartOptions(range,true,topBottomLabelYAxis(
    [
      {limit: 10, label: "High"},
      {limit: 0, label: "Low"},
    ],
    "right"
  ));  result.title = { text: "Stress Score" };
  result.legend = { display: false };
  result.scales.yAxes[0].scaleLabel.labelString = "Stress Score";
  return result;
}

export default StressChart;
