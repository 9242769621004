/**
 * Handled a fetch query, returning data of some type and an error with status code if request is rejected
 */

import { useEffect, useState } from "react";
import { AppError } from "../utils/utils";
import useErrorState from "./useErrorState";

/**
 * Hook for managing simple API calls with error handling, where auth is not required
 */
export default function useData<T>(fetcher: (signal: AbortSignal) => Promise<T>): UseDataReturn<T> {
  const [data, setData] = useState<T>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (data || error) return;
    const abortController = new AbortController();
    fetcher(abortController.signal).then(setData).catch(setError);
    return () => abortController.abort();
  },[data,error]);

  return { data, error }
}

export interface UseDataReturn<T> {
  data: T|undefined,
  error: AppError|undefined,
}
