export const PERIODS = {
  CUSTOM: "custom",
  ALL: "all",
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
};

// These registration types are set when generating the link for patient registration invite
// see: `/server/aws/patient-reg-invite.js`
export const REG_TYPES = {
  NEW: "new",
  EXISTING: "existing",
};

export const ROLES = {
  ADMIN: 1,
  CLINICIAN: 2,
  AFFILIATE: 3,
}

export const SIBO_LABELS = [
  "lactulose",
  "glucose",
];

export const HELP_EMAIL = "healthcare@foodmarble.com"

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

/**
 * Earliest date used in data fetching 
 */
export const DATA_START_DATE_ISO = "2018-01-01"; 