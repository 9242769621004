/**
 * Decorator class for browser FileReader API
 *  - Allows for calling FileReader read functions with async/await
 */
export default class FileReaderPromises {
  constructor() {
    this.reader = new FileReader();
  }

  /**
   * @param {Blob} blob
   * @param {(reader: FileReader, blob: Blob) => void} next
   * @returns {Promise<string|ArrayBuffer>}
   */
  _createPromise(blob,next) {
    return new Promise((resolve, reject) => {
      this.reader.onload = () => {
        resolve(this.reader.result);
      };
      this.reader.onerror = reject;
      next(this.reader, blob);
    });
  }

  /**
   * @param {Blob} blob
   * @returns
   */
  readAsDataURL(blob) {
    return this._createPromise(blob,(reader, blob) => reader.readAsDataURL(blob));
  }

  /**
   * @param {Blob} blob 
   * @returns 
   */
  readAsText(blob) {
    return this._createPromise(blob,(reader,blob) => reader.readAsText(blob));
  }

  /**
   * @param {Blob} blob 
   * @returns
   */
  readAsBinaryString(blob) {
    return this._createPromise(blob,(reader,blob) => reader.readAsBinaryString(blob));
  }

  /**
   * @param {Blob} blob 
   * @returns 
   */
  readAsArrayBuffer(blob) {
    return this._createPromise(blob,(reader,blob) => reader.readAsArrayBuffer(blob));
  }
}
