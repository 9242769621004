import { ReactComponent as InformationIcon } from "../../../assets/icons/information.svg";
import Tippy from "@tippyjs/react";
import { TippyProps } from "@tippyjs/react";
import s from "./Information.module.scss";

type InformationProps = Omit<TippyProps, "data" | "content"> & { data: string | JSX.Element };

export const Information = ({ data, ...rest }: InformationProps) => {
  const content = typeof data === "string" ? <span>{data}</span> : data;
  return (
    <Tippy content={content} {...rest}>
      <InformationIcon className={s.information} />
    </Tippy>
  );
};
