/*
 * Component which renders a chart given a canvas ref
 * Receives one props:
 *  - canvasRef
 */

import React from "react";

interface ChalChartProps {
  canvasRef: React.MutableRefObject<any>;
  id: string;
  style?: React.CSSProperties;
  className?: string;
}

const ChalChart = ({ canvasRef, id, style={}, className }: ChalChartProps) => {
  return (
    <div id={id} style={style} className={className}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default ChalChart;
