import Gas from "../Gas";
import { Challenge } from "./Challenge";
import { ChallengePpmHandler } from "./ChallengePpmHandler";

/**
 * Proxy class for operating on a challenge with respect to H2 ppms
 */
export class HydrogenHandler extends ChallengePpmHandler {
  static POSITIVE_RISE = 20;
  static HIGH_BASELINE = 15;

  constructor(challenge: Challenge) {
    super(challenge, Gas.H2);
    Object.setPrototypeOf(this, HydrogenHandler.prototype);
  }

  risePeriodMinutes() {
    if (this.challenge.isSIBO()) return Challenge.RISE_PERIOD_MINUTES.SIBO;
    const [start, end] = this.challenge.timeRangeDateTime();
    return end.diff(start).as("minutes");
  }

  positiveCriteria() {
    return this.rise(HydrogenHandler.POSITIVE_RISE);
  }

  positiveRise() {
    // POSITIVE RISE (a.k.a. RISE TO POSITIVE CRITERIA)
    return this.maxRiseTest();
  }

  highBaseline() {
    return HydrogenHandler.HIGH_BASELINE;
  }

  /**
   * For Hydrogen, relevant result is rise from baseline within appropriate period for challenge
   */
  result() {
    // RESULT (i.e. the increase from baseline within the positive rise period)
    // The "result" coming from the database is an f_score, thus we need to recalculate it as a ppm
    // For this, we calculate the difference between Max and baseline
    if (!this.challenge.hasGas(this.gas)) return null;
    const scoresInRisePeriod = this.testsInPositiveRisePeriod().map((t) => (t.ppm(this.gas) ?? 0));
    const maxMinusBaseline = Math.max(...scoresInRisePeriod) - (this.challenge.baselineTest.ppm(this.gas) ?? 0);
    const result = maxMinusBaseline >= 0 ? maxMinusBaseline : 0;
    return result;
  }

  isBorderline(): boolean {
    if (!this.challenge.isSIBO()) return false;
    if (this.isPositive()) return false;
    return !!this.maxTestInDurationWithPositiveRise({minute: 100});
  }
}
