import React from "react";
import s from "./IFTASelect.module.scss";

interface SimpleIFTASelectProps extends Omit<React.HTMLProps<HTMLSelectElement>,"id"|"label"|"ref"> { 
  id: string, 
  label: string,
}

export default function SimpleIFTASelect({id,label,...rest}: SimpleIFTASelectProps) {
  return (
    <div className={s.IFTASelect}>
      <select className={s.iftaField} id={id} {...rest} />
      <label className={s.iftaLabel} htmlFor={id}>{label}</label>
      <label htmlFor={id} className={s.arrow}>&or;</label>
    </div>
  );
}