import { Provider } from "../../../../utils/data-classes/Provider";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

// UTIL

export function validatePatientPhoneNumber(phoneNumber: string | number | boolean | number[]) {
  if (!phoneNumber) return true;
  if (!isValidPhoneNumber(phoneNumber.toString())) return "Invalid phone number";
  if (!isAllowedForSMS(phoneNumber.toString())) return "SMS not enabled for this country calling code";
  return true;
}

function countryCodesAllowedForSMS() {
  const result = ["US"];
  if (process.env.NODE_ENV === "development") result.push("IE");
  return result;
}

function isAllowedForSMS(phoneNumber: string): boolean {
  const country = parsePhoneNumber(phoneNumber)?.country;
  if (!country || !countryCodesAllowedForSMS().includes(country)) return false;
  return true;
}

export const getIdtMailFromIdentifier = (identifier: string, provider: Provider) => {
  let domain = provider?.domain ?? "foodmarble.com";
  let result = identifier ? `${identifier}@${domain}` : "";
  return result.toLowerCase();
};
