/*
 * This is the router for pages that does not require dash user login
 * (login page itself, user registration pages, password reset page etc.)
 */

import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Login from "../pages/login/Login";
import { NOT_FOUND_ROUTE } from "./DashRoute";
import { LoginRoutes } from "./DashRouter";
import { PreLoginRoutes } from "./PreLoginRoutes";

const preLoginPaths = Object.values(PreLoginRoutes).map(r => r.path);

const loginOnlyPaths = Object.values(LoginRoutes).filter(r => !preLoginPaths.includes(r.path)).map(r => r.path);

const PreloginRouter = () => (
  <BrowserRouter>
    <Switch>
      {Object.values(PreLoginRoutes).map((route,i) => <Route exact key={i} {...route} />)}
      {loginOnlyPaths.map((path,i) => <Route exact key={i} path={path} component={Login}/>)}
      <Route exact {...NOT_FOUND_ROUTE} />
    </Switch>
  </BrowserRouter>
);

export default PreloginRouter;
