import React from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { ControlledCountryDropdown, ControlledCountryDropdownProps } from "../../../../components/inputs/ControlledInputs/ControlledInputs";
import s from "./IFTASelect.module.scss";

type IFTACountryDropdownProps<TFieldValues extends FieldValues> = 
  & Omit<React.HTMLProps<HTMLInputElement>,"id"|"form"|"name"> 
  & Omit<ControlledCountryDropdownProps<TFieldValues>,"id"|"form"|"name"> & { 
    id: string,
    label: string,
    form: UseFormReturn<TFieldValues,any>,
    name: Path<TFieldValues>
  }

export default function IFTACountryDropdown<TFieldValues extends FieldValues>({id,label,...rest}: IFTACountryDropdownProps<TFieldValues>) {
  return (
    <div className={s.IFTASelect}>
      <ControlledCountryDropdown className={s.IFTACountryDropdown} {...rest } />
      <label className={s.iftaLabel} htmlFor={id}>{label}</label>
      <label htmlFor={id} className={s.arrow}>&or;</label>
    </div>
  );
}