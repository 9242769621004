/**
 * Simple styled components for making react-pdf more sane
 */

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { INNER_BORDER, OUTER_BORDER, ROW_PADDING } from "../arrears-invoice-style";
import { COLOR } from "../../../../styles/_variables/variables";
import { Style } from "@react-pdf/types";

function StyledText({ defaultStyle, style, children }: StyledProps) {
  return <Text style={{ ...defaultStyle, ...style }}>{children}</Text>;
}

function StyledView({ defaultStyle, style, children }: StyledProps) {
  return <View style={{ ...defaultStyle, ...style }}>{children}</View>;
}

export function H1(props: MarkdownProps) {
  return <StyledText defaultStyle={s.h1} {...props} />;
}

export function H2(props: MarkdownProps) {
  return <StyledText defaultStyle={s.h2} {...props} />;
}

export function H3(props: MarkdownProps) {
  return <StyledText defaultStyle={s.h3} {...props} />;
}

export function Strong(props: MarkdownProps) {
  return <StyledText defaultStyle={s.strong} {...props} />;
}

export function Table(props: MarkdownProps) {
  return <StyledView defaultStyle={s.table} {...props} />;
}

export function Thead(props: MarkdownProps) {
  return <StyledView defaultStyle={s.thead} {...props} />
}

export function Th(props: MarkdownProps) {
  return <StyledText defaultStyle={s.th} {...props} />;
}

export function Tfoot(props: MarkdownProps) {
  return <StyledView defaultStyle={s.tfoot} {...props} />;
}

export function Tr(props: MarkdownProps) {
  return <StyledView defaultStyle={s.tr} {...props} />;
}

const s = StyleSheet.create({
  h1: {
    fontSize: 16,
  },
  h2: {
    fontSize: 14,
  },
  h3: {},
  strong: {
    fontWeight: 900,
  },
  table: {
    border: OUTER_BORDER,
    display: "flex",
    flexDirection: "column",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: ROW_PADDING,
    paddingRight: ROW_PADDING,
  },
  thead: {
    backgroundColor: COLOR.clinicalGreen,
  },
  th: {
    color: "#FFFFFF",
  },
  tfoot: {
    borderTop: INNER_BORDER,
  }
});

interface MarkdownProps {
  style?: Style;
  children?: React.ReactNode;
}

interface StyledProps extends MarkdownProps {
  defaultStyle: Style;
  style?: Style;
  children?: React.ReactNode;
}
