import React from "react";
import { UseFormRegisterReturn } from "react-hook-form"
import s from "./LabelledNumberInput.module.scss";

type LabelledNUmberInputProps<TFieldName extends string> = Omit<React.HTMLProps<HTMLInputElement>,"id"|"label"|"ref"> & { 
  id: string, 
  label: string,
  reg: UseFormRegisterReturn<TFieldName>
  percentage?: boolean;
}

export default function LabelledNumberInput<TFieldName extends string>({id,label,reg,percentage=false,...rest}: LabelledNUmberInputProps<TFieldName>) {
  return (
    <div className={s.PercentageInput}>
      <label htmlFor={id}>{label}</label>
      <div className={s.input}>
        <input type="number" id={id} {...reg} {...rest} />
        <span>{percentage && "%"}</span>
      </div>
    </div>
  );
}