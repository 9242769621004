import { useEffect, useState } from "react";
import { createStaff, Staff } from "../../utils/data-classes/Staff";
import { devLog, AppError } from "../../utils/utils";
import useErrorState from "../../hooks/useErrorState";

interface UseValidatePasswordResetKeyReturn { 
  staff: Staff|undefined, 
  error: AppError|undefined,
}

const useValidatePasswordResetKey = (key: string): UseValidatePasswordResetKeyReturn => {
  const [staff, setStaff] = useState<Staff>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    const abortController = new AbortController();
    fetchStaff(key,abortController.signal).then(setStaff).catch(setError);
    return () => abortController.abort();
  },[key]);

  return { staff, error };
}

const fetchStaff = async (key: string,signal: AbortSignal): Promise<Staff> => {
  devLog(`usePasswordResetKey: fetching staff`);
  const res = await fetch("/api/validate-password-reset-key",{
    method: "POST",
    body: JSON.stringify({key}),
    signal: signal,
  })
  const { staff, error }: { staff: any, error: string|undefined } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return createStaff(staff);
}

export default useValidatePasswordResetKey;