/**
 * Input with Infield Top-Aligned label (IFTA)
 *  - Input which includes a label embedded in the footprint of the field.
 * Used in place of using placeholders as labels for accessibility.
 * Other inputs and selects using this styling are included in the IFTAElements directory.   * 
 *  - reg prop used to connect this element to a react-hook-form form. 
 */

import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import s from "./IFTAInput.module.scss";

type IFTAInputProps<TFieldName extends string> = Omit<React.HTMLProps<HTMLInputElement>,"id"|"label"|"ref"> & { 
  id: string, 
  label: string,
  reg: UseFormRegisterReturn<TFieldName>
}

export default function IFTAInput<TFieldName extends string>({id,label,reg,...rest}: IFTAInputProps<TFieldName>) {
  return (
    <div className={s.IFTAInput}>
      <input className={s.iftaField} id={id} {...reg} {...rest} />
      <label className={s.iftaLabel} htmlFor={id}>{label}</label>
    </div>
  );
}