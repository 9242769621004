import { DateTime, Zone, DurationUnit, DiffOptions, Duration, DurationObjectUnits, DateTimeOptions, DateTimeJSOptions } from "luxon";
import React from "react";
import { LoginContextAction, setProviderLogo } from "../contexts/LoginContext/LoginContextActions";
import { DashAPIRoute } from "./apis";
import { Provider } from "./data-classes/Provider";
import foodmarbleLogo from "../assets/provider-logos/foodmarble.png";
import { CdAction } from "./CdActions";
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';;

/**
 * Parses a valid search params string into an object of strings.
 * Returns empty object if urlParamsString is falsey.
 * @example 
 * // returns { ptid: "1"; chalEpochMs: "1655983912015" };
 * getURLParams("?ptid=1&chalEpochMs=1655983912015")
 * @example getURLParams(new URL(window.location.href).search)
 * @example getURLParams(history.location.search)
 */
export const parseURLSearchParams = (urlParamsString: string): { [param: string]: string } => {
  if (!urlParamsString) return {};
  if (typeof(urlParamsString) !== "string") throw new TypeError("urlParamsString must be a string");
  if (!isValidURLParamsString(urlParamsString)) throw new TypeError("Invalid urlParamsString");
  const result = Object.fromEntries(new URLSearchParams(urlParamsString).entries());
  return result;
}

const isValidURLParamsString = (searchParamString: string): boolean => {
  return searchParamString[0] === "?" 
    && (searchParamString.match(/\?/g) || []).length === 1 
    && !!(new URLSearchParams(searchParamString));
}

/**
 * Creates a URLSearchParams object out of an object with values of any type.
 * - nullish values are filtered out and values are converted to strings
 * @param {{[param: string]: *}} obj 
 * @returns 
 */
export const createURLSearchParams = (obj: { [param: string]: any }) => {
  const objWithStrings = Object.entries(obj)
    .filter(([_,value]) => value ?? value)
    .map(([key,value]) => [key,`${value}`]);
  return new URLSearchParams(objWithStrings);
}

/**
 * @param {string} base 
 * @param {URLSearchParams} params 
 * @returns 
 */
export const buildDashURL = (base: string,params: URLSearchParams): string => {
  if (!params || !params.toString()) return base;
  return `${base}?${params}`; 
}

/**
 * A modified console.log (with timestamps) that only logs in development
 * Use this function for logs that you want to keep in the code when pushing to repo
 */
export const devLog = (...log: any[]): void => {
  // NODE_ENV is a built-in env variable: https://create-react-app.dev/docs/adding-custom-environment-variables/
  if (process.env.NODE_ENV !== "development") return;

  console.log(
    "\x1B[34m%s\x1b[0m",
    Date.now().toString().slice(-5) + ":",
    ...log
  );
};

export function devError(error: Error) {
  if (process.env.NODE_ENV !== "development") return;
  console.error(error);
}

/**
 * @param {string} token 
 * @param {CdAction} cdAction 
 * @param {number} cdAction.cdaId
 * @param {string|null} extra 
 * @returns {Promise<{success: boolean, cdlId: number, error: String}>}
 */
export const logCdAction = async (token: string|undefined, cdAction: CdAction, extra?: string|undefined): Promise<{success: boolean, cdlId: number, error: string}> => {
  const res = await fetch(DashAPIRoute.LOG_CD_ACTION.path, {
    method: 'POST',
    body: JSON.stringify({
      token,
      cdaId: cdAction,
      extra: extra,
    }),
  });
  const { success, cdlId, error } = await res.json();
  return { success, cdlId, error }
}

/**
 * @param {string} ISO1 
 * @param {string} ISO2 
 * @param {DurationUnit} unit
 * @returns 
 */
export const diff = (ISO1: string, ISO2: string, unit: DurationUnit, opts: DiffOptions={}): Duration => {
  const t1 = DateTime.fromISO(ISO1);
  const t2 = DateTime.fromISO(ISO2);
  const result = t1.diff(t2, unit, opts);
  return result;
}

/**
 * @param {String} startISO 
 * @param {String} endISO 
 * @param {DurationUnit} unit 
 * @param {String} zone 
 * @param {Object} opts 
 * @returns {Number}
 */
export const timeElapsed = (startISO: string, endISO: string, unit: keyof DurationObjectUnits, opts: DiffOptions={}): number | undefined => {
  return diff(endISO, startISO, unit, opts).toObject()[unit];
}

export const isInRangeInclusive = (x: any, a: any, b: any) => x >= a && x <= b;

export interface TAppError {
  code: number;
  message: string;
  isOperational: boolean;
}

export class AppError extends Error implements TAppError {
  readonly code: number;
  readonly message: string;
  readonly isOperational: boolean;
  constructor(code: number,message: string,isOperational=true) {
    super(message)
    this.message = message;
    this.code = code;
    this.isOperational = isOperational;
  }
}

export function createAppError({code,message,isOperational=true}: TAppError) {
  return new AppError(code,message,isOperational);
}

export const asciiOnlyString = (string: string): string => string.replace(/[^\x00-\x7F]/g, "");

export function cleanFilenameString(s: string): string {
  return asciiOnlyString(s)
    .trim()
    .replace(/\s+/g, '_') // spaces
    .replace(/[/\\?%*:|"<>]/g, ''); // illegal characters for filenames
}


export const emailFormattedString = (email: string): string => asciiOnlyString(email).replace(/ /g, "").toLowerCase();

export const capitalize = (string: string): string => `${string.charAt(0).toUpperCase()}${string.slice(1,string.length)}`;


export const isNullish = (object: any): boolean => object === null || object === undefined;

/**
 * Check if ISO string is a pure date, ie has no hours, minutes, etc
 */
export const isISODateWithoutTime = (isoTime: string): boolean => {
  const time = DateTime.fromISO(isoTime);
  if (!time.isValid) return false;
  return time.toISO() === DateTime.fromISO(time.toISODate()).toISO();
}

/**
 * Scroll vertically to the location of HTML element with the provided ref, plus an optional offset in pixels
 */
export const scrollToElement = (ref: React.RefObject<HTMLElement>,offset=0): void => {
  if (!ref.current) return;
  return window.scrollTo(0,ref.current.offsetTop + offset);
}

export const getFileExtension = (filename: string): string | undefined => {
  const parts = filename.split('.').reverse();
  if (parts.length === 1) return undefined;
  parts.pop();
  parts.reverse();
  return parts.join(".");
}

/**
 * Dispatches a given providers logo if it has own, and resets to default logo on derender
 */
export const tempSetProviderLogoEffect = (dispatch: React.Dispatch<LoginContextAction>,provider: Provider|undefined): React.EffectCallback => () => {
  if (!provider?.logo) return;
  provider.fetchLogoImage().then(dataUrl => dispatch(setProviderLogo(dataUrl)));
  return () => dispatch(setProviderLogo(foodmarbleLogo));
}

export const verifyLoginToken = async (token: string): Promise<{success: boolean, staff: any, provider: any, patients: any[], support: any, preferences: any}> => {
  return await authFetch(token,DashAPIRoute.VERIFY_TOKEN.path, {
    method: "POST",
  }).then((data) => data.json());
};

export const downloadDocument = async (filename: string, document: JSX.Element) => {
  devLog(`Downloading "${filename}"`);
  const blob = await pdf((document)).toBlob();
  await saveAs(blob, filename);
}

/**
 * Run fetch with a login token applie to the auth header
 */
export const authFetch = async (token: string, input: RequestInfo, init?: RequestInit|undefined) => {
  const headersInit = init?.headers ?? {};
  const headers = new Headers(headersInit);
  headers.append("Authorization",token);
  const method = init?.method ?? "GET";
  devLog(`${method} ${input}`);
  return fetch(input,init ? { ...init, headers, } : { headers });
}

export type HostEnv = "clinical" | "clinical-test" | "localhost";

export const hostEnv = (): HostEnv => {
  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENV === "prod") return "clinical";
    return "clinical-test";
  }
  return "localhost";
}

export const hostUrl = (): URL => {
  switch (hostEnv()) {
    case "clinical":
      return new URL("https://clinical.foodmarble.com");
    case "clinical-test":
      return new URL("https://clinical-test.foodmarble.com");
    case "localhost": {
      return new URL("http://127.0.0.1:3000");
    }
  }
}

export const invalidateLoginToken = async (token: string): Promise<{success: boolean}> => {
  const res = await authFetch(token,"/api/logout",{ method: "POST" });
  const { success, error }: { success: true, error: undefined } | { success: false, error: string }= await res.json();
  if (error) throw new AppError(res.status,error);
  return { success };
}


type Time = DateTime|string|number;
type TimeOpts<T extends Time> = T extends string 
  ? DateTimeOptions 
  : T extends number 
    ? DateTimeJSOptions 
    : undefined;

/**
 * @param time DateTime, time ISO string, or milliseconds
 * @param opts 
 * @returns 
 */
export function parseTime<T extends Time>(time: T, opts?: TimeOpts<T>): DateTime {
  if (typeof(time) === "string") return DateTime.fromISO(time,opts);
  if (typeof(time) === "number") return DateTime.fromMillis(time,opts);
  return time;
}

/**
 * @param time DateTime, time ISO string, or milliseconds
 * @param opts 
 * @returns 
 */
export function dateStr<T extends Time>(time: T, opts?: TimeOpts<T>) {
  return parseTime(time,opts).toFormat("dd-MMM-yyyy");
}

export function dateFormat(provider: Provider|undefined): DateFormat {
  if (!provider || provider.country === "US") return {
    date: "MM-dd-yyyy",
    datetime: "MM-dd-yyyy HH:mm",
  }
  return {
    date: "dd-MMM-yyyy",
    datetime: "dd-MMM-yyyy HH:mm",
  }
}

interface DateFormat {
  date: string;
  datetime: string;
}