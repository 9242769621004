import useSales from "../useSales"
import s from "../Sales.module.scss";
import PageTitle from "../../../components/PageTitle/PageTitle";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import SalesOverviewTable from "./SalesOverviewTable";
import SalesSummaryTable from "./SalesSummaryTable";
import DashLayout from "../../../layouts/dash/DashLayout";

export default function SalesOverview() {
  const { sales, error } = useSales();

  return (
     <DashLayout className={s.salesLayout}>
      <div className={s.top}>
        <div className={s.desc}>
          <PageTitle>Detailed View</PageTitle>
          <p>
            This page shows your monthly sales history and earnings.
            Total sales and earnings are displayed to the right.
            To view a breakdown of individual sales, click the "detailed view" to the left. 
          </p>
        </div>
        <div className={s.summaryTableContainer}>
          { sales && <SalesSummaryTable sales={sales} /> }
        </div>
      </div>
      <div className={s.tableContainer}>
        { !sales && <LoadingSpinner />}
        { sales && <SalesOverviewTable sales={sales} /> }
      </div>
    </DashLayout>
  );
}