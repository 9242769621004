import { Text } from "@react-pdf/renderer";
import { s } from "../arrears-invoice-style";
import { Tfoot, Tr } from "./markdown";
import useCurrency from "../../../../contexts/CurrencyContext/useCurrency";

export const TotalFooter = ({ label, val }: { label: string; val: number }) => {
  return (
    <Tfoot>
      <Tr>
        <Text style={{ width: "80%", textAlign: "right", fontWeight: 600 }}>{label}</Text>
        <Text style={s.ptTable.total}>{useCurrency(val)}</Text>
      </Tr>
    </Tfoot>
  );
};
