import React from "react";
import { FieldPathValue, Path, RegisterOptions, UseFormReturn, FieldValues } from "react-hook-form";
import { CountryDropdown, CountryDropdownProps, RegionDropdown, RegionDropdownProps } from "react-country-region-selector";
import { Controller } from "react-hook-form";
import PhoneInput, { DefaultInputComponentProps as PhoneInputProps, FeatureProps, isPossiblePhoneNumber } from "react-phone-number-input";

export type UseRegisterRules<TFieldValues extends FieldValues> = Omit<RegisterOptions<TFieldValues, Path<TFieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;

export type ControlledInputProps<TFieldValues extends FieldValues,InputProps> = Omit<Partial<InputProps>,"form"|"name"|"rules"|"shouldUnregister"|"defaultValue"> & {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
  rules?: UseRegisterRules<TFieldValues>;
  shouldUnregister?: boolean;
  defaultValue?: FieldPathValue<TFieldValues, Path<TFieldValues>>;
}

export type ControlledCountryDropdownProps<TFieldValues extends FieldValues> = ControlledInputProps<TFieldValues, CountryDropdownProps>;

export function ControlledCountryDropdown<TFieldValues extends FieldValues>(props: ControlledCountryDropdownProps<TFieldValues>) {
  const { form, name, rules, shouldUnregister, defaultValue, ...countryDropdownProps } = props;
  const rest = { form, name, rules, shouldUnregister, defaultValue };
  const { control } = form;
  return (
    <Controller
      control={control}
      render={(({ field }) => <CountryDropdown {...field} {...countryDropdownProps} value={field.value as string} />)}
      {...rest}
    />
  );
}

export type ControlledRegionDropdownProps<TFieldValues extends FieldValues> = ControlledInputProps<TFieldValues, RegionDropdownProps>;

export function ControlledRegionDropdown<TFieldValues extends FieldValues>(props: ControlledRegionDropdownProps<TFieldValues>) {
  const { form, name, rules, shouldUnregister, defaultValue, ...regionDropdownProps } = props;
  const rest = { form, name, rules, shouldUnregister, defaultValue };
  const { control } = form;
  return (
    <Controller
      control={control}
      render={({ field }) => (
        <RegionDropdown
          {...field}
          {...regionDropdownProps}
          value={field.value as string}
          country={regionDropdownProps.country || ""} />
      )}
      {...rest} 
    />
  );
}

export type ControlledPhoneInputProps<TFieldValues extends FieldValues> = ControlledInputProps<TFieldValues, FeatureProps<PhoneInputProps>>;

export function ControlledPhoneInput<TFieldValues extends FieldValues>(props: ControlledPhoneInputProps<TFieldValues>) {
  const { form, name, rules, shouldUnregister, defaultValue, ...phoneInputProps } = props;
  const rest = { form, name, rules, shouldUnregister, defaultValue };
  const { control } = form;
  return (
    <Controller
      control={control}
      render={({ field }) => <PhoneInput {...field} {...phoneInputProps} value={field.value as string} />}
      {...rest} 
    />
  );
}
