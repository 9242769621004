import { withRouter } from "react-router-dom";
import s from "./Header.module.scss";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { Provider } from "../../utils/data-classes/Provider";
import LogoutButton from "../buttons/LogoutButton/LogoutButton";
import React, { useContext } from "react";
import { Staff } from "../../utils/data-classes/Staff";
import I18NextLanguageSelect from "../menus/LanguageSelect/LanguageSelect";
import { languages } from "../../utils/data-classes/Language";

/**
 * @param {Object} props
 * @param {import("react-router-dom").RouteComponentProps["history"]} props.history
 * Dashboard Header
 *  - Renders provider logo or Foodmarble logo if specific provider not provided
 *  - Renders log-out button if logged in
 *  - Renders dash admin panel button if user is a dash admin
 */
const Header = ({ history }) => {
  const { state: { staff, provider, loginSuccess } } = useContext(LoginContext);

  const _languages = [
    languages["en"],
    languages["es"]
  ];

  return (
    <header className={s.header}>
      <div>
        <span>{staff?.permissions.dashAdmin && <DashAdminButton history={history} />}</span>
        <span><ProviderLogo history={history} provider={provider ?? Provider.DEFAULT} /></span>
        <span>
          {staff && <LogoutBox history={history} staff={staff} />}
          {hasLanguageSelect(loginSuccess,history) && <div className={s.languageSelect}><I18NextLanguageSelect languages={_languages}/></div>}
        </span>
      </div>
    </header>
  );
};
/**
 * @param {boolean} loginSuccess
 * @param {import("react-router-dom").RouteComponentProps["history"]} history
 */
const hasLanguageSelect = (loginSuccess,history) => {
  if (loginSuccess) return false;
  if (history.location.pathname.includes("register")) return true;
  if (history.location.pathname.includes("help/getting-started")) return true;
  return false;
}

const DashAdminButton = ({history}) => (
  <div className={s.dashAdminButton}>
    <button onClick={() => history.push('/dash-admin')}>
      Dashboard Admin Panel
    </button>
  </div>
);

/**
 * @param {Object} props 
 * @param {import("react-router-dom").RouteComponentProps["history"]} props.history 
 * @param {Provider} props.provider
 * @returns 
 */
const ProviderLogo = ({history}) => {
  const { state: { providerLogo } } = useContext(LoginContext); 

  if (providerLogo === undefined) return <></>

  return <img 
    className={s.logo} 
    src={providerLogo}
    alt="provider logo" 
    onClick={() => history.push("/")}
  />;
}

/**
 * @param {Object} param0 
 * @param {Staff} param0.staff
 */
const LogoutBox = ({history,staff}) => (
  <div className={s.logoutBox}>
    <p>Hi, {staff.displayName}</p>
    <LogoutButton history={history} />
  </div>
)

export default withRouter(Header);
