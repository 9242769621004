import { useFormContext } from "react-hook-form";
import IFTASelect from "../../../../register/IFTAElements/IFTASelect/IFTASelect";
import { useContext } from "react";
import { RequestOrderContext, RequestOrderFormFieldValuesBase } from "../RequestOrderContext";

export default function StaffSelect() {
  const { register } = useFormContext<RequestOrderFormFieldValuesBase>();
  const { state } = useContext(RequestOrderContext);
  const staffs = state.staffs.sort((a, b) => (a.surname < b.surname ? -1 : 1));
  return (
    <IFTASelect
      id="select-staff"
      label="Staff"
      defaultValue=""
      disabled={!staffs.length}
      reg={register("stfid", {
        valueAsNumber: true,
      })}
    >
      <option value="">Select Staff</option>
      {staffs.map((staff, i) => (
        <option key={i} value={staff.stfid}>
          {staff.surname}, {staff.name}
        </option>
      ))}
    </IFTASelect>
  );
}
