import React, { useContext, useEffect } from "react";
import s from "./AddPatient.module.scss";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { AddPatientContext } from "../../../contexts/AddPatientContext/AddPatientContext";
import { ChallengePromptContext } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptContext";
import { ChallengePromptForm, ChallengePromptFormFieldValues } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptForm";

export const SelectChallenge = () => {
  const { state: { patient }, dispatch: addPatientDispatch } = useContext(AddPatientContext);
  const { state: { loginToken } } = useContext(LoginContext);
  const { state: { patient: patientToPrompt, success, error }, dispatch: promptDispatch } = useContext(ChallengePromptContext);

  const submitAction = async (data: ChallengePromptFormFieldValues): Promise<void> => {
    promptDispatch({type: "DO_ACTION", payload: {
      action: "CREATE",
      formData: data,
    }});
  };

  useEffect(() => {
    if (!patient) return;
    promptDispatch({ type: "INIT_CHALLENGE_FORM", payload: {
      action: "CREATE",
      patient,
      requestedChallenge: undefined,
    } });
  }, [patient]);

  useEffect(() => {
    if (!success) return;
    addPatientDispatch({type: "SET_STAGE", payload: "SUCCESS"});
  },[success]);

  useEffect(() => {
    if (!error) return;
    addPatientDispatch({type: "SET_ERROR", payload: error });
  },[error]);

  return (
    <div>
      {patientToPrompt && loginToken && <ChallengePromptForm submitAction={submitAction} />}
    </div>
  );
};

export const SelectChallengePrompt = () => {
  const { dispatch } = useContext(AddPatientContext);
  const next = (action: "yes" | "no") => dispatch({
    type: "SET_STAGE",
    payload: action === "yes" ? "SELECT_CHALLENGE" : "SUCCESS",
  });
  return (
    <>
      <h1>Prompt a Challenge</h1>
      <p className={s.orderPrompt}>Would you like your patient to be prompted to perform a challenge after signing into the app?</p>
      <div className={s.btnGroup}>
        <button className={s.btnSecondary} onClick={() => next("no")}>No</button>
        <button className={s.btnPrimary} onClick={() => next("yes")}>Yes</button>
      </div>
    </>
  );
};
