import React, { useContext } from "react";
import { OrderItem } from "../../../../../server/database/read";
import { Currency } from "../../../contexts/OrderContext/OrderContext";
import { Sku } from "../../../hooks/useSkus";
import DashLayout from "../../../layouts/dash/DashLayout";
import { OrderHistoryContext, OrderHistoryContextProvider } from "../../../contexts/OrderHistoryContext";
import { OrderHistoryItem } from "../../../hooks/useOrderHistory";
import s from "./OrderHistory.module.scss";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { DateTime } from "luxon";
import { useDateFormat } from "../../../hooks/useDateFormat";

export default function OrderHistory() {
  return (
    <OrderHistoryContextProvider>
      <DashLayout className={s.orderHistoryLayout}>
        <OrderHistoryPage />
        <ErrorPage />
      </DashLayout>
    </OrderHistoryContextProvider>
  )
}

const OrderHistoryPage = (): JSX.Element => {
  const { state: { error } } = useContext(OrderHistoryContext);

  if (error) return <></>;

  return (
    <div className={s.orderHistory}>
      <div className={s.desc}>
        <PageTitle>Order History</PageTitle>
        <div>
          <p></p>
          {/* <p>This page shows your order histroy. To get individual or combined invoices select the relevant tick boxes and hit the download invoice button on the right.</p> */}
          <div>
            {/* <InvoiceDownloadButton /> */}
          </div>
        </div>
      </div>
      <OrderHistoryTable />
    </div>
  )
}

const ErrorPage = (): JSX.Element => {
  const { state: { error } } = useContext(OrderHistoryContext);

  if (!error) return <></>;

  return (
    <div>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
    </div>
  )
}

const OrderHistoryTable = () => {
  const { state: { history } } = useContext(OrderHistoryContext);
  return (
    <div className={s.orderHistoryTable}>
      <OrderHistoryHeader />
      { history?.map((item,i) => <OrderHistoryOrderRows key={i} historyItem={item} />)}
    </div>
  )
}

const OrderHistoryHeader = () => (
  <div className={s.orderHistoryHeader}>
    <span className={s.col}><InvoiceCheckboxAll /></span>
    <span className={s.col}>Date</span>
    <span className={s.col}>Order Contents</span>
    <span className={s.col}>Order Status</span>
    <span className={s.col}>Quantity</span>
    <span className={s.col}>Cost</span>
  </div>
)

const OrderHistoryOrderRows = ({historyItem}: {historyItem: OrderHistoryItem}) => {
  const { dispatch } = useContext(OrderHistoryContext);
  const { itemsAndSkus } = historyItem
  const subItemsAndSkus = itemsAndSkus.slice(1,itemsAndSkus.length);

  if (historyItem.itemsAndSkus.length === 0) return <></>;

  return (
    <div className={s.order} onClick={() => dispatch({type: "TOGGLE_ORDER", payload: historyItem})}>
      <OrderHistoryFirstRow item={historyItem}/>
      <>{subItemsAndSkus.map((item,i) => <OrderHistorySubRow key={i} item={item.orderItem} sku={item.sku} currency={historyItem.currency}/>)}</>
    </div>
  )
}

const OrderHistoryFirstRow = ({item}: {item: OrderHistoryItem}) => {
  const { state: { selected } } = useContext(OrderHistoryContext);
  const { orderItem: orderItem, sku } = item.itemsAndSkus[0];

  return (
    <div className={s.orderRow}>
      <span className={s.col}><input type="checkbox" onChange={() => {}} checked={!!selected[item.order.orid]} /></span>
      <span className={s.col}>{DateTime.fromMillis(item.order.createdOnMs,{zone: 'utc'}).toFormat(useDateFormat("date"))}</span>
      <span className={s.col}>{sku.shortLabel}</span>
      <span className={s.col}>{item.statusLogs[0]?.label ?? "Unknown"}</span>
      <span className={s.col}>{orderItem.quantity}</span>
      <span className={s.col}>{costString(orderItem,item.currency)}</span>
    </div>
  );
}

const InvoiceCheckboxAll = () => {
  const { state: { history, selected }, dispatch } = useContext(OrderHistoryContext);
  return <input
    type="checkbox"
    checked={!!history && history.length === Object.keys(selected).length}
    onChange={e => dispatch({type: e.target.checked ? "SELECT_ALL_ORDERS" : "DESELECT_ALL_ORDERS" })}
  />
}

const OrderHistorySubRow = ({item,sku,currency}: {item: OrderItem, sku: Sku, currency: Currency|undefined}) => (
  <div className={s.orderRow}>
    <span className={s.col}></span>
    <span className={s.col}></span>
    <span className={s.col}>{sku.shortLabel}</span>
    <span className={s.col}></span>
    <span className={s.col}>{item.quantity}</span>
    <span className={s.col}>{costString(item,currency)}</span>
  </div>
);

const costString = (item: OrderItem,currency: Currency|undefined) => {
  if (!currency) return "Not Found";
  return currency.symbol + (item.unitPriceNet * item.quantity).toFixed(2);
}