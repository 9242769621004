import { useContext, useEffect, useState } from "react";
import { AppError, authFetch, devLog } from "../utils/utils";
import { ClinicianRequestType } from "../utils/data-classes/ClinicianRequestType";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import useErrorState from "./useErrorState";

interface ClinicianRequestTypeBackend {
  crtId: number;
  addedOnMs: number | null;
  crType: number | null;
  label: string | null;
  description: string | null;
}

export interface UseClinicianRequestTypesReturn {
  clinicianRequestTypes: ClinicianRequestType[] | undefined, 
  error: AppError | undefined,
}

export default function useClinicianRequestTypes(): UseClinicianRequestTypesReturn {
  const [clinicianRequestTypes, setClinicianRequestTypes] = useState<ClinicianRequestType[]>();
  const [error, setError] = useErrorState();

  const { state: {loginToken, staff } } = useContext(LoginContext);

  useEffect(() => {
    if (clinicianRequestTypes) return;
    if (!loginToken || !staff) return;
    fetchClinicianRequestTypes(loginToken)
      .then(setClinicianRequestTypes)
      .catch(setError);
  }, [clinicianRequestTypes]);
  
  return { clinicianRequestTypes, error };
}

type FetchClinicianRequestTypesRawData = { success: true, clinicianRequestTypes: ClinicianRequestTypeBackend[] } | { success: false, error: string };

const fetchClinicianRequestTypes = async (loginToken: string): Promise<ClinicianRequestType[]> => {
  devLog("useClinicianRequestTypes: Fetching clinician request types");

  const res = await authFetch(loginToken, "/api/clinician-request-types", {method: "GET"});
  const data: FetchClinicianRequestTypesRawData = await res.json();
  if (!data.success) throw new AppError(res.status,data.error);
  return data.clinicianRequestTypes.map(type => createClinicianRequestType(type));
}

const createClinicianRequestType = (type: ClinicianRequestTypeBackend): ClinicianRequestType => new ClinicianRequestType(
  type.crtId,
  type.addedOnMs ?? undefined,
  type.crType ?? undefined,
  type.label ?? undefined,
  type.description ?? undefined,
);