import { Page, Text, View } from "@react-pdf/renderer";
import { Patient } from "../../../utils/data-classes/Patient";
import { Provider } from "../../../utils/data-classes/Provider";
import { Staff } from "../../../utils/data-classes/Staff";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import Symptom from "../../../utils/data-classes/Symptom";
import ChartHandler from "../../../pages/digest/ChartHandler";
import { PdfLogoSrc } from "../../ProviderLogo";
import { ReferringClinician } from "../../../hooks/usePatientPreferences";
import { shared, s } from "./challenge-report-styles";
import ChallengeDataTable from "./components/ChallengeDataTable";
import { SummaryTable } from "./components/ChallengeReportSummaryTable";
import { HeaderPageOne, HeaderPageTwo } from "./components/ChallengeReportHeaders";
import { ChallengeMetaData, PatientDetailsBox, StaffDetailsBox } from "./components/ChallengeMetaData";
import { References } from "./components/ChallengeReportReferences";
import { TestCriteriaBox } from "./components/ChallengeReportTestCriteria";
import ChartBox from "./components/ChallengeReportChartBox";
import NotesBox from "./components/ChallengeReportNotesBox";
import { ChallengeFollowonContentRecord } from "../../../hooks/useChallengeFollowonContent";
import { ChallengeFollowonBox } from "./components/ChallengeFollowon/ChallengeFollowon";

export function ChallengeReport(props: ChallengeReportProps): JSX.Element {
  return (
    <>
      <PageOne {...props} />
      <PageTwo {...props} />
    </>
  );
}

function ChallengeReportPage({ children }: { children: React.ReactNode }) {
  return (
    <Page style={shared.body}>
      {children}
      <PageNumber />
    </Page>
  );
}

function PageNumber() {
  return (
    <View style={s.pageNumber.main}>
      <Text
        style={s.pageNumber.content}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
      />
    </View>
  );
}

function PageOne(props: ChallengeReportProps): JSX.Element {
  const { challenge, symptoms, chartHandler } = props;
  return (
    <ChallengeReportPage>
      <HeaderPageOne {...props} />
      <ChallengeMetaData {...props} />
      <View style={s.detailsBoxes.main}>
        <PatientDetailsBox {...props} />
        <StaffDetailsBox {...props} />
      </View>
      <SummaryTable challenge={challenge} />
      {chartHandler && <ChartBox chartHandler={chartHandler} />}
      <ChallengeDataTable challenge={challenge} symptoms={symptoms} />
    </ChallengeReportPage>
  );
}

function PageTwo(props: ChallengeReportProps) {
  const { challenge, followonContent } = props;
  return (
    <ChallengeReportPage>
      <HeaderPageTwo {...props} />
      <TestCriteriaBox challenge={challenge} />
      <ChallengeFollowonBox challenge={challenge} followonContent={followonContent} />
      <NotesBox challenge={challenge} />
      <References challenge={challenge} />
    </ChallengeReportPage>
  );
}

export interface ChallengeReportProps {
  provider: Provider;
  patient: Patient;
  staff?: Staff;
  challenge: Challenge;
  symptoms: Symptom[];
  chartHandler: ChartHandler | undefined;
  logoSrc: PdfLogoSrc;
  referringClinician: ReferringClinician | undefined;
  followonContent: ChallengeFollowonContentRecord;
}
