import { Document } from "@react-pdf/renderer";
import { SummaryPage } from "./components/SummaryPage";
import { DirectToPatientPages } from "./components/DirectToPatientPages";
import { BulkOrdersPages } from "./components/BulkOrdersPages";
import CurrencyContext from "../../../contexts/CurrencyContext/CurrencyContext";
import ArrearsInvoiceContext from "./ArrearsInvoiceContext";
import { ArrearsInvoice } from "../../../utils/data-classes/ArrearsInvoice";
import { Sku } from "../../../hooks/useSkus";

export default function ArrearsInvoiceDocument(props: ArrearsInvoiceProps) {
  return (
    <Document>
      <ArrearsInvoiceContext.Provider value={props.invoice}>
        <CurrencyContext.Provider value={props.invoice.currency}>
          <SummaryPage />
          <DirectToPatientPages />
          <BulkOrdersPages />
        </CurrencyContext.Provider>
      </ArrearsInvoiceContext.Provider>
    </Document>
  );
}

interface ArrearsInvoiceProps {
  invoice: ArrearsInvoice;
  skus: {
    [skuId: number]: Sku;
  };
}

