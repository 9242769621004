import s from "./RequestOrder.module.scss";
import { useContext } from "react";
import { OrderItem, RequestOrderContext } from "./RequestOrderContext";
import { fmtCurrency } from "../order-request-util";

export default function OrderItemsBasket({ canSubmit, processing }: { canSubmit: boolean; processing: boolean }) {
  return (
    <div className={s.basket}>
      <h4>Order Items</h4>
      <BasketTable />
      <button className={s.btnPrimary} disabled={!canSubmit} type="submit">
        {processing ? "Processing..." : "Submit"}
      </button>
    </div>
  );
}

function BasketTable() {
  const { state } = useContext(RequestOrderContext);
  const { order } = state;
  return (
    <table>
      <Header />
      <tbody>
        {order?.orderItems.map((item, i) => (
          <Item key={i} item={item} />
        ))}
        <br />
      </tbody>
      <Footer />
    </table>
  );
}

function Header() {
  return (
    <thead>
      <tr>
        <th>Sku</th>
        <th colSpan={2}>Unit Price</th>
        <th>Quantity</th>
        <th colSpan={2}>Total</th>
        <th></th>
      </tr>
    </thead>
  );
}

function Footer() {
  const { order } = useContext(RequestOrderContext).state;
  const total = order?.orderItems.reduce((acc, cur) => acc + cur.unitPriceNet * cur.quantity, 0);
  return (
    <tfoot>
      <tr>
        <td>Total</td>
        <td></td>
        <td></td>
        <td></td>
        <td>{fmtCurrency(total)}</td>
        <td>{order?.currency ?? ""}</td>
        <td></td>
      </tr>
    </tfoot>
  );
}

function Item({ item }: { item: OrderItem }): JSX.Element {
  const { state } = useContext(RequestOrderContext);
  const { order } = state;

  return (
    <tr>
      <td>{item.sku.shortLabel}</td>
      <td>{fmtCurrency(item.unitPriceNet)}</td>
      <td>{order?.currency}</td>
      <td>{item.quantity}</td>
      <td>{fmtCurrency(item.unitPriceNet * item.quantity)}</td>
      <td>{order?.currency}</td>
      <td>
        <RemoveFromBasketButton item={item} />
      </td>
    </tr>
  );
}

function RemoveFromBasketButton({ item }: { item: OrderItem }) {
  const { dispatch } = useContext(RequestOrderContext);
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch({ type: "REMOVE_ITEM", payload: item });
  };
  return (
    <button type="button" className={s.removeFromBasketButton} onClick={onClick} title="Remove item from basket">
      ✖
    </button>
  );
}
