import React from "react";
import { ReactComponent as CopySvg } from "../../../assets/icons/copy.svg";
import s from "./CopyToClipboardButton.module.scss";

type CopyToClipboardButtonProps = Omit<React.HTMLProps<HTMLButtonElement>, "data" | "onClick" | "type" | "children"> & {
  data: string;
};

export default function CopyToClipboardButton({ data, ...rest }: CopyToClipboardButtonProps) {
  const className = rest.className ?? s.copyBtn;
  const onClick = () => navigator.clipboard.writeText(data);
  return (
    <button type="button" className={className} onClick={onClick} {...rest}>
      <CopySvg className={s.copyIcon} />
    </button>
  );
};

const copyToClipboard = (data: string) => navigator.clipboard.writeText(data);

