import { DateTime } from "luxon";
import { PERIOD } from "./DateRangeContext";

export type DateRangeContextAction = 
  | { type: "SET EARLIEST TIME"; payload: { earliestTime: DateTime; }; }
  | { type: "UPDATE BY RANGE"; payload: { range: [DateTime, DateTime]; }; }
  | { type: "UPDATE BY PERIOD"; payload: { period: PERIOD; }; };

export const setEarliestTime = (earliestTime: DateTime): DateRangeContextAction => ({
  type: "SET EARLIEST TIME",
  payload: { earliestTime },
});

export const updateByRange = (range: [DateTime, DateTime]): DateRangeContextAction => ({
  type: "UPDATE BY RANGE",
  payload: { range },
});

export const updateByPeriod = (period: PERIOD): DateRangeContextAction => ({
  type: "UPDATE BY PERIOD",
  payload: { period },
});
