import Gas from "../Gas";
import { Challenge } from "./Challenge";
import ChallengePpmHandler from "./ChallengePpmHandler";

/**
 * Proxy class for operating on a challenge with respect to CH4 ppms
 */

export class MethaneHandler extends ChallengePpmHandler {
  static POSITIVE_VALUE = 10;
  static HIGH_BASELINE = 10;

  constructor(challenge: Challenge) {
    super(challenge, Gas.CH4);
    Object.setPrototypeOf(this, MethaneHandler.prototype);
  }

  /**
   * For hydrogen, rise over threshold is positive it occurs at any time
   * @returns 1 minute more than duration of challenge in minutes
   */
  risePeriodMinutes() {
    const millis = this.challenge.lastTest.testCreatedOnMs - this.challenge.firstTest.challengeCreatedOnMs;
    return 1 + (millis / (1000 * 60));
  }

  positiveCriteria() {
    return this.challenge.isSIBO() ? MethaneHandler.POSITIVE_VALUE : this.rise(MethaneHandler.POSITIVE_VALUE);
  }

  positiveRise() {
    if (this.challenge.isSIBO()) {
      return ((this.maxTest()?.ppm(this.gas) ?? 0) >= this.positiveCriteria()) ? this.maxTest() : null;
    }
    return ((this.maxTest()?.ppm(this.gas) ?? 0) >= this.positiveCriteria()) ? this.maxRiseTest() : null;
  }

  highBaseline() {
    return MethaneHandler.HIGH_BASELINE;
  }

  /**
   * For Methane, relevant result is peak value anywhere during challenge
   */
  result() {
    const peakTest = this.peakTestInPositiveRisePeriod();
    return peakTest.ppm(this.gas);
  }
  
  isBorderline(): boolean {
    return false;
  }
}
