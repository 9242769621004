
export class ProviderShipping {
  readonly shipId : number;
  readonly shippingAddress1 : string;  
  readonly shippingCity : string;
  readonly shippingZip : string;
  readonly shippingState: string;
  readonly shippingCountry: string;
  readonly shippingName : string;
  readonly shippingEmail : string;
  readonly shippingPhone : string;

  constructor(shipId: number,shippingAddress1: string,shippingCity: string,shippingZip: string,shippingState: string, shippingCountry: string, shippingName: string,shippingEmail: string, shippingPhone: string) {
    this.shipId = shipId;
    this.shippingAddress1 = shippingAddress1;
    this.shippingCity = shippingCity;
    this.shippingZip = shippingZip;
    this.shippingState = shippingState;
    this.shippingCountry = shippingCountry;
    this.shippingName = shippingName;
    this.shippingEmail = shippingEmail;
    this.shippingPhone = shippingPhone;
  }

}

export const createProviderShipping = ({ shipId,shippingAddress1,shippingCity,shippingZip,shippingState, shippingCountry, shippingName,shippingEmail,shippingPhone}: any) => {
    return new ProviderShipping(shipId,shippingAddress1,shippingCity,shippingZip,shippingState,shippingCountry,shippingName,shippingEmail,shippingPhone);
  }