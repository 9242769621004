/*
 * Custom hook to retrieve all relevant user log everytime query range challenges
 * Receives loginToken (for verification), ptid and a query range (an array with start and end dates) as params
 * Returns a digest object, that contains the following: {
 *  challenges: { object with chalTime keys and corresponding chal arrays: each array contains the chal tests }
 *  meals: [ array with meal objects. each meal has "foods" key containing ingredients. each food has "fodmaps" key containing fodmap info ]
 *  ppms: [ array with ppm values for each breath test ]
 *  symptoms: [ array with symptom logs ]
 *  sleeps: [ array with sleep logs ]
 *  stress: [ array with stress logs ]
 * }
 */

import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Challenge, createChallenges } from "../utils/data-classes/challenge/Challenge";
import Meal, { createMeal } from "../utils/data-classes/Meal";
import MedLog, { createMedLog } from "../utils/data-classes/MedLog";
import Ppm, { createPpm } from "../utils/data-classes/Ppm";import Sleep, { createSleep } from "../utils/data-classes/Sleep";
import Stress, { createStress } from "../utils/data-classes/Stress";
import Symptom, { createSymptoms } from "../utils/data-classes/Symptom";
import { devLog, AppError, authFetch, parseURLSearchParams } from "../utils/utils";
import { DigestContentFlag } from "../pages/digest/DigestLogic";
import { DateRange, DateRangeContext } from "../contexts/DateRangeContext/DateRangeContext";
import useErrorState from "./useErrorState";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { useHistory, useParams } from "react-router-dom";

const useDigest = (initialState: Partial<Digest>, method: UseDigestMethod, { content, range }: UseDigestProps): UseDigestReturn => {
  const [digest, setDigest] = useState<Digest>(createDigest({...initialState}));
  const [error, setError] = useErrorState();

  const [startDT, endDT] = range;
  const ptid = usePtid();
  const loginToken = useContext(LoginContext).state.loginToken;
  const patientToken = usePatientToken();

  useEffect(() => {
    if (content.length === 0) return;
    if (getFetchMethod(method) === "LOGIN" && (!loginToken || !ptid)) return;
    if (getFetchMethod(method) === "PATIENT_TOKEN" && !patientToken) return;
    if (!startDT || !endDT) return;

    setDigest(createDigest({}));

    // Create a signal controller to be able to cancel fetch request if needed
    const abortController = new AbortController();

    devLog(`useDigest: Fetching for ${ptid}`);
    // Fetch the digest data and update the state upon success

    fetchDigest(method,loginToken,patientToken,ptid,startDT,endDT,content,abortController.signal)
      .then(digest => {
        devLog(`DIGEST ${ptid}: `, { ...digest });
        setDigest(digest);
      })
      .catch(err => {
        if (err.name === "AbortError") return;
        setError(err);
      });

    // Cancel fetch request if the component is unmounted before fetch completes
    return () => abortController.abort();
  }, [ptid, startDT, endDT, loginToken]);

  return { 
    digest, 
    error 
  };
};

type FetchMethod = "LOGIN" | "PATIENT_TOKEN";

function getFetchMethod(method: UseDigestMethod): FetchMethod {
  if (method === "LOGIN") return "LOGIN";
  return "PATIENT_TOKEN";
}

type PatientService = "XEALTH_BREATH_TESTING" | "XEALTH_RPM";

function getPatientService(method: UseDigestMethod): PatientService {
  switch (method) {
    case "CHALLENGES_TOKEN":
      return "XEALTH_BREATH_TESTING";
    case "RPM_TOKEN":
      return "XEALTH_RPM";
    default:
      throw new Error("Invalid digest fetching method for patient service");
  }
}

const fetchDigest = async (method: UseDigestMethod, loginToken: string|undefined, patientToken: string | undefined, ptid: number | undefined, startDT: DateTime, endDT: DateTime, content: DigestContentFlag[], signal: AbortSignal) => {
  switch (getFetchMethod(method)) {
    case "LOGIN":
      if (!loginToken || !ptid) throw new Error("Missing data");
      return await fetchDigestByLogin(loginToken,ptid,startDT,endDT,content,signal);
    case "PATIENT_TOKEN":
      if (!patientToken) throw new Error("Missing data");
      return await fetchDigestByPatientToken(patientToken,startDT,endDT,content,getPatientService(method),signal)
  }
}

const fetchDigestByPatientToken = async (token: string, startDT: DateTime, endDT: DateTime, content: DigestContentFlag[], patientService: PatientService, signal: AbortSignal) => {
  const params = new URLSearchParams({
    patientService,
    startISO: startDT.toISO(),
    endISO: endDT.toISO(),
    content: JSON.stringify(content),
  });
  const url = `/api/digest/${token}?${params.toString()}`;
  const res = await fetch(url,{method: "GET",signal});
  const { digest, error }: any = await res.json();
  if (res.status !== 200) throw new AppError(res.status,error);
  return createNewDigest(digest);
}

const fetchDigestByLogin = async (token: string, ptid: number, startDT: DateTime, endDT: DateTime, content: DigestContentFlag[], signal: AbortSignal) => {
  const params = new URLSearchParams({
    ptid: ptid.toString(),
    startISO: startDT.toISO(),
    endISO: endDT.toISO(),
    content: JSON.stringify(content),
  });
  const url = `/api/digest?${params.toString()}`;
  const res = await authFetch(token,url, {
    method: "GET",
    signal,
  });
  const { digest, error }: any = await res.json();
  if (res.status !== 200) throw new AppError(res.status,error);
  return createNewDigest(digest);
}

export const createDigest = ({ppms,symptoms,challenges,meds,sleeps,stress,meals}: Partial<Digest>): Digest => {
  return {ppms,symptoms,challenges,meds,sleeps,stress,meals};
}

/**
 * Create digest object populated with rich objects generated from backend data
 */
const createNewDigest = ({ppms,symptoms,challenges,meds,sleeps,stress,meals}: {ppms: any[],symptoms: any[],challenges: any[],meds: any[],sleeps: any[],stress: any[],meals: any[]}) => {
  return {
    meals: meals.map(meal => createMeal(meal)),
    ppms: ppms.map(ppm => createPpm(ppm)),
    symptoms: createSymptoms(symptoms),
    challenges: createChallenges(challenges),
    meds: meds.map(med => createMedLog(med)),
    sleeps: sleeps.map(sleep => createSleep(sleep)),
    stress: stress.map(s => createStress(s)),
  }
}

function usePtid() {
  const history = useHistory();
  const ptid = parseInt(parseURLSearchParams(history.location.search).ptid);
  return ptid;
}

function usePatientToken(): string {
  const { token }: { token: string } = useParams();
  return token;
}

export interface Digest {
  ppms: Ppm[]|undefined;
  symptoms: Symptom[]|undefined; 
  challenges: Challenge[]|undefined;
  meds: MedLog[]|undefined;
  meals: Meal[]|undefined;
  sleeps: Sleep[]|undefined;
  stress: Stress[]|undefined;
}

interface UseDigestProps {
  range: DateRange;
  content: DigestContentFlag[];
}

export type UseDigestMethod = "LOGIN" | "CHALLENGES_TOKEN" | "RPM_TOKEN";

export interface UseDigestReturn {
  digest: Digest;
  error: AppError|undefined;
}

export default useDigest;
