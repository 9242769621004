import React, { useContext } from "react";
import s from "./AddPatient.module.scss";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import { AddPatientContext } from "../../../contexts/AddPatientContext/AddPatientContext";
import ClinicianLinkMenu from "../../../components/menus/ClinicianLinkMenu/ClinicianLinkMenu";
import loadingPng from "../../../assets/loading.png";
import { useAddPatientFormContext } from "./add-patient-util/useAddPatientFormContext";

export const CliniciansToLink = () => {
  const clinicians = useContext(AddPatientContext).state.linkedClinicians;
  const form = useAddPatientFormContext();
  const {
    formState: { errors },
  } = form;

  return (
    <>
      <h1>LINK CLINICIANS</h1>
      <p className={s.explanation}>
        As you are an admin user you must select one or more clinicians from your site to link to this patient.
        <br />
        These clinicians will have access to this patients data and will receive email notifications when this patient
        completes challenges.
      </p>
      <div className={s.linkClinicians}>
        {!clinicians && (
          <div>
            <img className={s.loading} src={loadingPng} />
          </div>
        )}
        {clinicians && (
          <>
            <ClinicianLinkMenu
              id="link-clinicians"
              label="Clinicians to link"
              form={form}
              path="stfids"
              clinicians={clinicians}
              rules={{
                required: "One or more clinician to link is required",
                validate: {
                  nonEmpty: (stfids) => {
                    return (
                      (stfids instanceof Array && stfids.length > 0) ||
                      "You must select one or more clinicians to link to this patient"
                    );
                  },
                },
              }}
            />
            <FormErrorMessage errors={errors} name="stfids" />
            <CliniciansToLinkSubmitButton />
          </>
        )}
      </div>
    </>
  );
};

const CliniciansToLinkSubmitButton = () => {
  const { trigger } = useAddPatientFormContext();
  const {
    state: { processing, by },
    dispatch,
  } = useContext(AddPatientContext);
  const isByIdt = by === "identifier";

  const isValid = () => trigger(["stfids"]);

  const onClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isByIdt) return; // Submit form
    event.preventDefault();
    if (!(await isValid())) return;
    dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE_PROMPT" });
  };

  return (
    <button type="submit" className={s.btnPrimary} onClick={onClick}>
      {processing ? "Processing..." : isByIdt ? "Submit" : "Next"}
    </button>
  );
};
