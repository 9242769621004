import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { currencies, Currency, OrderCurrencyCode } from "../contexts/OrderContext/OrderContext";
import { Sku } from "./useSkus";
import { AppError, authFetch } from "../utils/utils";
import useErrorState from "./useErrorState";

interface ItemAndSku {
  sku: Sku,
  orderItem: OrderItem
}

export class OrderHistoryItem {
  readonly order: OrderHistoryOrder;
  readonly statusLogs: OrderHistoryStatusLog[];
  readonly itemsAndSkus: ItemAndSku[];

  constructor({order,statusLogs,itemsAndSkus}: {order: OrderHistoryOrder, statusLogs: OrderHistoryStatusLog[], itemsAndSkus: ItemAndSku[]}) {
    this.order = order;
    this.statusLogs = statusLogs;
    this.itemsAndSkus = itemsAndSkus;
  }

  get currency(): Currency|undefined {
    const { order: { currency: currencyCode } } = this;
    const result = currencies[currencyCode as OrderCurrencyCode];
    return result;
  }
}

export interface OrderItem {
  oiId: number;
  createdOnMs: number;
  orid: number;
  skuId: number;
  quantity: number;
  unitPriceGross: number;
  unitPriceNet: number;
  unitDiscount: number;
  unitTax: number;
}

export interface OrderHistoryOrder {
  orid: number;
  stfid: number;
  ptid: number;
  prid: number;
  blid: number;
  createdOnMs: number;
  shippingAddress1: string;
  shippingCity: string;
  shippingZip: string;
  shippingState: string;
  shippingCountry: string;
  shippingName: string;
  shippingEmail: string;
  shippingPhone: string;
  trackingNo: string;
  isSelfPay: boolean;
  total: number;
  taxTotal: number;
  currency: string;
  stripeMethod: string;
  stripeIntent: string;
}

export interface OrderHistoryOrderItem {
  sku: Sku;
  quantity: number;
  cost: {
    amount: number;
    currency: Currency;
  }
}

export interface OrderHistoryStatusLog {
  orslid: number;
  orderStatus: number;
  updatedOn: Date;
  label: string;
  countAsSale: boolean;
}

export default function useOrderHistoryNew(): { history: OrderHistoryItem[] | undefined, error: AppError | undefined } {
  const { state: { loginToken } } = useContext(LoginContext);
  const [history, setOrderHistory] = useState<OrderHistoryItem[]>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (history || !loginToken) return;
    const abortController = new AbortController();
    fetchOrderHistory(loginToken,abortController.signal)
      .then(res => setOrderHistory(res.filter(item => item.itemsAndSkus.length > 0 && item.order.currency)))
      .catch(setError);
    
    return;
  },[history]);

  return { history, error };
}

const fetchOrderHistory = async (loginToken: string, signal: AbortSignal): Promise<OrderHistoryItem[]> => {
  const res = await authFetch(loginToken,"/api/get-order-history",{signal});
  const { history, error }: { history: any[], error: string } = await res.json();
  if (error) throw new AppError(res.status,error);
  const result: OrderHistoryItem[] = history.map(item => new OrderHistoryItem({
    order: item.order,
    statusLogs: item.statusLogs.sort(compareStatusLogs),
    itemsAndSkus: item.itemsAndSkus,
  }));
  return result;
}

const statusPriority = [6,3,5,4,2,1];

const compareStatusLogs = (a: OrderHistoryStatusLog, b: OrderHistoryStatusLog) => {
  const aPriority = statusPriority.indexOf(a.orderStatus);
  const bPriority = statusPriority.indexOf(b.orderStatus);
  if (aPriority < bPriority) return -1;
  if (aPriority === bPriority) return 0;
  return 1;
}
