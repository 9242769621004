import { createPatient, Patient } from "../../utils/data-classes/Patient";
import { Preferences } from "../../utils/data-classes/Preferences";
import { createProvider, Provider } from "../../utils/data-classes/Provider";
import { createStaff, Staff } from "../../utils/data-classes/Staff";
import { Support } from "../../utils/data-classes/Support";
import { LoginDetails } from "./LoginContext";

/* ACTIONS */

export type LoginContextAction = 
  | { type: "SET_LOGIN_TOKEN", payload: string | undefined, } 
  | { type: "SET_LOGIN_SUCCESS", payload: boolean, } 
  | { type: "LOG_IN", payload: { staff: Staff, provider: Provider, patients: Patient[], support: Support, preferences: Preferences }, } 
  | { type: "LOG_OUT", } 
  | { type: "SET_PROVIDER_LOGO", payload: string | undefined, }
  | { type: "SET_LOGGING_OUT", payload: boolean }
  | { type: "SET_LOGIN_DETAILS", payload: LoginDetails }

export const setLoginToken = (loginToken: string | undefined): LoginContextAction => ({
  type: "SET_LOGIN_TOKEN",
  payload: loginToken,
});

export const setLoginSuccess = (loginSuccess: boolean): LoginContextAction => ({
  type: "SET_LOGIN_SUCCESS",
  payload: loginSuccess,
});

export const logIn = ({ staff, provider, patients, support, preferences }: { staff: any; provider: any; patients: any[]; support: any; preferences: any}): LoginContextAction => ({
  type: "LOG_IN",
  payload: {
    staff: createStaff({ ...staff }),
    provider: createProvider(provider),
    patients: patients.map(patient => createPatient(patient)),
    support,
    preferences: preferences,
  },
});

export const logOut = (): LoginContextAction => ({
  type: "LOG_OUT",
});

export const requestLogout = (): LoginContextAction => ({
  type: "SET_LOGGING_OUT", payload: true,
})

export const abortLogout = (): LoginContextAction => ({
  type: "SET_LOGGING_OUT", payload: false,
})

export const setProviderLogo = (dataUrl: string | undefined): LoginContextAction => {
  return {
    type: "SET_PROVIDER_LOGO",
    payload: dataUrl,
  };
};

export const setLoginDetails = ({email, method}: LoginDetails): LoginContextAction => {
  return { 
    type: "SET_LOGIN_DETAILS",
    payload: {
      email,
      method,
    }
  }
}
