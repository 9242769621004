type IsIndexable<T,K> = undefined extends T ? never 
  : string extends T ? K 
    : number extends T ? K 
      : symbol extends T ? K 
        : never;
type IndexableKeys<T> = { [K in keyof T]-?: IsIndexable<T[K],K> }[keyof T];

export function binByProp<T>(arr: T[], prop: IndexableKeys<T>) {
  return arr.reduce<Record<string|number|symbol,T[]>>((acc,cur) => {
    const val = cur[prop] as unknown as string|number|symbol;
    if (!acc[val]) acc[val] = [];
    acc[val].push(cur);
    return acc;
  },{});
}