import { Text, View } from "@react-pdf/renderer";
import { Challenge } from "../../../../utils/data-classes/challenge/Challenge";
import { shared, s } from "../challenge-report-styles";
import { Strong } from "./ChallengeReportUtil";
import { citation } from "./ChallengeReportReferences";

export function TestCriteriaBox({ challenge }: { challenge: Challenge; }) {
  return (
    <View style={s.testCriteria.main}>
      <Text style={shared.header}>Test Criteria</Text>
      <View style={s.testCriteria.body}>
        {testCriteriaBoxContent(challenge).map((content, i) => <Text key={i} style={s.testCriteria.text}>{content}</Text>)}
      </View>
    </View>
  );
}

function testCriteriaBoxContent(challenge: Challenge) {
  if (challenge.isSIBO()) return [
    <Text>An increase in breath <Strong>hydrogen</Strong> levels of greater than 20 ppm above baseline in the first 90 minutes of the test is suggestive of small intestinal bacterial overgrowth. {citation("rezaie")} Tests that reach positive criteria within 100 minutes are considered borderline.</Text>,
    <Text>Detection of breath <Strong>methane</Strong> levels greater than 10 ppm at any point during a test (including baseline) is considered abnormal and is suggestive of intestinal methanogen overgrowth. {citation("rezaie")}</Text>
  ];
  return [
    <Text>An increase in breath <Strong>hydrogen</Strong> levels of greater than 20 ppm above baseline at any point during a test is suggestive of {challenge.label.toLowerCase()} malabsorption. {citation("rezaie")}</Text>,
    <Text>Detection of breath <Strong>methane</Strong> levels greater than 10 ppm above baseline at any point during a test is suggestive of {challenge.label.toLowerCase()} malabsorption. {citation("hammer")}</Text>
  ];
}
