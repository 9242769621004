import s from "./RequestOrder.module.scss";
import PreloginLayout from "../../../../layouts/prelogin/PreloginLayout";
import { DaLink } from "../../DashAdmin";
import { LoginRoutes } from "../../../../routers/DashRouter";

export default function RequestOrder() {
  return (
    <PreloginLayout>
      <div className={s.dashAdmin}>
        <h1>Order Requests</h1>
        <div className={s.dashAdminNav}>
          <DaLink route={LoginRoutes.ORDER_REQUEST_ARREARS}>Arrears Orders</DaLink>
          <DaLink route={LoginRoutes.ORDER_REQUEST_PREPAID}>Pre-paid Orders</DaLink>
        </div>
      </div>
    </PreloginLayout>
  );
}