import { useParams } from "react-router-dom";
import useChallengeReportData, { ChallengeReportData } from "./useChallengeReportData";
import { ChallengeReportDocument } from "../../../../pdf-generator/templates/ChallengeReportDocument/ChallengeReportDocument";
import { useEffect, useRef, useState } from "react";
import ChartHandler from "../../ChartHandler";
import ChallengeChartOptions from "../ChallengeChartOptions";
import ChalChart from "../ChalChart";
import s from "./DownloadChallengeReportByToken.module.scss";
import PreloginLayout from "../../../../layouts/prelogin/PreloginLayout";
import { downloadDocument } from "../../../../utils/utils";
import LoadingSpinner from "../../../../components/loading-spinner/LoadingSpinner";
import { getChallengeReportFilename } from "../ChalCard";
import { render } from "@testing-library/react";

export default function DownloadChallengeReportByToken() {
  const { token }: { token: string } = useParams();

  const { data, error } = useChallengeReportData(token);

  const pdf = usePDF(data);

  useEffect(() => {
    if (!data || !pdf) return;
    const filename = getChallengeReportFilename(data?.patient, data?.provider, data?.challenge);
    downloadDocument(filename, pdf);
  }, [pdf]);

  const downloading = !pdf && !error;
  const complete = !downloading && !error;

  return (
    <PreloginLayout>
      <div className={s.downloadChalReport}>
        <h3>
          {downloading && "Downloading Challenge Report..."}
          {error && `${error.code}: ${error.message}`}
          {complete && "Download Complete"}
        </h3>
        {downloading && <LoadingSpinner />}
      </div>
    </PreloginLayout>
  );
}

function usePDF(data: ChallengeReportData | undefined) {
  const [chartAnimationComplete, setChartAnimationComplete] = useState(false);
  const [chartHandler, setChartHandler] = useState<ChartHandler>();
  const [result, setResult] = useState<JSX.Element>();

  const canvasRef = useRef(null);

  useEffect(() => {
    render(<ChalChart canvasRef={canvasRef} id={s.canvasWrapper} />);
  }, []);

  useEffect(() => {
    if (!data?.challenge) return;
    setChartHandler(new ChartHandler(ChallengeChartOptions(data.challenge)));
  }, [data?.challenge]);

  useEffect(() => {
    if (!chartHandler || !canvasRef.current) return;
    chartHandler.makeChart(canvasRef.current, setChartAnimationComplete);
  }, [chartHandler]);

  useEffect(() => {
    if (result) return;
    if (!data || !chartHandler || !chartAnimationComplete) return;
    setResult(<ChallengeReportDocument {...data} chartHandler={chartHandler} />);
  }, [data, chartHandler, chartAnimationComplete]);

  return result;
}
