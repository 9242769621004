import React, { useState } from "react";
import { PathValue, FieldPath, FieldValues, Path, UseFormRegisterReturn, UseFormReturn, RegisterOptions } from "react-hook-form";
import _uniqueId from 'lodash/uniqueId';
import s from "./LabelledRadioButton.module.scss";

export type LabelledRadioButtonProps<TFieldValues extends FieldValues, TFieldPath extends Path<TFieldValues>> = {
  form: UseFormReturn<TFieldValues,any>,
  path: TFieldPath,
  options?: RegisterOptions<TFieldValues,TFieldPath>,
} & Omit<React.HTMLProps<HTMLInputElement>,keyof UseFormRegisterReturn<TFieldPath> | "type" | "form" | "path" | "options">;



export default function LabelledRadioButton<TFieldValues extends FieldValues,TFieldPath extends Path<TFieldValues>>({
  form, path, options={}, id, className, children, ...rest
}: LabelledRadioButtonProps<TFieldValues,TFieldPath>) {
  const [indputId] = useState(_uniqueId("radio-"));
  return (
    <label className={className ?? s.otherRadio} id={id}>
      <input id={indputId} type="radio" {...form.register(path,options)} {...rest} />
      <label htmlFor={indputId}>{children}</label>
    </label>
  );
}
