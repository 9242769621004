import { DashAPIRoute } from "../../utils/apis";
import { AppError } from "../../utils/utils";

class ClinicalOrderStatus {
  readonly orderStatus: number;
  readonly label: string | undefined;
  readonly countAsSale: boolean | undefined;
  constructor(orderStatus: number,label: string,countAsSale: boolean) {
    this.orderStatus = orderStatus;
    this.label = label ?? undefined;
    this.countAsSale = countAsSale ?? undefined;
  }

  static get(orderStatus: number): ClinicalOrderStatus {
    const map: Record<number,ClinicalOrderStatus> = Object.fromEntries(Object.values(ClinicalOrderStatus).map(status => [status.orderStatus,status]));
    return map[orderStatus];
  }

  static fromJSON({orderStatus, label, countAsSale}: {orderStatus: number, label: string, countAsSale: boolean}): ClinicalOrderStatus {
    const existing = ClinicalOrderStatus.get(orderStatus);
    if (existing) return existing;
    return new ClinicalOrderStatus(orderStatus,label,countAsSale);
  }

  static ADDRESS_SUPPLIED = new ClinicalOrderStatus(1,"Address supplied",true);
  static SHIPPED_FROM_FM_HQ = new ClinicalOrderStatus(2,"Shipped from FM HQ",true);
  static ORDERED_BY_PRACTICE = new ClinicalOrderStatus(3,"Ordered by practice",true);
  static PAYMENT_SUCCEEDED = new ClinicalOrderStatus(4,"Payment Succeeded",true);
  static PAYMENT_FAILED = new ClinicalOrderStatus(5,"Payment Failed",false);
  static READY_FOR_FULFILLMENT = new ClinicalOrderStatus(12,"Ready for fulfillment",true);
}

export const insertOrderStatusLog = async (...logs: OrderStatusLog[]) => {
  const res = await fetch(DashAPIRoute.INSERT_ORDER_STATUS_LOG.path,{
    method: "POST",
    body: JSON.stringify({
      logs: logs.map(toPost),
    }),
  })
  const { orslids, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return orslids;
}

function toPost(log: OrderStatusLog): { orid: number; orderStatus: number; extra: string | undefined; } {
  return {
    orid: log.orid,
    orderStatus: log.orderStatus.orderStatus,
    extra: log.extra,
  }
}

export interface OrderStatusLog {
  orid: number;
  orderStatus: ClinicalOrderStatus;
  extra?: string|undefined;
}

export default ClinicalOrderStatus;