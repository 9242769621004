import { useContext } from "react";
import { RequestOrderContext, RequestOrderFormFieldValuesBase } from "../RequestOrderContext";
import IFTAInput from "../../../../register/IFTAElements/IFTAInput/IFTAInput";
import { useFormContext } from "react-hook-form";

export default function QuantityInput() {
  const { register } = useFormContext<RequestOrderFormFieldValuesBase>();
  const {
    state: { item },
  } = useContext(RequestOrderContext);
  return (
    <IFTAInput
      id="quantity-input"
      type="number"
      label="Quantity"
      disabled={!item}
      reg={register("quantity", {
        required: "Quantity is required",
        valueAsNumber: true,
        min: {
          value: 1,
          message: "Quantity must be positive",
        },
      })}
    />
  );
}
