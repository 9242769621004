import { useContext, useEffect, useState } from "react";
import { currencies } from "../contexts/OrderContext/OrderContext";
import { createProvider } from "../utils/data-classes/Provider";
import { AppError } from "../utils/utils";
import { Sku } from "./useSkus";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { CurrencyCode } from "../../../server/consts";
import { ArrearsInvoice, ArrearsInvoiceItems } from "../utils/data-classes/ArrearsInvoice";

export default function useArrearsInvoice(token: string) {
  const [invoice, setInvoice] = useState<ArrearsInvoice>();
  const [error, setError] = useState<AppError>();

  const { provider } = useContext(LoginContext).state;

  useEffect(() => {
    if (!token || !provider) return;
    const abortController = new AbortController();
    fetchArrearsInvoice(token, abortController.signal)
      .then(setInvoice)
      .catch((err) => setError(err instanceof AppError ? err : new AppError(400, "Something went wrong")));
    return () => abortController.abort();
  }, [token, provider]);

  return { invoice, error };
}

async function fetchArrearsInvoice(invoiceToken: string, signal: AbortSignal) {
  const res = await fetch(`/api/arrears-invoice/${invoiceToken}`, {
    method: "GET",
    signal,
  });
  const data = await res.json();
  const error = data.error;
  if (error !== undefined) throw new AppError(res.status, error);
  const invoice: ArrearsInvoiceForPDF = data.invoice;
  return createArrearsInvoice(invoice);
}

function createArrearsInvoice(data: ArrearsInvoiceForPDF) {
  return new ArrearsInvoice({
    invoiceId: data.invoiceId,
    createdOnMs: data.createdOnMs,
    periodStartedOnMs: data.periodStartedOnMs,
    periodEndedOnMs: data.periodEndedOnMs,
    orid: data.orid ?? undefined,
    token: data.token,
    billing: data.billing,
    provider: createProvider(data.provider),
    total: data.total ?? undefined,
    taxTotal: data.taxTotal ?? undefined,
    currency: currencies[data.currency as CurrencyCode],
    items: data.items,
    skus: data.skus,
  });
}

interface ArrearsInvoiceForPDF {
  invoiceId: number;
  createdOnMs: number;
  periodStartedOnMs: number;
  periodEndedOnMs: number;
  orid: number | null;
  token: string;
  billing: any;
  provider: any;
  total: number | null;
  taxTotal: number | null;
  currency: string;
  skus: { [skuId: number]: Sku };
  items: ArrearsInvoiceItems;
}


