/*
 * Component to create a challenge card and corresponding chart.
 * If the `useExpandedArea` hook returns false a strip with general info about the challenge is rendered.
 * Otherwise, card expands and provides further information (challenge chart)
 */

import { Chart } from "chart.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import ChalChart from "./ChalChart";
import s from "./Challenges.module.scss";
import HighBaselineAlert from "./HighBaselineAlert";
import { setDefaultConfig } from "../charts/chart-funcs";
import { ChallengeReportDocument } from "../../../pdf-generator/templates/ChallengeReportDocument/ChallengeReportDocument";
import { authFetch, logCdAction, scrollToElement } from "../../../utils/utils";
import { Challenge } from "../../../utils/data-classes/challenge/Challenge";
import { symptomsForChallenge } from "../../../utils/data-classes/challenge/challenge-util";
import Gas from "../../../utils/data-classes/Gas";
import { Patient } from "../../../utils/data-classes/Patient";
import { Provider } from "../../../utils/data-classes/Provider";
import { CdAction } from "../../../utils/CdActions";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { useDateFormat } from "../../../hooks/useDateFormat";
import PdfDownloadButton from "../../../components/buttons/PdfDownloadButton/PdfDownloadButton";
import ChallengeReviewButton from "../../../components/buttons/ChallengeReviewButton/ChallengeReviewButton";
import { ReactComponent as TickIcon } from "../../../assets/icons/tick.svg";
import { ChallengesContext, ChalCardData } from "./ChallengesContext";
import { getLinkBase } from "../../help/getting-started/GettingStarted";
import InterfaceButton from "../../../components/buttons/InterfaceButton/InterfaceButton";
import FoodIntoleranceGuidanceButton from "./FoodIntoleranceAdviceButton";
import EditNotesButton from "./EditNotesButton";

setDefaultConfig(Chart);

export default function ChalCard(props: ChalCardProps) {
  const {
    state: { linkedCard },
    dispatch,
  } = useContext(ChallengesContext);
  const { card } = props;
  const [chartAnimationComplete, setChartAnimationComplete] = useState(false);

  const thisRef = useRef(null);
  const canvasRef = useRef(null);

  /*
   Create a chart with three datasets:
    - Breath scores: Ppm values for challenge tests
    - Lowest preceding value: A dataset to draw a line on where the lowest-before-max value <isindex/>
    - Positive criteria: A dataset to draw a line on 20ppm above the lowest preceding (to indicate high rise lower limit)
   */
  const shouldExpand = useExpandedArea(card);
  useEffect(() => {
    if (!canvasRef.current) return;
    card.chartHandler.makeChart(canvasRef.current, setChartAnimationComplete);
  }, [shouldExpand, card.chartHandler]);

  // Scroll to this element on loading of challenge if this challenges epochMs is provided
  const shouldScrollIntoView = card === linkedCard;
  useEffect(() => {
    if (!shouldScrollIntoView) return;
    scrollToElement(thisRef, -83);
    dispatch({ type: "TOGGLE_CARD", payload: card });
  }, [card.challenge, card.chartHandler]);

  if (!card.chartHandler) return <></>;

  return (
    <div className={`${s.challengeCard} ${card.challenge.settings.isReviewed ? s.reviewed : ""}`} ref={thisRef}>
      <Strip {...props} />
      {shouldExpand && (
        <ExpandedArea {...props} canvasRef={canvasRef} chartAnimationComplete={chartAnimationComplete} />
      )}
    </div>
  );
}

/**
 * Challenge Strip with the overview
 */
function Strip({ card }: ChalCardProps) {
  const { challenge } = card;
  const {
    state: { chosen },
    dispatch,
  } = useContext(ChallengesContext);
  const shouldExpand = useExpandedArea(card);
  const onClick = () => dispatch({ type: "TOGGLE_CARD", payload: card });
  return (
    <div id={shouldExpand ? s.openChallengeStrip : ""} onClick={onClick} className={s.chalInfo}>
      <span>
        <input type="checkbox" checked={shouldExpand} onChange={(e) => false} />
      </span>
      <span className={s.chalLabelCell}>
        {challenge.label.toUpperCase()} ({challenge.dose}){highBaseline(challenge) && <HighBaselineAlert />}
      </span>
      <span>{challenge.createdOnDateTimeUTC.toLocal().toFormat(useDateFormat("datetime"))}</span>
      <ResultTable challenge={challenge} className={s.chalCardResultTable}/>
      <ReviewStatus reviewed={card.challenge.settings.isReviewed} />
    </div>
  );
}

function ExpandedArea({ canvasRef, card, chartAnimationComplete }: ChalCardExpandedAreaProps) {
  const { challenge, symptoms, chartHandler } = card;
  const {
    state: { staff, provider, loginToken },
  } = useContext(LoginContext);
  const {
    state: { patient, preferences, pdfLogoSrc, followonContent },
  } = useContext(ChallengesContext);
  if (!patient || !staff || !provider || !loginToken || !pdfLogoSrc || !followonContent) return <></>;
  const filename = getChallengeReportFilename(patient, provider, challenge);
  const Document = () => (
    <ChallengeReportDocument
      patient={patient}
      staff={staff}
      provider={provider}
      challenge={challenge}
      chartHandler={chartHandler}
      symptoms={symptomsForChallenge(challenge, symptoms)}
      logoSrc={pdfLogoSrc}
      referringClinician={preferences?.referringClinician}
      followonContent={followonContent}
    />
  );
  return (
    <div>
      <ChalChart canvasRef={canvasRef} id={s.challengeCanvasWrapper} />
      <div className={s.pdfDownloadButtonContainer}>
        <EditNotesButton patient={patient} challenge={challenge}/>
        <FoodIntoleranceGuidanceButton patient={patient} challenge={challenge} />
        <ChallengeReviewButton cards={[card]} />
        <PdfDownloadButton
          title="Download Challenge Report"
          document={<Document />}
          filename={filename}
          isDocumentDataReady={chartAnimationComplete}
          onDownload={() =>
            logCdAction(loginToken, CdAction.DOWNLOAD_CHALLENGE_REPORT, challenge.tests[0].chalCreatedOn)
          }
        >
          Download
        </PdfDownloadButton>
      </div>
    </div>
  );
}

export const ResultTable = ({ challenge, className }: { challenge: Challenge, className: string }): JSX.Element => (
  <div className={className}>
    <ResultTableRow challenge={challenge} gas={Gas.H2} />
    <ResultTableRow challenge={challenge} gas={Gas.CH4} />
  </div>
);

const ResultTableRow = ({ challenge, gas }: { challenge: Challenge; gas: Gas }): JSX.Element => {
  const hasGas = challenge.hasGas(gas);
  const handler = challenge.ppmHandler(gas);
  return (
    <div>
      <span>{gas.fancy}</span>
      <span className={s.numeric}>
        <span>{hasGas && challenge.baselineTest.ppm(gas)}</span>
        <span>{hasGas && "ppm"}</span>
      </span>
      <span className={s.numeric}>
        <span>{hasGas && handler.areaUnderCurve().toFixed(0)}</span>
        <span>{hasGas && "ppm mins"}</span>
      </span>
      <span className={`${s.numeric} ${!!handler.positiveRise() ? s.positive : s.negative}`}>
        <span>{hasGas && handler.result()}</span>
        <span>{hasGas && "ppm"}</span>
      </span>
    </div>
  );
};

export const getChallengeReportFilename = (patient: Patient, provider: Provider, tests: Challenge): string => {
  const { lastName, identifier } = patient;

  const date = tests.date(provider).toString().replace(/\//g, "_");
  const name = lastName ? lastName.replace(/ /g, "_") : identifier;

  const result = `${date}_${name}_${tests.label}_Challenge.pdf`;
  return result;
};

export const highBaseline = (challenge: Challenge) => {
  const firstPpm = challenge.firstTest.ppm(Gas.H2);
  if (!firstPpm) return false;
  return firstPpm > 15;
};

export function ReviewStatus({ reviewed }: { reviewed: boolean }) {
  return (
    <span className={s.reviewStatus}>
      {reviewed && <TickIcon title="Report reviewed" />}
    </span>
  );
}

interface ChalCardProps {
  card: ChalCardData;
}

interface ChalCardExpandedAreaProps extends ChalCardProps {
  canvasRef: React.MutableRefObject<null>;
  chartAnimationComplete: boolean;
}

/**
 * Returns true if a given card should be expanded, ie is selected
 */
function useExpandedArea(card: ChalCardData): boolean {
  const {
    state: { chosen },
  } = useContext(ChallengesContext);
  return !!chosen[card.index];
}

function getAdvicePdfFilename(substrate: string) {
  if (substrate === "Sucrose") return "Managing_CSID.pdf";
  if (["Fructose","Inulin","Sorbitol","Lactose"].includes(substrate)) return `Managing_${substrate}_Intolerance.pdf`;
  return undefined;
}

