import React from "react"
import { Currency } from "../../../contexts/OrderContext/OrderContext"
import { Sale } from "../useSales"
import s from "../Sales.module.scss";

export default function SalesSummaryTable({sales}: {sales: Sale[]}) {
  const rowData = getRowData(sales);
  const shouldDisplayCurrencyName = hasConflictingCurSymbols(sales); // display currency name is multiple of same currency symbol, eg USD and NZD
  return (
    <div className={s.salesSummaryTable}>
      <div>
        <span></span>
        <span>Dashboard</span>
        <span>foodmarble.com</span>
        <span>Total</span>
      </div>
      <OrdersRows rows={rowData} />
      <CurrencyRows rows={rowData} displayCur={shouldDisplayCurrencyName} />
    </div>
  )
}

const OrdersRows = ({rows}: {rows: SummaryRowData[]}) => {
  if (rows.length === 0) return <></>;
  return (
    <>
      <OrdersRow label="Orders" row={rows[0]} />
      { rows.length > 1 && rows.slice(1,rows.length).map((r,i) => <OrdersRow key={i} row={r} />)}
    </>
  )
}

const OrdersRow = ({label,row}: {label?: string|undefined, row: SummaryRowData}) => {
  const { orders } = row;
  return (
    <div>
      <span>{label ?? ""}</span>
      <span>{orders.dashboard}</span>
      <span>{orders.website}</span>
      <span>{orders.dashboard + orders.website}</span>
    </div>
  )
}

const CurrencyRows = ({rows,displayCur}: {rows: SummaryRowData[], displayCur: boolean}) => {
  if (rows.length === 0) return <></>;
  return (
    <>
      <CurrencyRow label="Earnings" row={rows[0]} displayCur={displayCur} />
      { rows.length > 1 && rows.slice(1,rows.length).map((r,i) => (
        <CurrencyRow key={i} row={r} displayCur={displayCur} />
      ))}
    </>
  )
}

const CurrencyRow = ({label,row,displayCur}: {label?: string|undefined, row: SummaryRowData,displayCur: boolean}) => {
  const { earnings, currency } = row;
  return (
    <div>
      <span>{label ?? ""}</span>
      <span>{currencyString(earnings.dashboard,currency,displayCur)}</span>
      <span>{currencyString(earnings.website,currency,displayCur)}</span>
      <span>{currencyString(earnings.dashboard + earnings.website,currency,displayCur)}</span>
    </div>
  )
}

const currencyString = (value: number,currency: Currency, displayName: boolean): string => {
  return `${displayName ? `${currency.name} ` : ""}${currency.symbol}${value.toFixed(2)}`;
}

interface SummaryRowData {
  currency: Currency;
  orders: { 
    dashboard: number;
    website: number;
  },
  earnings: {
    dashboard: number;
    website: number;
  }
}

const getRowData = (sales: Sale[]): SummaryRowData[] => {
  const obj = sales.reduce<{[currencyCode: string]: SummaryRowData}>((acc,cur) => {
    if (!acc[cur.currency.name]) acc[cur.currency.name] = {
      currency: cur.currency,
      orders: {
        dashboard: 0,
        website: 0,
      },
      earnings: {
        dashboard: 0,
        website: 0,
      }
    };
    if (cur.source === "Dashboard") {
      acc[cur.currency.name].orders.dashboard += cur.quantity;
      acc[cur.currency.name].earnings.dashboard += cur.earnings;
    }
    if (cur.source === "foodmarble.com") {
      acc[cur.currency.name].orders.website += cur.quantity;
      acc[cur.currency.name].earnings.website += cur.earnings;
    }
    return acc;
  },{});
  return Object.values(obj);
}

const hasConflictingCurSymbols = (sales: Sale[]) => {
  const currencies = Array.from(new Set(sales.map(s => s.currency)));
  const symbols: Partial<Record<string,true>> = {};
  for (const cur of currencies) {
    if (symbols[cur.symbol]) return true;
    symbols[cur.symbol] = true;
  }
  return false;
}