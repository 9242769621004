import Chart from "chart.js";
import { DateTime } from "luxon";
import Fodmap from "../../../utils/data-classes/Fodmap";
import Food from "../../../utils/data-classes/Food";
import Gas from "../../../utils/data-classes/Gas";
import Meal from "../../../utils/data-classes/Meal";
import { isISODateWithoutTime } from "../../../utils/utils";
import styles from "../_styles";
import { DATASET_LABEL as CHALLENGES_DATASET_LABEL } from "../challenges/ChallengeChartOptions";

export const CHART_DATE_FORMAT = {
  SHORT: "LLL dd, yyyy",
  LONG: "LLL dd, yyyy, t",
}

export const getFromTooltip = {
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem 
   * @param {Chart.ChartData} data 
   * @returns 
   */
  dataset: (tooltipItem,data) => {
    return data.datasets[tooltipItem.datasetIndex];
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem 
   * @param {Chart.ChartData} data 
   * @returns
   */
  datapoint: (tooltipItem,data) => {
    return getFromTooltip.dataset(tooltipItem,data).data[tooltipItem.index];
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem 
   * @param {Chart.ChartData} data 
   * @returns 
   */
  label: (tooltipItem,data) => {
    return getFromTooltip.dataset(tooltipItem,data).label;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns {string}
   */
  name: (tooltipItem,data) => {
    return getFromTooltip.dataset(tooltipItem,data).name;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns {Gas}
   */
  gas: (tooltipItem,data) => {
    return getFromTooltip.dataset(tooltipItem,data).gas;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem 
   * @param {Chart.ChartData} data 
   * @returns {Meal}
   */
  meal: (tooltipItem,data) => {
    return getFromTooltip.datapoint(tooltipItem,data).meal;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem 
   * @param {Chart.ChartData} data 
   * @returns {Fodmap}
   */
  fodmap: (tooltipItem,data) => {
    return getFromTooltip.datapoint(tooltipItem,data).fodmap;
  },
  /**
   * @param {Chart.ChartTooltipItem|Chart.ChartTooltipItem[]} tooltipItem
   * @param {Chart.ChartData} data
   * @returns time of datapoint where tooltipItem label values are an ISO time, in short form if time is just a date
   */
  formattedTime: (tooltipItem,data) => {
    const timeISO = (tooltipItem instanceof Array) ? tooltipItem[0].label : tooltipItem.label;
    const dateFormat = isISODateWithoutTime(timeISO) ? CHART_DATE_FORMAT.SHORT : CHART_DATE_FORMAT.LONG;
    const result = DateTime.fromISO(timeISO).toFormat(dateFormat);
    return result;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns {Food[]}
   */
  foods: (tooltipItem,data) => {
    return getFromTooltip.datapoint(tooltipItem,data).foods;
  },
}

/**
 * Returns a function that checks if the name property of a dateset obtained from a TooltipItem contains a given string
 * @param {string} string
 * @returns {(tooltipItem: Chart.ChartTooltipItem,data:Chart.ChartData) => boolean}
 */
const doesNameIncludeStringCallback = (string) => (tooltipItem,data) => {
  return getFromTooltip.name(tooltipItem,data)?.includes(string);
}

export const isTooltipFor = {
  fodmaps: doesNameIncludeStringCallback("fodmap:"),
  noFodmaps: doesNameIncludeStringCallback("meal fixers"),
  combinedFodmaps: doesNameIncludeStringCallback("combinedFodmaps"),
  breaths: doesNameIncludeStringCallback("breath tests"),
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  challengeBreaths: (tooltipItem,data) => {
    const label = getFromTooltip.label(tooltipItem,data);
    return label === CHALLENGES_DATASET_LABEL.H2 || label === CHALLENGES_DATASET_LABEL.CH4;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  positiveCriteria: (tooltipItem,data) => {
    return getFromTooltip.dataset(tooltipItem,data).label.toLowerCase().includes("positive criteria");
  }
}

/**
 * @constant
 * @type {Chart.ChartTooltipOptions}
 */
export const genericTooltips = {
  caretPadding: 10,
  backgroundColor: styles.tooltipBackground,
  displayColors: false,
  titleMarginBottom: 12,
  position: "custom", // this helps anchoring tooltips by the cursor ("custom" gets defined on Charts.defaults, by setDefaultConfig)
};

export const noFodmapsCallbacks = {
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  label: (tooltipItem,data) => {
    const meal = getFromTooltip.meal(tooltipItem,data);
    const totalMealFodmap = meal.totalFodmapSum().toFixed(2);
    return `Known Fodmap Content: ${totalMealFodmap} g`;
  },
}

export const combinedFodmapsCallbacks = {
  /**
   * @param {Chart.ChartTooltipItem[]} tooltipItems
   * @param {Chart.ChartData} data
   * @returns
   */
  title: (tooltipItems,data) => "Daily Total FODMAPs",
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns {string[]}
   */
  label: (tooltipItem,data) => {
    const meal = getFromTooltip.meal(tooltipItem,data);
    const result = meal.fodmaps.map(fodmap => `${fodmap.name}: ${fodmap.value.toFixed(2)} g`);
    return result;
  },
  /**
   * 
   * @param {Chart.ChartTooltipItem[]} tooltipItems 
   * @param {Chart.ChartData} data 
   * @returns 
   */
  footer: (tooltipItems,data) => {
    const value = parseFloat(tooltipItems[0].value);
    return `Total: ${value.toFixed(2)} g`;
  }
}

export const breathCallbacks = {
  /**
   * @param {Chart.ChartTooltipItem[]} tooltipItems
   * @param {Chart.ChartData} data
   * @returns
   */
  title: (tooltipItems,data) => {
    const { value } = tooltipItems[0]
    const dataset = getFromTooltip.dataset(tooltipItems[0],data);
    return `${dataset.label}: ${value} ppm`;
  },
}

export const fodmapsCallbacks = {
  /**
   * @param {Chart.ChartTooltipItem[]} tooltipItems
   * @param {Chart.ChartData} data
   * @returns
   */
  title: (tooltipItems,data) => {
    const meal = getFromTooltip.meal(tooltipItems[0],data);
    // If label is different than sublabel, include sublabel (ie. meal shared)
    let combinedLabel = [meal.label, `( ${meal.sublabel} )`]; // array needed for multiple lines (chart.js)
    return meal.sublabel && meal.label !== meal.sublabel
      ? combinedLabel
      : meal.label;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  label: (tooltipItem,data) => {
    const fodmap = getFromTooltip.fodmap(tooltipItem,data);
    return `${fodmap.name}: ${parseFloat(tooltipItem.value).toFixed(2)} g`;
  },
  /**
   * @param {Chart.ChartTooltipItem[]} tooltipItems
   * @param {Chart.ChartData} data
   * @returns
   */
  footer: (tooltipItems,data) => {
    const meal = getFromTooltip.meal(tooltipItems[0],data);
    return `Total Fodmap Content: ${meal.totalFodmapSum().toFixed(2)} g`;
  },
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  afterLabel: (tooltipItem,data) => {
    const dataset = getFromTooltip.dataset(tooltipItem,data);
    let ingredientsStr = "Ingredients: ";
    const meal = getFromTooltip.meal(tooltipItem,data);
    const { foods } = meal;
    Object.values(foods).forEach((food) => {
      ingredientsStr += food.label ? food.label + " " : "";
      ingredientsStr += food.sublabel
        ? "(" + food.sublabel + ")"
        : "";
      ingredientsStr += food.amount ? " " + food.amount + " " : "";
      ingredientsStr += food.unit ? food.unit + "; " : "";
    });
    ingredientsStr = ingredientsStr.slice(0, -2); // removes the last ';'
    return ingredientsStr;
  },
}

export const positiveCriteriaCallbacks = {
  /**
   * @param {Chart.ChartTooltipItem[]} tooltipItems
   * @param {Chart.ChartData} data
   * @returns
   */
  title: (tooltipItems,data) => "",
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  label: (tooltipItem,data) => `Positive criteria: ${tooltipItem.value} ppm`,
}

export const challengeBreathCallbacks = {
  /**
   * @param {Chart.ChartTooltipItem[]} tooltipItems
   * @param {Chart.ChartData} data
   * @returns
   */
  title: (tooltipItems,data) => "Gas Concentration",
  /**
   * @param {Chart.ChartTooltipItem} tooltipItem
   * @param {Chart.ChartData} data
   * @returns
   */
  label: (tooltipItem,data) => {
    const gas = getFromTooltip.gas(tooltipItem,data);
    return `${gas.description}: ${tooltipItem.value} ppm`;
  },
}