import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function usePatientPreferences(ptid: number) {
  const { data, error } = useDataAuth(fetchPatientPreferences(ptid));
  return { preferences: data, error };
}

export interface PatientPreferences {
  privacyConsent: boolean|undefined;
  testConsentForm: boolean|undefined;
  useNonRestrictedApp: boolean|undefined;
  referringClinician: ReferringClinician|undefined;
}

export function createPatientPreferences(preferences: PatientPreferences): PatientPreferences {
  return {
    privacyConsent: preferences.privacyConsent ?? undefined,
    testConsentForm: preferences.testConsentForm ?? undefined,
    useNonRestrictedApp: preferences.useNonRestrictedApp ?? undefined,
    referringClinician: preferences.referringClinician ? createReferringClinician(preferences.referringClinician) : undefined,
  }
}

export interface ReferringClinician {
  salutation: string;
  postNominal: string;
  name: string;
  surname: string|undefined;
};

function createReferringClinician(referringClinician: any): ReferringClinician|undefined {
  if (!referringClinician) return undefined;
  const [salutation,name,surname,postNominal] = referringClinician;
  return {
    salutation,
    postNominal,
    name,
    surname,
  }
}

const fetchPatientPreferences = (ptid: number) => async (loginToken: string,signal: AbortSignal): Promise<PatientPreferences> => {
  const res = await authFetch(loginToken,`/api/patient-preferences/${ptid}`,{signal});
  const { error, preferences } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return createPatientPreferences(preferences);
}
