/**
 * This component contains the front-end payment flow for Stripe.
 *
 * Component currently includes flow for using Apple and Google pay, however this is incomplete and the feature has 
 * been disabled. Since there are no components for switching to Apple or Google pay the traditional checkout flow is
 * the only one which can be accessed.
 * 
 * The component follows a similar flow as the payments interface of the old website, however post-processing 
 * is handled by parent components to avoid multiple updates, since updates to device orders happen regardless 
 * of if self-pay is used.
 * 
 * 1. When the page loads, we create a paymentRequest (this is exclusively for Google/Apple Pay)
 *      1.1. If paymentRequest gets created succesfully, Google/Apple Pay UI appears
 * 2. The actual flow starts with customers intention to pay (clicking on one of the "pay" buttons)
 *    1.1. When this happens, we set the firstCustomerInteraction flag to true
 * 3. We then create a stripeCustomer and a paymentIntent.
 * 4. The next step is creating a paymentMethod.
 *    4.1. For Google/Apple Pay, paymentMethod is created via listening to paymentRequest's "paymentmethod" event
 *    4.2. For traditional checkout, we set traditionalPaymentActive flag to true and run stripe.createPaymentMethod
 * 5. Once we have a paymentMethod, we automatically attempt to charge customer by confirming paymentIntent.
 * 6. paymentIntent confirmation results with a paymentStatus
 *    6.1. If the payment is succesful, it'll be one of the SUCCESSFUL_ORDERS
 *        6.1.1. If the order is a clinical device/order, paymentStatus will be 34
 *    6.2. If the payment failed for some reason, paymentStatus will be the failed order's status (see the function setOrderStatus)
 *    6.3. At any other times, paymentStatus has to be 'null' as it indicates the completion of a payment
 *
 * In the event of the "error" state being set with a non-operational error, an order_status_log is inserted into the database
 * indicating a failed payment. 
 *
 * Other than the use of hooks, the whole flow works through Stripe API.
 * The documentation is essential yet also scattered across Stripe's various doc pages, so I won't be able to link it here.
 */


import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElement, PaymentMethod, PaymentIntent, Stripe, PaymentRequest, StripeErrorType, StripeError, StripeElementStyle, StripeElementStyleVariant, StripeElementClasses, StripeElementsOptions} from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import OrderContext, { LocationDetails, Order, OrderContextState, OrderCountryCode, OrderCurrencyCode } from "../../../contexts/OrderContext/OrderContext";
import { APIRequest, DashAPIRoute } from "../../../utils/apis";
import { AppError, devLog } from "../../../utils/utils";
import s from "./SelfPay.module.scss";
import ClinicalOrderStatus, { insertOrderStatusLog } from "../../../pages/register/ClinicalOrderStatus";
import useTranslation from "../../../hooks/useTranslation";
import { RegisterSubmitButton } from "../../../pages/register/RegisterForm";

const inputStyles: StripeElementStyle = {
  base: {
    fontSize: '16px',
    color: '#424770',
    fontFamily: 'Montserrat',
    '::placeholder': {
      color: '#aab7c4',
    },
    fontWeight: "300", 
  },
  invalid: {
    color: '#9e2146'
  },
}

type PaymentType = "standard" | "applepay" | "googlepay"

type OrderStatusNameType = 
  | "SUCCESS_CLINICAL"
  | "UNKOWN_ERROR"
  | StripeErrorType

type OrderStatusCodeType = 34 | 20 | 3 | 4 | 5 | 17 | 18 | 19 | 21 | 22;

const OrderStatus: Record<OrderStatusNameType,OrderStatusCodeType> = {
  SUCCESS_CLINICAL: 34,
  UNKOWN_ERROR: 20,
  card_error: 3,
  invalid_request_error: 4,
  authentication_error: 5,
  api_connection_error: 17,
  rate_limit_error: 18,
  api_error: 19,
  idempotency_error: 21,
  validation_error: 22,
}

interface Customer {
  id: string
}

const SUCCESSFUL_ORDERS = [OrderStatus.SUCCESS_CLINICAL];

interface SelfPayProps {
  stfid: number,
  patientCode: string,
  orid: number,
}

const SelfPay = (props: SelfPayProps) => {
  const { stfid, orid } = props;
  const { state: orderCtx, dispatch: orderDispatch } = useContext(OrderContext);

  const { t } = useTranslation();

  const stripe = useStripe();

  // Get the card number from Stripe UI element to bind it to Stripe payment method later
  const elements = useElements();
  const [cardNumber,setCardNumber] = useState<StripeCardNumberElement>();
  useEffect(() => {
    if (elements) setCardNumber(elements.getElement(CardNumberElement)||undefined)
  }, [elements]);

  // orderProcessing is a flag. Set to true when an order is currently in process and set it back to false when the processing is over
  const [orderProcessing, setOrderProcessing] = useState(false);

  const [error, setError] = useState<SelfPayError>();
  useEffect(() => {
    if (error) {
      setOrderProcessing(false);
      if (!error.isOperational) insertOrderStatusLog({orid,orderStatus:ClinicalOrderStatus.PAYMENT_FAILED,extra: error.extra});
    }
  }, [error]);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  useEffect(() => {
    if (paymentMethod) devLog('Payment Method Created: ', paymentMethod.id )
  },[paymentMethod]);

  const [orderStatus,setOrderStatus] = useState<OrderStatusCodeType>();
  const [paymentStatus, setPaymentStatus] = useState<OrderStatusCodeType>(); // keep it undefined for all times except when a new payment attempt completed
  useEffect(() => {
    devLog("payment status changed to: ", paymentStatus)
  },[paymentStatus]);

  /****************************************  CREATE STRIPE CUSTOMER AND PAYMENT INTENT  ****************************************
   * firstCustomerInteraction -> stripeCustomer ?-> paymentIntent
  *****************************************************************************************************************************/
  // firstCustomerInteraction is a flag.
  // Set to true when the customer press "Pay" button first time (could be traditional checkout or apple/google pay).
  // This helps to create stripeCustomer and paymentIntent only once and only after customer's intent to pay
  const [firstCustomerInteraction, setFirstCustomerInteraction] = useState(false);

  const [stripeCustomerKey, setStripeCustomerKey] = useState<string>();
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();

  useEffect(() => {
    if (!firstCustomerInteraction) return;
    if (!orderCtx.order.total || !orderCtx.delivery.email) {
      setError(new SelfPayError(400,t("selfPay.errors.missingInfo")));
      setFirstCustomerInteraction(false);
      return;
    }
    createStripeCustomerThenPaymentIntent(orderCtx.delivery,orderCtx.billing,stfid,orderCtx.order)
      .then(({stripeCustomerKey,paymentIntent}) => {
        setStripeCustomerKey(stripeCustomerKey);
        setPaymentIntent(paymentIntent);
      })
      .catch((error: Error|ServersideStripeError) => {
        setError(new SelfPayError(400,error.message,false,error instanceof ServersideStripeError ? error.name : undefined))
      }) 
      .finally(() => setFirstCustomerInteraction(false));
  },[firstCustomerInteraction]);

  useEffect(() => {
    if (stripeCustomerKey) devLog("Stripe Customer Created: ", stripeCustomerKey )
  }, [stripeCustomerKey]);

  useEffect(() => {
    if(paymentIntent) devLog("Payment Intent Created: ", paymentIntent.id )
  }, [paymentIntent]);

  /*************************************  CREATE GOOGLE / APPLE PAY BUTTON  ********************************************
   * stripe ?-> paymentRequest ?-> Google / Apple Pay Button
  **********************************************************************************************************************/
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [applePay, setApplePay] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<PaymentType>("standard");
  useEffect(() => {
    const { order, delivery } = orderCtx;
    if (!stripe) return;
    if (!order.total || !order.currency || !delivery.countryCode) return;

    createPaymentRequest(stripe,delivery.countryCode,order.currency,order.total).then(({ paymentRequest, applePay }) => {
      if (!paymentRequest) return;
      setPaymentRequest(paymentRequest);
      setApplePay(applePay || false);
    });
  },[stripe]);

  /**************************************  SET PAYMENT METHOD: GOOGLE / APPLE PAY  **************************************
   * paymentRequest.onPaymentMethod ?-> paymentMethod (google / apple)
  ***********************************************************************************************************************/
  useEffect(() => {
    if (!paymentRequest || !paymentIntent?.client_secret) return;

    paymentRequest.on("paymentmethod", (e) => {
      if (!e.paymentMethod) {
        e.complete('fail');
        return;
      }

      setPaymentMethod(e.paymentMethod);
      setPaymentType(applePay ? "applepay" : "googlepay");
      e.complete("success");
    });
  },[stripe,paymentRequest,paymentIntent,applePay]);

  /************************************  SET PAYMENT METHOD: TRADITIONAL CHECKOUT  **************************************
   * form.onSubmit -> handlePurchase -> traditionalPaymentActive -> paymentMethod (traditional)
  ***********************************************************************************************************************/
  const [traditionalPaymentActive, setTraditionalPaymentActive] = useState<boolean>(false);
  
  const handlePurchase = async (e: React.FormEvent) =>  {
    e.preventDefault();
    if (orderProcessing) return;

    setError(undefined);
    setFirstCustomerInteraction(true);
    setOrderProcessing(true);
    setTraditionalPaymentActive(true);
  }

  useEffect(() => {
    if (!traditionalPaymentActive) return;
    if (!stripeCustomerKey || !paymentIntent || !cardNumber) return;

    if (!stripe) {
      // Sentry.captureException(new Error("Stripe Connection Failed"))
      setError(new SelfPayError(401,"Stripe Connection failed"));
      setPaymentStatus(undefined);
      return;
    }

    // CREATE PAYMENT METHOD
    devLog("Creating Payment Method for Standard Checkout...");
    createPaymentMethod(stripe,cardNumber,orderCtx).then(({paymentMethod,error}) => {
      setTraditionalPaymentActive(false);

      if (paymentMethod) {
        setPaymentMethod(paymentMethod);
        setPaymentType("standard");
        return;
      }

      if (error) {
        console.error("Payment method creation failed: ", error.message );
        setPaymentStatus(OrderStatus[error.type]);
        setError(new SelfPayError(400,error.message,false,error.type));
        return;
      }

      console.error("Both paymentMethod and methodError seems returning undefined for some reason: ", paymentMethod, error )
      setError(new SelfPayError(400,"There seems to be a problem with your payment method. Our payment processor did not accept your card as valid.",false));
      setPaymentStatus(OrderStatus.UNKOWN_ERROR);
    })
    .catch(error => {
      setTraditionalPaymentActive(false);
      setError(new SelfPayError(400,`Unknown Error in standard payment method creation: ${error.message}`,false));
    });
  },[traditionalPaymentActive,stripe,stripeCustomerKey,paymentIntent,cardNumber]);

  /*********************************  CONFIRM PAYMENT INTENT WHEN PAYMENT METHOD IS SET  *********************************
   * paymentMethod -> paymentStatus
  ***********************************************************************************************************************/
  useEffect(() => {
    if (!stripe || !paymentMethod || !paymentIntent?.client_secret) return;

    confirmPaymentIntent(stripe,paymentIntent.client_secret,paymentMethod).then(({error}) => {
      const orderStatus = error 
        ? (OrderStatus[error.type] ?? OrderStatus.UNKOWN_ERROR)
        : OrderStatus.SUCCESS_CLINICAL;
      setOrderStatus(orderStatus);
      setPaymentStatus(orderStatus);

      if (error) {
        devLog(error.type === "authentication_error" ? "Payment failed in Stripe SDK authentication:" : "Payment failed:", error.message, error.type);
        setError(new SelfPayError(400,error.message,false,error.type));
        return;
      }

      devLog("Payment Succeeded!", paymentIntent.status)
    });
  },[stripe,paymentMethod,paymentIntent]);

  /****************************  PAYMENT ATTEMPT AFTERMATH: DATABASE, INVOCE, EMAIL  ***********************************
   * paymentStatus ?-> insertCustomerToDB ?-> insertOrderToDB ?-(if order is successful)-> createInvoice ?-> sendConfirmationEmail
   * SUCCESSFUL_ORDERS.indexOf(paymentStatus) >= 0 -> purchaseComplete
  ***********************************************************************************************************************/
  useEffect(() => {
    if (!paymentStatus) return;
    if (!paymentMethod || !paymentIntent || !stripeCustomerKey) {
      devLog("A Payment Attempt Is Made Without Adequate Information");
      setPaymentStatus(undefined);
      setOrderProcessing(false);
      return;
    }
    if (paymentStatus !== OrderStatus.SUCCESS_CLINICAL) return;

    // ensure that stripeCustomerId is never null or undefined
    const stripeCustomerId = stripeCustomerKey || "cus_creation_failed";
    
    orderDispatch({type: "SET_PAYMENT_DETAILS", payload: { paymentIntent, paymentMethod, stripeCustomerId }});
  },[paymentStatus, paymentIntent, paymentMethod, stripeCustomerKey]);

  if (orderCtx.orderComplete) return (
    <div>
      <p>{t("selfPay.success")}</p>
    </div>
  );

  return (
    <div className="chck-cardinfo">
      <form className={s.strpCard} onSubmit={handlePurchase}>
        {/* TRADITIONAL CHECK-OUT UI */}
        <h2>{t("selfPay.cardDetails")}</h2>
        <CardNumberElement
          onFocus={() => setError(undefined)}
          options={{
            style: inputStyles,
            classes: {
              base: s.stripeElement,
            }
          }}
        />
        <div className={s.expiryAndCvc}>
          <CardExpiryElement
            onFocus={() => setError(undefined)}
            options={{
              style: inputStyles,
              classes: {
                base: s.stripeHalfwidth,
              }
            }}
          />
          <CardCvcElement
            id="strp-cardcvc"
            onFocus={() => setError(undefined)}
            options={{
              style: inputStyles,
              classes: {
                base: s.stripeHalfwidth,
              }
            }}
          />
        </div>
        
        <RegisterSubmitButton disabled={orderProcessing || paymentStatus !== undefined} processing={orderProcessing}>{t("selfPay.confirmAndPay")}</RegisterSubmitButton>

        {/* ERROR MESSAGE (IF EXISTS) */}
        { error && <span className="strp-error-message">{ error.message }</span> }

        {/* GOOGLE/APPLE PAY UI */}
        {/* <div className="strp-card">
          <div className="strp-universal-button-container">
            { !error ? <hr/> : <></>}

            <p className="strp-altpaytext">
              or pay with{ !applePay && <span><img alt="Google Pay" src={ GooglePayMark } className='strp-pay-mark' /></span> }:
            </p>
            <PaymentRequestButtonElement
              onClick={() => setFirstCustomerInteraction(true)}
            />
          </div>
        </div> */}

      </form>
    </div>
  );
}

class ServersideStripeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ServersideStripeError";
  }
}

class ServersideCustomerCreationError extends ServersideStripeError {
  constructor() {
    super(`
      We have encountered an unknown error in setting up a customer for your purchase environment. 
      This means that we won't be able to accept your payment. 
      If the problem persists, please contact with us via healthcare@foodmarble.com
    `);
    this.name = "ServersideCustomerCreationError"
  }
}

class ServersidePaymentIntentCreationError extends ServersideStripeError {
  constructor() {
    super(`
      We have encountered an unknown error in setting up a payment intent for your purchase environment. 
      This means that we won't be able to accept your payment. 
      If the problem persists, please contact with us via healthcare@foodmarble.com`
    );
    this.name = "ServersidePaymentIntentCreationError";
  }
}

class ServersideMissingCustomerIdError extends ServersideStripeError {
  constructor() {
    super(`
      Apologies but we have encountered a strange problem and failed to set up your purchase environment.
      This means that we won't be able to accept your payment.
      If the problem persists, please get in touch with us via healthcare@foodmarble.com`
    );
    this.name = "ServersideMissingCustomerIdError";
  }
}

/**
 * @todo
 * @returns 
 */
const createStripeCustomer = async (delivery: LocationDetails, billing: LocationDetails): Promise<{stripeCustomerKey: string|undefined, error: AppError|undefined}> => {
  const res = await fetch("/api/create-stripe-customer-for-patient",{
    method: "POST",
    body: JSON.stringify({
      billing: {
        name: `${billing.firstName} ${billing.lastName}`,
        email: delivery.email,
        phone: delivery.phone,
        address: billing.address,
        city: billing.city,
        countryCode: billing.countryCode,
        zipCode: billing.zipCode,
        stateCode: billing.stateCode,
      }
    })
  });
  const data = await res.json();
  const stripeCustomerKey: string = data.stripeCustomerKey;
  const error = data.error;
  return { stripeCustomerKey, error };
}

/**
 * @todo
 * @returns 
 */
const createPaymentIntent = async (stfid: number, customerId: string, order: Order): Promise<{paymentIntent: PaymentIntent,error: AppError}> => {
  const { paymentIntent, error } = await new APIRequest(DashAPIRoute.CREATE_PAYMENT_INTENT).post({
    stfid,
    customerId,
    order,
  });
  return { paymentIntent, error };
}

/**
 * @throws ServersideStripeError
 * @returns 
 */
const createStripeCustomerThenPaymentIntent = async (delivery: LocationDetails,billing: LocationDetails,stfid: number,order: Order): Promise<{stripeCustomerKey: string|undefined, paymentIntent: PaymentIntent|undefined}> => {
  const { stripeCustomerKey, error: customerError } = await createStripeCustomer(delivery,billing);
  if (!stripeCustomerKey || customerError) throw new ServersideCustomerCreationError();
  
  const { paymentIntent, error: paymentIntentError } = await createPaymentIntent(stfid,stripeCustomerKey,order);
  if (!paymentIntent || paymentIntentError) throw new ServersidePaymentIntentCreationError();

  return { stripeCustomerKey, paymentIntent }
}


const createPaymentRequest = async (stripe: Stripe,countryCode: OrderCountryCode,currency: OrderCurrencyCode,amount: number) => {
  devLog("Creating a Payment Request...");
  const paymentRequest = stripe.paymentRequest({
    country: countryCode,
    currency: currency.toLowerCase(),
    total: {
      label: "Total",
      amount: parseInt((amount * 100).toFixed(0)), // Amount must be provided in subunit
    }
  });
  const canMakePayment = await paymentRequest.canMakePayment();
  return { 
    paymentRequest: canMakePayment ? paymentRequest : undefined, 
    applePay: canMakePayment?.applePay || false
  }
}

const createPaymentMethod = (stripe: Stripe, cardNumber: StripeCardNumberElement, ctx: OrderContextState) => {
  devLog("Creating Payment Method for Standard Checkout...");
  const { billing, delivery } = ctx;
  return stripe.createPaymentMethod({
    type: "card",
    card: cardNumber,
    billing_details: {
      name: `${billing.firstName} ${billing.lastName}`,
      email: delivery.email,
      phone: billing.phone,
      address: {
        line1: billing.address,
        city: billing.city,
        country: billing.countryCode,
        postal_code: billing.zipCode,
        state: billing.countryCode === "US" ? billing.stateCode: undefined,
      }
    }
  })
}

/**
 * @todo
 * @returns
 */
const confirmPaymentIntent = async (stripe: Stripe, clientSecret: string, paymentMethod: PaymentMethod): Promise<{confirmed: boolean,error: StripeError|undefined}> => {
  const result = (confirmed: boolean, error: StripeError | undefined) => ({ confirmed, error })
  devLog("Confirming Payment Intent...");
  const { paymentIntent: updatedIntent, error: intentError} = await stripe.confirmCardPayment(
    clientSecret,
    { payment_method: paymentMethod.id},
    { handleActions: false },
  );

  if (intentError) return result(false,intentError);
  if (updatedIntent.status === "succeeded") return result(true,undefined);

  const { error: authError } = await stripe.confirmCardPayment(clientSecret);
  if (authError) return result(false,authError);
  return result(true,undefined);
}

class SelfPayError extends AppError {
  readonly extra: string|undefined;
  constructor(code: number, message: string|undefined, isOperational?: boolean, extra?: string) {
    super(code,message ?? "",isOperational);
    this.extra = extra;
  }
}

export default SelfPay;