import s from "./DialogNav.module.scss"

const DialogNav = ({onCloseButtonClick}) => {
    return (
        <div className={s.nav}>
            <button
                type="button"
                className={s.close}
                onClick={onCloseButtonClick}
            >
            ✕
            </button>
        </div>
    );
}

export default DialogNav;