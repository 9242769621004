import { DateTime } from "luxon";
import MultiChartRenderer from "../../../../components/charts/MultiChartRenderer";
import Symptom from "../../../../utils/data-classes/Symptom";
import { createDatasetForStool, createDatasetsForAverageStool, createDatasetsForStool } from "../../charts/chart-datasets";
import { stoolMultichartOptions } from "../../charts/chart-options";

import s from "../DayToDay.module.scss";

/**
 * @param {Object} props
 * @param {Symptom[]|undefined} props.stools 
 * @param {import("../../../../contexts/DateRangeContext/DateRangeContext").DateRange} props.range 
 * @returns
 */
const StoolMultiChart = ({stools,range}) => {
  const dataSetsArray = stools ? createDatasets(stools) : null;
  const options = range && range.length ? stoolMultichartOptions(range) : null;
  return <MultiChartRenderer id={s.stoolMultichartCanvasWrapper} dataSetsArray={dataSetsArray} options={options}/>
}

/**
 * @param {Symptom[]} stools 
 */
const createDatasets = (stools) => {
  return [
    createDatasetForStool(stools),
    ...createDatasetsForAverageStool(stools),
  ];
}

export default StoolMultiChart;