import { Currency } from "../../contexts/OrderContext/OrderContext";
import { Sale } from "./useSales";

export const sumQuantity = (sales: Sale[], source?: string) => {
  return sales.reduce((sum, sale) => sum + ((!source || sale.source.toLowerCase() === source) ? sale.quantity : 0), 0);
};
export const sumEarnings = (sales: Sale[], source?: string) => {
  return sales.reduce((sum, sale) => sum + ((!source || sale.source.toLowerCase() === source) ? sale.earnings : 0), 0);
};
export const currencyString = (value: number,currency: Currency, displayName?: boolean): string => {
  return `${displayName ? `${currency.name} ` : ""}${currency.symbol}${value.toFixed(2)}`;
}