
export const DASHBOARD_LINK = "https://clinical.foodmarble.com";

export function redirectToProductionDashboard() {
  window.location.href = DASHBOARD_LINK;
}

export function isInternalDeployment() {
  return process.env.REACT_APP_INTERNAL_DEPLOYMENT === "true";
}

