import { Language, LanguageCode, languages } from "./Language";
import FileReaderPromises from '../FileReaderPromise';

export class Provider {
  readonly prid: number;
  readonly createdOn : string;
  readonly label : string;
  readonly description : string;
  readonly country : string;
  readonly domain : string;
  readonly emailLabel : string;
  readonly videoLink : string|null;
  readonly logo: string|null;
  readonly langs: Language[];

  static DEFAULT = new Provider(2,"","FoodMarble Provider","Internal Patients","Ireland","foodmarble.com","FoodMarble",null,null);

  constructor(prid:number,createdOn: string,label: string,description: string,country: string,domain: string,emailLabel: string,videoLink: string|null,logo: string|null) {
    this.prid = prid;
    this.createdOn = createdOn;
    this.label = label;
    this.description = description;
    this.country = country;
    this.domain = domain;
    this.emailLabel = emailLabel;
    this.videoLink = videoLink;
    this.logo = logo;
    this.langs = [languages["en"],languages["es"]];
  }

  get countryCode(): string|undefined {
    return this.country;
  }

  get hasPhoneAccess(): boolean {
    return this.country === "US";
  }

  async fetchLogoImage() {
    const DEFAULT_FILENAME = "foodmarble.png";
    const filename = this.logo ?? DEFAULT_FILENAME;
    const res = await fetch(`/api/logos/${filename}`);
    const blob = await res.blob();
    const dataUrl = (await new FileReaderPromises().readAsDataURL(blob)).toString();
    return dataUrl;
  }
}

export const createProvider = ({ prid,createdOn,label,description,country,domain,emailLabel,videoLink,logo }: any) => {
  return new Provider(prid,createdOn,label,description,country,domain,emailLabel,videoLink,logo);
}