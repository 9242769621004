/**
 * Functions for creating ChartData and ChartDataSets objects for populating charts.js charts
 */

import styles from "../_styles";
import Chart from "chart.js";
import { consumedOnDateBucketsArray, createArrayAlternatingValuesFromOppositeEnds, createArraysWithContinuousSequentialDays } from "../../../utils/data-classes/generic-fns";
import Meal, { xxNewCombinedDailyMeal } from "../../../utils/data-classes/Meal";
import PpmDatapoint from "../../../utils/data-classes/PpmDatapoint";
import { COLORS, isDayToDayAggregateView } from "./chart-funcs";
import Ppm, { dailyAveragePpms, ppmsWithGas } from "../../../utils/data-classes/Ppm";
import Gas from "../../../utils/data-classes/Gas";
import Symptom, { dailyAverageSymptomsWithLabel, maxDailySymptomsArrays, SymptomLabel } from "../../../utils/data-classes/Symptom";
import { DateTime } from "luxon";
import tinycolor from "tinycolor2";
import tinygradient from "tinygradient";
import { getColorForDataset } from "../day-to-day/charts/MedChart";

/* HELPERS */

/**
 * Breaks up a dataset with gaps between datapoints greater than a day, and inserts joining datasets
 * based on given joiningSetGenerator, generated using the datapoints at the start and end of each break.
 *  - Used for breaking up a line graph into different segement styles where there are temporal gaps in the data
 *  - **Chart options should be configured to apply legend click toggle on/off action to all datasets with the same label**
 * @param {Chart.ChartDataSets} dataset 
 * @param {Chart.ChartPoint[]} data
 * @param {(dataset: Chart.ChartDataSets) => Chart.ChartDataSets} joiningSetGenerator 
 * @param {boolean} hideInLegend
 */
 const createDatasetBrokenOnNonSequentialDays = (dataset,data,joiningSetGenerator,hideInLegend=false) => {
  const unbrokenDataset = {...dataset, hideInLegendAndTooltip: true}; // hide legend by default to avoid every segment having a legend
  const brokenData = createArraysWithContinuousSequentialDays(data,"x");
  const result = [];
  for (let i=0; i<brokenData.length; i++) {
    result.push({ ...unbrokenDataset, data: brokenData[i]});
    if (i<brokenData.length-1) {
      const connecting = createJoiningDataPointsArray(brokenData[i],brokenData[i+1]);
      result.push(joiningSetGenerator({...unbrokenDataset, data: connecting}));
    }
  }
  if (result[0]) result[0].hideInLegendAndTooltip = hideInLegend; // Unhide legend of first segment if legend is meant to be visible
  return result;
}

/**
 * @param {Chart.ChartPoint[]} arr1 
 * @param {Chart.ChartPoint[]} arr2 
 */
const createJoiningDataPointsArray = (arr1,arr2) => {
  const first = arr1[arr1.length-1];
  const second = arr2[0];
  return [first,second];
}

/** DATASET GENERATORS */

/**
 * Functions for creating charts.js ChartDataSets given an already generated ChartPoint[] or number[] array.
 * - Includes functions that do not take any data, for adding styling properties to other datasets.
 */
const datasets = {
  /**
   * Styling function for generating a Dataset with invisible points and line point style in legend, showing tooltips on hover
   * @param {string} color 
   * @returns {Chart.ChartDataSets}
   */
  invisiblePointsWithLineLegend: (color) => ({
    backgroundColor: color,
    hoverBackgroundColor: color,
    borderColor: color,
    hoverBorderColor: color,
    pointStyle: "line",
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointRadius: 0,
    pointHoverRadius: 0,
    pointHitRadius: 5,
  }),
  /**
   * @param {Chart.ChartPoint[]|number[]} data 
   * @returns {Chart.ChartDataSets}
   */
  sleeps: (data) => ({
    label: "Stress Score",
    data,
    backgroundColor: styles.sleepBlue,
    hoverBackgroundColor: styles.sleepBlueHover,
    fill: false,
    pointStyle: "circle", //'circle', 'cross', 'crossRot', 'dash', 'line', 'rect', 'rectRounded', 'rectRot', 'star', 'triangle'
    pointRadius: 3,
    pointHoverRadius: 10,
    borderWidth: 1,
    borderColor: styles.sleepBlue,
    hoverBorderColor: styles.sleepBlueHover,
    barThickness: 15,
  }),
  /**
   * @param {Chart.ChartPoint[]|number[]} data 
   * @returns {Chart.ChartDataSets}
   */
  stress: (data) => {
    // This is almost exactly the same function with createDataForSleep
    // The reason why I haven't used one function for both is (1) clarity in the code, (2) flexibility to differentiate charts if needed
    return {
      label: "Sleep Score",
      data,
      backgroundColor: styles.sleepBlue,
      hoverBackgroundColor: styles.sleepBlueHover,
      fill: false,
      pointStyle: "circle", //'circle', 'cross', 'crossRot', 'dash', 'line', 'rect', 'rectRounded', 'rectRot', 'star', 'triangle'
      pointRadius: 3,
      pointHoverRadius: 10,
      borderWidth: 1,
      borderColor: styles.sleepBlue,
      hoverBorderColor: styles.sleepBlueHover,
      barThickness: 15,
    }
  },
  /**
   * @param {Chart.ChartPoint[]} data 
   * @returns {Chart.ChartDataSets}
   */
  noFodmapMeal: (data) => ({
    name: "meal fixers",
    type: "line",
    label: "No known FODMAP",
    yAxisID: "dynamic",
    data,
    backgroundColor: styles.mealBackground,
    hoverBackgroundColor: `${styles.mealBackgroundHover}`,
    fill: false,
    pointStyle: "rectRounded",
    pointRadius: 8,
    pointHoverRadius: 9,
    borderColor: `${styles.mealBorder}`,
    barThickness: 15,
  }),
  /**
   * @param {Chart.ChartPoint[]} data 
   * @param {string} label 
   * @returns {Chart.ChartDataSets}
   */
  seperateFodmapMeal: (data,label) => {
    return {
      name: "fodmap:" + label,
      type: "bar",
      label: label,
      yAxisID: "dynamic",
      data,
      backgroundColor: styles.mealGreen + "40", // make transparent
      hoverBackgroundColor: styles.mealGreen,
      borderColor: styles.mealGreen,
      hoverBorderColor: styles.mealGreen,
      borderWidth: { top: 1.5 }, // only put a border on top to separate fodmaps
      barThickness: 15,
    }
  },
  /**
   * 
   * @param {Chart.ChartPoint[]} data 
   * @param {string} label 
   * @param {[DateTime,DateTime]} range
   * @returns {Chart.ChartDataSets}
   */
  combinedFodmapMeal: (data,label,[startDT,endDT]) => {
    const days = endDT.diff(startDT,"days").as("days");
    return {
      ...datasets.seperateFodmapMeal(data,label),
      name: "combinedFodmaps",
      hideInLegendAndTooltip: true,
      barThickness: 15 - 2*Math.floor(days/28), // progressively reduce width of bars for larger and larger time ranges
    }
  },
  /**
   * @param {Chart.ChartPoint[]} data 
   * @param {string} label 
   * @param {boolean} hidden 
   * @param {Chart.ChartDataSets} colorsDataset
   * @returns {Chart.ChartDataSets}
   */
  symptom: (data,label,hidden,colorsDataset) => ({
    pointRadius: 3,
    pointHoverRadius: 10,
    showLine: false,
    borderWidth: 2,
    borderDash: undefined,
    fill: false,
    hideInLegendAndTooltip: true,
    ...colorsDataset,
    name: "symptom:" + label.replace(" ", "_").toLowerCase(),
    type: "scatter",
    label: label,
    yAxisID: "static",
    data: data,
    hidden,
  }),
  /**
   * @param {Chart.ChartPoint[]} data 
   * @param {string} label 
   * @param {boolean} hidden 
   * @param {Chart.ChartDataSets} colorsDataset
   * @returns {Chart.ChartDataSets}
   */
  peakSymptoms: (data,label,hidden,colorsDataset) => {
    return {
      ...datasets.symptom(data,label,hidden,colorsDataset),
      ...datasets.invisiblePointsWithLineLegend(styles.symptomPurple),
      ...colorsDataset,
      type: "line",
      showLine: true,
      hideInLegendAndTooltip: false,
    };
  },
  /**
   * @param {Chart.ChartPoint[]} data 
   * @param {Gas} gas 
   * @param {boolean} showLine
   * @param {boolean} hidden
   * @returns {Chart.ChartDataSets}
   */
  ppm: (data,gas,showLine,hidden) => {
    // Use gas color but with reduced opacity
    const backgroundColor = tinycolor(COLORS[gas.gas]).setAlpha(0.4).toHex8String();
    const borderColor = tinycolor(COLORS[gas.gas]).setAlpha(0.1).toHex8String(); 
    return {
      name: `breath tests ${gas.gas}`,
      type: "line",
      showLine,
      label: `${gas.description} (all data)`,
      yAxisID: "static",
      data: data,
      backgroundColor,
      hoverBackgroundColor: backgroundColor,
      pointRadius: 3,
      pointHoverRadius: 10,
      borderColor,
      hoverBorderColor: borderColor,
      borderWidth: 2,
      fill: false,
      hideInLegendAndTooltip: false,
      hidden,
    }
  },
  /**
   * @param {Chart.ChartPoint[]} data 
   * @param {Gas} gas 
   * @param {boolean} hidden
   * @param {boolean} hideInLegend
   * @returns {Chart.ChartDataSets}
   */
  averagePpm: (data,gas,hidden,hideInLegend,) => ({
    hideInLegendAndTooltip: hideInLegend,
    name: `mean breath tests ${gas.gas}`,
    type: "line",
    label: `Daily Avg. ${gas.description}`,
    yAxisID: "static",
    data: data,
    backgroundColor: COLORS[gas.gas],
    hoverBackgroundColor: COLORS[gas.gas],
    pointRadius: 0,
    pointHoverRadius: 10,
    pointHitRadius: 3,
    showLine: true,
    borderColor: COLORS[gas.gas],
    hoverBorderColor: COLORS[gas.gas],
    borderWidth: 2,
    fill: false,
    opacity: 1,
    hidden,
    ...datasets.invisiblePointsWithLineLegend(COLORS[gas.gas]),
  }),
  /**
   * @param {Chart.ChartPoint[]} data
   * @param {string} backgroundColor
   * @param {string} borderColor
   * @returns {Chart.ChartDataSets}
   */
  stool: (data, backgroundColor=undefined,borderColor=undefined) => {
    const _backgroundColor = backgroundColor || tinycolor(styles.stoolOrange).setAlpha(0.4).toHex8String();
    const _borderColor = borderColor || tinycolor(styles.stoolOrange).setAlpha(0.1).toHex8String();

    return {
      pointRadius: 3,
      pointHoverRadius: 10,
      borderWidth: 2,
      borderDash: undefined,
      fill: false,
      backgroundColor: _backgroundColor,
      hoverBackgroundColor: _backgroundColor,
      borderColor: _borderColor,
      hoverBorderColor: _borderColor,
      name: "stool",
      type: "scatter",
      showLine: false,
      label: "Stool Form",
      yAxisID: "static",
      data,
    }
  },
  /**
   * @param {Chart.ChartPoint[]} data 
   * @returns {Chart.ChartDataSets}
   */
  stoolPeak: (data) => ({
    ...datasets.stool(data,styles.stoolsOrange,styles.stoolsOrange),
    name: "stool peak",
    type: "line",
    showLine: true,
    label: "Peak Stool Form",
  }),
  /**
   * @param {Chart.ChartPoint[]} data 
   * @param {boolean} hideInLegend
   * @returns {Chart.ChartDataSets}
   */
  averageStool: (data,hideInLegend=true) => ({
    ...datasets.stool(data),
    name: "stool peak",
    type: "line",
    showLine: true,
    label: "Daily Average Stool Form",
    yAxisID: "static",
    data,
    hideInLegendAndTooltip: hideInLegend,
    ...datasets.invisiblePointsWithLineLegend(styles.stoolOrange),  
  }),
  /**
   * @param {Symptom} symptom 
   * @param {boolean} isRawData 
   * @param {{[label: string]: string}} colorsMap
   * @returns {Chart.ChartDataSets}
   */
  symptomColors: (symptom,isRawData,colorsMap) => {
    const color = colorsMap[symptom.label];
    const backgroundColor = tinycolor(color).setAlpha(isRawData ? 0.4 : 1).toHex8String();
    const borderColor = tinycolor(color).setAlpha(isRawData ? 0.1 : 1).toHex8String();
    return {
      backgroundColor,
      hoverBackgroundColor: backgroundColor,
      borderColor,
      hoverBorderColor: borderColor,
      pointBackgroundColor: backgroundColor,
      pointBorderColor: borderColor,
    }
  },
  /**
   * @param {Chart.ChartDataSets} dataset 
   * @returns {Chart.ChartDataSets}
   */
  disjointConnecting: (dataset) => ({
    ...dataset,
    borderDash: [5,5],
    pointRadius: 0,
    pointHitRadius: 0,
  }),
} 

/* STRESS */

/**
 * @param {Stress[]} stress 
 * @returns {Chart.ChartData}
 */
export const createDataForStress = (stress) => {
  const times = stress?.map(s => s.pertainsTo) ?? [];
  const scores = stress?.map(s => s.score) ?? [];
  return {
    labels: times,
    datasets: [
      datasets.sleeps(scores),
    ],
  };
}

/* SLEEPS */

/**
 * @param {Sleep[]} sleeps 
 * @returns {Chart.ChartData}
 */
export const createDataForSleeps = (sleeps) => {
  // For sleep and stress, we don't only create dataset, but we create the data object itself
  // This is because, here, we provide times as labels
  const times = sleeps?.map(s => s.pertainsTo) ?? [];
  const scores = sleeps?.map((s) => s.score) ?? [];
  return {
    labels: times,
    datasets: [
      datasets.stress(scores),
    ],
  };
}

/* MEALS */

/**
 * @param {Meal[]} meals 
 * @returns {Chart.ChartDataSets[]}
 */
export const createDatasetsForSeparateFodmaps = (meals) => {
  // Create and return a dataset for each meal
  return Object.entries(datapointsByFodmap(meals))
    .filter(([_, fmObj]) => fmObj.some((data) => data.y > 0)) // filter non-existing fodmaps
    .map(([label, fmObj]) => seperateFodmapDataset(fmObj,label));
}

/**
 * @param {Meal[]} meals 
 * @param {[DateTime,DateTime]} range
 * @returns {Chart.ChartDataSets[]}
 */
export const createDatasetsForCombinedFodmps = (meals,range) => {
  const result = combinedDailyMeals(meals)
    .sort((a,b) => a.consumedOn > b.consumedOn ? -1 : 1)
    .map(meal => createDatasetForCombinedFodmaps(meal,range));
  if (result.length > 0) {
    result[0].hideInLegendAndTooltip = false;
  }
  return result;
}

/**
 * Get an array containing one Meal for each day covered by input array of Meals, with each Meal containing summed fodmaps for that day
 * @param {Meal[]} meals 
 * @returns 
 */
const combinedDailyMeals = (meals) => {
  const mealsByDate = consumedOnDateBucketsArray(meals);
  const result = mealsByDate.map(meals => xxNewCombinedDailyMeal(meals));
  return result
}

/**
 * @typedef FmDatapointObj
 * @property {Chart.ChartData[]} Fructose
 * @property {Chart.ChartData[]} Lactose
 * @property {Chart.ChartData[]} Sorbitol
 * @property {Chart.ChartData[]} FOS
 * @property {Chart.ChartData[]} GOS
 * @property {Chart.ChartData[]} Inulin
 * @property {Chart.ChartData[]} Mannitol
 */

/**
 * @param {Meal[]} meals 
 * @returns Object containg all fodmap datapoints in an array for each fodmap covered by input array of meals 
 */
const datapointsByFodmap = (meals) => {
  let result = {
    Fructose: [],
    Lactose: [],
    Sorbitol: [],
    FOS: [],
    GOS: [],
    Inulin: [],
    Mannitol: [],
  };
  const datapoints = meals.map(meal => meal.fodmapDatapoints());
  datapoints.forEach(data => {
    Object.values(data).forEach(fodmapItem => {
      result[fodmapItem.fodmap.name].push(fodmapItem);
    })
  });
  return result;
}

/**
 * @param {Meal[]} meals 
 * @returns {Chart.ChartDataSets}
 */
export const createDatasetForNoFodmapMeals = (meals) => {
  let noFodmapMealsKeys = getNoFodmapMealsKeys(meals);
  let mealFixers = noFodmapMealsKeys.map((mkey) => ({
    x: meals[mkey].consumedOn,
    y: 0,
    meal: meals[mkey],
  }));
  if (mealFixers.length === 0) return {};
  return datasets.noFodmapMeal(mealFixers);
}

/**
 * @param {Chart.ChartPoint[]} fmObj 
 * @param {string} label
 * @returns {Chart.ChartDataSets}
 */
const seperateFodmapDataset = (fmObj,label) => {
  return datasets.seperateFodmapMeal(fmObj,label);
}

/**
 * @param {Meal[]} meals 
 * @returns 
 */
function getNoFodmapMealsKeys(meals) {
  let mealKeys = Object.keys(meals);
  let noFodmapMeals = mealKeys.filter((mkey) => {
    let foods = meals[mkey].foods;
    let foodKeys = Object.keys(foods);
    let totalFodmapValue = foodKeys.reduce((acc, fkey) => {
      let fodmaps = foods[fkey].fodmaps;
      let fodmapValue = Object.values(fodmaps).reduce(
        (acc, val) => (acc += val),
        0
      );
      return (acc += fodmapValue);
    }, 0);
    return totalFodmapValue === 0;
  });
  return noFodmapMeals;
}

/**
 * @param {Meal} combinedMeal
 * @param {[DateTime,DateTime]} range
 */
const createDatasetForCombinedFodmaps = (combinedMeal,range) => {
  const datapoint = {
    x: combinedMeal.consumedOn,
    y: combinedMeal.totalFodmapSum(),
    meal: combinedMeal,
  }
  return datasets.combinedFodmapMeal([datapoint],"Daily Total FODMAPs",range);
}

/* SYMPTOMS */

/**
 * 
 * @param {Symptom[]} symptoms 
 * @param {[DateTime,DateTime]} range 
 */
export const createDatasetsForPeakSymptoms = (symptoms,range,labelOrder) => {
  const peakSymptoms = maxDailySymptomsArrays(symptoms).flat();
  const colorsMap = generateSymptomColorMap(symptoms);
  return Object.entries(symptomsByLabel(peakSymptoms))
    .filter(([_,data]) => data.length > 0)
    .map(([label,symptoms],index) => {
      const data = symptoms.filter(s => s.label === label).map(s => s.datapoint());
      return datasets.peakSymptoms(data,isDayToDayAggregateView(range) ? `Max ${label}` : label ,index>2,datasets.symptomColors(symptoms[0],false,colorsMap));
    });
}

/**
 * @param {Symptom[]} symptoms 
 * @param {string[]} labelOrder
 * @returns 
 */
export const createDatasetsForRawSymptoms = (symptoms,labelOrder) => {
  const colorsMap = generateSymptomColorMap(symptoms);
  return Object.entries(symptomsByLabel(symptoms))
    .filter(([_,data]) => data.length > 0)
    .sort(([l1,s1],[l2,s2]) => labelOrder.indexOf(l1) < labelOrder.indexOf(l2) ? -1 : 1)
    .map(([label,symptoms],index) => {
      const data = symptoms.filter(s => s.label === label).map(s => s.datapoint());
      return datasets.symptom(data,label,index>2,datasets.symptomColors(symptoms[0],true,colorsMap));
    });
}

/**
 * @param {Symptom[]} symptoms 
 * @returns {{[k: string]: Symptom[]}}
 */
const symptomsByLabel = (symptoms) => {
  return symptoms.reduce((acc,s) => {
    if (!acc[s.label]) acc[s.label] = [];
    acc[s.label].push(s);
    return acc;
  },{});
}

const BaseSymptomColors = {
  purple: "#bd10e0",
  yellow: "#f0c135",
  blue: "#005a70",
}

const CUSTOM_SYMPTOM_COLORS = [
  "#C99161",
  "#FBAFE4",
  "#949494",
  "#009E73",
];

const NAMED_SYMPTOM_COLORS = {
  "Abdominal Pain": "#D55E00",
  "Bloating": "#57B4E9",
  "Distention": "#0173B2",
  "Flatulence": "#DE9006",
  "Heartburn": "#CB78BC",
  "Nausea": "#ECE134",
}

/**
 * @param {Symptom[]} symptoms 
 * @returns {{[label: string]: string}}
 */
const generateSymptomColorMap = (symptoms) => {
  if (symptoms.length === 0) return {};
  
  const backupColors = [...CUSTOM_SYMPTOM_COLORS].reverse();
  const labels = Array.from(new Set(symptoms.map(s => s.label)));
  return labels.reduce((acc,label) => {
    if (!acc[label]) acc[label] = getColorForDataset(NAMED_SYMPTOM_COLORS,label,backupColors);
    return acc;
  },{});
}

/* PPMS */

/**
 * @param {Ppm[]} ppms 
 * @param {Gas} gas 
 * @param {boolean} showLine
 * @param {boolean} hidden
 * @returns {Chart.ChartDataSets}
 */
export const createDatasetForPpms = (ppms,gas,showLine,hidden) => {  
  const data = ppmsWithGas(ppms,gas).map(ppm => new PpmDatapoint(ppm));
  if (data.length === 0) return {};
  return datasets.ppm(data,gas,showLine,hidden);
}

/**
 * Creates datasets for average ppm line chart
 *  - Broken into different datasets when tests occur on non-sequential days, with those gaps displayed as a dashed line to avoid misrepresenting trends
 * @param {Ppm[]} ppms 
 * @param {Gas} gas 
 * @param {boolean} hidden
 * @returns 
 */
export const createDatasetsForAveragePpms = (ppms,gas,hidden) => {
  const data = dailyAveragePpms(ppms,gas).map(ppm => new PpmDatapoint(ppm));
  const dataset = datasets.averagePpm(data,gas,hidden,true);
  return createDatasetBrokenOnNonSequentialDays(dataset,data,datasets.disjointConnecting);
}

/* STOOL */

/**
 * @param {Symptom[]} stools 
 * @return {Chart.ChartDataSets}
 */
export const createDatasetForStool = (stools) => {
  const data = stools.map(stool => stool.datapoint()).filter(data => data.y > 0);
  if (data.length === 0) return {}; 
  return datasets.stool(data);
}

/**
 * @param {Symptom[]} stools 
 * @returns {Chart.ChartDataSets[]}
 */
export const createDatasetsForAverageStool = (stools) => {
  const data = dailyAverageSymptomsWithLabel(stools,SymptomLabel.STOOL_FORM).map(s => s.datapoint());
  const dataset = datasets.averageStool(data,true);
  return createDatasetBrokenOnNonSequentialDays(dataset,data,datasets.disjointConnecting);
}