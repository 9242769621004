import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useInvoice from "./useInvoice";
import Invoice from "../../pdf-generator/templates/Invoice/Invoice";
import { AppError, downloadDocument } from "../../utils/utils";
import InvoiceDocument from "../../pdf-generator/templates/Invoice/InvoiceDocument";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import s from "./DownloadInvoice.module.scss"
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import useErrorState from "../../hooks/useErrorState";

interface UrlParams {
  invoiceToken: string;
}

export default function DownloadInvoice(): JSX.Element {
  const [downloading, setDownloading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useErrorState({
    code: 400,
    message: "An error occurred generating invoice PDF",
    isOperational: false,
  });

  const { invoiceToken }: UrlParams = useParams();
  const { invoice, error: invoiceError } = useInvoice(invoiceToken);

  useEffect(() => {
    if (!invoice) return;
    if (downloading || complete || error) return;
    setDownloading(true);
    downloadInvoice(invoice)
      .then(() => setComplete(true))
      .catch(setError)
      .finally(() => setDownloading(false));
  },[invoice,downloading,complete]);

  useEffect(() => {
    if (!invoiceError) return;
    setError(invoiceError);
  },[invoiceError]);

  if (error) return <ErrorPage error={error} />

  return (
    <PreloginLayout>
      <div className={s.downloadInvoice}>
        <h3>{downloading ? "Downloading Invoice..." : "Download Complete"}</h3>
        { downloading && <LoadingSpinner />}
      </div>
    </PreloginLayout>
  )
}

const ErrorPage = ({error}: {error: AppError}) => {
  return (
    <PreloginLayout>
      <div className={s.downloadInvoice}>
        <h3>Error {error.code}</h3>
        <p>{error.message}</p>
      </div>
    </PreloginLayout>
  );
}

const downloadInvoice = async (invoice: Invoice) => {
  const document = <InvoiceDocument invoices={[invoice]} />
  await downloadDocument(invoice.filename(),document);
}