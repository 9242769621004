import { useEffect, useState } from "react";
import { AppError } from "../utils/utils";
import useErrorState from "./useErrorState";

function useCdContent<TCat extends CdContentCategory>(category: TCat): UseCdContentReturn<TCat>;
function useCdContent(category: CdContentCategory): any {
  const [content, setContent] = useState<CdContent<any>[]>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (!!content) return;
    const abortController = new AbortController();
    fetchCdContent(category, abortController.signal).then(setContent).catch(setError);
    return () => abortController.abort();
  }, [category]);

  return { content, error };
}

function createMetadata<TCat extends CdContentCategory>(
  category: TCat,
  meta: CdContentMetadata<TCat>
): CdContentMetadata<TCat>;
function createMetadata(category: CdContentCategory, data: any): any {
  switch (category) {
    case "challenge_pdf_follow_on_testing":
      return {
        substrate: data.substrate,
        resultType: data.result_type,
      };
    default:
      throw new TypeError("Invalid category");
  }
}

function createCdContent<TCat extends CdContentCategory>(category: TCat, content: CdContent<TCat>): CdContent<TCat> {
  return {
    cdcid: content.cdcid,
    createdOn: content.createdOn,
    category: content.category,
    isActive: content.isActive,
    metadata: createMetadata(category, content.metadata),
    content: content.content,
  };
}

async function fetchCdContent<TCat extends CdContentCategory>(
  category: TCat,
  signal: AbortSignal
): Promise<CdContent<TCat>[]>;
async function fetchCdContent(
  category: CdContentCategory,
  signal: AbortSignal
): Promise<CdContent<any>[]> {
  const res = await fetch(`/api/cd-content/${category}`, { signal });
  const data = await res.json();
  if (data.error !== undefined) throw new AppError(res.status, data.error);
  const content = data.content;
  if (!(content instanceof Array)) throw new TypeError("Invalid content");
  return content.map((c) => createCdContent(category, c));
}

interface UseCdContentReturn<TCat extends CdContentCategory> {
  content: CdContent<TCat>[] | undefined;
  error: AppError | undefined;
}

export type CdContentCategory = "challenge_pdf_follow_on_testing";

export type ResultType = "positive" | "borderline" | "negative";

type CdContentMetadata<TCat extends CdContentCategory> = TCat extends "challenge_pdf_follow_on_testing"
  ? CdContentMetadata_challenge_pdf_follow_on_testing
  : never;

interface CdContentMetadata_challenge_pdf_follow_on_testing {
  substrate: string;
  resultType: ResultType;
}

export interface CdContent<TCategory extends CdContentCategory> {
  cdcid: number;
  createdOn: Date;
  category: TCategory;
  isActive: boolean;
  metadata: CdContentMetadata<TCategory>;
  content: string;
}

export default useCdContent;