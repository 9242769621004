import { Page, Text, View, Image } from "@react-pdf/renderer";
import { ReactNode } from "react";
import FoodMarbleHealthcareLogo from "../../../../assets/provider-logos/foodmarble-healthcare.png";
import { s } from "../arrears-invoice-style";

export function ArrearsInvoicePage({ children }: { children: ReactNode; }) {
  return (
    <Page style={s.general.body}>
      <Header />
      <View style={s.general.content}>{children}</View>
      <PageNumber />
    </Page>
  );
}

function Header() {
  return (
    <View style={{ height: "130px", width: "300px", paddingLeft: "40px", paddingTop: "34px" }}>
      <Image src={FoodMarbleHealthcareLogo} />
    </View>
  );
}

function PageNumber() {
  return (
    <View style={s.pageNumber.main}>
      <Text
        style={s.pageNumber.content}
        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} />
    </View>
  );
}
