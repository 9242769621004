import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import s from "./PreLoginError.module.scss";

/**
 * Error message component - Displays additional prompt to check URL if error message raised at point of fetching registration details
 * @param {Object} props
 * @param {AppError} props.error
 * @returns 
 */
const PreLoginError = ({error}) => {
  return (
    <PreloginLayout>
      <div className={s.error}>
        <h1>ERROR {error.code}</h1>
        <p>{error.message}</p>
      </div>
    </PreloginLayout>
  );
};

export default PreLoginError