import { DateTime } from "luxon"

const tz = {
  toLocal: (millis: number): DateTime => {
    return DateTime.fromMillis(millis,{zone: 'utc'}).toLocal();
  },
  toUTC: (localISO: string): DateTime => {
    return DateTime.fromISO(localISO).setZone('utc');
  },
  toDateMillisUTC: (localISO: string): number => {
    return tz.toUTC(DateTime.fromISO(localISO).toISODate()).toMillis();
  },
}

export default tz