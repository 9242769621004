import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { Patient } from "../../../utils/data-classes/Patient";
import { AppError } from "../../../utils/utils";
import DialogNav from "../DialogNav/DialogNav";
import s from "./ChallengePromptForm.module.scss";
import { ChallengePromptContext } from "./ChallengePromptContext";
import { ClinicianRequestType } from "../../../utils/data-classes/ClinicianRequestType";
import { LanguageCode } from "../../../utils/data-classes/Language";
import { LanguageSelectWidget } from "../../menus/LanguageSelect/LanguageSelect";
import FormErrorMessage from "../../errors/FormErrorMessage/FormErrorMessage";
import RoundLabelledCheckbox from "./RoundLabelledCheckbox/RoundLabelledCheckbox";
import FilledRadioButton from "./FilledRadioButton/FilledRadioButton";
import { Information } from "../../tooltips/Information/Information";
import Modal from "../Modal/Modal";

const minLimits = {
  duration: {
    min: 1,
    max: 360,
  },
  breathInterval: {
    min: 1,
    max: 30,
  }
}

export const ChallengePromptModal = (props: ChallengePromptModalProps) => {  
  const { state: { action, patient, error }, dispatch } = useContext(ChallengePromptContext);
  const isOpen = !!(action !== undefined && patient);
  const close = () => dispatch({type: "RESET"});
  const submitAction = async (data: ChallengePromptFormFieldValues) => dispatch({
    type: "DO_ACTION",
    payload: { action: "CREATE", formData: data },
  });
  return (
    <Modal isOpen={isOpen} onRequestClose={close} contentLabel={props.contentLabel}>
      <div>
        <DialogNav onCloseButtonClick={close} />
        { error && <ChallengePromptError error={error} /> }
        { !error && <>
          { action === "CREATE" && <ChallengePromptForm submitAction={submitAction}/> }
          { action === "REMIND" && <ReminderForm /> }
          { action === "CANCEL" && <DeletionForm /> }
        </>}
      </div>
    </Modal>
  )
}

const ChallengePromptError = ({error}: {error: AppError}) => {
  return (
    <div className={s.chalPromptForm}>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
    </div>
  )
}

export const ChallengePromptForm = ({submitAction}: ChallengePromptFormProps) => {
  const { state: promptState } = useContext(ChallengePromptContext);
  const { clinicianRequestTypesMap, patient: patientToPrompt, processing, success, error } = promptState;

  const [requestType, setRequestType] = useState<ClinicianRequestType>();

  const form = useForm<ChallengePromptFormFieldValues>({
    defaultValues: { 
      crtId: undefined,
      lang: "en",
      durationMins: undefined,
      intervalMins: undefined,
      finishChalOnTwoSuccessivePositive: false,
    }
  });
  const { handleSubmit, watch } = form;

  useEffect(() => {
    if (!clinicianRequestTypesMap) return;
    setRequestType(clinicianRequestTypesMap[watch("crtId")]);
  },[watch("crtId")]);

  if (error) return <Error error={error} />

  if (!patientToPrompt || !clinicianRequestTypesMap) return <></>;

  if (success && requestType) return <CreatedPromptSuccess patient={patientToPrompt} type={requestType} />

  const onSubmit = async (data: ChallengePromptFormFieldValues) => {
    await submitAction(data);
  }

  const { sibo, nonSibo } = getSplitRequestTypes(clinicianRequestTypesMap);

  return (
    <form className={s.chalPromptForm} onSubmit={handleSubmit(onSubmit)}>
      <BasicOptions form={form} sibo={sibo} nonSibo={nonSibo} />
      <AdvancedOptions form={form} requestType={requestType} />
      <button type="submit" disabled={processing}>{processing ? "Sending challenge prompt..." : "Submit"}</button>
    </form>
  );
}

const SimpleForm = (props: SimpleFormProps) => {
  const { state: { loginToken } } = useContext(LoginContext);
  const { state: { action, patient: patientToPrompt, requestedChallenge, processing, success }, dispatch: challengePromptDispatch } = useContext(ChallengePromptContext);

  const form = useForm();

  useEffect(() => {
    if (!patientToPrompt || action === props.action) return;
    challengePromptDispatch({type: "INIT_CHALLENGE_FORM", payload: {
      action: props.action,
      patient: patientToPrompt,
      requestedChallenge,
    }});
  },[patientToPrompt,requestedChallenge]);

  if (!loginToken || !patientToPrompt || !requestedChallenge) return <></>

  const onSubmit = async () => {
    challengePromptDispatch({
      type: "DO_ACTION",
      payload: { action: props.action }
    });
  }

  if (success) return <Success><p>{props.successMsg}</p></Success>

  return (
    <form className={s.reminderForm} onSubmit={form.handleSubmit(onSubmit)}>
      <h1>{props.title}</h1>
      <p>{props.description}</p>
      <div className={s.buttons}>
        <button type="button" disabled={processing} onClick={() => challengePromptDispatch({type: "RESET"})}>No</button>
        <button type="submit" disabled={processing} title={props.title}>Yes</button>
      </div>
    </form>
  );
}

const ReminderForm = () => {
  const { requestedChallenge } = useContext(ChallengePromptContext).state;
  return <SimpleForm
    action="REMIND"
    title="Send Challenge Reminder"
    description={`Would you like to remind this patient to complete their ${requestedChallenge} challenge?`}
    successMsg="Challenge reminder sent successfully"
  />;
}

const DeletionForm = () => {
  const { requestedChallenge } = useContext(ChallengePromptContext).state;
  return <SimpleForm
    action="CANCEL"
    title="Cancel Requested Challenge"
    description={`Would you like to cancel this patient's ${requestedChallenge} challenge?`}
    successMsg="Challenge request cancelled successfully"
  />;
}

const getSplitRequestTypes = (requestTypesMap: Partial<Record<number,ClinicianRequestType>>): ClinicianRequestTypeSplit => {
  return Object.values(requestTypesMap).reduce<ClinicianRequestTypeSplit>((acc,cur) => {
    if (!cur) return acc;
    if (cur.isSIBO()) acc.sibo.push(cur);
    else acc.nonSibo.push(cur);
    return acc;
  },{ sibo: [], nonSibo: []});
}

const SubstrateRadioButtons = ({requestTypes,form}: SubstrateRadioButtonsProps) => {
  return (
    <>
      { requestTypes.map((type,i) => (
        <Controller
          key={i}
          control={form.control}
          name="crtId"
          render={({field: { name, onChange }}) => <FilledRadioButton id={`substrate-radio-${type.label}`} label={type.label || ""} name={name} value={type.crtId} onChange={onChange} />}
          rules={{
            required: "Substrate is required",
          }}
        />
      ))}
    </>
  );
}

const BasicOptions = ({form,sibo,nonSibo}: {form: UseFormReturn<ChallengePromptFormFieldValues,any>,sibo: ClinicianRequestType[], nonSibo: ClinicianRequestType[]}) => (
  <div className={s.basicOptions}>
    <h1>Select Challenge</h1>
    <p>Please select the substrate you would like the patient to use for their Challenge:</p>
    <div className={s.fodmapLists}>
      <div className={s.radioList}>
        <h3>SIBO</h3>
        <SubstrateRadioButtons form={form} requestTypes={sibo} />
      </div>
      <div className={s.radioList}>
        <h3>FODMAPs</h3>
        <SubstrateRadioButtons form={form} requestTypes={nonSibo} />
      </div>
    </div>
    <div className={s.languageSelect}>
      <LanguageSelectWidget control={form.control} name="lang" selected={form.watch("lang")} />
    </div>
    <FormErrorMessage errors={form.formState.errors} name="lang"/>
    <FormErrorMessage errors={form.formState.errors} name="crtId"/>
  </div>
)

const AdvancedOptions = ({form,requestType}: {form: UseFormReturn<ChallengePromptFormFieldValues,any>,requestType: ClinicianRequestType|undefined}) => (
  <div className={s.advancedOptions}>
    <h1>Advanced Options</h1>
    <p>To change Challenge parameters, use the dropdown menus below.</p>
    <div className={s.advancedOptionsInputs}>
      <DurationInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="durationMins" />
      <BreathIntervalInput form={form} requestType={requestType} />
      <FormErrorMessage errors={form.formState.errors} name="intervalMins" />
      <FinishChalCheckbox form={form} />
      <FormErrorMessage errors={form.formState.errors} name="finishChalOnTwoSuccessivePositive" />
    </div>
  </div>
);

const durationMinsOptions = [120,180,240,300];

const DurationInput = ({form,requestType}: {form: UseFormReturn<ChallengePromptFormFieldValues,any>,requestType: ClinicianRequestType|undefined}) => {
  const { watch, register, setValue } = form;

  useEffect(() => {
    setValue("durationMins",defaultDuration(requestType));
  },[requestType]);

  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="chal-duration">Challenge duration:</label>
      <select id="chal-duration" defaultValue="" disabled={!watch("crtId")} {...register("durationMins",{
        required: "Duration is required",
        valueAsNumber: true,
        min: {
          value: minLimits.duration.min,
          message: `Minimum duration in minutes is ${minLimits.duration.min}`
        },
        max: {
          value: minLimits.duration.max,
          message: `Maximum duration in minutes is ${minLimits.duration.max}`,
        }
      })}>
        { !requestType && <option value=""></option>}
        { requestType && durationMinsOptions.map((n,i) => <option key={i} value={n}>{n} minutes</option>)}
      </select>
      <Tooltip data={undefined} />
    </div>
  );
}

function defaultDuration(requestType: ClinicianRequestType|undefined): number {
  switch (requestType?.label) {
    case "Lactulose": return 120;
    case "Glucose": return 120;
    default: return 180;
  }
}

function defaultInterval(requestType: ClinicianRequestType|undefined): number {
  switch (requestType?.label) {
    case "Inulin": return 30;
    default: return 15;
  }
}

const breathIntervalOptions = [15, 20, 30];

function allowedIntervals(duration: number) {
  if (duration >= 300) return [30];
  return breathIntervalOptions;
}

const BreathIntervalInput = ({form,requestType}: {form: UseFormReturn<ChallengePromptFormFieldValues,any>,requestType: ClinicianRequestType|undefined}) => {
  form.setValue("intervalMins",defaultInterval(requestType));
  return (
    <div className={s.numericInputContainer}>
      <label htmlFor="breath-interval">Breath test interval:</label>
      <select id="breath-interval" defaultValue={defaultInterval(requestType)} {...form.register("intervalMins",{
        required: "Breath test interval is required",
        valueAsNumber: true,
        min: {
          value: minLimits.breathInterval.min,
          message: `Minimum breath test interval minutes is ${minLimits.breathInterval.min}`,
        },
        max: {
          value: minLimits.breathInterval.max,
          message: `Maximum breath tes interval minutes is $${minLimits.breathInterval.max}`,
        }
      })}>
        {requestType && allowedIntervals(form.watch("durationMins")).map((n,i) => <option key={i} value={n}>{n} minutes</option>)}
      </select>
      <Tooltip data={intervalTooltipContent(requestType,form.watch("durationMins"))} />
    </div>
  );
}

const Tooltip = ({data}: {data: string|undefined}) => {
  return <span>{data !== undefined && <Information data={data} />}</span>
}

function intervalTooltipContent(requestType: ClinicianRequestType | undefined,chalDuration: number): string | undefined {
  if (requestType?.label === "Inulin") return "Breath interval will change to 15 minutes once a rise is detected. After 3 hours patients are asked if they would like to continue testing. Patients are permitted to eat a small low FODMAP meal at this point.";
  if (chalDuration >= 300) return "Breath interval will change to 15 minutes once a rise is detected.";
  return undefined;
}

const FinishChalCheckbox = ({form}: {form: UseFormReturn<ChallengePromptFormFieldValues,any>}) => (
  <RoundLabelledCheckbox id="finish-challenge-checkbox" reg={form.register("finishChalOnTwoSuccessivePositive")} >
    Finish Challenge when two successive breath tests reach positive criteria
  </RoundLabelledCheckbox>
)

const CreatedPromptSuccess = ({patient,type}: {patient: Patient, type: ClinicianRequestType}) => {
  const { state: { provider } } = useContext(LoginContext);

  const smsSent = patient.phone && provider?.hasPhoneAccess;

  return (
    <Success>
      <p>{type.label} challenge prompt email {smsSent ? " and SMS " : ""}sent to {patient.name}</p>
    </Success>
  );
}

const Success = ({children}: {children: React.ReactNode}) => {
  return (
    <div className={s.success}>
      <h1>Success</h1>
      {children}
    </div>
  );
}

/**
 * @param {Object} props
 * @param {AppError} props.error 
 * @returns 
 */
const Error = ({error}: {error: AppError}) => (
  <div className={s.error}>
    <h1>Error {error.code}</h1>
    <p>{error.message}</p>
  </div>
)

interface ChallengePromptModalProps {
  defaultLabel?: string,
  contentLabel?: string,
}

export interface ChallengePromptFormFieldValues {
  crtId: number;
  lang: LanguageCode;
  durationMins: number;
  intervalMins: number;
  finishChalOnTwoSuccessivePositive: boolean;
}

interface ChallengePromptFormProps {
  submitAction: (data: ChallengePromptFormFieldValues) => Promise<void>,
}

interface SubstrateRadioButtonsProps {
  requestTypes: ClinicianRequestType[],
  form: UseFormReturn<ChallengePromptFormFieldValues,any>,
}

type ClinicianRequestTypeSplit = {
  sibo: ClinicianRequestType[];
  nonSibo: ClinicianRequestType[];
}

interface SimpleFormProps {
  action: "REMIND" | "CANCEL";
  title: string;
  description: string;
  successMsg: string;
}