/**
 * This module contains
 *  - Enum classes for storing and comparying API routes
 *  - APIRoute-calling functions
 *      - These wrap `fetch` with error handling to return a uniform response of the form
 *          - { ...responseData, error: APPError | undefined } 
 */

import { AppError, devLog } from "./utils";

/**
 * Class for generating enums for particular API routes
 */
export class APIRoute {
  #_path;
  /**
   * @param {String} path
   */
  constructor(path) {
    this.#_path = `${path}`;
  }

  get path() {
    return this.#_path;
  }
}

/**
 * Enum class for Clinical Dashboard backend API routes
 */
export class DashAPIRoute extends APIRoute {
  static ACTIVATE_PATIENT = new DashAPIRoute('activate-patient');
  static ADD_CUSTOMER = new DashAPIRoute('add-customer');
  static ADD_ORDER = new DashAPIRoute('add-order');
  static ADD_PATIENT = new DashAPIRoute('add-patient');
  static ADD_STAFF = new DashAPIRoute('add-staff');
  static CREATE_PAYMENT_INTENT = new DashAPIRoute('create-payment-intent');
  static CREATE_PW_RESET_KEY = new DashAPIRoute('create-pw-reset-key');
  static GET_DIGEST = new DashAPIRoute('digest');
  static GET_PATIENT = new DashAPIRoute('get-patient');
  static GET_PATIENT_REGISTRATION_DETAILS = new DashAPIRoute('get-patient-registration-details');
  static GET_PATIENT_PASSWORD_RESET_LINK = new DashAPIRoute('get-patient-password-reset-link');
  static GET_PROVIDER = new DashAPIRoute('get-provider');
  static GET_PROVIDERS = new DashAPIRoute('get-all-providers');
  static GET_SITE_OVERVIEW = new DashAPIRoute('get-site-overview');
  static GET_SUMMARIES = new DashAPIRoute('get-summaries');
  static GET_SUMMARY = new DashAPIRoute('get-summary');
  static GET_STAFF_SIGNUP_INFO = new DashAPIRoute('get-staff-signup-info');
  static INSERT_BILLING = new DashAPIRoute('insert-billing');
  static INSERT_ORDER_STATUS_LOG = new DashAPIRoute('insert-order-status-log');
  static INVITE_PATIENT = new DashAPIRoute('invite-patient');
  static INVITE_STAFF = new DashAPIRoute('invite-staff');
  static LOG_CD_ACTION = new DashAPIRoute('log-cd-action');
  static LOGIN = new DashAPIRoute('login');
  static UPDATE_STAFF_PASSWORD = new DashAPIRoute("update-staff-password");
  static UPDATE_ORDER = new DashAPIRoute('update-order');
  static SEND_CHALLENGE_PROMPT = new DashAPIRoute('send-challenge-prompt');
  static VALIDATE_PASSWORD_RESET_KEY = new DashAPIRoute("validate-password-reset-key");
  static VERIFY_TOKEN = new DashAPIRoute('verify-token');

  /**
   * @param {String} name
   */
  constructor(name) {
    const path = `/api/${name}`;
    super(path);
  }
}

export class APIRequest {
  #route;
  #options;
  #params;
  /**
   * @param {APIRoute} route 
   * @param  {...any} params 
   */
  constructor(route,...params) {
    this.#route = route;
    this.#params = params;
    this.#options = {}
  }

  get path() {
    const extension = this.#params.length > 0 ? `/${this.#params.join('/')}` : "";
    return `${this.#route.path}${extension}`;
  }

  /**
   * @param {APIRoute} route 
   */
  setRoute(route) {
    this.#route = route;
    return this;
  }

  setOptions(options) {
    this.#options = options;
    return this;
  }

  /**
   * @param {String} method 
   */
  setMethod(method) {
    this.#options.method = method.toUpperCase();
    return this;
  }

  setBody(body) {
    this.#options.body = JSON.stringify(body);
    return this;
  }

  /**
   * @param {AbortSignal} signal 
   */
  setSignal(signal) {
    this.#options.signal = signal;
    return this;
  }

  validate() {
    const methods = ["POST","GET"];
    if (!(this.#route instanceof APIRoute)) throw new TypeError(`Invalid APIRoute`);
    if (!(methods.includes(this.#options.method))) throw new TypeError("Invalid HTTP method");
    if (this.#options.method === "POST" && !this.#options.body) throw new TypeError("Body not found for method `POST`");
    if (this.#options.signal && !(this.#options.signal instanceof AbortSignal)) throw new TypeError("Invalid object for option `signal`: required object: AbortSignal");
  }

  /**
   * @returns {Promise<{[k: string]: *, error: AppError}>}
   */
  async invoke() {
    this.validate();

    devLog(`Fetching ${this.path}`);
    const res = await fetch(this.path,this.#options).catch(error => error);
    if (res instanceof Error) return { error: new AppError(400,"Communication Error") };
  
    const data = await res.json();
    const { error } = data;
    const result = { ...data, error: error ? new AppError(res.status,error) : undefined }
    return result;
  }

  /**
   * @param {Object} body 
   * @param {AbortSignal} signal 
   */
  post(body,signal=undefined) {
    this.setMethod("POST");
    this.setBody(body);
    this.setSignal(signal);
    return this.invoke();
  }

  /**
   * @param {AbortSignal} signal 
   * @returns 
   */
  get(signal=undefined) {
    const string = "string";
    this.setMethod("GET");
    this.setSignal(signal);
    return this.invoke();
  }
}