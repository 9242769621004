import DashLayout from "../../layouts/dash/DashLayout";
import s from "./DashError.module.scss";

const DashError = () => {
  return (
    <DashLayout>
      <div className={s.error}>
        <h1>Something Went Wrong</h1>
        <p>
          If the problem persists, please contact us via{" "}
          <a href="mailto:healthcare@foodmarble.com">healthcare@foodmarble.com</a>.
        </p>
      </div>
    </DashLayout>
  );
};

export default DashError;
