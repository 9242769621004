import { isValidPhoneNumber } from "react-phone-number-input";

export const validator: TValidator = {
  phoneNumber: (message) => (phoneNumber) => (!phoneNumber || isValidPhoneNumber(phoneNumber)) || message,
}

const _validationRegEx = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  emailPrefix: /^[A-Z0-9._%+-]+$/i,
  zipCodeUS: /^(?!0{3})[0-9]{3,5}$/,
  domainName: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
}

export const validationRegEx: Record<keyof (typeof _validationRegEx),RegExp> = _validationRegEx;

type TValidatorFunction<T> = (k: T) => true | string;
type TValidator = {
  phoneNumber: (message: string) => TValidatorFunction<string>;
}
