import { DateTime } from "luxon";
import { localISO } from "../../../../server/database/helpers";
import tz from "../tz";
import Fodmap from "./Fodmap";
import FodmapDatapoint from "./FodmapDatapoint";
import Food, { createFoods } from "./Food";

class Meal {
  readonly createdOn: string;
  readonly consumedOn: string;
  readonly label: string;
  readonly sublabel: string|undefined;
  readonly foods: Food[];
  private readonly foodIdMap: Partial<{ [foodId: number]: Food }>;
  constructor(createdOnMs: number,consumedOnMs: number,label: string,sublabel: string|null|undefined,foods: Food[]) {
    this.createdOn = tz.toLocal(createdOnMs).toISO();
    this.consumedOn = tz.toLocal(consumedOnMs).toISO();
    this.label = label;
    this.sublabel = sublabel ?? undefined;
    this.foods = foods;

    this.foodIdMap = Object.fromEntries(this.foods.map(food => [food.foodId,food]));
  }

  /**
   * @returns Array of all fodmaps in foods in meal
   */
  get fodmaps(): Fodmap[] {
    const result: Fodmap[] = [];
    this.foods.forEach(food => food.fodmaps.forEach(fodmap => result.push(fodmap)));
    return result;
  }

  fodmapSum(label: string): number {
    return this.foods.reduce((sum,food) => sum + (food.getFodmap(label)?.value ?? 0),0);
  }

  /**
   * @returns Array with a Fodmap object storing summed value for that fodmap in meal
   */
  summedFodmaps(): Fodmap[] {
    const sums = Object.fromEntries(Fodmap.LABELS.map(label => [label,0]));
    this.fodmaps.forEach(fodmap => sums[fodmap.label] += fodmap.value);
    const result = Fodmap.LABELS.map(label => new Fodmap(label,sums[label]));
    return result;
  }

  getFood(foodId: number): Food | undefined {
    return this.foodIdMap[foodId];
  }

  /**
   * @returns sum of all fodmap values in meal
   */
  totalFodmapSum(): number {
    return this.foods.reduce((sum,food) => sum + food.totalFodmaps(),0);
  }

  fodmapDatapoints(): { [fodmapName: string]: FodmapDatapoint; } {
    const result = Object.fromEntries(this.summedFodmaps().map(fodmap => [
      fodmap.name,
      new FodmapDatapoint(fodmap,this),
    ]));
    return result;
  }
}

interface RawMeal {
  createdOnMs: number;
  consumedOnMs: number;
  label: string;
  sublabel: string;
  foods: any[];
}

export const createMeal = ({createdOnMs,consumedOnMs,label,sublabel,foods}: RawMeal): Meal => {
  return new Meal(createdOnMs,consumedOnMs,label,sublabel,createFoods(foods));
}

export const createMeals = (rawMeals: RawMeal[]): Meal[] => {
  return rawMeals.map(rawMeal => createMeal(rawMeal));
}

export const fodmapSumsInMeals = (meals: Meal[],label: string) => {
  return meals.reduce((sum,meal) => sum + meal.fodmapSum(label),0);
}

export const combinedFodmapSumInMeals = (meals: Meal[]) => {
  return Fodmap.LABELS.reduce((sum,label) => sum + fodmapSumsInMeals(meals,label),0);
}

export const xxNewCombinedDailyMeal = (mealsOnSameDay: Meal[]) => {
  const consumedOn = tz.toDateMillisUTC(mealsOnSameDay[0].consumedOn);
  const createdOn = tz.toDateMillisUTC(mealsOnSameDay[0].createdOn)
  const label = "Total FODMAPs";
  const sublabel = undefined;

  const fodmaps = summedFodmapsInMeals(mealsOnSameDay);
  const foods = [new Food(-1,"combined-foods",null,null,null,null,null,fodmaps)];
  const result = new Meal(createdOn,consumedOn,label,sublabel,foods);
  return result;
}

/**
 * @param {Meal[]} meals 
 * @returns
 */
const summedFodmapsInMeals = (meals: Meal[]): Fodmap[] => {
  const flatFodmapsArr = meals.map(meal => meal.fodmaps).flat();
  const fodmapSums = flatFodmapsArr.reduce<{[fodmapLabel: string]: number}>((sums,f) => {
    if (!sums[f.label]) sums[f.label] = 0;
    sums[f.label] += f.value;
    return sums;
  },{});
  const result = Object.entries(fodmapSums).map(([label,value]) => new Fodmap(label,value));
  return result
}

export default Meal;