import Header from "../../components/header/Header";
import InternalDeploymentWarningBanner from "../../components/internal-deployment-warning/InternalDeploymentWarningBanner";
import s from "./PreloginLayout.module.scss";

export default function PreloginLayout({ children }) {
  return (
    <div className={s.prelayout}>
      <InternalDeploymentWarningBanner />
      <Header />
      <main>{children}</main>
    </div>
  );
}
