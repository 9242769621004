import React, { useContext } from "react";
import { ReactComponent as FlaskBellIcon } from "../../../assets/icons/flask-bell-icon.svg";
import { ReactComponent as FlaskIcon } from "../../../assets/icons/flask-icon.svg";
import { Patient } from "../../../utils/data-classes/Patient";
import TooltipButton from "../TooltipButton/TooltipButton";
import s from "./ChallengePromptModalButton.module.scss";
import 'tippy.js/dist/tippy.css'
import { ChallengePromptAction, ChallengePromptContext, ChallengePromptContextType } from "../../dialogs/ChallengePromptModal/ChallengePromptContext";
import { ReactComponent as CancelIcon } from "../../../assets/icons/bin.svg";

type ChallengePromptModalButtonProps = React.HTMLProps<HTMLButtonElement> & {
  action: ChallengePromptAction;
  patient: Patient;
  requestedChallenge?: string|undefined;
}

const ChallengePromptModalButton = ({action,patient,requestedChallenge,...rest}: ChallengePromptModalButtonProps) => {
  const { state: { promptedPatients }, dispatch } = useContext(ChallengePromptContext);
  const isSent = promptedPatients.includes(patient);
  const title = createTitle(action,patient,isSent);
  const disabled = isDisabled(rest.disabled,action,isSent);
  const className = s.promptButton;

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: "INIT_CHALLENGE_FORM",
      payload: {
        action,
        patient,
        requestedChallenge,
      }
    })
  }

  return (
    <TooltipButton tooltipMessage={title} title={title} disabled={disabled} onClick={onClick}>
      { action === "CREATE" && <FlaskIcon width={30} height={30} className={className} /> }
      { action === "REMIND" && <FlaskBellIcon width={36} height={36} className={s.promptButton} /> }
      { action === "CANCEL" && <CancelIcon width={40} height={36} className={s.promptButton} /> }
    </TooltipButton>
  );
}

function isDisabled(disabled: boolean | undefined, action: ChallengePromptAction,isSent: boolean) {
  if (disabled !== undefined) return disabled;
  if (action === "CANCEL") return false;
  return isSent;
}

function createTitle(action: ChallengePromptAction,patient: Patient,isSent: boolean) {
  if (action === "CANCEL") return "Cancel requested challenge";
  if (isSent) return `Patient challenge prompt email ${patient.phone ? "& SMS " : ""}already sent`;
  if (action === "CREATE") return "Prompt patient to complete a challenge";
  if (action === "REMIND") return "Remind patient to complete a challenge";
  return "";
}

export default ChallengePromptModalButton;