import useSales from "../useSales";
import s from "../Sales.module.scss";
import PageTitle from "../../../components/PageTitle/PageTitle";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import SalesDetailedOverviewTable from "./SalesDetailedOverviewTable";
import React from "react";
import DashLayout from "../../../layouts/dash/DashLayout";

export default function SalesDetailedOverview() {
  const { sales, error } = useSales();

  return (
    <DashLayout className={s.salesLayout}>
      <div className={s.top}>
        <div className={s.desc}>
          <PageTitle>Detailed Sales Overview</PageTitle>
          <p>
            This page shows a detailed breakdown of your sales and earnings to date.
            For an overview of total sales, click "overview" to the left.  
          </p>
        </div>
      </div>
      <div className={s.tableContainer}>
        { !sales && <LoadingSpinner />}
        { sales && <SalesDetailedOverviewTable sales={sales} /> }
      </div>
    </DashLayout>
  )
}