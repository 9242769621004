export type FoodIntoleranceSubstrate = "fructose" | "inulin" | "lactose" | "sorbitol" | "sucrose";

export type SIBOSubstrate = "glucose" | "lactulose";

export type Substrate = FoodIntoleranceSubstrate | SIBOSubstrate;

export const SIBO_SUBSTRATES = ["glucose","lactulose"];

export const FOOD_INTOLERANCE_SUBSTRATES = ["fructose","inulin","lactose","sorbitol","sucrose"];

export const SUBSTRATES = [...SIBO_SUBSTRATES,...FOOD_INTOLERANCE_SUBSTRATES];

export function isSubstrate(str: string) {
  return SUBSTRATES.includes(str);
}

export function isFoodIntoleranceSubstrate(str: string) {
  return FOOD_INTOLERANCE_SUBSTRATES.includes(str);
}

export function isSIBOSubstrate(str: string) {
  return SIBO_SUBSTRATES.includes(str);
}