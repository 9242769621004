import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { ControlledPhoneInput, ControlledPhoneInputProps } from "../../../../components/inputs/ControlledInputs/ControlledInputs";
import s from "./IFTAPhoneInput.module.scss";

type IFTAPhoneInputProps<TFieldValues extends FieldValues> = 
  & Omit<React.HTMLProps<HTMLInputElement>,"id"|"form"> 
  & ControlledPhoneInputProps<TFieldValues> & { 
    id: string,
    label: string,
  }

export default function IFTAPhoneInput<TFieldValues extends FieldValues>({id,label,...rest}: IFTAPhoneInputProps<TFieldValues>) {
  return (
    <div className={s.IFTAPhoneInput}>
      <ControlledPhoneInput className={s.IFTAPhoneInput} {...rest } />
      <label className={s.iftaLabel} htmlFor={id}>{label}</label>
    </div>
  );
}