import { loadStripe } from "@stripe/stripe-js";

// set stripe as a global variable (or set event listener for it) before the component even initializes
const stripePublicKey = process.env.REACT_APP_ENV === "prod"
  ? "pk_live_yS4pix7pUqXRMZbVg1PvRMig"
  : "pk_test_ru53XUEGlkarpWxty69MhUbL";
  
const STRIPE_PROMISE = loadStripe(stripePublicKey);

export default STRIPE_PROMISE;
