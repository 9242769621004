import { DateTime } from "luxon";
import { ArrearsInvoiceItem } from "../../../utils/data-classes/ArrearsInvoice";

export function isLast(index: number, arr: any[]) {
  return index === arr.length - 1;
}

export function getTotal(items: ArrearsInvoiceItem[]) {
  return items.reduce((acc, cur) => acc + cur.quantity * cur.unitPriceNet, 0);
}

export function splitArrayOnThresholdLength<T>(arr: T[], threshold: number) {
  return arr.reduce<T[][]>((acc, item, i) => {
    if (i === 0 || acc[acc.length - 1].length > threshold) acc.push([]);
    acc[acc.length - 1].push(item);
    return acc;
  }, []);
}

/**
 * Ordering of order items in a patient invoice entry.
 * @param a
 * @param b
 * @returns
 */
export function comparePatientInvoiceItems(a: ArrearsInvoiceItem, b: ArrearsInvoiceItem) {
  if (isShipping(a.skuId)) return 2; // Shipping last
  if (isSubstrate(a.skuId)) return 1; // Substrates second
  return 0; // Devices & Combos first
}

function isSubstrate(skuId: number): boolean {
  return skuId === 7 || skuId === 94;
}

function isShipping(skuId: number): boolean {
  return skuId === 8;
}

export function formatMillis(fmt: string, millis: number) {
  return DateTime.fromMillis(millis, { zone: "utc" }).toFormat(fmt);
}

export const DATE_FMT = "dd-MMM-yyyy";

export function date(millis: number, fmt: string): string {
  return DateTime.fromMillis(millis, { zone: "utc" }).toFormat(fmt);
}

