
export type LanguageCode = "en" | "es";

export class Language {
  readonly code: LanguageCode;
  readonly name: string;

  constructor(code: LanguageCode, name: string) {
    this.code = code;
    this.name = name;
  }
}

export const languages: Record<LanguageCode, Language> = {
  en: new Language("en", "English"),
  es: new Language("es", "Español"),
};

export const languageOrder = [
  languages["en"],
  languages["es"],
]
