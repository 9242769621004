import { DateTime } from "luxon";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import { Sku } from "../../../hooks/useSkus";
import { OrderHistoryItem, OrderItem } from "../../../hooks/useOrderHistory";
import { Patient } from "../../../utils/data-classes/Patient";
import usePatientOrderHistory from "./usePatientOrderHistory";
import s from "./Patient.module.scss";
import { useDateFormat } from "../../../hooks/useDateFormat";

export default function PatientOrderHistory({patient}: {patient: Patient|undefined}) {
  const { history } = usePatientOrderHistory(patient?.ptid);
  return (
    <div>
      {(!patient || !history) && <LoadingSpinner />}
      {patient && history && history.length > 0 && <PatientOrderHistoryTable history={history} />}
    </div>
  )
}

function PatientOrderHistoryTable({history}: {history: OrderHistoryItem[]}) {
  return (
    <div className={s.patientOrderHistoryTable}>
      <h3>Orders</h3>
      <div>
        <span>Order Created On</span>
        <span>Order</span>
        <span>Quantity</span>
        <span>Self Pay</span>
        <span>Status</span>
      </div>
      {history.map((item,i) => <OrderRow key={i} item={item} />)}
    </div>
  )
}

function OrderRow({item}: {item: OrderHistoryItem}) {
  return (
    <>
      {item.itemsAndSkus.map((itemAndSku,i) => i === 0 ? <OrderRowTop key={i} item={item} {...itemAndSku} /> : <OrderSubRow key={i} {...itemAndSku} />)}
    </>
  )
}

function OrderRowTop({item,orderItem,sku}: {item: OrderHistoryItem, orderItem: OrderItem, sku: Sku}) {
  return (
    <div>
      <span>{DateTime.fromMillis(item.order.createdOnMs).toFormat(useDateFormat("datetime"))}</span>
      <span>{sku.shortLabel}</span>
      <span>{orderItem.quantity}</span>
      <span>{item.order.isSelfPay ? "✔️" : "❌"}</span>
      <span>{getStatus(item)}</span>
    </div>
  )
}

function OrderSubRow({orderItem,sku}: {orderItem: OrderItem, sku: Sku}) {
  return (
    <div>
      <span></span>
      <span>{sku.shortLabel}</span>
      <span>{orderItem.quantity}</span>
      <span></span>
      <span></span>
    </div>
  )
}

type PatientOrderStatus = "Pending Patient Payment" | "Pending Patient Address" | "Order Shipped" | "Payment Succeeded" | "Awaiting Shipment"

function getStatus(item: OrderHistoryItem): PatientOrderStatus {
  if (item.statusLogs.find(log => log.orderStatus === 3)) return "Order Shipped";
  if (item.statusLogs.find(log => log.orderStatus === 4)) return "Payment Succeeded";
  if (!item.order.isSelfPay) {
    if (item.order.shippingAddress1) return "Awaiting Shipment";
    return "Pending Patient Address";
  }
  if (!item.order.shippingAddress1) return "Pending Patient Payment";
  return "Awaiting Shipment";
}