import { ROLES } from "../consts";

export type Role = "admin" | "clinician" | "affiliate";

export interface Permissions {
  admin: boolean;
  clinician: boolean;
  affiliate: boolean;
  dashAdmin: boolean;
}
export class Staff {
  stfid: number;
  salutation: string;
  name: string;
  surname: string;
  email: string;
  roleId: number;
  permissions: Permissions;
  constructor(stfid: number,salutation: string,name: string,surname: string,email: string,roleId: number,permissions: any) {
    this.stfid = stfid;
    this.salutation = salutation;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.roleId = roleId;
    this.permissions = permissions;
  }

  get displayName(): string {
    return (this.salutation && this.surname) ? `${this.salutation} ${this.surname}` : this.name;
  }

  get role(): Role {
    switch (this.roleId) {
      case ROLES.ADMIN:
        return "admin";
      case ROLES.AFFILIATE:
        return "affiliate";
      default:
        return "clinician";
    }
  }
}

export const createStaff = ({stfid,salutation,name,surname,email,roleId,permissions}: any) => {
  return new Staff(stfid,salutation,name,surname,email,roleId,permissions);
}