/*
 * Component to create a header row. Each 'column' containes a title describing the data which will be displayed in each ChalCard strip under this header row
 */

import s from "./Challenges.module.scss";
import { Information } from "../../../components/tooltips/Information/Information";

const ChalCardHeader = () => {
  return (
    <div className={`${s.chalHeaderCard} ${s.challengeCard} ${s.stickyContainer}`}>
      <div className={s.chalHeader}>
        <span></span>
        <span>Test Substrate</span>
        <span>Date</span>
        <span></span>
        <span>Baseline</span>
        <span>Area Under Curve <AUCInfo /></span>
        <span>Result <ResultInfo /></span>
        <span>Reviewed</span>
      </div>
    </div>
  );
};

export const AUCInfo = () => {
  return <Information 
    data="AUC is a quantitative measure of the gas produced during a Challenge. Total hydrogen and methane AUC values are displayed."
  />
}

export const ResultInfo = () => {
  return <Information
    data="Increase in hydrogen levels above baseline in the first 90 minutes for lactulose and glucose. Increase in hydrogen levels above baseline at any point for FODMAPs and custom foods. Maximum methane levels reached at any point."
  />
}

export default ChalCardHeader;