import React, { useContext, SetStateAction, Dispatch, useEffect } from "react";
import s from "./AddPatient.module.scss";
import { By } from "./By";
import DialogNav from "../../../components/dialogs/DialogNav/DialogNav";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import { DashAPIRoute } from "../../../utils/apis";
import { FormProvider, useForm } from "react-hook-form";
import { Provider } from "../../../utils/data-classes/Provider";
import Modal from "../../../components/dialogs/Modal/Modal";
import { AppError, authFetch } from "../../../utils/utils";
import { createPatient } from "../../../utils/data-classes/Patient";
import { AddPatientContext, AddPatientContextProvider } from "../../../contexts/AddPatientContext/AddPatientContext";
import { LanguageCode } from "../../../utils/data-classes/Language";
import { ChallengePromptContextProvider } from "../../../components/dialogs/ChallengePromptModal/ChallengePromptContext";
import { IndividualOrderOptions } from "./add-patient-util/useIndividualOrderOptions";
import { CliniciansToLink } from "./CliniciansToLink";
import AddPatientDetails from "./AddPatientDetails";
import { getIdtMailFromIdentifier } from "./add-patient-util/add-patient-util";
import { AddPatientOrderDevicePrompt, AddPatientOrderDevice } from "./AddPatientOrderDevice";
import { SelectChallengePrompt, SelectChallenge } from "./SelectChallenge";

interface RegistrationFormOption {
  name: By,
  content: string,
}

export const registrationFormOptions: RegistrationFormOption[] = [
  { name: "email", content: "By Email" },
  { name: "identifier", content: "By Identifier"},
];

interface AddPatientModalProps {
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const AddPatientModal = ({ isOpen, setIsOpen}: AddPatientModalProps): JSX.Element  => {
  const close = () => setIsOpen(false);
  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <AddPatientContextProvider>
        <ChallengePromptContextProvider preReg={true}>
          <AddPatient setPatientDialogOpen={setIsOpen} />
        </ChallengePromptContextProvider>
      </AddPatientContextProvider>
    </Modal>
  );
}

export type PaymentMethod = "patient-self-pay" | "individual-arrears" | "no-payment";

export interface AddPatientFormFieldValues {
  email: string,
  firstName: string,
  lastName: string,
  identifier: string,
  dateOfBirth: string,
  phone: string,
  addPatientWithoutDevice: boolean,
  device: boolean,
  deviceModel: DeviceModel,
  fodmaps: boolean,
  glucose: boolean,
  paymentMethod: PaymentMethod,
  lang: LanguageCode,
  stfids: number[],
  provideReferringClinician: boolean,
  referringClinician: {
    salutation: string,
    postNominal: string,
    name: string,
    surname: string,
  }
}

interface AddPatientProps {
  setPatientDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const AddPatient = ({setPatientDialogOpen}: AddPatientProps) => {
  const { state: { loginToken, provider, staff } } = useContext(LoginContext);
  const { state: { stage, by, patient, orderOptions }, dispatch } = useContext(AddPatientContext);

  const form = useForm<AddPatientFormFieldValues>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      identifier: "",
      phone: "",
      dateOfBirth: "",
      addPatientWithoutDevice: false,
      device: false,
      deviceModel: "2",
      fodmaps: false,
      glucose: false,
      paymentMethod: "no-payment",
      lang: "en",
      stfids: [],
      referringClinician: {
        salutation: "",
        postNominal: "",
        name: "",
        surname: "",
      }
    }
  });
  const { handleSubmit, reset } = form;
  
  const close = () => {
    setPatientDialogOpen(false);
    if (patient) {
      window.location.reload();
      return;
    }
  };

  useEffect(() => {
    reset();
  },[by]);

  if (!provider || !orderOptions) return <></>;

  const onSubmit = (provider: Provider) => async (data: AddPatientFormFieldValues): Promise<void> => {
    if (!loginToken) return;
    try {
      dispatch({type: "SET_PROCESSING", payload: true});

      const { email, identifier, firstName, lastName, phone, dateOfBirth, stfids, lang, provideReferringClinician, referringClinician } = data;
      const body = {
        by,
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        dateOfBirth: dateOfBirth || undefined,
        phone: phone || undefined,
        email: email.toLowerCase() || getIdtMailFromIdentifier(identifier,provider).toLowerCase(),
        identifier: identifier || email.split("@")[0],
        skuId: getSkuId(orderOptions,data),
        selfPay: isSelfPay(data),
        lang,
        stfids: staff?.permissions.admin ? stfids : undefined,
        referringClinician: provideReferringClinician ? referringClinician : undefined,
      }
      const res = await authFetch(loginToken,DashAPIRoute.ADD_PATIENT.path,{
        method: "POST",
        body: JSON.stringify(body),
      })
      const { patient, skuId, error } = await res.json();
      if (error) throw new AppError(res.status,error);
      dispatch({type: "SET_PATIENT", payload: createPatient(patient)});
      if (skuId) dispatch({type: "SET_ORDER_COMPLETE", payload: true});
      return;
    } catch(error) {
      const appError = error instanceof AppError ? error : new AppError(400,"An unknown error occurred");
      dispatch({type: "SET_ERROR", payload: appError});
      console.error(error instanceof Error ? error.message : appError.message);
      return;
    }
  }

  return (
    <FormProvider {...form}>
      <div className={s.addPatient}>
        <form className={s.addPatientForm} onSubmit={handleSubmit(onSubmit(provider))}>
          <DialogNav onCloseButtonClick={close} />
          { stage === "PATIENT_DETAILS" && <AddPatientDetails /> }
          { stage === "LINK_CLINICIANS" && <CliniciansToLink /> }
          { stage === "ORDER_DEVICE_PROMPT" && <AddPatientOrderDevicePrompt /> }
          { stage === "ORDER_DEVICE" && <AddPatientOrderDevice /> }
          { stage === "SUCCESS" && <AddPatientSuccess /> }
          { stage === "ERROR" && <ErrorMessage />}
        </form>
        { stage === "SELECT_CHALLENGE_PROMPT" && <SelectChallengePrompt />}
        { stage === "SELECT_CHALLENGE" && <SelectChallenge /> }
      </div>
    </FormProvider>
  )
}

const ErrorMessage = () => {
  const { state: { error} } = useContext(AddPatientContext)
  if (!error) return <></>;
  return (
    <div>
      <h1>Error {error.code}</h1>
      <p>{error.message}</p>
    </div>
  );
}

const AddPatientSuccess = () => {
  const { state: { by, orderComplete } } = useContext(AddPatientContext);
  return (
    <div className={s.success}>
      <h1>Success</h1>
      <p className={s.explanation}>
        { by === "email" && (
          <>
            You've successfully registered the patient. The patient will
            receive an email with instructions to complete their patient
            signup{orderComplete && ` and provide a delivery address to receive their device order`}. Once they complete the process, you will be able to see
            their data here on your dashboard.
          </>
        )}
        { by === "identifier" && (
          <>
            You've successfully registered the patient. You will now receive
            an email with instructions for the patient to complete patient
            signup. <b>Please forward the email to them.</b> There will be a
            link inside the email that they have to follow to set their app
            login password. They will then be shown where to download the
            app.
          </>
        )}
      </p>
    </div>
  )
};

type DeviceModel = "1" | "2";

export const getSkuId = (options: IndividualOrderOptions, {addPatientWithoutDevice,device,deviceModel,fodmaps,glucose}: AddPatientFormFieldValues): number|undefined => {
  if (!device || addPatientWithoutDevice) return undefined;
  const combos = options["hydrogenMethane"];
  if (fodmaps && glucose) return combos.fodmapsGlucose.skuId;
  if (fodmaps) return combos.fodmaps.skuId;
  if (glucose) return combos.glucose.skuId;
  return combos.device.skuId;
}

function isSelfPay({device, addPatientWithoutDevice, paymentMethod }: AddPatientFormFieldValues) {
  if (!device) return false;
  if (addPatientWithoutDevice) return false;
  return paymentMethod === "patient-self-pay";
}

export default AddPatientModal;
