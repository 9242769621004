import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AppError, downloadDocument } from "../../utils/utils";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import s from "../invoice/DownloadInvoice.module.scss"
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import useArrearsInvoice from "../../hooks/useArrearsInvoice";
import { ArrearsInvoice } from "../../utils/data-classes/ArrearsInvoice";
import useSkus, { Sku } from "../../hooks/useSkus";
import ArrearsInvoiceDocument from "../../pdf-generator/templates/ArrearsInvoice/ArrearsInvoiceDocument";

interface UrlParams {
  token: string;
}

export default function DownloadArrearsInvoice(): JSX.Element {
  const [downloading, setDownloading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState<AppError>();

  const { token }: UrlParams = useParams();
  const { invoice, error: invoiceError } = useArrearsInvoice(token);
  const { skus } = useSkus();

  useEffect(() => {
    if (!invoice || !skus) return;
    if (downloading || complete || error) return;
    setDownloading(true);
    downloadInvoice(invoice,skus)
      .then(() => setComplete(true))
      .catch(err => setError(err instanceof AppError ? err : new AppError(400,"An error occurred generating invoice PDF")))
      .finally(() => setDownloading(false));
  },[invoice,skus,downloading,complete]);

  useEffect(() => {
    if (!invoiceError) return;
    setError(invoiceError);
  },[invoiceError]);

  if (error) return <ErrorPage error={error} />

  return (
    <PreloginLayout>
      <div className={s.downloadInvoice}>
        <h3>{downloading ? "Downloading Invoice..." : "Download Complete"}</h3>
        { downloading && <LoadingSpinner />}
      </div>
    </PreloginLayout>
  )
}

const ErrorPage = ({error}: {error: AppError}) => {
  return (
    <PreloginLayout>
      <div className={s.downloadInvoice}>
        <h3>Error {error.code}</h3>
        <p>{error.message}</p>
      </div>
    </PreloginLayout>
  );
}

const downloadInvoice = async (invoice: ArrearsInvoice,skus: Sku[]) => {
  const skuRecord = Object.fromEntries(skus.map(sku => [sku.skuId,sku]))
  const document = <ArrearsInvoiceDocument invoice={invoice} skus={skuRecord}/>
  await downloadDocument(invoice.filename(),document);
}