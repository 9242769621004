import { OrderingItem } from "./BulkOrderContext";
import DiscountScheme from "./DiscountScheme";
import { OrderingFormFieldValues } from "./Ordering";
import { Pricing } from "./usePricings";
import { Price, createPrice } from "./Price";

export type OrderingPrice = Price<keyof OrderingFormFieldValues>;

export type OrderingPrices = Record<keyof OrderingFormFieldValues, OrderingPrice>;

export const createOrderingPrice = (item: OrderingItem, quantity: number, pricings: { [skuId: number]: Pricing; }, discountSchemes: { [skuId: number]: DiscountScheme | undefined; }): OrderingPrice => {
  const pricing = pricings[item.skuId];
  const discountScheme = discountSchemes[item.skuId];
  return createPrice(item.name, quantity, pricing, discountScheme);
};
