import { devLog } from "../../utils/utils";
import { DateRangeContextAction } from "./DateRangeContextActions";
import { DateRangeContextState, PERIOD, DateRange } from "./DateRangeContext";

const dateRangeContextReducer = (state: DateRangeContextState, action: DateRangeContextAction): DateRangeContextState => {
  switch (action.type) {
    case "SET EARLIEST TIME": {
      let { earliestTime } = action.payload;
      devLog(`RANGE_CTX: Setting earliest time: ${earliestTime.toISODate()}`);
      return { ...state, earliestTime };
    }
    case "UPDATE BY RANGE": {
      // DETERMINE THE PERIOD FOR A GIVEN RANGE AND UPDATE THE STATE
      // This is not 100% accurate as it guesses the period based on the number of days within the range
      // But it should be accurate enough for our purposes for now
      let { range } = action.payload;

      if (!state.earliestTime)
        throw new Error("Cannot update by range before earliest time is set");

      let period;
      let [startDT, endDT] = range;
      let dateDiff = endDT.diff(startDT, "days").as("days");
      let longestDateDiff = endDT.diff(state.earliestTime, "days").as("days");

      if (dateDiff === 1)
        period = PERIOD.DAY;
      else if (dateDiff === 7)
        period = PERIOD.WEEK;
      else if (dateDiff > 27 && dateDiff < 32)
        period = PERIOD.MONTH;
      else if (dateDiff >= longestDateDiff)
        period = PERIOD.ALL;

      else
        period = PERIOD.CUSTOM;

      devLog(
        `RANGE_CTX: Updating by range: ${range[0].toISODate()} to ${range[1].toISODate()} (${period})`
      );

      return {
        ...state,
        range,
        period,
      };
    }
    case "UPDATE BY PERIOD": {
      // DETERMINE THE RANGE FOR A GIVEN PERIOD AND UPDATE THE STATE
      // This is when a user switches between, say, DAY and WEEK by only using period-picker
      let { period } = action.payload;

      if (!state.earliestTime)
        throw new Error("Cannot update by period before earliest time is set");
      if (!state.range[1])
        throw new Error("Cannot update by period if an end DateTime has not been set");

      // When the period is changed, the range is updated by changing the start date (end date stays fixed)
      // CUSTOM should not be an available option for a period for the dash user to select
      // If the given period is ALL, we set the startDT to earliestTime to cover all the range available
      // If other three (DAY, WEEK, MONTH) we set it to one period earlier than the start date
      if (period === PERIOD.CUSTOM) {
        throw new Error("Cannot update date range by CUSTOM");
      }
      let endDT = state.range[1];
      let startDT = period === PERIOD.ALL
        ? state.earliestTime
        : endDT.minus({ [period]: 1 });
      let range: DateRange = [startDT, endDT];

      devLog(
        `RANGE_CTX: Updating by period: ${startDT.toISODate()} to ${endDT.toISODate()} (${period})`
      );

      return {
        ...state,
        range,
        period,
      };
    }
    default:
      return {
        range: [undefined, undefined],
        earliestTime: undefined,
        period: undefined,
      };
  }
};

export default dateRangeContextReducer;