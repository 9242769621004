import Symptom from "../../../utils/data-classes/Symptom";

export function splitSymptoms(symptoms: Symptom[]): Symptom[][] {
  return Object.values(symptoms.reduce<{[label: string]: Symptom[]}>((acc,cur) => {
    if (!acc[cur.label]) acc[cur.label] = [];
    acc[cur.label].push(cur);
    return acc;
  },{}));
}

export function sortSymptomsByMax(symptoms: Symptom[][]): Symptom[][] {
  const symptomsWithMax = symptoms.map(s => ({
    data: s,
    max: s.reduce((max,{score}) => score > max ? score : max,0)
  }));
  return symptomsWithMax.sort((a,b) => a.max > b.max ? -1 : 1).map(({data}) => data);
}

function isPercentage(str: string) {
  return !!str.match(/^\d*\.?\d*%$/g);
}

export function parsePercentage(str: string) {
  if (!isPercentage(str)) throw new Error("String is not a valid percentage");
  return parseFloat(str.substring(0,str.length-1))/100;
}

export function subdivideWidth(widthUsed: number,divisions: number): string {
  const availableWidth = 1 - widthUsed;
  const percentage = (100*availableWidth/divisions).toFixed(2);
  return `${percentage}%`;
}