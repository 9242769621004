import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { LoginContextAction, logOut } from "../../contexts/LoginContext/LoginContextActions";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import { AppError, invalidateLoginToken } from "../../utils/utils";
import { Redirect, useParams } from "react-router-dom";
import { setLoginToken } from "../../contexts/LoginContext/LoginContextActions";
import s from "./Login.module.scss";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";

export default function MagicLinkLogin() {
  const magicToken = useMagicToken();
  const { state: { loginSuccess, loginToken }, dispatch: loginDispatch } = useContext(LoginContext);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<AppError|undefined>();

  useEffect(() => {
    // Testing if can force logout before logging in
  checkLoggedInAndLogOut(loginToken, loginDispatch)
  
    // if (loginSuccess) return
    setProcessing(true);
    login(magicToken)
      .then(token => loginDispatch(setLoginToken(token)))
      .catch(err => setError(err instanceof AppError ? err : new AppError(400,"Something went wrong")))
      .finally(() => setProcessing(false));
  },[loginSuccess]);

  if (loginSuccess) return <Redirect to="/" />

  return (
    <PreloginLayout>
      <div className={s.login}>
        { processing && <LoggingIn />}
        { error && <Err error={error} /> }
      </div>
    </PreloginLayout>
  )
}

function LoggingIn() {
  return (
    <div>
      <p>Logging in...</p>
      <LoadingSpinner />
    </div>
  )
}

function Err({error}: {error: AppError}) {
  return (
    <div className={s.error}>
      <h3>Error {error.code}</h3>
      <p>{error.message}</p>
    </div>
  )
}

async function checkLoggedInAndLogOut( loginToken: string | undefined, loginDispatch: React.Dispatch<LoginContextAction>) {
  if (loginToken) {
    loginDispatch(logOut());
    await invalidateLoginToken(loginToken);
  } 
  return 
}

async function login(magicToken: string) {
  const res = await fetch(`/api/login/magic-link/${magicToken}`);
  const { token, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return token;
}

function useMagicToken(): string {
  const { token }: { token: string } = useParams();
  return token;
}