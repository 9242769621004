import { useHistory } from "react-router-dom";
import { Patient } from "../../utils/data-classes/Patient";
import s from "./DigestHeader.module.scss";

const DigestHeader = ({ patient }: { patient: Patient }) => {
  return (
    <div className={s.digestHeader}>
      <HomeButton />
      <h1>{patient.name}</h1>
      <span>{patient.email}</span>
    </div>
  );
};

const HomeButton = () => {
  const history = useHistory();
  const goHome = () => history.push("/");
  return (
    <button className={s.homeButton} onClick={goHome} title="Return to home page">
      <div className={s.backArrow}></div>
      <span>Home</span>
    </button>
  );
};

export default DigestHeader;
