import ReactModal from "react-modal";
import s from "./Modal.module.scss";

ReactModal.setAppElement("#root");

/**
 * Wrapper for ReactModal including our preferred default styling
 */
export default function Modal({className=s.modalContent,overlayClassName=s.modalOverlay,...rest}: ReactModal.Props) {
  return <ReactModal className={className} overlayClassName={overlayClassName} {...rest} />
}