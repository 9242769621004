import React from "react";
import { FieldValues } from "react-hook-form";
import { ControlledRegionDropdown, ControlledRegionDropdownProps } from "../../../../components/inputs/ControlledInputs/ControlledInputs";
import s from "./IFTASelect.module.scss";

type IFTARegionSelecttProps<TFieldValues extends FieldValues> = 
  & Omit<React.HTMLProps<HTMLInputElement>,"id"|"form"> 
  & ControlledRegionDropdownProps<TFieldValues> & { 
    id: string,
    label: string,
  }

export default function IFTARegionSelect<TFieldValues extends FieldValues>({id,label,...rest}: IFTARegionSelecttProps<TFieldValues>) {
  return (
    <div className={s.IFTASelect}>
      <ControlledRegionDropdown className={s.IFTACountryDropdown} {...rest } />
      <label className={s.iftaLabel} htmlFor={id}>{label}</label>
      <label htmlFor={id} className={s.arrow}>&or;</label>
    </div>
  );
}