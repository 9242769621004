import { Currency } from "../../contexts/OrderContext/OrderContext";
import { Billing } from "../../pages/bulk-ordering/useBilling";
import { Provider } from "./Provider";
import { cleanFilenameString } from "../utils";
import { DateTime } from "luxon";
import { Sku } from "../../hooks/useSkus";

export class ArrearsInvoice {
  readonly invoiceId: number;
  readonly createdOnMs: number;
  readonly periodStartedOnMs: number;
  readonly periodEndedOnMs: number;
  readonly orid: number | undefined;
  readonly token: string;
  readonly billing: Billing;
  readonly provider: Provider;
  readonly total: number | undefined;
  readonly taxTotal: number | undefined;
  readonly currency: Currency;
  readonly skus: { [skuId: number]: Sku };
  readonly items: ArrearsInvoiceItems;

  constructor(invoice: TArrearsInvoice) {
    this.invoiceId = invoice.invoiceId;
    this.createdOnMs = invoice.createdOnMs;
    this.periodStartedOnMs = invoice.periodStartedOnMs;
    this.periodEndedOnMs = invoice.periodEndedOnMs;
    this.billing = invoice.billing;
    this.provider = invoice.provider;
    this.orid = invoice.orid;
    this.token = invoice.token;
    this.total = invoice.total;
    this.taxTotal = invoice.taxTotal;
    this.currency = invoice.currency;
    this.skus = invoice.skus;
    this.items = invoice.items;
  }

  filename(): string {
    const createdOn = DateTime.fromMillis(this.createdOnMs, { zone: "utc" }).toISODate();
    return cleanFilenameString(`FoodMarble_Invoice_${createdOn}_${this.invoiceId}_${this.provider.label}.pdf`);
  }

  private getTotal(items: ArrearsInvoiceItem[]) {
    return items.reduce((sum, cur) => sum + cur.quantity * cur.unitPriceNet, 0);
  }

  shippedToPatientTotal(): number {
    return this.getTotal(this.items.devices.shippedToPatient.flat());
  }

  shippedToProviderTotal(): number {
    return this.getTotal(this.items.devices.shippedToProvider);
  }

  deviceTotal(): number {
    return this.shippedToPatientTotal() + this.shippedToProviderTotal();
  }

  substrateTotal(): number {
    return this.getTotal(this.items.substrates);
  }
}

interface TArrearsInvoice {
  invoiceId: number;
  createdOnMs: number;
  periodStartedOnMs: number;
  periodEndedOnMs: number;
  orid: number | undefined;
  token: string;
  billing: Billing;
  provider: Provider;
  total: number | undefined;
  taxTotal: number | undefined;
  currency: Currency;
  items: ArrearsInvoiceItems;
  skus: { [skuId: number]: Sku };
}

export interface ArrearsInvoiceItem {
  invoiceItemId: number;
  createdOnMs: number;
  testedOnMs: number | undefined;
  orderedOnMs: number | undefined;
  invoiceId: number;
  ptid: number | undefined;
  skuId: number;
  quantity: number;
  unitPriceNet: number;
  unitDiscount: number;
  unitPriceGross: number;
  unitTax: number;
}

export interface PatientInvoiceItem extends ArrearsInvoiceItem {
  testedOnMs: number;
}

export interface SubstrateInvoiceItem extends ArrearsInvoiceItem {
  testedOnMs: undefined;
  orderedOnMs: number;
}

export interface PatientInvoiceEntry {
  challengeCreatedOnMs: number;
  items: ArrearsInvoiceItem[];
}

export interface ArrearsInvoiceItems {
  devices: {
    shippedToPatient: PatientInvoiceItem[][];
    shippedToProvider: PatientInvoiceItem[];
  };
  substrates: SubstrateInvoiceItem[];
}
