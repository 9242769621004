import { CurrencyCode } from "../../../../../../server/consts";

export class OrderRequest implements TOrderRequest {
  readonly orrqid: number;
  readonly createdOnMs: number;
  readonly prid: number;
  readonly stfid: number | undefined;
  readonly currency: CurrencyCode;
  readonly items: OrderRequestItem[];
  readonly ortid: number;
  readonly status: OrderRequestStatus;
  readonly token: string;
  constructor(request: TOrderRequest) {
    this.orrqid = request.orrqid;
    this.createdOnMs = request.createdOnMs;
    this.prid = request.prid;
    this.stfid = request.stfid;
    this.currency = request.currency as CurrencyCode;
    this.items = request.items;
    this.ortid = request.ortid;
    this.status = request.status;
    this.token = request.token;
  }

  isPrePaid() {
    return this.ortid == 2 || this.ortid == 4;
  }
}

export function createOrderRequest(data: any): OrderRequest {
  const items: any[] = data.items;
  return new OrderRequest({
    orrqid: data.orrqid,
    createdOnMs: data.createdOnMs,
    prid: data.prid,
    stfid: data.stfid,
    currency: data.currency,
    items: items.map(createOrderRequestItem),
    ortid: data.ortid,
    status: data.status,
    token: data.token,
  });
}

function createOrderRequestItem(data: any): OrderRequestItem {
  return {
    orrqiId: data.orrqiId,
    orrqid: data.orrqid,
    skuId: data.skuId,
    quantity: data.quantity,
    unitPriceNet: data.unitPriceNet,
    unitDiscount: data.unitDiscount,
    unitPriceGross: data.unitPriceGross,
    unitTax: data.unitTax,
  };
}

interface TOrderRequest {
  orrqid: number;
  createdOnMs: number;
  prid: number;
  stfid: number | undefined;
  currency: string;
  items: OrderRequestItem[];
  ortid: number;
  status: OrderRequestStatus;
  token: string;
}

export interface OrderRequestItem {
  orrqiId: number;
  orrqid: number;
  skuId: number;
  quantity: number;
  unitPriceNet: number;
  unitDiscount: number;
  unitPriceGross: number;
  unitTax: number;
}

export type OrderRequestStatus = "REQUESTED" | "CONFIRMED" | "DECLINED";
