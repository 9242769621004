import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { AppError, authFetch, devLog } from "../../utils/utils";

export default function useBilling(): { billing: Billing|undefined, error: BillingError|undefined } {
  const [billing, setBilling] = useState<Billing>();
  const [error, setError] = useState<BillingError>();

  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!loginToken || billing || error) return;
    const abortController = new AbortController();
    fetchBilling(loginToken,abortController.signal).then(setBilling).catch(setFetchError(setError));
    return () => abortController.abort();
  },[loginToken,billing,error]);

  return { billing, error }
}

export interface Billing {
  blid: number,
  billingAddress1: string | undefined,
  billingCity: string | undefined,
  billingZip: string | undefined,
  billingState: string | undefined,
  billingCountry: string | undefined,
  billingName: string | undefined,
  billingEmail: string | undefined,
  billingPhone: string | undefined,
  billingInterval: string | undefined,
  stripeCustomerKey: string | undefined,
}

interface BillingBackend {
  blid: number,
  billingAddress1: string | undefined,
  billingCity: string | null,
  billingZip: string | null,
  billingState: string | null,
  billingCountry: string | null,
  billingName: string | null,
  billingEmail: string | null,
  billingPhone: string | null,
  billingInterval: string | null,
  stripeCustomerKey: string | null,
}

type FetchBillingJSON = 
  | { billing: BillingBackend, error: undefined }
  | { billing: undefined, error: string }

const fetchBilling = async (token: string, signal: AbortSignal): Promise<Billing> => {
  const res = await authFetch(token,"/api/billing",{signal});
  const { billing, error }: FetchBillingJSON = await res.json();
  if (error !== undefined) throw new BillingError(res.status,error);
  return {
    blid: billing.blid,
    billingAddress1: billing.billingAddress1 ?? undefined,
    billingCity: billing.billingCity ?? undefined,
    billingZip: billing.billingZip ?? undefined,
    billingState: billing.billingState ?? undefined,
    billingCountry: billing.billingCountry ?? undefined,
    billingName: billing.billingName ?? undefined,
    billingEmail: billing.billingEmail ?? undefined,
    billingPhone: billing.billingPhone ?? undefined,
    billingInterval: billing.billingInterval ?? undefined,
    stripeCustomerKey: billing.stripeCustomerKey ?? undefined,
  }
}

const setFetchError = (setter: React.Dispatch<SetStateAction<BillingError|undefined>>) => (err: any): void => {
  if (err instanceof BillingError) return setter(err);
  setter(new BillingError(400,"Bad Request"));
}

export class BillingError extends AppError {
  constructor(code: number, message: string) {
    super(code,message,true);
    this.name = "BillingError";
  }
}