import React, { useContext } from "react";
import { AppError, authFetch } from "../../utils/utils";
import { BulkOrderContext, ORDERING_ITEMS, OrderingItem } from "./BulkOrderContext";
import { OrderingPrices } from "./OrderingPrice";
import s from "./Ordering.module.scss";
import DialogNav from "../../components/dialogs/DialogNav/DialogNav";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import { OrderingFormFieldValues } from "./Ordering";
import { getExtraDiscount, getTotal } from "./ordering-utils";
import { Stripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import STRIPE_PROMISE from "../register/StripePromise";
import ClinicalOrderStatus, { insertOrderStatusLog } from "../register/ClinicalOrderStatus";
import Modal from "../../components/dialogs/Modal/Modal";

export function BulkOrderingModal() {
  return (
    <Elements stripe={STRIPE_PROMISE}>
      <_BulkOrderingModal />
    </Elements>
  );
}

export function _BulkOrderingModal() {
  const { state: { loginToken } } = useContext(LoginContext);
  const { state: { processing, complete, ordering, quantities, pricing, error }, dispatch } = useContext(BulkOrderContext);
  const { medaire2, fodmaps, glucose } = quantities;
  const stripe = useStripe();

  const close = () => {
    if (!complete) {
      dispatch({ type: "SET_ORDERING", payload: false });
      return;
    }
    window.location.reload();
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!stripe || !loginToken || !( medaire2 || fodmaps || glucose) || !pricing) return;

      dispatch({ type: "SET_PROCESSING", payload: true });
      await placeOrder(stripe,loginToken, { medaire2, fodmaps, glucose }, pricing);
      dispatch({ type: "SET_COMPLETE", payload: true });
    } catch (err) {
      dispatch({ type: "SET_ERROR", payload: err instanceof AppError ? err : new AppError(400, "Bad Request") });
    }
  };

  return (
    <Modal isOpen={ordering} onRequestClose={close}>
      <div className={s.orderingModalContent}>
        {!complete && !error && (
          <form onSubmit={onSubmit}>
            <DialogNav onCloseButtonClick={close} />
            <h1>Order Summary</h1>
            <OrderingModalSummaryTable />
            <button type="submit" className={s.btnPrimary} disabled={processing || complete}>
              {processing ? "Processing..." : "Submit"}
            </button>
          </form>
        )}
        {complete && (
          <div>
            <h1>Success</h1>
            <button type="button" className={s.btnPrimary} onClick={close}>Return</button>
          </div>
        )}
        {error && (
          <div>
            <h1>Error {error.code}</h1>
            <p>{error.message}</p>
            <button type="button" className={s.btnPrimary} onClick={close}>Return</button>
          </div>
        )}
      </div>
    </Modal>
  );
}

const OrderingModalSummaryTable = () => {
  const { state: { quantities, pricing, discountSchemes } } = useContext(BulkOrderContext);

  if (!pricing) return <></>;

  const currency = pricing.medaire2.currency;

  const extraDiscount = getExtraDiscount(quantities,discountSchemes ?? {});

  return (
    <table className={s.orderingModalSummaryTable}>
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(pricing).map(
          (price, i) => <OrderingModalSummaryRow key={i} quantity={quantities[price.name]} item={ORDERING_ITEMS[price.name]} />
        )}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td>Total</td>
          <td>{currency.parse(getTotal(pricing))}</td>
        </tr>
      </tfoot>
    </table>
  );
};

const OrderingModalSummaryRow = ({ item, quantity }: { item: OrderingItem; quantity: number; }) => {
  const { state: { pricing } } = useContext(BulkOrderContext);
  if (!quantity || !pricing)
    return <></>;
  const price = pricing[item.name];
  return (
    <tr>
      <td>{item.label}</td>
      <td>{quantity}</td>
      <td>{price.currency.parse(quantity * price.unitPriceNet())}</td>
    </tr>
  );
};

type PlaceOrderResJSON = 
  | { success: true, orid: number, error: undefined, clientSecret: undefined, paymentMethodId: undefined , paymentComplete: boolean | undefined }
  | { success: false, orid: number, error: string, clientSecret: string, paymentMethodId: string , paymentComplete: boolean | undefined }
  | { success: false, orid: number, error: string, clientSecret: undefined, paymentMethodId: undefined, paymentComplete: boolean | undefined}

const placeOrder = async (stripe: Stripe, loginToken: string, values: OrderingFormFieldValues, prices: OrderingPrices) => {
  const statuses: ClinicalOrderStatus[] = [];
  const res = await authFetch(loginToken, `/api/place-order`, {
    method: "POST",
    body: JSON.stringify({
      items: createItems(values),
      price: getTotal(prices),
    })
  });
  const data: PlaceOrderResJSON = await res.json();
  const { success, error, orid, clientSecret, paymentMethodId } = data;
  let paymentComplete = data.paymentComplete ?? false;
  if (error && !clientSecret) throw new AppError(res.status,error);
  try {
    if (clientSecret) {
      const { error: confirmationError } = await stripe.confirmCardPayment(clientSecret,{
        payment_method: paymentMethodId,
      });
      if (confirmationError) throw new AppError(400,confirmationError.message ?? "Something went wrong");
      paymentComplete = true;
    }
    if (paymentComplete) statuses.push(ClinicalOrderStatus.PAYMENT_SUCCEEDED);
    if (paymentComplete || success) statuses.push(ClinicalOrderStatus.READY_FOR_FULFILLMENT);
  } catch (err) {
    statuses.push(ClinicalOrderStatus.PAYMENT_FAILED);
    throw err;
  } finally {
    await insertOrderStatusLog(...statuses.map(orderStatus => ({orid,orderStatus})));
  }
};

const createItems = (values: OrderingFormFieldValues): { skuId: number; quantity: number; }[] => {
  return Object.values(ORDERING_ITEMS)
    .map(({ name, skuId }) => ({ skuId, quantity: values[name] }))
    .filter(({ quantity }) => quantity);
};
