import { ProviderPreferences } from "../utils/data-classes/Preferences";
import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function useProviderPreferences() {
  const { data, error } = useDataAuth(fetchProviderPreferences);
  return { preferences: data, error };
}

async function fetchProviderPreferences(loginToken: string,signal: AbortSignal) {
  const res = await authFetch(loginToken,"/api/provider-preferences",{
    method: "GET",
    signal
  });
  const data = await res.json();
  const error = data.error;
  if (error !== undefined) throw new AppError(res.status,error);
  const result: {[prid: number]: ProviderPreferences} = data.preferences;
  return result;
}