import InterfaceButton from "../../../components/buttons/InterfaceButton/InterfaceButton";
import { Challenge, postChallengeSettings } from "../../../utils/data-classes/challenge/Challenge";
import { Patient } from "../../../utils/data-classes/Patient";
import { ReactComponent as Icon } from "../../../assets/icons/edit-note.svg";
import Modal from "../../../components/dialogs/Modal/Modal";
import { useContext, useEffect, useState } from "react";
import DialogNav from "../../../components/dialogs/DialogNav/DialogNav";
import { UseFormReturn, useForm } from "react-hook-form";
import s from "./Challenges.module.scss";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import useSubmitReducer, { SubmitAction, SubmitState } from "../../../hooks/useSubmitReducer";

export default function EditNotesButton({ patient, challenge }: { patient: Patient; challenge: Challenge }) {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<FormFieldValues>({
    defaultValues: {
      notes: challenge.settings?.notes ?? "",
    },
  });

  const [state, dispatch] = useSubmitReducer(form);
  const hasNotes = !!challenge.settings.notes;
  const addEdit = !hasNotes ? "add" : "edit";

  const openModal = () => setIsEditing(true);

  const closeModal = () => setIsEditing(false);

  const onAfterClose = () => {
    if (!state.complete) {
      dispatch({ type: "RESET" });
      return;
    }
    window.location.reload();
  };

  return (
    <>
      <InterfaceButton type="button" title="Edit notes" icon={<Icon />} onClick={openModal}>
        Edit notes
      </InterfaceButton>
      <Modal isOpen={isEditing} onRequestClose={closeModal} onAfterClose={onAfterClose}>
        <div className={s.editNotesModalContent}>
          <DialogNav onCloseButtonClick={closeModal} />
          <h1>{addEdit} Note</h1>
          <p>
            To {hasNotes ? "edit" : "add"} a note {hasNotes ? "on" : "to"} the report, use the field below and click
            'Submit'.
          </p>
          <Form form={form} state={state} dispatch={dispatch} patient={patient} challenge={challenge} />
        </div>
      </Modal>
    </>
  );
}

function Form({
  form,
  state,
  dispatch,
  patient,
  challenge,
}: {
  form: UseFormReturn<FormFieldValues>;
  state: SubmitState;
  dispatch: React.Dispatch<SubmitAction>;
  patient: Patient;
  challenge: Challenge;
}) {
  const { loginToken } = useContext(LoginContext).state;
  const { processing, error, complete } = state;

  const abortController = new AbortController();
  useEffect(() => {
    return () => abortController.abort();
  }, []);

  const onSubmit = async (data: FormFieldValues) => {
    if (!loginToken) return;
    dispatch({ type: "PROCESSING" });
    await updateChallengeNotes(loginToken, patient, challenge, data.notes, abortController.signal)
      .then(() => dispatch({ type: "COMPLETE" }))
      .catch((err) => dispatch({ type: "ERROR", payload: err }));
  };

  const hasOldNote = !!challenge.settings.notes;
  const hasNewNote = !!form.watch("notes");
  const canSubmit = !processing && !complete && !error && !(!hasOldNote && !hasNewNote);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <textarea {...form.register("notes")} />
      <FormErrorMessage errors={form.formState.errors} name="notes" />
      <button className={s.btnPrimary} disabled={!canSubmit}>
        {!processing ? "Submit" : "Processing..."}
      </button>
      <p className={error && s.editNotesError}>
        {error && `${error.code}: ${error.message}`}
        {complete && `Notes updated successfully`}
      </p>
    </form>
  );
}

async function updateChallengeNotes(
  loginToken: string,
  patient: Patient,
  challenge: Challenge,
  notes: string,
  signal: AbortSignal
) {
  return await postChallengeSettings(
    loginToken,
    patient.ptid,
    [
      {
        chalEpochMs: challenge.epochMs,
        settings: { notes },
      },
    ],
    signal
  );
}

interface FormFieldValues {
  notes: string;
}
