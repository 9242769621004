import s from "./DialogBase.module.scss";
import Modal from 'react-modal';
import DialogNav from "../DialogNav/DialogNav";

/**
 * 
 * @param {Object} props 
 * @param {Boolean} props.isOpen
 * @param {Function} props.onClose
 * @param {Function} props.afterOpen
 * @param {String} props.contentLabel
 * @param {String} props.appElement
 * @returns 
 */
const DialogBase = (props) => {

	Modal.setAppElement(props.appElement)

	return (			
			<Modal
				isOpen={props.isOpen}
				onAfterOpen={props.afterOpen}
				onRequestClose={props.onClose}
				contentLabel={props.contentLabel}
				className={s.Modal}
				overlayClassName={s.Overlay}
			>
				{props.children}
			</Modal>
	);
}

export default DialogBase;
