import { Provider, createProvider } from "../utils/data-classes/Provider";
import { useState, useEffect } from "react";
import { devLog, AppError } from "../utils/utils";

function useProvider(prid: number|undefined|null): UseProviderReturn {
  const [provider, setProvider] = useState<Provider|null>(null);
  const [error, setError] = useState<AppError|null>(null);

  useEffect(() => {
    if (!prid) return;
    const abortController = new AbortController();
    fetchProvider(prid,abortController.signal)
      .then(setProvider)
      .catch(err => setError(err instanceof AppError ? err : new AppError(400,"Something went wrong")));
    return () => abortController.abort();
  },[prid]);

  return { provider, error };
}

const fetchProvider = async (prid: number, signal: AbortSignal): Promise<Provider> => {
  devLog(`useProviders: Fetching providers`);
  const res = await fetch(`/api/get-provider/${prid}`,{signal});
  const { provider, error } = await res.json();

  if (error !== undefined) throw new AppError(res.status,error);

  devLog(`PROVIDER: `, provider);
  return createProvider(provider);
}

interface UseProviderReturn {
  provider: Provider | null;
  error: AppError | null;
}

export default useProvider;