import { useEffect, useState } from "react";
import { currencies, OrderCurrencyCode } from "../../contexts/OrderContext/OrderContext";
import Invoice from "../../pdf-generator/templates/Invoice/Invoice";
import { AppError } from "../../utils/utils";
import useErrorState from "../../hooks/useErrorState";

const useInvoice = (invoiceToken: string) => {
  const [invoice, setInvoice] = useState<Invoice>();
  const [error, setError] = useErrorState();

  useEffect(() => {
    if (invoice || error || !invoiceToken) return;
    fetchInvoice(invoiceToken).then(setInvoice).catch(setError);
  },[invoice]);

  return { invoice, error };
}

const fetchInvoice = async (invoiceToken: string): Promise<Invoice> => {
  const res = await fetch(`/api/invoice/${invoiceToken}`,{
    method: "GET",
  });
  const data = await res.json();
  if (data.error) throw new AppError(res.status,data.error);
  const invoiceRaw = data.invoices[0];
  const currency = currencies[invoiceRaw.currency as OrderCurrencyCode];
  if (!currency) throw new AppError(404,"Currency not found");
  return new Invoice({
    id: invoiceRaw.id,
    createdOnMs: invoiceRaw.createdOnMs,
    periodEndedOnMs: invoiceRaw.periodEndedOnMs,
    periodStartedOnMs: invoiceRaw.periodStartedOnMs,
    customer: invoiceRaw.customer,
    currency,
    items: invoiceRaw.items,
    supplier: invoiceRaw.supplier,
  });
}

export default useInvoice;