import React, { useContext } from "react";
import { useEffect } from "react";
import { Link as ReactRouterLink, useHistory, withRouter } from "react-router-dom";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import { CdAction } from "../../utils/CdActions";
import { logCdAction } from "../../utils/utils";
import s from "./DashAdmin.module.scss";
import DashRoute from "../../routers/DashRoute";
import { LoginRoutes } from "../../routers/DashRouter";

const DashAdmin = () => {
  const { loginToken } = useContext(LoginContext).state;

  useEffect(() => {
    logCdAction(loginToken, CdAction.VISIT_DASHBOARD_ADMIN, undefined);
  }, []);

  return (
    <PreloginLayout>
      <div className={s.dashAdmin}>
        <h1>Dashboard Admin Panel</h1>
        <div className={s.dashAdminNav}>
          <DaLink route={LoginRoutes.INVITE_STAFF}>Invite Staff to Sign Up</DaLink>
          <DaLink route={LoginRoutes.ADD_PROVIDER}>Create New Provider</DaLink>
          <DaLink route={LoginRoutes.PATIENT_PASSWORD_RESET}>Get a Password Reset Link</DaLink>
          <DaLink route={LoginRoutes.ORDER_REQUEST}>Add Order</DaLink>
        </div>
      </div>
    </PreloginLayout>
  );
};

export const DaLink = ({ route, children }: { route: DashRoute; children: React.ReactNode }) => {
  return (
    <ReactRouterLink to={route.path}>
      <button>{children}</button>
    </ReactRouterLink>
  );
};

export default withRouter(DashAdmin);
