import React, { useState, useEffect, useContext } from "react";
import ReactRouterDom, { Link, withRouter } from "react-router-dom";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import usePatientFromURLSearchParams from "../../hooks/usePatientFromURLSearchParams";
import DashRoute from "../../routers/DashRoute";
import { LoginRoutes } from "../../routers/DashRouter";
import { buildDashURL } from "../../utils/utils";
import s from "./Sidebar.module.scss";

interface SidebarProps {
  history: ReactRouterDom.RouteComponentProps["history"],
}

interface SidebarLinkLiProps extends React.HTMLProps<HTMLLIElement> {
  history: ReactRouterDom.RouteComponentProps["history"],
  route: DashRoute,
  params?: URLSearchParams | null,
}

const Sidebar = ({ history }: SidebarProps) => {
  /* 
    URLSearchParams provided
      - either by <Home /> when staff clicked on a particular patient
      - or by us in a custom link (when sending a challenge result notification etc) 
    -> needed for keeping track of patient details when navigating the digest
  */
  const params = new URLSearchParams(history.location.search);

  const [state, setState] = useState(SidebarState.BASE);
  useEffect(() => {
    setState(getSidebarState(history));
  },[history]);

  const { state: { support, staff } } = useContext(LoginContext);

  const patient = usePatientFromURLSearchParams();

  return (
    <nav>
      <ul className={s.mainMenu}>
        <SidebarLinkLi history={history} route={LoginRoutes.BASE}>Site Overview</SidebarLinkLi>
        { state === SidebarState.DIGEST && (
          <ul>
            {patient?.uid && <SidebarLinkLi history={history} route={LoginRoutes.CHALLENGES} params={params}>Challenges</SidebarLinkLi>}
            {patient?.uid && <SidebarLinkLi history={history} route={LoginRoutes.DAY_TO_DAY} params={params}>Day To Day</SidebarLinkLi>}
            <SidebarLinkLi history={history} route={LoginRoutes.PATIENT} params={params}>Patient</SidebarLinkLi>
          </ul>
        )}
        { support?.bulkOrdering && <OrderingLinks history={history} state={state} />}
        { staff?.permissions.affiliate && <SalesLinks history={history} state={state} />}
      </ul>
      <ul className={s.bottomMenu}>
        <SidebarLinkLi history={history} route={LoginRoutes.CONTACT}>Contact Us</SidebarLinkLi>
      </ul>
    </nav>
  );
};

const OrderingLinks = ({ history, state }: SidebarProps & { state: SidebarState }) => {
  const { state: { preferences } } = useContext(LoginContext);
  if (!preferences) return <></>;
  return (
    <>
      <SidebarLinkLi history={history} route={LoginRoutes.ORDERS}>Orders</SidebarLinkLi>
      {state === SidebarState.ORDERS && (
        <ul>
          <SidebarLinkLi history={history} route={LoginRoutes.ORDER_HISTORY}>Order History</SidebarLinkLi>
          { !preferences.provider.bulkOrderingInArrears && <SidebarLinkLi history={history} route={LoginRoutes.ADD_CARD}>Add Payment Method</SidebarLinkLi> }
        </ul>
      )}
    </> 
  )
}

const SalesLinks = ({ history, state }: SidebarProps & { state: SidebarState }) => {
  return (
    <>
      <SidebarLinkLi history={history} route={LoginRoutes.SALES}>Sales</SidebarLinkLi>
      {state === SidebarState.SALES && (
        <ul>
          <SidebarLinkLi history={history} route={LoginRoutes.SALES}>Overview</SidebarLinkLi>
          <SidebarLinkLi history={history} route={LoginRoutes.SALES_DETAILED}>Detailed View</SidebarLinkLi>
        </ul>
      )}
    </>
  )
}

const getSidebarState = (history: ReactRouterDom.RouteComponentProps["history"]): SidebarState => {
  if (history.location.pathname.indexOf("digest") !== -1) return SidebarState.DIGEST;
  if (history.location.pathname.indexOf("orders") !== -1) return SidebarState.ORDERS;
  if (history.location.pathname.indexOf("sales") !== -1) return SidebarState.SALES;
  return SidebarState.BASE;
}

enum SidebarState {
  BASE = "BASE",
  DIGEST = "DIGEST",
  ORDERS = "ORDERS",
  SALES = "SALES",
}

const isSelected = (history: ReactRouterDom.RouteComponentProps["history"], route: DashRoute) => history.location.pathname === route.path;

const SidebarLinkLi = ({ route, params, history, ...rest }: SidebarLinkLiProps) => (
  <Link to={params ? buildDashURL(route.path,params) : route.path}>
    <li className={isSelected(history, route) ? s.activeNav : s.passiveNav} {...rest } />
  </Link>
);

export default withRouter(Sidebar);
