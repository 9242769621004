import variables from "./_variables.module.scss";

type Distance = 
  | "sidebarWidth"
  | "noSidebar"
  | "headerHeight"
  | "footerHeight"
  | "noHeader"
  | "noHeaderNoFooter";

type Color = 
  | "clinicalGreen"
  | "fmBlue"
  | "lightBlue"
  | "darkBlue"
  | "bgGray"
  | "darkerBgGray"
  | "ghostGray"
  | "lightGray"
  | "darkGray"
  | "lightBlack"
  | "successGreen"
  | "alertRed"
  | "errRed";


type ScssVariable = Distance | Color;

export const DISTANCE = variables as Record<Distance,string>;

export const COLOR = variables as Record<Color,string>;

const _variables = variables as Record<ScssVariable,string>;

export default _variables;