import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorPage from "../../components/errors/ErrorPage/ErrorPage";
import { HELP_EMAIL } from "../../utils/consts";
import useValidatePasswordResetKey from "./useValidatePasswordResetKey";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import { parseURLSearchParams, AppError } from "../../utils/utils";
import s from "./ResetPassword.module.scss";
import { useForm, UseFormReturn } from "react-hook-form";
import useSubmitReducer, { SubmitAction, SubmitState } from "../../hooks/useSubmitReducer";
import IFTAInput from "../register/IFTAElements/IFTAInput/IFTAInput";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";

interface ResetPasswordFormFieldValues {
  password: string;
  confirmation: string;
}

const ResetPassword = () => {
  const form = useForm<ResetPasswordFormFieldValues>({
    defaultValues: {
      password: "",
      confirmation: "",
    }
  });
  const [state,dispatch] = useSubmitReducer(form);
  const { error, complete } = state;
  const [fetching, setFetching] = useState(true);

  const history = useHistory();
  const { key } = parseURLSearchParams(history.location.search);

  const { staff, error: keyError } = useValidatePasswordResetKey(key);

  useEffect(() => {
    if (staff || keyError) setFetching(false);
    if (keyError) dispatch({type: "ERROR", payload: keyError});
  },[staff,keyError]);

  if (error) return <ErrorPage code={error.code} message={error.message}/>;

  return (
    <PreloginLayout>
      { !fetching && (
        <div className={s.resetPassword}>
          {!complete && <Form resetKey={key} form={form} state={state} dispatch={dispatch}/>}
          { complete && !error && <Success />}
          { complete && error && <Error error={error} />}
        </div>
      )}
    </PreloginLayout>
  )
}

const Form = ({form,state,dispatch,resetKey}: {form: UseFormReturn<ResetPasswordFormFieldValues,any>,state: SubmitState,dispatch: React.Dispatch<SubmitAction>,resetKey: string}) => {
  const { processing } = state;
  const { register, formState: { errors }, getValues, handleSubmit } = form;

  const onSubmit = async (data: ResetPasswordFormFieldValues) => {
    dispatch({type: "PROCESSING"});
    await requestPasswordReset(resetKey,data.password).catch(err => dispatch({type: "ERROR", payload: err}));
    dispatch({type: "COMPLETE"});
  }

  return (
    <>
      <h1>Password Reset</h1>
      <p>Please enter your new password below to reset your password.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IFTAInput id="password-input" label="Password" type="password" reg={register("password",{
          required: "Password is required",
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters"
          }
        })} />
        <FormErrorMessage errors={errors} name="password" />
        <IFTAInput id="password-confirmation-input" label="Confirm password" type="password" reg={register("confirmation", {
          required: "Password confirmation is required",
          validate: {
            isEqual: (value) => value === getValues("password") || "Password and password confirmation must match",
          }
        })} />
        <FormErrorMessage errors={errors} name="confirmation" />
        <button type="submit" className={s.btnPrimary} disabled={processing}>Submit</button>
      </form>
    </>
  );
}

const Success = () => (
  <>
    <h1>Success</h1>
    <p>Password Reset Successfully</p>
  </>
);

const Error = ({error}: {error: AppError}) => (
  <>
    <h1>Error: {error.code}</h1>
    <p>{error.message}</p>
    <p>Please reload the page and try again to reset your password.</p>
    <p>If you encounter further issues please contact <a href={`mailto: ${HELP_EMAIL}`}>${HELP_EMAIL}</a>.</p>
  </>
);

const requestPasswordReset = async (key: string,password: string) => {
  const res = await fetch("/api/update-staff-password",{
    method: "POST",
    body: JSON.stringify({
      key,
      password,
    })
  })
  const { error }: { error: string|undefined } = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
};

export default ResetPassword;