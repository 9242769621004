import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import s from "./ErrorPage.module.scss";

export default function ErrorPage({ code, message, layout=PreloginLayout }: ErrorPageProps) {
  const Layout = layout;
  return (
    <Layout>
      <ErrorMessage code={code} message={message} />
    </Layout>
  );
};


function ErrorMessage({ code, message }: ErrorMessageProps) {
  return (
    <div className={s.errorPage}>
      <h1>ERROR {code}</h1>
      <h2>{message}</h2>
    </div>
  );
}

interface ErrorMessageProps {
  code: number;
  message: string;
}

interface ErrorPageProps extends ErrorMessageProps {
  layout?: (props: LayoutProps) => JSX.Element;
}

interface LayoutProps {
  children: React.ReactNode;
}