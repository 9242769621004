/**
 *  Class for maintaining references in a parent component to a chart rendered in its children.
 */
import { Chart } from "chart.js";

/**
 * @typedef ChartHandler
 * @property {(string,Function)=>Chart} makeChart
 * @property {()=>Chart} getChart
 * @property {()=>String} getImage 
 */
class ChartHandler {
  #chart; 
  #image; 
  #setChartAnimationComplete;

  /**
   * @param {Object} options - Carts.js Chart options
   */
  constructor(options) {
    this.options = options;
    this.options.options.animation = (this.options.options.animation) 
      ? this.options.options.animation
      : {}
    this.options.options.animation.onComplete = () => {
      this.#image = this.getChart().toBase64Image('image/png', 1);
      this.#setChartAnimationComplete(true);
    }
  }

  /**
   * @param {String} context 
   * @returns {Chart}
   */
  makeChart(context, setChartAnimationComplete) {
    this.#chart = new Chart(context, this.options);
    this.#setChartAnimationComplete = setChartAnimationComplete;
    return this.#chart;
  }

  /**
   * @returns {Chart}
   */
  getChart() {
    return this.#chart;
  }

  /**
   * get base64 image string
   * @returns {String}
   */
  getImage() {
    return this.#image;
  }
}

export default ChartHandler;