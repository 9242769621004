import React, { useContext, useEffect, useState } from "react";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import s from "./StaffSignup.module.scss";
import { useParams } from "react-router-dom";
import { HELP_EMAIL, ROLES } from "../../utils/consts";
import { DashAPIRoute } from "../../utils/apis";
import { UseFormReturn, useForm } from "react-hook-form";
import useStaffSignupInfo, { AffilInfo, StaffSignupInfo } from "../../hooks/useStaffSignupInfo";
import { AppError, tempSetProviderLogoEffect } from "../../utils/utils";
import { Provider } from "../../utils/data-classes/Provider";
import { LoginContext, LoginMethod } from "../../contexts/LoginContext/LoginContext";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";
import _difference from "lodash/difference";
import ClinicianLinkMenu from "../../components/menus/ClinicianLinkMenu/ClinicianLinkMenu";
import IFTAInput from "../register/IFTAElements/IFTAInput/IFTAInput";
import { validationRegEx } from "../../utils/FormValidation";
import IFTASelect from "../register/IFTAElements/IFTASelect/IFTASelect";
import LabelledCheckbox from "../../components/inputs/LabelledCheckbox/LabelledCheckbox";
import AffiliateContract from "../../components/affiliate-contract/AffiliateContract";
import useSubmitReducer from "../../hooks/useSubmitReducer";

const MIN_PASSWORD_LENGTH = 6;

const NO_SALUTATION = "No Salutation";
const SALUTATIONS = [
  NO_SALUTATION,
  "Dr",
  "Prof",
  "Mr",
  "Ms",
];

interface StaffSignupFormFieldValues {
  salutation: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  stfids: number[];
  contractConsent: boolean;
}

const StaffSignup = () => {
  const { token }: { token: string } = useParams();
  const { dispatch: loginDispatch } = useContext(LoginContext);
  const { staffSignupInfo, error: staffSignupInfoError } = useStaffSignupInfo(token);

  const form = useForm<StaffSignupFormFieldValues,any>({
    defaultValues: {
      salutation: "",
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      stfids: [],
      contractConsent: false,
    }
  });

  const [fetched, setFetched] = useState(false);
  const [{processing,complete,error},formDispatch] = useSubmitReducer(form);

  const { register, formState: { errors }, handleSubmit, watch, setValue } = form;

  useEffect(() => {
    if (fetched) return;
    if (staffSignupInfoError) {
      formDispatch({type: "ERROR", payload: staffSignupInfoError});
      setFetched(true);
      return;
    }
    if (!staffSignupInfo) return;
    const { role, clinicians, provider } = staffSignupInfo;
    setFetched(true);
    if (role.roleId === ROLES.ADMIN && clinicians.length === 0) {
      formDispatch({type: "ERROR", payload: new Clinicians404Error(provider)});
    }
  },[staffSignupInfo]);

  // Set header logo if prospective staff's provider has one
  useEffect(tempSetProviderLogoEffect(loginDispatch,staffSignupInfo?.provider),[staffSignupInfo?.provider]);

  const email = watch("email");
  useEffect(() => {
    setValue("email",email.toLowerCase());
  },[email]);

  if (!fetched || !staffSignupInfo || !token) return <></>
  if (complete) return <Success />
  if (error) return <Error error={error} />

  const onSubmit = async (data: StaffSignupFormFieldValues) => {
    formDispatch({type: "PROCESSING"});
    addStaff(token,staffSignupInfo,data)
      .then(() => formDispatch({type: "COMPLETE"}))
      .catch(err => formDispatch({type: "ERROR", payload: err}));
  }

  const { affiliateInfo, clinicians, provider, role, authMethod } = staffSignupInfo;

  return (
    <PreloginLayout>
      <form className={s.register} onSubmit={handleSubmit(onSubmit)}>
        <div className={s.intro}>
          <h1>Clinical Dashboard Signup</h1>
          <p>Please fill out the following information to complete your sign up.</p>
          <div>
            <div>
              <strong>Provider:</strong> <span>{provider.emailLabel} Provider</span>
            </div>
            <div>
              <strong>Role:</strong> <span>{role.label}</span>
            </div>
          </div>
        </div>
        <div className={s.inputs}>
          {
            (role.roleId === ROLES.ADMIN) && (
              <>
                <ClinicianLinkMenu id="link-clinicians" label="Associated clinicians" form={form} path="stfids" clinicians={clinicians} rules={{
                  required: "Associated clinician is required",
                  validate: {
                    nonEmpty: (val) => {
                      return val instanceof Array && val.length > 0 || "Associated clinician is required";
                    },
                  }
                }}/>
                <FormErrorMessage errors={errors} name="stfids" />
              </>
            )
          }
          <IFTASelect id="salutation" label="Salutation" reg={register("salutation",{required: "Salutation is required"})}>
            <option value="">Select Salutation</option>
            { SALUTATIONS.map((salutation,i) => <option key={i} value={salutation}>{salutation}</option>)}
          </IFTASelect>
          <FormErrorMessage errors={errors} name="salutation" />
          <IFTAInput id="name" label="First Name" reg={register("name", {required: "First name is required"})} />
          <FormErrorMessage errors={errors} name="name" />
          <IFTAInput id="surname" label="Surname" reg={register("surname", {required: "Surname is required"})} />
          <FormErrorMessage errors={errors} name="surname" />
          <IFTAInput type="email" id="email" label="Email address" reg={register("email",{
            required: "Email address is required",
            pattern: {
              value: validationRegEx.email,
              message: "Invalid email address",
            }
          })} />
          <FormErrorMessage errors={errors} name="email" />
          { authMethod === "PASSWORD" && <PasswordInputs form={form} /> }
          {
            (role.roleId === ROLES.AFFILIATE && affiliateInfo) && (<AffiliateFields form={form} affiliateInfo={affiliateInfo} provider={provider} />)
          }
          <button disabled={!fetched || processing}>
            {processing ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </PreloginLayout>
  );
}

const PasswordInputs = ({form}: {form: UseFormReturn<StaffSignupFormFieldValues,any>}) => {
  const { register, formState: { errors }, getValues } = form;
  return (
    <>
      <IFTAInput type="password" id="password" label="Password" reg={register("password", {
        required: "Password is required",
        minLength: {
          value: MIN_PASSWORD_LENGTH,
          message: `Password must be at least ${MIN_PASSWORD_LENGTH} characters`,
        }
      })} />
      <FormErrorMessage errors={errors} name="password" />
      <IFTAInput type="password" id="passwordConfirmation" label="Confirm password" reg={register("passwordConfirmation", {
        required: "Password is required",
        minLength: {
          value: MIN_PASSWORD_LENGTH,
          message: `Password must be at least ${MIN_PASSWORD_LENGTH} characters`,
        },
        validate: (value) => value === getValues("password") || "Password and password confirmation must match",
      })} />
      <FormErrorMessage errors={errors} name="passwordConfirmation" />
    </>
  );
}

const AffiliateFields = (
  { form, affiliateInfo, provider }: {affiliateInfo: AffilInfo, form: UseFormReturn<StaffSignupFormFieldValues, any>, provider: Provider }
) => {
  const { formState: { errors } } = form;
  return (
    <div>
      <AffiliateDetail label="Discount" val={affiliateInfo.discount} />
      <AffiliateDetail label="Commission" val={affiliateInfo.commission} />
      <div><AffiliateContractSign form={form} provider={provider} commission={affiliateInfo.commission} /></div>
      <div className={s.registerCheckboxes}>
        <LabelledCheckbox form={form} name="contractConsent" rules={{ required: "You must agree to the Affiliate Agreement to proceed" }}>
          I agree to the above Affiliate Contract
        </LabelledCheckbox>
        <FormErrorMessage errors={errors} name="contractConsent" />
      </div>
    </div>
  );
};

const AffiliateDetail = ({label, val}: {label: string, val: number}) => {
  if (val * 100 === 0) return <></>;
  return (
    <div>
      <strong>{label}:</strong>
      <span> {val * 100}%</span>
    </div>
  )
};

const AffiliateContractSign = ({form, provider, commission}: {form: UseFormReturn<StaffSignupFormFieldValues>, provider: Provider, commission: number}) => {
  const name = form.watch("name");
  const lastName = form.watch("surname");
  const affiliateEmail = form.watch("email");
  return (
      <div className={s.contractWrapper}>
        <AffiliateContract provider={provider} fullName={name + " " + lastName} affiliateEmail={affiliateEmail} commission={commission}/>
      </div>
  )
}

const Success = () => (
  <PreloginLayout>
    <div className={s.register}>
      <div className={s.intro}>
        <h1>Success</h1>
        <p>You have successfully registered for the FoodMarble Clinical Dashboard</p>
      </div>
    </div>
  </PreloginLayout>
)

/**
 * @param {Object} props
 * @param {AppError|Clinicians404Error} props.error
 */
const Error = ({error}: {error: AppError}) => {
  const message = error instanceof Clinicians404Error ? error.toJSX() : <p>{error.message}</p> 
  return (
    <PreloginLayout>
      <div className={s.register}>
        <div className={s.intro}>
          <h1>ERROR {error.code}</h1>
          {message}
        </div>
      </div>
    </PreloginLayout>
  )
}

class Clinicians404Error extends AppError {
  readonly provider: Provider;
  constructor(provider: Provider) {
    super(404,"Clinicians not found");
    this.provider = provider;
  }

  toJSX() { 
    return (
      <>
        <p>No clinician accounts were found for {this.provider.label}, and so an admin account cannot be created.</p>
        <p>If you believe this to be in error please contact <a href={`mailTo: ${HELP_EMAIL}`}><b>{HELP_EMAIL}</b></a>.</p>
      </>
    );
  }
}

interface AddStaffBodyBase {
  token: string;
  salutation: string | undefined;
  name: string;
  surname: string;
  email: string;
  stfids: number[] | undefined;
}

interface AddStaffBodyPassword extends AddStaffBodyBase {
  authMethod: "PASSWORD";
  password: string;
}

interface AddStaffBodyMagicLink extends AddStaffBodyBase {
  authMethod: "MAGIC LINK";
}

type AddStaffBody = AddStaffBodyMagicLink | AddStaffBodyPassword;

const createBody = (token: string, staffSignupInfo: StaffSignupInfo, formData: StaffSignupFormFieldValues): AddStaffBody => {
  const {salutation,name,surname,email,password,stfids} = formData;
  const { authMethod, role } = staffSignupInfo;
  const result: AddStaffBodyBase = {
    token,
    salutation: salutation === NO_SALUTATION ? undefined : salutation,
    name,
    surname,
    email,
    stfids: role.roleId === ROLES.ADMIN ? stfids : undefined,
  };
  switch (authMethod) {
    case "MAGIC LINK":
      return {
        ...result,
        authMethod: "MAGIC LINK",
      };
    case "PASSWORD":
      if (!password) throw new AppError(400,"Password not defined");
      return {
        ...result,
        authMethod: "PASSWORD",
        password,
      };
    case "SAML":
      throw new AppError(400,"SAML Sign-up not implemented");
    default:
      throw new AppError(400,`Invalid auth method "${authMethod}"`);
  }
}

const addStaff = async (token: string, staffSignupInfo: StaffSignupInfo,formData: StaffSignupFormFieldValues) => {
  const body = createBody(token,staffSignupInfo,formData);
  const res = await fetch(DashAPIRoute.ADD_STAFF.path,{
    method: "POST",
    body: JSON.stringify(body),
  });
  const { error } = await res.json();
  if (error) throw new AppError(res.status,error);
  return;
}

export default StaffSignup;

