import { useContext } from "react";
import CurrencyContext from "./CurrencyContext";
import { Currency } from "../OrderContext/OrderContext";

function displayCurrency(val: number, currency: Currency) {
  return Intl.NumberFormat("en-US", { style: "currency", currency: currency.name }).format(val);
}

export default function useCurrency(val: number) {
  const currency = useContext(CurrencyContext);
  return displayCurrency(val, currency);
}
