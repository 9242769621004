import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../../../contexts/LoginContext/LoginContext";
import { Sku } from "../../../../hooks/useSkus";
import { AppError, authFetch } from "../../../../utils/utils";
import useErrorState from "../../../../hooks/useErrorState";

export default function useIndividualOrderOptions(): UseIndividualOrderOptionsReturn {
  const [options, setOptions] = useState<IndividualOrderOptions>();
  const [error, setError] = useErrorState();
  
  const { state: { loginToken } } = useContext(LoginContext);
  
  useEffect(() => {
    if (!loginToken || options || error) return;
    const abortController = new AbortController();
    fetchIndividualOrderOptions(loginToken,abortController.signal)
      .then(setOptions)
      .catch(setError);
    return () => abortController.abort();
  },[loginToken,options,error]);
  
  return { options, error };
}

const fetchIndividualOrderOptions = async (loginToken: string,signal: AbortSignal): Promise<IndividualOrderOptions> => {
  const res = await authFetch(loginToken,"/api/get-individual-order-options",{signal});
  const { options, error }: FetchIndividualOrderOptionsJSON = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return options;
}

interface Combos {
  device: Sku;
  fodmaps: Sku;
  glucose: Sku;
  fodmapsGlucose: Sku;
}

export interface IndividualOrderOptions {
  hydrogenMethane: Combos;
}

interface UseIndividualOrderOptionsReturn {
  options: IndividualOrderOptions|undefined;
  error: AppError|undefined;
}

type FetchIndividualOrderOptionsJSON = 
  | { options: IndividualOrderOptions, error: undefined }
  | { options: undefined, error: string }