import React, { useContext, useState } from "react";
import { UseFormReturn, useForm,  } from "react-hook-form";
import { RegistrationContext, RegistrationType } from "../../contexts/RegisterContext/RegisterContext";
import s from "./Register.module.scss";
import FormErrorMessage from "../../components/errors/FormErrorMessage/FormErrorMessage";
import PrivacyPolicy from "../../components/privacy-policy/PrivacyPolicy";
import { AppError } from "../../utils/utils";
import { APIRequest, DashAPIRoute } from "../../utils/apis";
import { REG_TYPES } from "../../utils/consts";
import LabelledCheckbox from "../../components/inputs/LabelledCheckbox/LabelledCheckbox";
import { Patient } from "../../utils/data-classes/Patient";
import useTranslation from "../../hooks/useTranslation";
import loadingPng from "../../assets/loading.png";
import IFTAInput from "./IFTAElements/IFTAInput/IFTAInput";
import useOrderPriceTotal from "./useOrderPriceTotal";

interface RegistrationFormFieldValues {
  password: string,
  passwordConfirmation: string,
  clinicalPrivacyPolicyConsent: boolean, // Renamed from "privacyConsent" because Brave browser was hiding elements with that ID
  smsConsent: boolean,
  paymentConsent: boolean,
}

const MIN_PASSWORD_LENGTH = 6;

const RegisterForm = () => {
  const { state, dispatch } = useContext(RegistrationContext);

  const { t }  = useTranslation();

  const form = useForm<RegistrationFormFieldValues>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
      clinicalPrivacyPolicyConsent: false,
      smsConsent: false,
      paymentConsent: undefined,
    }
  })

  const { handleSubmit, formState: { errors }, getValues, register } = form;

  const onSubmit = (type: RegistrationType,patient: Patient) => (data: RegistrationFormFieldValues) => {
    dispatch({type: "SET_PROCESSING", payload: true});
    registerPatient(data,type,patient.code)
      .then(res => dispatch({type: "SET_IS_REGISTERED", payload: true }))
      .catch((error: Error|AppError) => dispatch({
        type: "SET_ERROR", 
        payload: error instanceof AppError ? error : new AppError(400,error.message)
      }));
  }

  if (state.isRegistered || !state.type || !state.patient) return <></>;

  const { email } = state.patient;

  return (
    <form onSubmit={handleSubmit(onSubmit(state.type,state.patient))}>
      {state.type === "new" && (
        <div>
          <div className={s.desc}>
            <h3>{t("register.desc.title")}</h3>
            <p>{t("register.desc.content",{email})}</p>
          </div>
          <IFTAInput id="password" label={t("forms.label.password")} type="password" reg={register("password",{
            required: t("forms.errors.required.password") as string,
            minLength: MIN_PASSWORD_LENGTH,
          })} />
          <IFTAInput id="passwordConfirmation" label={t("forms.label.passwordConfirmation")} type="password" reg={register("passwordConfirmation",{
            required: t("forms.errors.required.passwordConfirmation") as string,
            minLength: MIN_PASSWORD_LENGTH,
            validate: (val) => val === getValues("password") || t("forms.errors.password.match"),
          })} />
          <p className={s.passwordRules}><em>{t("register.passwordRules")}</em></p>
        </div>
      )}
      <div className={s.policyWrapper}><PrivacyPolicy /></div>
      <Checkboxes form={form} />
      <RegisterSubmitButton processing={state.processing}>{t("buttons.submit")}</RegisterSubmitButton>
    </form>
  );
}

interface SubmitButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>,"type"> {
  processing: boolean,
}

export const RegisterSubmitButton = ({disabled,processing,...rest}: SubmitButtonProps) => {
  return (
    <div className={s.submitBtnContainer}>
      { processing && <img src={loadingPng} /> }
      { !processing && <button type="submit" disabled={disabled} {...rest} /> }
    </div>
  )
}

const registerPatient = async (data: RegistrationFormFieldValues, registrationType: RegistrationType, patientCode: string): Promise<number> => {
  const { password, clinicalPrivacyPolicyConsent: privacyConsent, paymentConsent, smsConsent } = data;   
  const patientBody = { registrationType, REG_TYPES, patientCode, password, privacyConsent, paymentConsent, smsConsent };

  const { ptid, error: patientError } = await new APIRequest(DashAPIRoute.ACTIVATE_PATIENT).post(patientBody);
  if (patientError) throw patientError;
  return ptid;
}

const Checkboxes = ({form}: {form: UseFormReturn<RegistrationFormFieldValues>}) => {
  const { formState: { errors } } = form;
  const { t }  = useTranslation();
  const { state: { requiresConsentForm } } = useContext(RegistrationContext);
  const orderPriceTotal = useOrderPriceTotal();
  return (
    <div className={s.registerCheckboxes}>
      <LabelledCheckbox form={form} name="clinicalPrivacyPolicyConsent" rules={{required: t("forms.errors.required.privacyConsent")}}>
        {t("register.privacyConsentLabel")}
      </LabelledCheckbox>
      <FormErrorMessage errors={errors} name="clinicalPrivacyPolicyConsent" />
      <LabelledCheckbox form={form} name="smsConsent" rules={{required: t("forms.errors.required.smsConsent")}}>
        {t("register.smsConsentLabel")}
      </LabelledCheckbox>
      <FormErrorMessage errors={errors} name="smsConsent" />
      {requiresConsentForm && <LabelledCheckbox form={form} name="paymentConsent" rules={{required: t("forms.errors.required.paymentConsent")}}>
        {t("register.paymentConsentLabel",{orderPriceTotal})}
      </LabelledCheckbox>}
      <FormErrorMessage errors={errors} name="paymentConsent" />
    </div>
  )
}

export default RegisterForm;