import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../contexts/LoginContext/LoginContext";
import { UseDataReturn } from "./useData";
import useErrorState from "./useErrorState";

/**
 * Hook for managing simple API calls with error handling, where auth required
 */
export default function useDataAuth<T>(fetcher: (loginToken: string, signal: AbortSignal) => Promise<T>): UseDataReturn<T> {
  const [data, setData] = useState<T>();
  const [error,setError] = useErrorState();

  const { state: { loginToken } } = useContext(LoginContext);

  useEffect(() => {
    if (!loginToken || data || error) return;
    const abortController = new AbortController();
    fetcher(loginToken,abortController.signal).then(setData).catch(setError);
    return () => abortController.abort();
  },[loginToken,data,error]);

  return { data, error }
}