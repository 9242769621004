import React, { useContext, useReducer } from "react";
import s from "./SiteOverview.module.scss";
import { SiteOverviewContext } from "./SiteOverviewContext";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import TooltipButton from "../../components/buttons/TooltipButton/TooltipButton";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { ReactComponent as StopIcon } from "../../assets/icons/stop.svg";
import Modal from "../../components/dialogs/Modal/Modal";
import DialogNav from "../../components/dialogs/DialogNav/DialogNav";
import { AppError, authFetch } from "../../utils/utils";
import { createRPMProgram, RPMProgram } from "./RPMProgram";
import { Patient } from "../../utils/data-classes/Patient";
import { createErrorToDisplay, DEFAULT_ERROR, devErrorLog } from "../../hooks/useErrorState";

export default function RPMButton(props: RPMButtonProps) {
  const [state, dispatch] = useReducer(reducer, {
    patient: props.patient,
    isModalOpen: false,
    processing: false,
    complete: false,
    error: undefined,
    mode: !props.rpm ? "START" : "STOP",
  });
  return (
    <>
      <RPMTooltipButton state={state} dispatch={dispatch} />
      <RPMModal state={state} dispatch={dispatch} />
    </>
  );
}

function RPMTooltipButton({ state, dispatch }: UseRPMReducerReturn) {
  const { processing, error, mode } = state;
  const width = 28;
  return (
    <TooltipButton
      className={s.rpmButton}
      tooltipMessage={tooltipMessage()}
      title={tooltipMessage()}
      onClick={openModal}
      disabled={processing}
    >
      {mode === "START" && <PlayIcon width={width} height={width} />}
      {mode === "STOP" && <StopIcon width={width} height={width} />}
      <span className={s.rpmButton}>{label()}</span>
    </TooltipButton>
  );

  function openModal(e: React.MouseEvent) {
    e.preventDefault();
    dispatch({ type: "OPEN" });
  }

  function tooltipMessage() {
    if (error) return error.message;
    if (processing) return "Processing...";
    switch (mode) {
      case "START":
        return "Initiate RPM";
      case "STOP":
        return "Stop RPM";
    }
  }

  function label() {
    if (error) return "Error";
    switch (mode) {
      case "START":
        return "Start";
      case "STOP":
        return "Stop";
    }
  }
}

function RPMModal({ state, dispatch }: UseRPMReducerReturn) {
  const { patient, isModalOpen, processing, complete, error, mode } = state;
  const { loginToken } = useContext(LoginContext).state;
  const { dispatch: siteOverviewDispatch } = useContext(SiteOverviewContext);
  return (
    <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
      <div className={s.rpmModal} onClick={(e) => e.preventDefault()}>
        <DialogNav onCloseButtonClick={closeModal} />
        <h1>{title()}</h1>
        <ModalDescription />
        {!complete && !error && (
          <div className={s.btnGroup}>
            <button type="button" onClick={closeModal} className={s.btnPrimary}>
              No
            </button>
            <button type="button" onClick={submit} className={s.btnPrimary} disabled={processing}>
              {processing ? "Processing..." : "Yes"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );

  function closeModal(e: React.MouseEvent) {
    dispatch({ type: "CLOSE" });
  }

  function submit(e: React.MouseEvent) {
    e.preventDefault();
    if (!loginToken) return;
    dispatch({ type: "PROCESSING" });
    toggleRpm(loginToken, mode, patient.ptid)
      .then((rpm) => {
        siteOverviewDispatch({ type: "UPDATE_RPM", payload: { ptid: patient.ptid, rpm } });
        dispatch({ type: "COMPLETE" });
      })
      .catch((err) => dispatch({ type: "ERROR", payload: err }));
  }

  function title() {
    if (complete) return "Success";
    if (error) return `Error ${error.code}`;
    return `${mode === "START" ? "Start" : "Stop"} RPM?`;
  }

  function ModalDescription() {
    if (error) return <p>{error.message}</p>;
    if (mode === "START") {
      if (complete)
        return (
          <>
            <p>The patient has been notified to start their RPM program.</p>
            <p>You can view their data in the day-to-day view of your dashboard.</p>
          </>
        );
      return (
        <>
          <p>Do you want to start a remote monitoring program for patient {patient.name}?</p>
          <p>
            Their app will be switched to RPM mode and they will be notified by SMS and email to start taking daily
            readings.
          </p>
        </>
      );
    }
    if (complete) return <p>The patient has been notified to stop their RPM program.</p>;
    return (
      <>
        <p>Do you want to stop the remote monitoring program for patient {patient.name}?</p>
        <p>They will be notified by SMS and email to stop taking daily readings.</p>
      </>
    );
  }
}

async function toggleRpm(loginToken: string, mode: "START" | "STOP", ptid: number): Promise<RPMProgram | undefined> {
  const method = mode === "START" ? "POST" : "DELETE";
  const res = await authFetch(loginToken, "/api/rpm", {
    method: method,
    body: JSON.stringify({ ptid }),
  });
  const { rpm, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error);
  if (mode === "STOP") return undefined;
  return createRPMProgram(rpm);
}

function reducer(state: RpmButtonState, action: RpmButtonAction): RpmButtonState {
  switch (action.type) {
    case "OPEN":
      return { ...state, isModalOpen: true, processing: false, error: undefined, complete: false };
    case "PROCESSING":
      return { ...state, processing: true };
    case "COMPLETE":
      return { ...state, processing: false, error: undefined, complete: true };
    case "CLOSE":
      return {
        ...state,
        isModalOpen: false,
        error: undefined,
        processing: false,
        complete: false,
        mode: newMode(state),
      };
    case "ERROR":
      const error = action.payload;
      const errorToDisplay = createErrorToDisplay(error, DEFAULT_ERROR);
      if (errorToDisplay) devErrorLog(error, errorToDisplay);
      return {
        ...state,
        error: errorToDisplay,
      };
  }
}

function newMode(state: RpmButtonState): Mode {
  if (!state.complete) return state.mode;
  return state.mode === "START" ? "STOP" : "START";
}

interface RPMButtonProps {
  patient: Patient;
  rpm: RPMProgram | undefined;
}

interface UseRPMReducerReturn {
  state: RpmButtonState;
  dispatch: React.Dispatch<RpmButtonAction>;
}

interface RpmButtonState {
  patient: Patient;
  isModalOpen: boolean;
  processing: boolean;
  complete: boolean;
  error: AppError | undefined;
  mode: Mode;
}

type Mode = "START" | "STOP";

type RpmButtonAction =
  | { type: "OPEN" }
  | { type: "PROCESSING" }
  | { type: "COMPLETE" }
  | { type: "CLOSE" }
  | { type: "ERROR"; payload: AppError };
