import s from "./PrivacyPolicy.module.scss";
import useTranslation from "../../hooks/useTranslation";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className={s.policy}>
      <h1>{t("privacy.title")}</h1>
      <em>{t("privacy.effectiveFrom")}</em>
      <br />
      <br />
      <h2>{t("privacy.whatWeCollect.title")}</h2>
      <h3>{t("privacy.whatWeCollect.subtitle")}</h3>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.whatWeCollect.general.title")}</span>
        {t("privacy.whatWeCollect.general.content")}
      </p>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.whatWeCollect.health.title")}</span>
        {t("privacy.whatWeCollect.health.content")}
      </p>
      <ul>
        <li>{t("privacy.whatWeCollect.health.list.0")}</li>
        <li>{t("privacy.whatWeCollect.health.list.1")}</li>
        <li>{t("privacy.whatWeCollect.health.list.2")}</li>
        <li>{t("privacy.whatWeCollect.health.list.3")}</li>
        <li>{t("privacy.whatWeCollect.health.list.4")}</li>
        <li>{t("privacy.whatWeCollect.health.list.5")}</li>
        <li>{t("privacy.whatWeCollect.health.list.6")}</li>
        <li>{t("privacy.whatWeCollect.health.list.7")}</li>
      </ul>
      <p>{t("privacy.whatWeCollect.health.additional")}</p>
      <p>{t("privacy.whatWeCollect.health.gdpr")}</p>
      <h3>{t("privacy.whatWeCollect.automaticData.title")}</h3>
      <p>{t("privacy.whatWeCollect.automaticData.content")}</p>
      <h4>{t("privacy.whatWeCollect.automaticData.deviceInfo.title")}</h4>
      <ul>
        <li>{t("privacy.whatWeCollect.automaticData.deviceInfo.list.0")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.deviceInfo.list.1")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.deviceInfo.list.2")}</li>
      </ul>
      <h4>{t("privacy.whatWeCollect.automaticData.locationInfo.title")}</h4>
      <ul>
        <li>{t("privacy.whatWeCollect.automaticData.locationInfo.list.0")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.locationInfo.list.1")}</li>
      </ul>
      <h4><li>{t("privacy.whatWeCollect.automaticData.appUsage.title")}</li></h4>
      <ul>
        <li>{t("privacy.whatWeCollect.automaticData.appUsage.list.0")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.appUsage.list.1")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.appUsage.list.2")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.appUsage.list.3")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.appUsage.list.4")}</li>
      </ul>
      <h4>{t("privacy.whatWeCollect.automaticData.companies.title")}</h4>
      <ul>
        <li>{t("privacy.whatWeCollect.automaticData.companies.list.0")}</li>
        <li>{t("privacy.whatWeCollect.automaticData.companies.list.1")}</li>
      </ul>
      <h2>{t("privacy.whyWeCollect.title")}</h2>
      <p>{t("privacy.whyWeCollect.content")}</p>
      <ul>
        <li>{t("privacy.whyWeCollect.list.0")}</li>
        <li>{t("privacy.whyWeCollect.list.1")}</li>
        <li>{t("privacy.whyWeCollect.list.2")}</li>
        <li>{t("privacy.whyWeCollect.list.3")}</li>
        <li>{t("privacy.whyWeCollect.list.4")}</li>
        <li>{t("privacy.whyWeCollect.list.5")}</li>
        <li>{t("privacy.whyWeCollect.list.6")}</li>
        <li>{t("privacy.whyWeCollect.list.7")}</li>
        <li>{t("privacy.whyWeCollect.list.8")}</li>
      </ul>
      <h2>{t("privacy.principlesOfProcessing.title")}</h2>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.principlesOfProcessing.minimisation.title")}</span>
        {t("privacy.principlesOfProcessing.minimisation.content")}
      </p>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.principlesOfProcessing.noSale.title")}</span>
        {t("privacy.principlesOfProcessing.noSale.content")}
      </p>
      <h2>{t("privacy.aggregatedInfo.title")}</h2>
      <p>{t("privacy.aggregatedInfo.content")}</p>
      <h2>{t("privacy.specialCircumstances.title")}</h2>
      <p>{t("privacy.specialCircumstances.content")}</p>
      <ul>
        <li>{t("privacy.specialCircumstances.list.0")}</li>
        <li>{t("privacy.specialCircumstances.list.1")}</li>
        <li>{t("privacy.specialCircumstances.list.2")}</li>
        <li>{t("privacy.specialCircumstances.list.3")}</li>
      </ul>
      <h2>{t("privacy.dataRetention.title")}</h2>
      <h3>{t("privacy.dataRetention.whenYouUse.title")}</h3>
      <p>{t("privacy.dataRetention.whenYouUse.content")}</p>
      <h3>{t("privacy.dataRetention.afterUse.title")}</h3>
      <p>{t("privacy.dataRetention.afterUse.onDelete")}</p>
      <p>{t("privacy.dataRetention.afterUse.retention")}</p>
      <p>{t("privacy.dataRetention.afterUse.backups")}</p>
      <h2>{t("privacy.security.title")}</h2>
      <p>{t("privacy.security.content")}</p>
      <h2>{t("privacy.dataRemoval.title")}</h2>
      <p>{t("privacy.dataRemoval.content")}</p>
      <h2>{t("privacy.dataRetrieval.title")}</h2>
      <p>{t("privacy.dataRetrieval.content")}</p>
      <h2>{t("privacy.contactDetails.title")}</h2>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.contactDetails.companyName.title")}</span> FoodMarble
        {t("privacy.contactDetails.companyName.content")}
      </p>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.contactDetails.email.title")}</span>
        <a href="healthcare@foodmarble.com">healthcare@foodmarble.com</a>
      </p>
      <p>
        <span className={s.sameLineTitle}>{t("privacy.contactDetails.address.title")}</span> FoodMarble
        {t("privacy.contactDetails.address.content")}
      </p>

      <h2>Document Info</h2>
      <p><span className={s.sameLineTitle}>{t("privacy.documentInfo.createdOn.title")}</span> {t("privacy.documentInfo.createdOn.content")}</p>
      <p><span className={s.sameLineTitle}>{t("privacy.documentInfo.updatedOn.title")}</span> {t("privacy.documentInfo.updatedOn.content")}</p>
      <p><span className={s.sameLineTitle}>{t("privacy.documentInfo.version.title")}</span> {t("privacy.documentInfo.version.content")}</p>
    </div>
  )
}

export default PrivacyPolicy;