import { FieldValues, Path, useFormContext } from "react-hook-form";
import FormErrorMessage from "./FormErrorMessage";

interface FormErrorMessageGenericProps<TFieldValues extends FieldValues,TPath extends Path<TFieldValues>> {
  path: TPath;
}

export default function FormErrorMessageGeneric<TFieldValues extends FieldValues>({path}: FormErrorMessageGenericProps<TFieldValues,Path<TFieldValues>>) {
  const form = useFormContext<TFieldValues>();
  return <FormErrorMessage errors={form.formState.errors} name={path} />
}