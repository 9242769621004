import { AppError, authFetch } from "../../../utils/utils";
import { OrderHistoryItem } from "../../../hooks/useOrderHistory";
import useDataAuth from "../../../hooks/useDataAuth";

export default function usePatientOrderHistory(ptid: number|undefined) {
  const { data, error } = useDataAuth(fetchPatientOrderHistory(ptid));
  return { history: data, error };
}

const fetchPatientOrderHistory = (ptid: number|undefined) => async (loginToken: string, signal: AbortSignal): Promise<OrderHistoryItem[]|undefined> => {
  if (!loginToken || !ptid) return undefined;
  const res = await authFetch(loginToken,`/api/get-order-history?ptid=${ptid}`,{signal});
  const { history, error }: { history: any[], error: undefined} | { history: undefined, error: string} = await res.json();
  if (error !== undefined) throw new AppError(res.status,error);
  return history;
}