import React from "react";
import Dialog from "../Dialog/Dialog";
import DialogBase from "../DialogBase/DialogBase";
import s from "./SuccessDialog.module.scss";

/**
 * @param {Object} props 
 * @param {any} props.isOpen
 * @param {Function} [props.setIsOpen]
 * @param {any} [props.content]
 * @param {String} props.title
 * @param {Function} [props.afterOpen]
 * @param {String} [props.contentLabel]
 * @param {React.ReactNode} [props.children]
 * @param {React.MouseEventHandler} props.onClose
 * @returns 
 */
const SuccessDialog = (props) => {
  return (
    <DialogBase
				appElement={'body'}
				isOpen={!!props.isOpen}
				onClose={props.onClose}
				afterOpen={props.afterOpen}
				contentLabel={props.contentLabel}
		>
			<div className={s.successDialogContent}>
				<h2>{props.title}</h2>
				<div>
					{props.children}
					<button onClick={props.onClose}>Return</button>
				</div>
			</div>
		</DialogBase>
  );
}

export default SuccessDialog;
