import { FieldValues, Path, UseFormRegister, UseFormRegisterReturn, useFormContext } from "react-hook-form";
import IFTASelect from "../../../../register/IFTAElements/IFTASelect/IFTASelect";
import { Sku } from "../../../../../hooks/useSkus";
import { useContext } from "react";
import { RequestOrderContext, RequestOrderFormFieldValuesBase } from "../RequestOrderContext";

export default function SkuSelect() {
  const { register } = useFormContext<RequestOrderFormFieldValuesBase>();
  const { state } = useContext(RequestOrderContext);
  const label = (sku: Sku) => `${sku.sku} - ${sku.shortLabel}`;
  const skus = state.skus.filter(s => state.getPrice(s.skuId,0) !== undefined).sort((s1, s2) => (label(s1) < label(s2) ? -1 : 1));
  return (
    <IFTASelect
      id="select-skus"
      label="Sku"
      defaultValue=""
      disabled={skus.length === 0}
      reg={register("skuId", {
        required: "Sku is required",
        valueAsNumber: true,
      })}
    >
      <option value="">Select Sku</option>
      {skus.map((sku, i) => (
        <option key={i} value={sku.skuId}>
          {skuLabel(sku)}
        </option>
      ))}
    </IFTASelect>
  );
}

function skuLabel(sku: Sku) {
  return `${sku.sku} - ${sku.shortLabel}`;
}
