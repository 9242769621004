import s from "./InterfaceButton.module.scss";
import loadingPng from "../../../assets/loading.png";

/**
 * Button commonly used for major page actions.
 * Can be provided with an SVG icon (which should have height=100%).
 */
export default function InterfaceButton({
  icon,
  className = s.lablledIconButton,
  loading,
  disabled,
  children,
  ...rest
}: InterfaceButtonProps) {
  return (
    <button className={className} disabled={loading || disabled} {...rest}>
      <div>
        {icon && <div>{loading ? <img className={s.loadingIcon} src={loadingPng} /> : icon}</div>}
        {children && <div>{children}</div>}
      </div>
    </button>
  );
}

export type InterfaceButtonProps = {
  icon?: React.ReactNode;
  label?: React.ReactNode;
  loading?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
